import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Invocations, InvocationsReportRequest } from '../../data/report/invocations';
import { ReportingPageTextFieldFilter } from '../reporting/ReportingPageTextFieldFilter';
import { StyledTitleContainer } from './UsersWithWorkspacesReportPage';
import { PageContainer } from '../layout/PageComponents';
import { ReportingPageTable } from '../reporting/ReportingPageTable';

export type ReportingFilters = Omit<InvocationsReportRequest, 'nextToken'>;
type ExecutionStatus = Exclude<InvocationsReportRequest['executionStatuses'], undefined>[number];
type TriggerType = Exclude<InvocationsReportRequest['triggerTypes'], undefined>[number];

export interface InvocationsReportPageProps extends InvocationsReportRequest {
    invocations: Invocations;
    filters: ReportingFilters;
    nextToken?: string;
    isLoading: boolean;
    errors?: string;
    onQueryInvocations(request: InvocationsReportRequest): void;
    onSearchInvocations(request: InvocationsReportRequest): void;
    onSearch(request: ReportingFilters): void;
}

export const InvocationsReportPage: React.FC<InvocationsReportPageProps> = ({
    invocations,
    filters,
    nextToken,
    isLoading,
    errors,
    onQueryInvocations,
    onSearchInvocations,
    onSearch,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [searchFilters, setSearchFilters] = useState(filters);

    const handleSetSearchFilter = (value: string | number | undefined, filter: keyof ReportingFilters): void => {
        setSearchFilters((prev) => ({
            ...prev,
            [filter]: value === undefined || value === '' ? undefined : value,
        }));
    };

    const handleFilterStatusChange = (
        status: ExecutionStatus | TriggerType,
        checked: boolean,
        statusType: 'executionStatuses' | 'triggerTypes'
    ): void => {
        if (checked) {
            setSearchFilters((prev) => ({
                ...prev,
                [statusType]: [...(prev[statusType] ?? []), status],
            }));
        } else {
            setSearchFilters((prev) => ({
                ...prev,
                [statusType]: ((prev[statusType] ?? []) as (ExecutionStatus | TriggerType)[]).filter(
                    (sts) => status !== sts
                ),
            }));
        }
    };

    const handleSearch = (): void => {
        onSearchInvocations(searchFilters);
        onSearch(searchFilters);
    };

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledTitleContainer>
                <Typography variant="h3">Invocations</Typography>
            </StyledTitleContainer>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <ReportingPageTextFieldFilter
                    filterLabel="Invocation Id"
                    filterValue={searchFilters.invocationId ?? ''}
                    filterOnChange={(event) => handleSetSearchFilter(event.target.value, 'invocationId')}
                    comparatorLabel="Invocation Id Comparator"
                    comparatorValue={searchFilters.invocationIdComparator ?? 'equals'}
                    comparatorOnChange={(event) => handleSetSearchFilter(event.target.value, 'invocationIdComparator')}
                />
                <ReportingPageTextFieldFilter
                    filterLabel="Workspace"
                    filterValue={searchFilters.workspace ?? ''}
                    filterOnChange={(event) => handleSetSearchFilter(event.target.value, 'workspace')}
                    comparatorLabel="Workspace Comparator"
                    comparatorValue={searchFilters.workspaceComparator ?? 'equals'}
                    comparatorOnChange={(event) => handleSetSearchFilter(event.target.value, 'workspaceComparator')}
                />
                <ReportingPageTextFieldFilter
                    filterLabel="Workspace Owner"
                    filterValue={searchFilters.workspaceOwner ?? ''}
                    filterOnChange={(event) => handleSetSearchFilter(event.target.value, 'workspaceOwner')}
                    comparatorLabel="Workspace Owner Comparator"
                    comparatorValue={searchFilters.workspaceOwnerComparator ?? 'equals'}
                    comparatorOnChange={(event) =>
                        handleSetSearchFilter(event.target.value, 'workspaceOwnerComparator')
                    }
                />
                <ReportingPageTextFieldFilter
                    filterLabel="Environment"
                    filterValue={searchFilters.environment ?? ''}
                    filterOnChange={(event) => handleSetSearchFilter(event.target.value, 'environment')}
                    comparatorLabel="Environment Comparator"
                    comparatorValue={searchFilters.environmentComparator ?? 'equals'}
                    comparatorOnChange={(event) => handleSetSearchFilter(event.target.value, 'environmentComparator')}
                />
                <ReportingPageTextFieldFilter
                    filterLabel="Script"
                    filterValue={searchFilters.script ?? ''}
                    filterOnChange={(event) => handleSetSearchFilter(event.target.value, 'script')}
                    comparatorLabel="Script Comparator"
                    comparatorValue={searchFilters.scriptComparator ?? 'equals'}
                    comparatorOnChange={(event) => handleSetSearchFilter(event.target.value, 'scriptComparator')}
                />
                <ReportingPageTextFieldFilter
                    filterLabel="Duration"
                    filterType="number"
                    filterValue={searchFilters.duration ?? ''}
                    filterOnChange={(event) =>
                        handleSetSearchFilter(event.target.value === '' ? undefined : +event.target.value, 'duration')
                    }
                    comparatorLabel="Duration Comparator"
                    comparatorValue={searchFilters.durationComparator ?? 'gt'}
                    comparatorOnChange={(event) => handleSetSearchFilter(event.target.value, 'durationComparator')}
                />
                <ReportingPageTextFieldFilter
                    filterLabel="Logs"
                    filterType="number"
                    filterValue={searchFilters.logCount ?? ''}
                    filterOnChange={(event) =>
                        handleSetSearchFilter(event.target.value === '' ? undefined : +event.target.value, 'logCount')
                    }
                    comparatorLabel="Logs Comparator"
                    comparatorValue={searchFilters.logCountComparator ?? 'gt'}
                    comparatorOnChange={(event) => handleSetSearchFilter(event.target.value, 'logCountComparator')}
                />
                <ReportingPageTextFieldFilter
                    filterLabel="HTTP Logs"
                    filterType="number"
                    filterValue={searchFilters.httpLogCount ?? ''}
                    filterOnChange={(event) =>
                        handleSetSearchFilter(
                            event.target.value === '' ? undefined : +event.target.value,
                            'httpLogCount'
                        )
                    }
                    comparatorLabel="HTTP Logs Comparator"
                    comparatorValue={searchFilters.httpLogCountComparator ?? 'gt'}
                    comparatorOnChange={(event) => handleSetSearchFilter(event.target.value, 'httpLogCountComparator')}
                />
                <div>
                    <FormControl size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="From"
                                value={searchFilters.from ? dayjs(searchFilters.from) : null}
                                onChange={(value) =>
                                    handleSetSearchFilter(value?.isValid() ? value.toISOString() : undefined, 'from')
                                }
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="To"
                                value={searchFilters.to ? dayjs(searchFilters.to) : null}
                                onChange={(value) =>
                                    handleSetSearchFilter(value?.isValid() ? value.toISOString() : undefined, 'to')
                                }
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Order By"
                            value={searchFilters.orderByField ?? 'starttime'}
                            onChange={(event) => handleSetSearchFilter(event.target.value, 'orderByField')}
                        >
                            <MenuItem value="starttime">Invocation Start Time</MenuItem>
                            <MenuItem value="workspace">Workspace</MenuItem>
                            <MenuItem value="workspaceOwner">Workspace Owner</MenuItem>
                            <MenuItem value="environment">Environment</MenuItem>
                            <MenuItem value="duration">Duration</MenuItem>
                            <MenuItem value="logs">Logs</MenuItem>
                            <MenuItem value="httpLogs">HTTP Logs</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Order By Direction"
                            value={searchFilters.orderByDirection ?? 'desc'}
                            onChange={(event) => handleSetSearchFilter(event.target.value, 'orderByDirection')}
                        >
                            <MenuItem value="asc">Ascending</MenuItem>
                            <MenuItem value="desc">Descending</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ paddingLeft: 1 }}>
                        <FormLabel component="legend">Execution Status</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.executionStatuses ?? []).includes('FINISHED')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('FINISHED', checked, 'executionStatuses')
                                        }
                                        name="finished"
                                    />
                                }
                                label="Finished"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.executionStatuses ?? []).includes('RUNNING')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('RUNNING', checked, 'executionStatuses')
                                        }
                                        name="running"
                                    />
                                }
                                label="Running"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.executionStatuses ?? []).includes('DENIED')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('DENIED', checked, 'executionStatuses')
                                        }
                                        name="denied"
                                    />
                                }
                                label="Denied"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.executionStatuses ?? []).includes('TIMED_OUT')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('TIMED_OUT', checked, 'executionStatuses')
                                        }
                                        name="timedOut"
                                    />
                                }
                                label="Timed out"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.executionStatuses ?? []).includes('ABORTED')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('ABORTED', checked, 'executionStatuses')
                                        }
                                        name="aborted"
                                    />
                                }
                                label="Aborted"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.executionStatuses ?? []).includes('FUNCTION_ERROR')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('FUNCTION_ERROR', checked, 'executionStatuses')
                                        }
                                        name="functionError"
                                    />
                                }
                                label="Function error"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.executionStatuses ?? []).includes('RUNTIME_ERROR')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('RUNTIME_ERROR', checked, 'executionStatuses')
                                        }
                                        name="runtimeError"
                                    />
                                }
                                label="Runtime error"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ paddingLeft: 1 }}>
                        <FormLabel component="legend">Trigger Type</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.triggerTypes ?? []).includes('MANUAL')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('MANUAL', checked, 'triggerTypes')
                                        }
                                        name="manual"
                                    />
                                }
                                label="Manual"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.triggerTypes ?? []).includes('MANUAL_EVENT_LISTENER')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('MANUAL_EVENT_LISTENER', checked, 'triggerTypes')
                                        }
                                        name="manualEventListener"
                                    />
                                }
                                label="Manual Event Listener"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.triggerTypes ?? []).includes('EXTERNAL')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('EXTERNAL', checked, 'triggerTypes')
                                        }
                                        name="external"
                                    />
                                }
                                label="External"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.triggerTypes ?? []).includes('CHAINED')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('CHAINED', checked, 'triggerTypes')
                                        }
                                        name="chained"
                                    />
                                }
                                label="Chained"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(searchFilters.triggerTypes ?? []).includes('SCHEDULED')}
                                        onChange={(_, checked) =>
                                            handleFilterStatusChange('SCHEDULED', checked, 'triggerTypes')
                                        }
                                        name="scheduled"
                                    />
                                }
                                label="Scheduled"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <Box sx={{ paddingLeft: 2 }}>Invocations Displayed: {invocations.length}</Box>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            <ReportingPageTable
                invocations={invocations}
                filters={filters}
                nextToken={nextToken}
                isLoading={isLoading}
                errors={errors}
                showLogsLinks={false}
                onQueryInvocations={onQueryInvocations}
            />
        </PageContainer>
    );
};

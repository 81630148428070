import { styled } from '@mui/material/styles';
import { AvatarProps } from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { AppMainProps } from './AppMain';
import { AvatarContainer } from '../avatar';
import { DropdownMenu } from '../dropdown/DropdownMenu';
import { useNavigate } from 'react-location';

interface UserToolsProps extends AvatarProps {
    readonly userName?: string;
    readonly userAvatar?: string;
    readonly credentials: AppMainProps['credentials'];
    readonly showOrganizations?: boolean;
    onLogOut?(): void;
}

const StyledDropdownMenu = styled(DropdownMenu)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
    '& .MuiAvatar-root': {
        marginRight: theme.spacing(1),
    },
    '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
    },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
    // bit of magic that aligns this to the right
    marginLeft: theme.spacing(0.5),
}));

// const StyledAvatar = styled(Avatar)(({ theme }) => ({
//     color: theme.palette.primary.main,
//     fontSize: theme.typography.body2.fontSize,
// }));

export const UserTools: React.FC<UserToolsProps> = ({ credentials, showOrganizations, onLogOut }) => {
    const navigate = useNavigate();

    const displayName =
        credentials?.firstName && credentials?.lastName
            ? `${credentials?.firstName} ${credentials?.lastName}`
            : credentials?.email;

    const dropdownItems = [
        {
            label: 'Profile settings',
            name: 'Profile',
            action: () => navigate({ to: 'profile' }),
            startIcon: <ManageAccountsOutlinedIcon />,
        },
        ...(showOrganizations
            ? [
                  {
                      label: 'Billing details',
                      name: 'Billing',
                      action: () => navigate({ to: 'billing' }),
                      startIcon: <ReceiptLongRoundedIcon />,
                  },
              ]
            : []),
        {
            label: 'Log out',
            name: 'Logout',
            action: () => onLogOut?.(),
            startIcon: <LogoutOutlinedIcon />,
        },
    ];
    return (
        <StyledStack direction="row" spacing={1} alignItems="center">
            {/* <Notifications />
            <StyledAvatar>{userAvatar}</StyledAvatar> */}
            <StyledDropdownMenu dropdownItems={dropdownItems} endIcon={<KeyboardArrowDownRoundedIcon />}>
                <AvatarContainer credentials={credentials} />
                {displayName}
            </StyledDropdownMenu>
        </StyledStack>
    );
};

import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../router';
import { selectReadmeFileAction$ } from '../store/workspace/readme';
import {
    getDefaultReadmeFileUid,
    getUnsavedDefaultReadmeFileCached,
    getUnsavedReadmeFileCached,
} from '../store/workspace/utils';
import { loadErrorPage } from '../store/error';
import { getTemplate } from '../data/template';

export const readmeFileDetailsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        const workspaceUid = routeMatch.params.workspaceUid;
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const templateUid = routeMatch.params.templateUid!;
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const readmeFileUid = routeMatch.params.readmeFileUid!;

        const uid = workspaceUid ?? (await getTemplate(templateUid)).workspaceUid;

        if (readmeFileUid !== '/') {
            await getUnsavedReadmeFileCached(readmeFileUid, uid);
            selectReadmeFileAction$.next(readmeFileUid);
        } else {
            const defaultReadmeFileUid = getDefaultReadmeFileUid();
            getUnsavedDefaultReadmeFileCached(defaultReadmeFileUid);
            selectReadmeFileAction$.next(defaultReadmeFileUid);
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load README file.',
        });

        throw e;
    }

    return {};
};

import { styled } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface DotNavigationProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stageEnum: Record<string, any>;
    activeStep: number;
    onSetActiveStep: (step: number) => void;
}

export const StyledStepper = styled(Stepper)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    '& .MuiStep-root': {
        padding: 0,
    },
    '& .MuiStepConnector-root': {
        display: 'none',
    },
    '& .MuiButtonBase-root': {
        margin: 0,
        padding: 0,
    },
    '& .MuiStepLabel-iconContainer': {
        padding: theme.spacing(0, 0.5),
    },
    '& .MuiSvgIcon-root': {
        border: 'none',
        color: theme.palette.divider,
        height: 14,
        width: 14,
    },
    '& .Mui-active .MuiSvgIcon-root': {
        border: 'none',
        color: theme.palette.action.active + ' !important',
    },
    '& .Mui-completed .MuiSvgIcon-root': {
        marginBottom: theme.spacing(-0.5),
    },
}));

export const DotNavigation: React.FC<DotNavigationProps> = ({ stageEnum, activeStep, onSetActiveStep }) => {
    const steps = Object.keys(stageEnum)
        .filter((key) => !isNaN(+key))
        .map((key) => +key);

    return (
        <StyledStepper activeStep={activeStep}>
            {steps.map((step) => {
                return (
                    <Step key={step}>
                        <StepButton
                            icon={<FiberManualRecordIcon />}
                            onClick={() => {
                                onSetActiveStep(step);
                            }}
                            disabled={step >= activeStep}
                        />
                    </Step>
                );
            })}
        </StyledStepper>
    );
};

import { getTemplates as getTemplatesRcp } from '@avst-stitch/repository-lib/lib';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Response as Templates } from '@avst-stitch/repository-lib/lib/rpcs/getTemplates';
import { featureFlagsTopic$ } from '../store/config';
import { repositoryInvoker } from '../utils/repository';
export type { Templates };

export const getTemplates = async (): Promise<Templates> => {
    const templates = await getTemplatesRcp(repositoryInvoker);

    const mondayUser = featureFlagsTopic$.value.mondayUser ?? false;

    return templates.filter(
        (t) =>
            !mondayUser ||
            ((t.incomingApps.length === 0 || JSON.stringify(t.incomingApps) === JSON.stringify([APP.MONDAY.NAME])) &&
                (t.outgoingApps.length === 0 || JSON.stringify(t.outgoingApps) === JSON.stringify([APP.MONDAY.NAME])))
    );
};

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button } from '../../../buttons/Button';
import DialogActions from '@mui/material/DialogActions';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import InputLabel from '@mui/material/InputLabel';
import { Alert } from '../../../alert/Alert';

export interface AtlassianCloudInstanceSelection {
    readonly instances: { id: string; url: string }[];
    readonly saving: boolean; // Show spinner in save button and block it from emitting events
    readonly onSave: (cloudId: string, instanceUrl: string) => void;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly connectionType?: string;
}

export enum AtlassianCloudWizardStage {
    AUTHORIZE_APP,
    SELECT_AUTH_RESOURCE,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    minWidth: 430,
}));

export const AtlassianCloudInstanceSelection: React.FC<AtlassianCloudInstanceSelection> = ({
    instances,
    saving,
    onSave,
    onCancel,
    open,
    connectionType,
}) => {
    const [selectedConnection, setSelectedConnection] = useState('');

    const handleChange = (e: SelectChangeEvent): void => setSelectedConnection(e.target.value);

    const handleSave = (): void => {
        const instance = instances.find((i) => i.id === selectedConnection);
        if (instance) {
            onSave(instance.id, instance.url);
        }
    };

    const getConnectionTypeValue = (type?: string): string => {
        switch (type) {
            case 'Jira Cloud':
            case 'Confluence Cloud':
            case 'Jira Service Management Cloud':
                return type;
            default:
                return 'Your Cloud';
        }
    };

    return (
        <Dialog maxWidth="md" open={open}>
            <DialogTitle variant="h6">Authorize {getConnectionTypeValue(connectionType)} Site</DialogTitle>
            <DialogContent>
                <Stepper alternativeLabel activeStep={AtlassianCloudWizardStage.SELECT_AUTH_RESOURCE}>
                    <Step key={AtlassianCloudWizardStage.AUTHORIZE_APP}>
                        <StepLabel>Authorize App</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel key={AtlassianCloudWizardStage.SELECT_AUTH_RESOURCE}>
                            Select Authorized Resource
                        </StepLabel>
                    </Step>
                </Stepper>
                <Alert severity="info" text="Select the site to confirm authorization." />
                <StyledFormControl fullWidth>
                    <InputLabel id="connection-select-label">Site</InputLabel>
                    <Select
                        label="connection"
                        labelId="connection-select-label"
                        fullWidth
                        displayEmpty
                        value={selectedConnection}
                        onChange={handleChange}
                        renderValue={selectedConnection === '' ? () => '' : undefined}
                        variant="outlined"
                    >
                        {instances &&
                            instances.map((instance, i) => {
                                return (
                                    <MenuItem key={i} value={instance.id}>
                                        {instance.url}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </StyledFormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>
                <Button onClick={handleSave} busy={!!saving}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import { map, Observable } from 'rxjs';
import { getScript, saveBundledScript, saveBundledScriptSourceMap, Script } from '../data/script';
import { savedScriptDetails$, unsavedScriptDetails$ } from '../store/workspace/script';
import { getBundledOutputsFromSelectedWorkspace } from './bundler';
import { ScriptWithUid } from './types';
import { currentTypeDeclarationsLoaded$, totalTypeDeclarationsLoaded$ } from '../store/editor/editor';
import { wrapAsync } from './react';

export const getUnsavedScriptCached = async (scriptUid: string): Promise<Script> => {
    let script = unsavedScriptDetails$.value[scriptUid];

    if (!script) {
        script = await getScript(scriptUid);

        savedScriptDetails$.next({ ...savedScriptDetails$.value, [scriptUid]: script });
        unsavedScriptDetails$.next({ ...unsavedScriptDetails$.value, [scriptUid]: script });
    }

    return script;
};

export const getUnsavedScriptWithUidCached = async (scriptUid: string): Promise<ScriptWithUid> => {
    const script = await getUnsavedScriptCached(scriptUid);

    return {
        ...script,
        uid: scriptUid,
    };
};

export const saveScriptBundledOutput = async (
    scriptUid: string,
    externallyTriggerableScriptUids: string[],
    manual?: boolean
): Promise<void> => {
    const bundledOutput = await getBundledOutputsFromSelectedWorkspace({
        scriptUid,
        manual,
        externallyTriggerableScriptUids,
    });

    if (bundledOutput) {
        if (bundledOutput.length === 0) {
            throw Error('No bundled outputs found');
        }

        await Promise.all([
            ...bundledOutput
                .filter((output) => output.uid === scriptUid || externallyTriggerableScriptUids.includes(output.uid))
                .map((output) => {
                    if (output.path.endsWith('.js')) {
                        return saveBundledScript(output.uid, output.content, manual);
                    } else if (output.path.endsWith('.js.map')) {
                        return saveBundledScriptSourceMap(output.uid, output.content, manual);
                    }
                }),
        ]);
    }
};

export const bundleScriptWhenEventIsEmitted = (observable: Observable<{ uid: string }>): void => {
    observable
        .pipe(
            map(async ({ uid }) => {
                const bundleScriptIfTypesAreLoaded = async (): Promise<void> => {
                    try {
                        if (currentTypeDeclarationsLoaded$.value === totalTypeDeclarationsLoaded$.value) {
                            await saveScriptBundledOutput(uid, []);
                        } else {
                            setTimeout(wrapAsync(bundleScriptIfTypesAreLoaded), 100);
                        }
                    } catch (e) {
                        console.error(`Failed to try to bundle newly created script: ${uid}`, e);
                    }
                };

                bundleScriptIfTypesAreLoaded();
            })
        )
        .subscribe();
};

export const legacyImportsRegex = /import\s*\{?\s*.*\s*\}?\s*from\s*('|").*stitch-it.*('|")/gm;

import { LoaderFn } from 'react-location';
import { getOrganisations } from '../../data/report/organisations';
import { LocationGenerics } from '../../router';
import { organisations$ } from '../../store/report/organisations';

export const organisationsReportLoader: LoaderFn<LocationGenerics> = async () => {
    const organisations = await getOrganisations();
    organisations$.next(organisations);

    return {};
};

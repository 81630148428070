import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { ConsoleLogTypeIcon } from '../icons/ConsoleLogTypeIcon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { ConsoleHeader } from './ConsoleHeader';

export interface ConsoleProps {
    logs: {
        level: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'SUCCESS';
        message: JSX.Element;
    }[];
    filterByEnvironment: boolean;
    onClear(): void;
    onChangeLiveFeedbackSwitch?(checked: boolean): void;
    onChangeFilterByEnvironment?(checked: boolean): void;
}

const StyledFlexContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
});

const StyledList = styled(List)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
}));

let tooFast: boolean;
let scrolling: NodeJS.Timeout;

export const Console: React.FC<ConsoleProps> = ({
    logs,
    filterByEnvironment,
    onClear,
    onChangeLiveFeedbackSwitch,
    onChangeFilterByEnvironment,
}) => {
    const listRef = useRef<HTMLUListElement>(null);
    const lastElementRef = useRef<HTMLLIElement>(null);
    const afterLastRef = useRef<HTMLDivElement>(null);
    const [logLevels, setLogLevels] = useState([
        { level: 'Debug', checked: false },
        { level: 'Info', checked: false },
        { level: 'Warn', checked: false },
        { level: 'Error', checked: false },
        { level: 'Success', checked: false },
    ]);

    useEffect(() => {
        clearTimeout(scrolling);
        const isBottom =
            listRef.current &&
            lastElementRef.current &&
            listRef.current.scrollHeight -
                listRef.current.scrollTop -
                listRef.current.clientHeight -
                lastElementRef.current.clientHeight <
                (tooFast ? 300 : 100);
        if (lastElementRef.current && listRef.current && afterLastRef.current && isBottom) {
            listRef.current.scrollTo({ top: afterLastRef.current.offsetTop, behavior: tooFast ? 'auto' : 'smooth' });
        }
        tooFast = true;
        scrolling = setTimeout(() => {
            tooFast = false;
        }, 500);
    }, [logs.length]);

    const logLevelsChecked = logLevels.filter((logLevel) => logLevel.checked === true);

    const filteredLogs = logs.filter((log) => {
        return (
            logLevelsChecked.length === 0 ||
            !!logLevelsChecked.find((level) => level.level.toLowerCase() === log.level.toLowerCase())
        );
    });

    return (
        <StyledFlexContainer>
            <ConsoleHeader
                onClearLog={onClear}
                onLiveFeedback={onChangeLiveFeedbackSwitch}
                onFilterByEnvironment={onChangeFilterByEnvironment}
                filterByEnvironment={filterByEnvironment}
                logLevels={logLevels}
                onLogLevelChecked={setLogLevels}
                logLevelsCount={logLevelsChecked?.length}
            />
            <StyledList ref={listRef}>
                {filteredLogs.map((logItem, i) => (
                    <>
                        <StyledListItem ref={lastElementRef} key={i}>
                            <ListItemIcon>
                                <ConsoleLogTypeIcon type={logItem.level} />
                            </ListItemIcon>
                            {logItem.level === 'ERROR' ? <strong>{logItem.message}</strong> : logItem.message}
                        </StyledListItem>
                        <div key={'div' + i} ref={afterLastRef}></div>
                    </>
                ))}
            </StyledList>
        </StyledFlexContainer>
    );
};

// using constants file while we migrate to Figma for design tokens
export const ColorActionActive = 'rgba(0, 0, 0, 0.25)';
export const ColorActionDisabled = 'rgba(0, 0, 0, 0.38)';
export const ColorActionDisabledBackground = '#ebebeb';
export const ColorActionFocus = 'rgba(0, 0, 0, 0.16)';
export const ColorActionHover = '#cccccc';
export const ColorActionHoverOpacity = '#000000';
export const ColorActionSelected = '#eceff5';
export const ColorBackgroundDefault = '#fafbff';
export const ColorBackgroundPaper = '#ffffff';
export const ColorErrorContrastText = '#ffffff';
export const ColorErrorDark = '#c62828';
export const ColorErrorLight = '#ef5350';
export const ColorErrorMain = '#d32f2f';
export const ColorInfoContrastText = '#ffffff';
export const ColorInfoDark = '#01579b';
export const ColorInfoLight = '#03a9f4';
//export const ColorInfoLight = 'rgba(38, 132, 255, 0.16)';
export const ColorInfoMain = '#0288d1';
export const ColorPrimaryContrastText = '#ffffff';
export const ColorPrimaryDark = '#3545ca';
export const ColorPrimaryLight = '#6f83f6';
export const ColorPrimaryMain = '#3c4cea';
export const ColorSecondaryContrastText = '#ffffff';
export const ColorSecondaryDark = '#4a485f';
export const ColorSecondaryLight = '#673AB7';
export const ColorSecondaryMain = '#696788';
export const ColorSuccessContrastText = '#ffffff';
export const ColorSuccessDark = '#1b5e20';
export const ColorSuccessLight = '#4caf50';
export const ColorSuccessMain = '#2e7d32';
export const ColorTextDisabled = 'rgba(0, 0, 0, 0.38)';
export const ColorTextPrimary = 'rgba(0, 0, 0, 0.87)';
export const ColorTextSecondary = 'rgba(0, 0, 0, 0.60)';
export const ColorWarningContrastText = 'rgba(0, 0, 0, 0.87)';
export const ColorWarningDark = '#e65100';
export const ColorWarningLight = '#ff9800';
export const ColorWarningMain = '#ed6c02';
export const FontFamilyBase =
    'Helvetica Neue, -apple-system, BlinkMacSystemFont, Arial, Roboto, Ubuntu, system-ui, sans-serif';
export const FontSizeBase = '14';
export const FontSizeH1 = '30';
export const FontSizeH2 = '24';
export const FontSizeH3 = '20';
export const FontSizeH4 = '16';
export const FontSizeLarge = '26';
export const FontSizeMedium = '16';
export const FontSizeSmall = '12';
export const FontSizeXlarge = '34';
export const FontSizeXxlarge = '36';

import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConnectionType } from '../../data/apps';
import { monitor } from '../monitor';
import { atlassianCloudManagedConnectionOpenDialogAction$ } from './atlassian-cloud/manage-connection';
import { bitbucketCloudManagedConnectionOpenDialogAction$ } from './bitbucket-cloud/setup-connection';
import { genericAppManagedConnectionOpenDialogAction$ } from './generic/setup-connection';
import { githubManagedConnectionOpenDialogAction$ } from './github/authorize-connection';
import { gitlabManageConnectionOpenDialogAction$ } from './gitlab/setup-connection';
import { googleManagedConnectionOpenDialogAction$ } from './google/authorize-connection';
import { atlassianOnPremiseManagedConnectionOpenDialogAction$ } from './atlassian-on-premise/setup-connection';
import { mondayManagedConnectionOpenDialogAction$ } from './monday/setup-connection';
import { serviceNowManagedConnectionOpenDialogAction$ } from './servicenow/setup-connection';
import { slackManagedConnectionOpenDialogAction$ } from './slack/setup-connection';
import { tempoCloudManagedConnectionOpenDialogAction$ } from './tempo-cloud/setup-connection';
import { bitbucketOnPremiseManagedConnectionOpenDialogAction$ } from './bitbucket-on-premise/setup-connection';
import { jsmCloudAssetsManagedConnectionOpenDialogAction$ } from './jsmCloudAssets/setup-connection';
import { microsoftManageConnectionOpenDialogAction$ } from './microsoft/setup-connection';
import { zoomManageConnectionOpenDialogAction$ } from './zoom/setup-connection';
import { hotjarEvent } from '../../data/hotjar-events';
import { salesforceManagedConnectionOpenDialogAction$ } from './salesforce/setup-connection';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { statuspageManagedConnectionOpenDialogAction$ } from './statuspage/setup-connection';
import { trelloManageConnectionOpenDialogAction$ } from './trello/setup-connection';
import { opsgenieManagedConnectionOpenDialogAction$ } from './opsgenie/setup-connection';

export const openConnectionDetailsDialogAction$ = monitor(
    'openConnectionDetailsDialogAction$',
    new Subject<ConnectionDetails>()
);
export const connectionCreatedAction$ = monitor('connectionCreatedAction$', new Subject<string>());

openConnectionDetailsDialogAction$.subscribe((details) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (details.connectionType.name) {
        case APP.JIRA_CLOUD.NAME:
        case APP.CONFLUENCE_CLOUD.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD.NAME:
            atlassianCloudManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.BITBUCKET_CLOUD.NAME:
            bitbucketCloudManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.SLACK.NAME:
            slackManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.SERVICENOW.NAME:
            serviceNowManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.BITBUCKET_ON_PREMISE.NAME:
            bitbucketOnPremiseManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.JIRA_ON_PREMISE.NAME:
        case APP.CONFLUENCE_ON_PREMISE.NAME:
            atlassianOnPremiseManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.GOOGLE_SHEETS.NAME:
        case APP.GOOGLE_CALENDAR.NAME:
            googleManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.GENERIC.NAME:
            genericAppManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.GITHUB.NAME:
            githubManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.MONDAY.NAME:
            mondayManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.GITLAB.NAME:
            gitlabManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.TEMPO_CLOUD.NAME:
            tempoCloudManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD_ASSETS.NAME:
            jsmCloudAssetsManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.MICROSOFT.NAME:
            microsoftManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.ZOOM.NAME:
            zoomManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.SALESFORCE.NAME:
            salesforceManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.STATUSPAGE.NAME:
            statuspageManagedConnectionOpenDialogAction$.next(details);
            break;
        case APP.TRELLO.NAME:
            trelloManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.OPSGENIE.NAME:
            opsgenieManagedConnectionOpenDialogAction$.next(details);
            break;
        default:
            alert('Connector type not implemented');
            break;
    }
    if (details.uid) {
        segmentAnalyticsTrack('Connector EditClicked', {
            connector: {
                uid: details.uid,
                name: details.name,
                connectorType: details.connectionType.name,
            },
        });
    }
});

export type ConnectionDetails = {
    connectionType: ConnectionType;
    uid?: string;
    name?: string;
};

export const connectionCreatedFirstTime$ = monitor('connectionCreatedFirstTime$', new BehaviorSubject<boolean>(false));

export const connectionCreatedFirstTimeAction$ = monitor('connectionCreatedFirstTimeAction$', new Subject<void>());

connectionCreatedFirstTimeAction$.subscribe(() => {
    const event = 'connector-created';
    try {
        hotjarEvent(event);
    } catch (e) {
        console.error(`Error while triggering Hotjar event ${event}, error: ${(e as Error).message}`);
    }
});

import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface ServiceNowConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    clientId?: string;
    clientSecret?: string;
}

interface ServiceNowAppSetupSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export const serviceNowManagedConnectionOpenDialogAction$ = monitor(
    'serviceNowManagedConnectionOpenDialogAction$',
    new Subject<ServiceNowConnectionDetails>()
);
export const serviceNowManageConnectionCloseDialogAction$ = monitor(
    'serviceNowManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const serviceNowAppSetupOpenDialogAction$ = monitor(
    'serviceNowAppSetupOpenDialogAction$',
    new Subject<ServiceNowConnectionDetails>()
);
export const serviceNowAppSetupCloseDialogAction$ = monitor(
    'serviceNowAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const serviceNowAppSetupDialogOpen$ = monitor('serviceNowAppSetupDialogOpen$', new BehaviorSubject(false));
export const serviceNowAppSetupAuthorizeAction$ = monitor(
    'serviceNowAppSetupAuthorizeAction$',
    new Subject<ServiceNowAppSetupSaveProps>()
);
export const serviceNowAppSetupDialogErrors$ = monitor(
    'serviceNowAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const serviceNowSaveConnectionAction$ = monitor('serviceNowSaveConnectionAction$', new Subject<string>());
export const serviceNowConnectionCreatedAction$ = monitor('serviceNowConnectionCreatedAction$', new Subject<string>());
export const serviceNowConnectionSavedAction$ = monitor(
    'serviceNowConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const serviceNowManageConnectionInitiateSetupAction$ = monitor(
    'serviceNowManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const serviceNowManageConnectionDialogOpen$ = monitor(
    'serviceNowManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const serviceNowConnectionSaving$ = monitor('serviceNowConnectionSaving$', new BehaviorSubject(false));
export const serviceNowManageConnectionAuthorizeLoading$ = monitor(
    'serviceNowManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const serviceNowManageConnectionDetails$ = monitor(
    'serviceNowManageConnectionDetails$',
    new BehaviorSubject<ServiceNowConnectionDetails | undefined>(undefined)
);
export const serviceNowManageConnectionDialogErrors$ = monitor(
    'serviceNowManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const serviceNowAppSetupDialogStage$ = monitor('serviceNowAppSetupDialogStage$', new BehaviorSubject(0));

serviceNowAppSetupOpenDialogAction$.subscribe((details) => {
    serviceNowAppSetupDialogErrors$.next(undefined);
    serviceNowManageConnectionDialogErrors$.next(undefined);
    serviceNowAppSetupDialogOpen$.next(true);
    serviceNowAppSetupDialogStage$.next(0);
    serviceNowManageConnectionDetails$.next(details);
});

serviceNowAppSetupCloseDialogAction$.subscribe(() => {
    serviceNowAppSetupDialogOpen$.next(false);
    serviceNowManageConnectionAuthorizeLoading$.next(false);
});

serviceNowManagedConnectionOpenDialogAction$.subscribe((details) => {
    serviceNowAppSetupDialogErrors$.next(undefined);
    serviceNowManageConnectionDialogErrors$.next(undefined);
    serviceNowManageConnectionDialogOpen$.next(true);
    serviceNowManageConnectionDetails$.next(details);
});

serviceNowManageConnectionCloseDialogAction$.subscribe(() => {
    serviceNowManageConnectionDialogOpen$.next(false);
    serviceNowAppSetupDialogOpen$.next(false);
    serviceNowManageConnectionDetails$.next(undefined);
});

serviceNowConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

serviceNowAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            serviceNowConnectionSaving$.next(true);
            serviceNowAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = serviceNowManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('ServiceNow Connector type not defined');
                }
                if (!uid) {
                    throw Error('ServiceNow Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.serviceNow?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No ServiceNow Connector url configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: 'servicenow',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, serviceNowManageConnectionAuthorizeLoading$);

                serviceNowConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    serviceNowAppSetupDialogErrors$.next(e.message);
                } else {
                    serviceNowAppSetupDialogErrors$.next(
                        'Failed to save ServiceNow application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving ServiceNow application info', e);
                }
            }

            serviceNowConnectionSaving$.next(false);
            serviceNowManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

serviceNowSaveConnectionAction$
    .pipe(
        map(async (name) => {
            serviceNowConnectionSaving$.next(true);
            serviceNowManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = serviceNowManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('ServiceNow Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveServiceNowConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            message: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveServiceNowConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                serviceNowConnectionCreatedAction$.next(uid);
                                serviceNowConnectionSavedAction$.next({ uid, connectionTypeUid });
                                serviceNowManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    serviceNowManageConnectionDialogErrors$,
                                    APP.SERVICENOW.NAME
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, serviceNowManageConnectionDialogErrors$, APP.SERVICENOW.NAME);
            }

            serviceNowConnectionSaving$.next(false);
        })
    )
    .subscribe();

serviceNowManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            serviceNowConnectionSaving$.next(true);
            serviceNowManageConnectionAuthorizeLoading$.next(true);
            serviceNowManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = serviceNowManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('ServiceNow Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    serviceNowConnectionSavedAction$.next({ uid, connectionTypeUid });
                    serviceNowAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    serviceNowConnectionCreatedAction$.next(uid);
                    serviceNowConnectionSavedAction$.next({ uid, connectionTypeUid });
                    serviceNowManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    serviceNowConnectionSaving$.next(false);

                    serviceNowAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, serviceNowManageConnectionDialogErrors$, APP.SERVICENOW.NAME);
                serviceNowManageConnectionAuthorizeLoading$.next(false);
            }

            serviceNowConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(serviceNowConnectionSavedAction$);

const saveServiceNowConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        serviceNowConnectionSavedAction$.next({ uid, connectionTypeUid });
        serviceNowManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, serviceNowManageConnectionDialogErrors$, APP.SERVICENOW.NAME);
    }
};

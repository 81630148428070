import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { AppCard } from '../app-card';
import { Button } from '../buttons/Button';
import { Dropdown } from '../dropdown/Dropdown';
import { OnboardingWizardStage, OnboardingWizardSteps } from './OnboardingWizardSteps';
import { OpenInNewLink } from '../link/OpenInNewLink';
import { PageGridContainer } from '../layout';
import { SlackIcon } from '../icons/SlackIcon';
import isEmail from 'validator/lib/isEmail';
import { autoFocus, isFocused } from '../../utils/autoFocus';
import { handleKeyUp } from '../../utils/handleKeyUp';
import { SRConnectIcon } from '../icons/SRConnectIcon';

export interface OnboardingWizardProps {
    apps: {
        uid: string;
        type: string;
        name: string;
    }[];
    defaultOrganization: {
        uid: string;
        name: string;
    };
    documentationUrl: string;
    open?: boolean;
    roles: {
        uid: string;
        label: string;
    }[];
    saving?: boolean;
    scriptingFamiliarityOptions: {
        uid: string;
        label: string;
    }[];
    sharingPreferences: {
        uid: string;
        label: string;
    }[];
    slackCommunityUrl: string;
    supportPortalUrl: string;
    user: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    // youtubeChannelUrl: string;
    onFinish(event: OnboardingFinishedEvent): void;
}

export interface OnboardingResponses {
    selectedRole: string; // Uid
    selectedSharingPreference: string; // Uid
    selectedScriptingFamiliarity: string; // Uid
    optinResearch: boolean;
    selectedApps: string[]; // Uids
    selectedCustomRole: string;
    personalization: {
        integrations: boolean;
        sync: boolean;
        migrations: boolean;
    };
}

export interface OnboardingFinishedEvent extends OnboardingResponses {
    emails: string[];
    organization: {
        uid: string;
        name: string;
    };
    ignoreUpdateOrganization: boolean;
}

const StyledUserDetails = styled('div')(({ theme }) => ({
    left: 0,
    padding: theme.spacing(3),
    position: 'absolute',
    top: 0,
}));

const StyledContentContainer = styled('div')(({ theme }) => ({
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '90%',
    maxWidth: 1200,
    ...theme.typography.flexAlignCenter,
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(2, 0, 4),
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
}));

const StyledQuestion = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 0),
}));

const StyledSupportItem = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 0),
    '&:not(:last-of-type)': {
        borderBottom: `1px ${theme.palette.divider} solid`,
    },
}));

const StyledActionContainer = styled('div')(({ theme }) => ({
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    width: 200,
    ...theme.typography.flexAlignCenter,
}));

const StyledBoxContainer = styled(StyledContent)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.constants.boxShadow,
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
}));

const StyledBox = styled('div')(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(3),
    width: 350,
    '&:not(:last-of-type)': {
        borderRight: `1px ${theme.palette.divider} solid`,
    },
    '&>.MuiSvgIcon-root, img': {
        height: 24,
        marginBottom: theme.spacing(2),
        width: 24,
    },
}));

const StyledBoxDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: 35,
    marginBottom: theme.spacing(3),
}));

const StyledFooter = styled('div')(() => ({
    bottom: 0,
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
}));

const inputStyles = {
    '& .MuiMenuItem-root': {
        maxWidth: '100%',
    },
    '& .MuiInputBase-root': {
        width: 500,
        maxWidth: '100%',
    },
    '& .MuiSelect-select': {
        maxWidth: '100% !important',
    },
    '&+.MuiMenu-root': {
        '& .MuiButtonBase-root': {
            maxWidth: '100%',
        },
    },
};

export const OnboardingWizard: React.FC<OnboardingWizardProps> = ({
    apps,
    defaultOrganization,
    documentationUrl,
    open = false,
    roles,
    saving = false,
    scriptingFamiliarityOptions,
    sharingPreferences,
    slackCommunityUrl,
    supportPortalUrl,
    user,
    // youtubeChannelUrl,
    onFinish,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [stage, setStage] = useState<OnboardingWizardStage>(OnboardingWizardStage.GET_STARTED);
    const [currentRole, setCurrentRole] = useState('');
    const [customRole, setCustomRole] = useState('');
    const [currentPreference, setCurrentPreference] = useState('');
    const [currentFamiliarity, setCurrentFamiliarity] = useState('');
    const [receiveFeedbackSessions, setReceiveFeedbackSessions] = useState(true);
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const [integrations, setIntegrations] = useState(true);
    const [sync, setSync] = useState(true);
    const [migrations, setMigrations] = useState(true);
    const [organizationName, setOrganizationName] = useState(defaultOrganization.name);
    const [emailsValue, setEmailsValue] = useState('');

    const displayCustomRoleInput = roles.find((r) => r.label === 'Other')?.uid === currentRole;

    useEffect(() => {
        setOrganizationName(defaultOrganization.name);
    }, [open]);

    useEffect(() => {
        if (stage === OnboardingWizardStage.INVITE_TEAMMATES) {
            autoFocus(emailsInputRef);
        }
    }, [stage]);

    useEffect(() => {
        if (displayCustomRoleInput) {
            autoFocus(customRoleInputRef);
        }
    }, [displayCustomRoleInput]);

    const currentEmails = emailsValue
        .split(' ')
        .filter((ce) => ce.length)
        .map((ce) => ce.trim());

    const emailValidationError = currentEmails.some((ce) => !isEmail(ce));

    const handleSelect = (uid: string): void => {
        if (selectedApps.includes(uid)) {
            setSelectedApps(selectedApps.filter((sa) => sa !== uid));
        } else {
            setSelectedApps([...selectedApps, uid]);
        }
    };

    const handleOpenApp = (): void => {
        onFinish({
            optinResearch: receiveFeedbackSessions,
            personalization: { integrations, migrations, sync },
            selectedApps,
            selectedRole: currentRole,
            selectedCustomRole: customRole,
            selectedScriptingFamiliarity: currentFamiliarity,
            selectedSharingPreference: currentPreference,
            emails: currentEmails,
            organization: {
                name: organizationName,
                uid: defaultOrganization.uid,
            },
            ignoreUpdateOrganization: organizationName === defaultOrganization.name,
        });
    };

    const items = apps.map((a) => {
        return (
            <AppCard
                key={a.uid}
                onSelect={handleSelect}
                type={a.type}
                title={a.name}
                uid={a.uid}
                selected={selectedApps.includes(a.uid)}
            />
        );
    });

    const customRoleInputRef = useRef<HTMLInputElement>(null);
    const emailsInputRef = useRef<HTMLInputElement>(null);

    const getStageContent = (): // eslint-disable-next-line sonarjs/cognitive-complexity
    JSX.Element => {
        switch (stage) {
            case OnboardingWizardStage.GET_STARTED:
                return (
                    <Dialog
                        open={open}
                        fullScreen
                        sx={inputStyles}
                        onKeyUp={(event) =>
                            handleKeyUp({ event, enterFn: () => setStage(OnboardingWizardStage.CUSTOMIZE) })
                        }
                    >
                        <StyledContentContainer>
                            <SRConnectIcon sx={{ height: 100, marginBottom: 6, width: 100 }} />
                            <DialogTitle component="h1" variant="h4" mb={2}>
                                Welcome to ScriptRunner Connect
                            </DialogTitle>
                            <StyledSubtitle>
                                The code-centric tool for all your Atlassian-to-third-party integrations.
                            </StyledSubtitle>
                            <DialogActions>
                                <Button onClick={() => setStage(OnboardingWizardStage.CUSTOMIZE)}>Get started</Button>
                            </DialogActions>
                        </StyledContentContainer>
                        <StyledFooter>
                            <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                        </StyledFooter>
                    </Dialog>
                );
            case OnboardingWizardStage.CUSTOMIZE:
                return (
                    <Dialog
                        open={open}
                        fullScreen
                        sx={inputStyles}
                        onKeyUp={(event) =>
                            handleKeyUp({ event, enterFn: () => setStage(OnboardingWizardStage.SUPPORT_AND_COMMUNITY) })
                        }
                    >
                        <StyledUserDetails>
                            <Typography color="text.secondary">Logged in as</Typography>
                            <Typography>{user.email}</Typography>
                        </StyledUserDetails>
                        <StyledContentContainer>
                            <DialogTitle component="h3" variant="h5">
                                Customising your ScriptRunner Connect experience
                            </DialogTitle>
                            <StyledSubtitle>
                                Help us get you started by telling us a bit more about yourself and how you want to use
                                the tool.
                            </StyledSubtitle>
                            <StyledContent>
                                <StyledQuestion>What is your role?</StyledQuestion>
                                <Dropdown
                                    items={roles.map((r) => ({ value: r.uid, name: r.label }))}
                                    label="Select role"
                                    selectedItem={currentRole}
                                    onSelect={(uid) => setCurrentRole(uid)}
                                />
                                {displayCustomRoleInput && (
                                    <TextField
                                        inputRef={customRoleInputRef}
                                        value={customRole}
                                        label="Custom role"
                                        placeholder="Enter role"
                                        onChange={(e) => setCustomRole(e.target.value)}
                                    />
                                )}

                                <StyledQuestion>
                                    Will you need to share your work with colleagues or clients?
                                </StyledQuestion>
                                <Dropdown
                                    items={sharingPreferences.map((sp) => ({ value: sp.uid, name: sp.label }))}
                                    label="Select your sharing preferences"
                                    selectedItem={currentPreference}
                                    onSelect={(uid) => setCurrentPreference(uid)}
                                />
                                <StyledQuestion>How familiar are you with JavaScript?</StyledQuestion>
                                <Dropdown
                                    items={scriptingFamiliarityOptions.map((sf) => ({ value: sf.uid, name: sf.label }))}
                                    label="Select familiarity"
                                    selectedItem={currentFamiliarity}
                                    onSelect={(uid) => setCurrentFamiliarity(uid)}
                                />
                            </StyledContent>
                            <DialogActions>
                                <Button onClick={() => setStage(OnboardingWizardStage.SUPPORT_AND_COMMUNITY)}>
                                    Continue
                                </Button>
                            </DialogActions>
                        </StyledContentContainer>
                        <StyledFooter>
                            <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                        </StyledFooter>
                    </Dialog>
                );
            case OnboardingWizardStage.SUPPORT_AND_COMMUNITY:
                return (
                    <Dialog
                        open={open}
                        fullScreen
                        sx={inputStyles}
                        onKeyUp={(event) =>
                            handleKeyUp({ event, enterFn: () => setStage(OnboardingWizardStage.CONNECT) })
                        }
                    >
                        <StyledUserDetails>
                            <Typography color="text.secondary">Logged in as</Typography>
                            <Typography>{user.email}</Typography>
                        </StyledUserDetails>
                        <StyledContentContainer>
                            <DialogTitle component="h3" variant="h5">
                                Support and Community
                            </DialogTitle>
                            <StyledSubtitle>
                                Join our Slack Community, receive ScriptRunner Connect support, and find solutions to
                                your integration projects.
                            </StyledSubtitle>
                            <StyledContent>
                                <StyledSupportItem>
                                    <div>
                                        <Typography variant="subtitle1" mb={1}>
                                            Feedback sessions
                                        </Typography>
                                        <Typography color="text.secondary">
                                            Let me know about paid research and feedback opportunities
                                        </Typography>
                                    </div>
                                    <StyledActionContainer>
                                        <Checkbox
                                            checked={receiveFeedbackSessions}
                                            onChange={() => setReceiveFeedbackSessions(!receiveFeedbackSessions)}
                                        />
                                    </StyledActionContainer>
                                </StyledSupportItem>
                                <StyledSupportItem>
                                    <div>
                                        <Typography variant="subtitle1" mb={1}>
                                            Support portals
                                        </Typography>
                                        <Typography color="text.secondary">
                                            Report issues, make suggestions, and more
                                        </Typography>
                                    </div>
                                    <StyledActionContainer>
                                        <OpenInNewLink url={supportPortalUrl}>Visit support</OpenInNewLink>
                                    </StyledActionContainer>
                                </StyledSupportItem>
                                <StyledSupportItem>
                                    <div>
                                        <Typography variant="subtitle1" mb={1}>
                                            Slack community
                                        </Typography>
                                        <Typography color="text.secondary">
                                            Discuss common integrations with other members and learn{' '}
                                        </Typography>
                                    </div>
                                    <StyledActionContainer>
                                        <OpenInNewLink url={slackCommunityUrl}>Join Community</OpenInNewLink>
                                    </StyledActionContainer>
                                </StyledSupportItem>
                            </StyledContent>
                            <DialogActions>
                                <Button onClick={() => setStage(OnboardingWizardStage.CONNECT)}>Continue</Button>
                            </DialogActions>
                        </StyledContentContainer>
                        <StyledFooter>
                            <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                        </StyledFooter>
                    </Dialog>
                );
            case OnboardingWizardStage.CONNECT:
                return (
                    <>
                        <Dialog
                            open={open}
                            fullScreen
                            sx={inputStyles}
                            onKeyUp={(event) =>
                                handleKeyUp({
                                    event,
                                    enterFn: () => setStage(OnboardingWizardStage.PERSONALIZE_CONNECTIONS),
                                })
                            }
                        >
                            <StyledUserDetails>
                                <Typography color="text.secondary">Logged in as</Typography>
                                <Typography>{user.email}</Typography>
                            </StyledUserDetails>
                            <StyledContentContainer sx={{ overflow: 'auto' }}>
                                <DialogTitle component="h3" variant="h5">
                                    Connect your system landscape
                                </DialogTitle>
                                <StyledSubtitle>Which systems are you trying to integrate?</StyledSubtitle>
                                <StyledContent>
                                    <PageGridContainer>{items}</PageGridContainer>
                                </StyledContent>
                                <DialogActions>
                                    <Button onClick={() => setStage(OnboardingWizardStage.PERSONALIZE_CONNECTIONS)}>
                                        Continue
                                    </Button>
                                </DialogActions>
                            </StyledContentContainer>
                            <StyledFooter>
                                <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                            </StyledFooter>
                        </Dialog>
                    </>
                );
            case OnboardingWizardStage.PERSONALIZE_CONNECTIONS:
                return (
                    <Dialog
                        open={open}
                        fullScreen
                        sx={inputStyles}
                        onKeyUp={(event) =>
                            handleKeyUp({ event, enterFn: () => setStage(OnboardingWizardStage.INVITE_TEAMMATES) })
                        }
                    >
                        <StyledUserDetails>
                            <Typography color="text.secondary">Logged in as</Typography>
                            <Typography>{user.email}</Typography>
                        </StyledUserDetails>
                        <StyledContentContainer>
                            <DialogTitle component="h3" variant="h5">
                                Let's personalise
                            </DialogTitle>
                            <StyledSubtitle>How would you like to connect these systems?</StyledSubtitle>
                            <StyledBoxContainer>
                                <StyledBox>
                                    <Typography variant="subtitle1" mb={1}>
                                        Integrations
                                    </Typography>
                                    <StyledBoxDescription>Combining systems to work together.</StyledBoxDescription>
                                    <Checkbox
                                        checked={integrations}
                                        onChange={() => {
                                            setIntegrations(!integrations);
                                        }}
                                    />
                                </StyledBox>
                                <StyledBox>
                                    <Typography variant="subtitle1" mb={1}>
                                        Sync
                                    </Typography>
                                    <StyledBoxDescription>
                                        Ensuring that data is consistent between two or more systems.
                                    </StyledBoxDescription>
                                    <Checkbox
                                        checked={sync}
                                        onChange={() => {
                                            setSync(!sync);
                                        }}
                                    />
                                </StyledBox>
                                <StyledBox>
                                    <Typography variant="subtitle1" mb={1}>
                                        Migrations
                                    </Typography>
                                    <StyledBoxDescription>
                                        Moving data or applications from one system to another.
                                    </StyledBoxDescription>
                                    <Checkbox
                                        checked={migrations}
                                        onChange={() => {
                                            setMigrations(!migrations);
                                        }}
                                    />
                                </StyledBox>
                            </StyledBoxContainer>
                            <DialogActions>
                                <Button onClick={() => setStage(OnboardingWizardStage.INVITE_TEAMMATES)}>
                                    Continue
                                </Button>
                            </DialogActions>
                        </StyledContentContainer>
                        <StyledFooter>
                            <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                        </StyledFooter>
                    </Dialog>
                );
            case OnboardingWizardStage.INVITE_TEAMMATES:
                return (
                    <Dialog
                        open={open}
                        fullScreen
                        sx={inputStyles}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterCondition:
                                    !isFocused(emailsInputRef) && !!organizationName && !emailValidationError,
                                enterFn: () => setStage(OnboardingWizardStage.OPEN_APP),
                            })
                        }
                    >
                        <StyledUserDetails>
                            <Typography color="text.secondary">Logged in as</Typography>
                            <Typography>{user.email}</Typography>
                        </StyledUserDetails>
                        <StyledContentContainer>
                            <DialogTitle component="h3" variant="h5">
                                Invite teammates
                            </DialogTitle>
                            <StyledSubtitle>
                                A free personal team has been created for you. You can rename it or invite colleagues to
                                join you here, or at a later stage under the team management module.
                            </StyledSubtitle>

                            <StyledContent>
                                <TextField
                                    label="Team name"
                                    placeholder="Enter a name"
                                    value={organizationName}
                                    onChange={(e) => setOrganizationName(e.target.value)}
                                />
                                <TextField
                                    inputRef={emailsInputRef}
                                    label="Work emails (separated by spaces)"
                                    placeholder="Enter emails (separated by spaces)"
                                    multiline
                                    rows={2}
                                    error={emailValidationError}
                                    value={emailsValue}
                                    onChange={(e) => setEmailsValue(e.target.value)}
                                />
                            </StyledContent>
                            <DialogActions>
                                <Button
                                    disabled={!organizationName || emailValidationError}
                                    onClick={() => setStage(OnboardingWizardStage.OPEN_APP)}
                                >
                                    Continue
                                </Button>
                            </DialogActions>
                        </StyledContentContainer>
                        <StyledFooter>
                            <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                        </StyledFooter>
                    </Dialog>
                );
            case OnboardingWizardStage.OPEN_APP:
                return (
                    <Dialog
                        open={open}
                        fullScreen
                        sx={inputStyles}
                        onKeyUp={(event) => handleKeyUp({ event, enterFn: handleOpenApp })}
                    >
                        <StyledUserDetails>
                            <Typography color="text.secondary">Logged in as</Typography>
                            <Typography>{user.email}</Typography>
                        </StyledUserDetails>

                        <StyledContentContainer>
                            <DialogTitle component="h3" variant="h5">
                                You're good to go!
                            </DialogTitle>
                            <StyledSubtitle>Here are some supporting resources to help you get started.</StyledSubtitle>
                            <StyledBoxContainer>
                                <StyledBox>
                                    <ArticleOutlinedIcon />
                                    <Typography variant="subtitle1" mb={1}>
                                        Documentation
                                    </Typography>
                                    <StyledBoxDescription>
                                        A growing collection of technical and 'How to' docs to help you use the app.
                                    </StyledBoxDescription>
                                    <OpenInNewLink url={documentationUrl}>Open</OpenInNewLink>
                                </StyledBox>
                                {/* <StyledBox>
                                    <img src={process.env.PUBLIC_URL + '/youtube-icon.svg'} alt="Youtube icon"></img>
                                    <Typography variant="subtitle1" mb={1}>
                                        YouTube tutorials
                                    </Typography>
                                    <StyledBoxDescription>
                                        If you prefer more visual guides, we have great videos on our YouTube library.
                                    </StyledBoxDescription>
                                    <OpenInNewLink url={youtubeChannelUrl}>Open</OpenInNewLink>
                                </StyledBox> */}
                                <StyledBox>
                                    <SlackIcon />
                                    <Typography variant="subtitle1" mb={1}>
                                        Slack community
                                    </Typography>
                                    <StyledBoxDescription>
                                        Stay up to date and benefit from premium support.
                                    </StyledBoxDescription>
                                    <OpenInNewLink url={slackCommunityUrl}>Open</OpenInNewLink>
                                </StyledBox>
                            </StyledBoxContainer>
                            <DialogActions>
                                <Button busy={saving} disabled={saving} onClick={handleOpenApp}>
                                    Finish
                                </Button>
                            </DialogActions>
                        </StyledContentContainer>
                        <StyledFooter>
                            <OnboardingWizardSteps stage={stage} onSetStage={setStage} />
                        </StyledFooter>
                    </Dialog>
                );
        }
    };

    return getStageContent();
};

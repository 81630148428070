import { BehaviorSubject, Subject, map, combineLatest, filter, debounceTime } from 'rxjs';
import {
    InitEditorAction,
    UpdateEditorDependenciesAction,
    LibsEditorAction,
    Package,
    RenameEditorAction,
    UpdateApiModulesEditorAction,
    PrependEditorContentAction,
    AddScriptModulesEditorAction,
} from './types';
import { monitor } from '../monitor';
import * as tsService from '../../monaco/ts-service';
import { getUnsavedScriptWithUidCached } from '../../utils/script';
import { implicitlyIncludedPackages$, selectedWorkspacePackages$ } from '../workspace/package-manager';
import { featureFlagsTopic$ } from '../config';

export const initEditorAction$ = monitor('initEditorAction$', new Subject<InitEditorAction>());
export const updateEditorDependenciesAction$ = monitor(
    'updateEditorDependenciesAction$',
    new Subject<UpdateEditorDependenciesAction>()
);
export const libsEditorAction$ = monitor('libsEditorAction$', new Subject<LibsEditorAction>());
export const resetEditorAction$ = monitor('resetEditorAction$', new Subject<void>());
export const renameEditorAction$ = monitor('renameEditorAction$', new Subject<RenameEditorAction>());
export const updateApiModulesEditorAction$ = monitor(
    'updateApiModulesEditorAction$',
    new Subject<UpdateApiModulesEditorAction>()
);
export const prependEditorContentAction$ = monitor(
    'prependEditorContentAction$',
    new Subject<PrependEditorContentAction>()
);

export const activeDependencies$ = monitor('activeDependencies$', new BehaviorSubject<Record<string, string>>({}));
export const fetchPackagesAction$ = monitor('fetchPackageAction$', new Subject<Package[]>());
export const addScriptModulesEditorAction$ = monitor(
    'addScriptModulesEditorAction$',
    new Subject<AddScriptModulesEditorAction[]>()
);
export const compileCustomTsWorkerPath$ = monitor(
    'compileCustomTsWorkerPath$',
    new BehaviorSubject<string | null>(null)
);

export const currentTypeDeclarationsLoaded$ = monitor('currentTypeDeclarationsLoaded$', new BehaviorSubject(0));
export const totalTypeDeclarationsLoaded$ = monitor('totalTypeDeclarationsLoaded$', new BehaviorSubject(0));
export const editorAlertMessage$ = monitor('editorAlertMessage$', new BehaviorSubject<string | undefined>(undefined));
export const typescriptVersion$ = monitor('typescriptVersion$', new BehaviorSubject<string | undefined>(undefined));

addScriptModulesEditorAction$
    .pipe(
        map(async (scripts) => {
            const scriptsData = await Promise.all(
                scripts.map((script) => script.uid).map(getUnsavedScriptWithUidCached)
            );
            const monacoScripts = await tsService.addScriptModels(scriptsData);
            updateEditorDependenciesAction$.next({ scripts: monacoScripts });
        })
    )
    .subscribe();

initEditorAction$
    .pipe(
        map(async (action) => {
            const inlineSourceMap = !!featureFlagsTopic$.value.bundleWithEsbuild;

            const tsVersion = await tsService.init(action.language ?? 'ts', inlineSourceMap);
            typescriptVersion$.next(tsVersion);

            if (action.customWorkerPath) {
                await tsService.setCustomWorker(action.customWorkerPath);
            }
        })
    )
    .subscribe();

updateEditorDependenciesAction$
    .pipe(
        map(async (action) => {
            await tsService.addScriptDependencies(action.scripts);
        })
    )
    .subscribe();

libsEditorAction$
    .pipe(
        map(async (action) => {
            await tsService.setTypeDeclarations(action.libs, action.overwrite);
        })
    )
    .subscribe();

updateApiModulesEditorAction$
    .pipe(
        map(async (action) => {
            await tsService.updateApiHandlerModules(action.apiHandlers);
        })
    )
    .subscribe();

resetEditorAction$
    .pipe(
        map(async () => {
            await tsService.clearSourceModules();
            await tsService.setTypeDeclarations([], true);
            activeDependencies$.next({});
        })
    )
    .subscribe();

renameEditorAction$
    .pipe(
        map(async (action) => {
            await tsService.renameSourceModule(action);
        })
    )
    .subscribe();

combineLatest([selectedWorkspacePackages$, implicitlyIncludedPackages$])
    .pipe(
        debounceTime(100),
        filter(([, impPkgs]) => impPkgs.length > 0),
        map(([slcPkgs, impPkgs]) => {
            const fetchPackages = impPkgs.reduce<Record<string, { name: string; version: string }>>((acc, pkg) => {
                acc[pkg.name] = pkg;
                return acc;
            }, {});
            slcPkgs.forEach((pkg) => {
                fetchPackages[pkg.name] = { name: pkg.name, version: pkg.version };
            });
            return Object.values(fetchPackages);
        })
    )
    .subscribe((pkgs) => fetchPackagesAction$.next(pkgs));

export const legacyPackageImports$ = monitor('legacyPackageImports$', new BehaviorSubject<string[] | null>(null));
export const legacyPackagesDialogOpen$ = monitor('legacyPackagesDialogOpen$', new BehaviorSubject<boolean>(false));

import { env, featureFlagsTopic$ } from '../store/config';
import { initialize, LDClient, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { FeatureFlags } from '../store/config/types';

const ldClientCache: Record<string, LDClient> = {};

export const getFlagsFromLaunchDarkly = async (
    launchDarklyClientId: string,
    ldUser: LDSingleKindContext
): Promise<FeatureFlags> => {
    if (env === 'staging' && ldUser.email?.startsWith('user_') && ldUser.email.endsWith('@adaptavist.com')) {
        return {
            bundleWithUnoptimizedSkypackImports: true,
            disabledPaidTiers: false,
            bundleWithEsbuild: true,
            mondayUser: false,
            newConsole: false,
            scheduledTriggers: true,
            hideMicrosoftConnector: false,
            organizations: true,
            aiAssistance: false,
            showReadmeFile: false,
            srcBranding: true,
            salablePlan: true,
        };
    }

    const ldClientFromCache = ldClientCache[launchDarklyClientId];

    if (ldClientFromCache) return await ldClientFromCache.identify(ldUser);
    else {
        const ldClient = initialize(launchDarklyClientId, ldUser);
        await ldClient.waitForInitialization();
        ldClientCache[launchDarklyClientId] = ldClient;
        return ldClient.allFlags();
    }
};

export const loadFeatureFlags = async (launchDarklyClientId: string): Promise<void> => {
    const ldUser: LDSingleKindContext = {
        anonymous: true,
        kind: 'user',
        domain: document.location.hostname,
    };

    try {
        const flags = await getFlagsFromLaunchDarkly(launchDarklyClientId, ldUser);
        console.debug('Loaded initial flags', flags);
        featureFlagsTopic$.next(flags);
    } catch (err) {
        console.error('Error whilst fetching feature flags: ', err);
        throw err;
    }
};

import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { ProductIcon } from '../../components/icons/ProductIcon';
import { ApiHandlerDetails } from '../../components/workspace/api-handler-details';
import { apps$ } from '../../store/apps';
import { connectionCreatedAction$, openConnectionDetailsDialogAction$ } from '../../store/connection';
import { loggedInUserConnections$ } from '../../store/connections';
import {
    updateWizardStateAction$,
    wizardCreatedUnsavedWorkspaceResourceUid$,
    WizardState,
    wizardState$,
    WizardStep,
    wizardStep$,
} from '../../store/wizard';
import { selectedWorkspace$, selectedWorkspaceSelectedResource$ } from '../../store/workspace';
import {
    deleteApiHandlerAction$,
    saveApiHandlerAction$,
    savingSelectedApiHandler$,
    selectedApiHandlerDetails$,
    selectedApiHandlerErrors$,
} from '../../store/workspace/api-handler';
import { DeletionRequest } from '../../store/workspace/types';
import { getUserDisplayName } from '../../utils/user';
import { useState } from 'react';

export const ApiHandlerDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const [createdConnectionUid, setCreatedConnectionUid] = useState<string | undefined>(undefined);

    const errors = useObservableState(selectedApiHandlerErrors$);
    const details = useObservableState(selectedApiHandlerDetails$);
    const saving = useObservableState(savingSelectedApiHandler$);
    const connections = useObservableState(loggedInUserConnections$);
    const apps = useObservableState(apps$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    const wizardCreatedUnsavedWorkspaceResourceUid = useObservableState(wizardCreatedUnsavedWorkspaceResourceUid$);
    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);

    useSubscription(connectionCreatedAction$, (connectionUid) => {
        setCreatedConnectionUid(connectionUid);
    });

    const filteredConnections = connections
        .filter((c) => c.connectionType.uid === details?.connectionTypeUid)
        .map((c) => ({
            value: c.uid,
            name: c.name,
            authorized: c.authorized,
            icon: <ProductIcon name={c.connectionType.name} />,
        }));

    if (details?.selectedSharedConnection) {
        filteredConnections.unshift({
            value: details.selectedSharedConnection.uid,
            name: `${details.selectedSharedConnection.name} (owned by ${getUserDisplayName(
                details.selectedSharedConnection.ownedBy
            )})`,
            authorized: details.selectedSharedConnection.authorized,
            icon: <ProductIcon name={details.selectedSharedConnection.connectionType.name} />,
        });
    }

    const handleCancel = (event: DeletionRequest): void => {
        navigate({ to: '../../' });
        selectedWorkspaceSelectedResource$.next(undefined);
        if (details && !details.path && !details.connectionUid) {
            deleteApiHandlerAction$.next(event);
        }
    };

    const handleNewConnection = (): void => {
        const connectionType = (apps ?? [])
            .flatMap((app) => app.connectionType)
            .find((ct) => ct.uid === details?.connectionTypeUid);

        if (connectionType) {
            openConnectionDetailsDialogAction$.next({
                connectionType,
            });
        } else {
            console.error('Connection type not found');
            alert('Connection type not found');
        }
    };

    const handleCloseWizardWarning = (): void => {
        if (wizardState === WizardState.ACTIVE && wizardStep === WizardStep.SAVE_API_CONNECTION) {
            updateWizardStateAction$.next(WizardState.CONFIRM);
        }
    };

    const showWizardWarning =
        (wizardState === WizardState.ACTIVE || wizardState === WizardState.CONFIRM) &&
        wizardStep === WizardStep.SAVE_API_CONNECTION &&
        wizardCreatedUnsavedWorkspaceResourceUid === details?.uid;

    return (
        <ApiHandlerDetails
            uid={details?.uid ?? ''}
            saving={saving}
            errors={errors}
            createdConnectionUid={createdConnectionUid}
            connections={filteredConnections}
            path={details?.path ?? details?.defaultImportPath}
            remnantEnvironments={details?.remnantEnvironments}
            selectedConnectionUid={details?.connectionUid}
            onCancel={handleCancel}
            onSave={(event) => saveApiHandlerAction$.next(event)}
            onNewConnection={handleNewConnection}
            onWizardWarningClose={handleCloseWizardWarning}
            wizardWarning={showWizardWarning}
            workspaceLocked={!!selectedWorkspace?.locked}
            hasImplicitlySharedConnectionAttached={!!details?.selectedSharedConnection}
        />
    );
};

import { useObservableState } from 'observable-hooks';
import { ReadmeFileDetails } from '../../components/workspace/ReadmeFileDetails';
import {
    parsedReadmeFileContent$,
    readmeFileBeingSaved$,
    readmeFileHasUnsavedChanges$,
    saveReadmeFileAction$,
    selectedReadmeFileContentChangedAction$,
    selectedReadmeFileUid$,
    unsavedReadmeFileDetails$,
} from '../../store/workspace/readme';
import { ReadmeFile } from '../../data/readme';
import { selectedReadOnlyTemplate$ } from '../../store/templates';
import { getDefaultReadmeFile, getDefaultReadmeFileUid } from '../../store/workspace/utils';
import { selectedWorkspace$ } from '../../store/workspace';

export const ReadmeFileDetailsContainer: React.FC = () => {
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const readmeFileBeingSaved = useObservableState(readmeFileBeingSaved$);
    const readmeFileHasUnsavedChanges = useObservableState(readmeFileHasUnsavedChanges$);
    const parsedReadmeFileContent = useObservableState(parsedReadmeFileContent$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    const readOnlyMode = !!selectedReadOnlyTemplate?.templateUid;

    const handleContentChange = (content: string): void => {
        selectedReadmeFileContentChangedAction$.next(content);
    };

    const defaultReadmeFileUid = getDefaultReadmeFileUid();
    const readmeFileUid = selectedReadmeFileUid === defaultReadmeFileUid ? '' : selectedReadmeFileUid;

    let selectedReadmeFile: ReadmeFile | undefined;
    if (!selectedReadmeFileUid) {
        selectedReadmeFile = getDefaultReadmeFile();
    } else if (selectedReadmeFileUid === defaultReadmeFileUid) {
        selectedReadmeFile = unsavedReadmeFileDetails$.value[selectedReadmeFileUid] ?? getDefaultReadmeFile();
    } else {
        selectedReadmeFile = unsavedReadmeFileDetails$.value[selectedReadmeFileUid];
    }

    return (
        <ReadmeFileDetails
            readmeFileUid={readmeFileUid ?? ''}
            content={selectedReadmeFile?.content ?? ''}
            parsedContent={parsedReadmeFileContent[selectedReadmeFileUid ?? ''] ?? ''}
            saving={readmeFileBeingSaved ?? false}
            hasUnsavedChanges={!!readmeFileHasUnsavedChanges[selectedReadmeFileUid ?? '']}
            onContentChange={handleContentChange}
            onSave={() => saveReadmeFileAction$.next()}
            readOnlyMode={readOnlyMode}
            workspaceLocked={!!selectedWorkspace?.locked}
        />
    );
};

import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { Workspace } from '../components/layout';
import { navigateToApiHandlerAction$ } from '../store/workspace/api-handler';
import {
    loadingWorkspaceResources$,
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '../store/workspace';
import { navigateToScriptAction$ } from '../store/workspace/script';
import { navigateToEventListenerAction$ } from '../store/workspace/event-listener';
import { getBasePath } from '../utils/path';
import { featureFlagsTopic$ } from '../store/config';
import { navigateToReadmeFileAction$ } from '../store/workspace/readme';
import { navigateToScheduledTriggerAction$ } from '../store/workspace/scheduled-trigger';
import { selectedReadOnlyTemplate$ } from '../store/templates';
import { newWorkspaceFromTemplate$, openNewBlankWorkspaceAction$ } from '../store/workspaces';
import { getDefaultReadmeFileUid } from '../store/workspace/utils';

export const ReadOnlyTemplateContainer: React.FC = () => {
    const navigate = useNavigate();

    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const selectedResource = useObservableState(selectedWorkspaceSelectedResource$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    const defaultReadmeFileUid = getDefaultReadmeFileUid();

    useSubscription(navigateToScriptAction$, (uid) => {
        navigate({
            to: `${getBasePath()}template/${selectedReadOnlyTemplate?.templateUid}/script/${uid}`,
        });
    });
    useSubscription(navigateToApiHandlerAction$, (uid) => navigate({ to: `./api/${uid}` }));
    useSubscription(navigateToEventListenerAction$, (uid) => navigate({ to: `./event/${uid}` }));
    useSubscription(navigateToScheduledTriggerAction$, (uid) => navigate({ to: `./scheduled/${uid}` }));
    useSubscription(navigateToReadmeFileAction$, (uid) => {
        const readmeFileUid = uid === defaultReadmeFileUid ? '' : uid;
        navigate({
            to: `${getBasePath()}template/${selectedReadOnlyTemplate?.templateUid}/readme/${readmeFileUid}`,
        });
    });

    const templateUid = selectedWorkspace?.templateUid;

    const readmeFile = workspaceResources.readmeFile
        ? {
              ...workspaceResources.readmeFile,
              unsaved: false,
          }
        : {
              uid: defaultReadmeFileUid,
              name: 'README.md',
              unsaved: false,
          };

    return (
        <>
            <Workspace
                readOnlyTemplateMode={true}
                loadingWorkspaceResources={loadingWorkspaceResources}
                workspaceResources={workspaceResources}
                selectedWorkspaceLanguage={selectedWorkspace?.language}
                selectedNode={selectedResource}
                readmeFile={readmeFile}
                scriptHasUnsavedChanges={{}}
                scriptExecutionInProgress={{}}
                selectedWorkspaceUid={selectedWorkspaceUid}
                isTemplate={Boolean(templateUid)}
                selectedEnvironmentUid={selectedEnvironmentUid}
                onSelectScript={(uid) => navigateToScriptAction$.next(uid)}
                onSelectReadmeFile={(uid) => navigateToReadmeFileAction$.next(uid ?? '')}
                onSelectApiHandler={(uid) => navigateToApiHandlerAction$.next(uid)}
                onSelectEventListener={(uid) => navigateToEventListenerAction$.next(uid)}
                onSelectScheduledTrigger={(uid) => navigateToScheduledTriggerAction$.next(uid)}
                showScheduledTriggers={featureFlags.scheduledTriggers}
                eventListenerExecutionInProgress={{}}
                onCreateWorkspaceFromTemplate={() => {
                    newWorkspaceFromTemplate$.next({
                        name: selectedWorkspace?.name ?? '',
                        description: selectedWorkspace?.description,
                        createFromTemplateUid: selectedWorkspace?.templateUid,
                    });
                    openNewBlankWorkspaceAction$.next('template');
                }}
            />
        </>
    );
};

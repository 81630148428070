import { LoaderFn } from 'react-location';
import { getScheduledTrigger } from '../data/scheduled-trigger';
import { LocationGenerics } from '../router';
import { selectedReadOnlyTemplate$ } from '../store/templates';
import { selectedEnvironmentUid$, selectedWorkspaceSelectedResource$ } from '../store/workspace';
import {
    selectedScheduledTriggerDetails$,
    selectedScheduledTriggerErrors$,
} from '../store/workspace/scheduled-trigger';
import { loadErrorPage } from '../store/error';

export const scheduledTriggerLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const scheduledTriggerUid = routeMatch.params.scheduledTriggerUid!;

        const environmentUid = !selectedReadOnlyTemplate$.value
            ? //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              routeMatch.params.environmentUid!
            : selectedEnvironmentUid$.value;

        const scheduledTrigger = await getScheduledTrigger(scheduledTriggerUid, environmentUid);

        selectedScheduledTriggerDetails$.next(scheduledTrigger);
        selectedWorkspaceSelectedResource$.next({
            resourceType: 'SCHEDULED_TRIGGER',
            uid: scheduledTriggerUid,
        });
        selectedScheduledTriggerErrors$.next(undefined);

        return {};
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load Scheduled Trigger.',
        });

        throw e;
    }
};

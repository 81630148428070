import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from '@mui/system/styled';
import React, { useState } from 'react';
import { Button } from '../../buttons/Button';
import { GitlabIcon } from '../../icons/GitlabIcon';
import { ConnectionModalSecretTextField, ConnectionModalTextField } from '../../textfield/ConnectionModalTextField';
import { ConfirmReAuthDialog } from '../ConfirmReAuthDialog';
import { GitlabWizardStage, GitlabWizardSteps, StageType } from './GitlabWizardSteps';
import { OpenInNewLink } from '../../link/OpenInNewLink';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { DialogAlert, DialogTitleSmall } from '../../dialog';

export interface GitlabConnection {
    authorized?: boolean;
    clientInfo: ClientInfo;
    name: string;
    hasAuthorizedSaasConnections: boolean;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: GitlabWizardStage;
    setStage: (stage: GitlabWizardStage) => void;
    onSave: (props: ClientInfo) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface ClientInfo {
    clientId: string;
    clientSecret: string;
    instanceUrl: string;
}

export type InstanceType = 'SAAS' | 'SELF-MANAGED';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3.5),
    '&:first-of-type': {
        marginTop: theme.spacing(2),
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}));

const testUrl = async (instanceUrl: string): Promise<boolean> => {
    try {
        const url = new URL(instanceUrl);
        await fetch(instanceUrl, {
            method: 'GET',
            mode: 'no-cors',
        });
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch {
        return false;
    }
};

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.clientInfo.instanceUrl);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);
    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);
    const [instanceType, setInstanceType] = useState<InstanceType>(
        props.clientInfo.instanceUrl ? 'SELF-MANAGED' : 'SAAS'
    );

    const GITLAB_URL = 'https://gitlab.';
    const GITLAB_PATH = '/-/profile/applications';

    const handleChooseInstanceType = async (): Promise<void> => {
        props.setStage(instanceType === 'SELF-MANAGED' ? GitlabWizardStage.ADDURL : GitlabWizardStage.AUTHORIZE);
        if (instanceType === 'SAAS') {
            setInstanceUrl('');
            setClientId('');
            setClientSecret('');
        }
        props.onChooseInstanceType(instanceType);
    };

    const generateApplicationsUrl = (): string => {
        return instanceUrl.endsWith(GITLAB_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + GITLAB_PATH
            : instanceUrl + GITLAB_PATH;
    };

    const needsConfirmationMessage = (): boolean => {
        return instanceType === 'SAAS' && props.hasAuthorizedSaasConnections;
    };

    const handleSave = (): void => {
        if (needsConfirmationMessage()) {
            props.onConfirmationModalOpen({ instanceUrl, clientId, clientSecret });
        } else {
            props.onSave({
                instanceUrl,
                clientId,
                clientSecret,
            });
        }
    };

    switch (props.stage) {
        case GitlabWizardStage.CHOOSEINSTANCETYPE:
            return (
                <>
                    <DialogContent>
                        <StyledFormControl>
                            <DialogContentText>Choose the Instance type that you want to connect to:</DialogContentText>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={instanceType}
                                onChange={(e) => setInstanceType(e.target.value as InstanceType)}
                            >
                                <StyledFormControlLabel
                                    value="SAAS"
                                    control={<Radio />}
                                    label="SaaS version (www.gitlab.com)"
                                />
                                <StyledFormControlLabel
                                    value="SELF-MANAGED"
                                    control={<Radio />}
                                    label="Self-managed GitLab instance"
                                />
                            </RadioGroup>
                        </StyledFormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleChooseInstanceType}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case GitlabWizardStage.ADDURL:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="To setup an application link you need administrator privileges for your GitLab Instance."
                    />
                    <DialogContent>
                        <DialogContentText>The connector requires your GitLab instance url.</DialogContentText>
                        <ConnectionModalTextField
                            label={`${GITLAB_URL}<INSTANCE_NAME>.com`}
                            value={instanceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(GitlabWizardStage.CHOOSEINSTANCETYPE)}>
                            Back
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                if (!instanceUrl.startsWith(GITLAB_URL)) {
                                    props.setError(`Please enter a valid Workspace URL starting with ${GITLAB_URL}`);
                                    setBadUrlAttempted(true);
                                } else {
                                    if (await testUrl(instanceUrl)) {
                                        setInstanceUrl(instanceUrl);
                                        props.setStage(GitlabWizardStage.CREATE);
                                        setBadUrlAttempted(false);
                                    } else {
                                        props.setError(
                                            `Please enter a valid URL, starting with ${GITLAB_URL} or check that you have access to the instance`
                                        );
                                        setBadUrlAttempted(true);
                                    }
                                }
                            }}
                            disabled={!instanceUrl}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case GitlabWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have an application link to ScriptRunner Connect, skip the steps below and click next`}
                    />
                    <DialogAlert
                        severity="info"
                        text="To setup an application link you need administrator privileges for your GitLab Instance."
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the <OpenInNewLink url={generateApplicationsUrl()}>Applications</OpenInNewLink>{' '}
                                page in your GitLab Instance.
                            </li>
                            <li>
                                Under <strong>Add new application</strong> insert the following details:
                            </li>
                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField label="Redirect URI" value={props.callbackUrl} />
                            <li>
                                Make sure that the option <strong>Expire access tokens</strong> is checked.
                            </li>
                            <li>
                                Under <strong>Scopes</strong> select the <strong>api</strong> checkbox.
                            </li>
                            <li>
                                Scroll down and click <strong>Save application</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(GitlabWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(GitlabWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case GitlabWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Click on the newly created application (or an existing one if you skipped the previous
                                step).
                            </li>
                            <li>
                                Copy the <strong>Application ID</strong> into the form below.
                            </li>
                            <li>
                                Click on the <strong>Copy</strong> button next to <strong>Secret</strong> and copy the
                                value into the form below.
                            </li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Application ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(GitlabWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(GitlabWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case GitlabWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in GitLab you need to authorize our app to be able to make requests on
                            your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(
                                    instanceType === 'SAAS'
                                        ? GitlabWizardStage.CHOOSEINSTANCETYPE
                                        : GitlabWizardStage.DETAILS
                                );
                            }}
                        >
                            Back
                        </Button>
                        <Button busy={props.saving} onClick={handleSave}>
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case GitlabWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const GitlabAppConfigureDialog: React.FC<GitlabConnection> = (props) => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [instanceType, setInstanceType] = useState<InstanceType>('SAAS');
    const [currentClientInfo, setCurrentClientInfo] = useState<ClientInfo | undefined>(undefined);
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;

    const handleConfirmationModalOpen = (clientInfo: ClientInfo): void => {
        setCurrentClientInfo(clientInfo);
        setConfirmationModalOpen(true);
    };
    const handleConfirm = (): void => {
        if (currentClientInfo) {
            props.onSave(currentClientInfo);
        }
        setConfirmationModalOpen(false);
    };
    const handleCancel = (): void => {
        setConfirmationModalOpen(false);
    };

    const reauthorizationConnectionMessage = `You are about to re-authorize your GitLab connector. You will be automatically authorized with the
    account you are currently signed in on GitLab website. If you want to use a different account, before
    reauthorization, please sign out on`;

    const anotherNewConnectionMessage = `You are about to authorize a new GitLab connector. Please keep in mind that you already have an existing GitLab connector and you will be automatically authorized with the
    account you are currently signed in on GitLab website. If you want to use a different account, before
    creating new connector, please sign out on`;
    return (
        <>
            <Dialog open={props.open} onClose={() => props.onClose()}>
                <DialogTitleSmall title="Configure Connector" variant="h6" icon={<GitlabIcon />} />
                {props.errors && error}

                <GitlabWizardSteps stage={props.currentStage} instanceType={instanceType} />
                <StageContent
                    {...props}
                    stage={props.currentStage}
                    setStage={props.setStage}
                    onChooseInstanceType={(type) => setInstanceType(type)}
                    onConfirmationModalOpen={(clientInfo) => handleConfirmationModalOpen(clientInfo)}
                />
            </Dialog>
            <ConfirmReAuthDialog
                isReauthorization={!!props.authorized}
                link="https://gitlab.com"
                open={confirmationModalOpen}
                message={props.authorized ? reauthorizationConnectionMessage : anotherNewConnectionMessage}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        </>
    );
};

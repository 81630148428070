import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AvatarContainer } from '../avatar';
import { Button } from '../buttons/Button';
import { DialogAlert } from '../dialog';
import { Dropdown } from '../dropdown/Dropdown';
import { PageContainer, PageItalicText, PageTitle } from '../layout';
import { StyledImageBox, StyledImageTextBox } from '../organization/OrganizationComponents';

export interface ProfileProps {
    email: string;
    avatar?: string;
    firstName?: string;
    lastName?: string;
    roleUid?: string;
    company?: string;
    customRole?: string;
    showPasswordReset: boolean;
    showOtherRoleField: boolean;
    roles: {
        value: string;
        name: string;
    }[];
    onInitiateAvatarUpload(): void;
    onChangeRole(roleUid: string): void;
    onUpdate(event: UpdateProfileEvent): void;
    onPasswordReset(): void;
    onCancel(): void;
    loading: boolean;
    validationError?: string;
}

export interface UpdateProfileEvent {
    firstName: string;
    lastName: string;
    roleUid: string;
    company: string;
    customRole: string;
}

const ProfilePicContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    padding: theme.spacing(4.5, 0),
    '&>.MuiAvatar-root': {
        marginRight: theme.spacing(1),
    },
}));

const StyledResetBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    margin: theme.spacing(1, 0, 2, 0),
}));

const StyledResetLink = styled(Link)(({ theme }) => ({
    marginRight: theme.spacing(1.5),
}));

export const Profile: React.FC<ProfileProps> = ({
    email,
    avatar,
    firstName,
    lastName,
    roleUid,
    company,
    showPasswordReset,
    showOtherRoleField,
    roles,
    customRole,
    onInitiateAvatarUpload,
    onChangeRole,
    onUpdate,
    onPasswordReset,
    onCancel,
    loading,
    validationError,
}) => {
    const [currentFirstName, setFirstName] = useState(firstName);
    const [currentLastName, setLastName] = useState(lastName);
    const [currentRole, setCurrentRole] = useState(roleUid);
    const [currentCompany, setCompany] = useState(company);
    const [isChanged, setIsChanged] = useState(false);
    const [currentCustomRole, setCustomRole] = useState(customRole);

    useEffect(() => {
        if (
            currentFirstName !== firstName ||
            currentLastName !== lastName ||
            currentRole !== roleUid ||
            currentCompany !== company ||
            currentCustomRole !== customRole ||
            validationError
        ) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [currentFirstName, currentLastName, currentRole, currentCompany, currentCustomRole]);

    const handleChangeRole = (uid: string): void => {
        setCurrentRole(uid);
        onChangeRole(uid);
    };

    const handleUpdate = (): void => {
        onUpdate({
            firstName: currentFirstName ?? '',
            lastName: currentLastName ?? '',
            roleUid: currentRole ?? '',
            company: currentCompany ?? '',
            customRole: currentCustomRole ?? '',
        });
        setIsChanged(false);
    };

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <PageTitle title="Profile settings" />
            {validationError && <DialogAlert severity="error" alertTitle="Error" text={validationError} />}
            <ProfilePicContainer>
                {avatar ? (
                    <StyledImageBox size={80}>
                        <img src={`data:image/jpeg;base64,${avatar}`} width={80} height={80} alt="${avatar}" />
                    </StyledImageBox>
                ) : (
                    <AvatarContainer
                        credentials={{ firstName: currentFirstName ?? '', lastName: currentLastName ?? '', email }}
                        size="large"
                    />
                )}
                <StyledImageTextBox>
                    <Link onClick={onInitiateAvatarUpload}>Upload picture</Link>
                    <PageItalicText>File type .PNG, .JPEG (max file size 2MBs)</PageItalicText>
                </StyledImageTextBox>
            </ProfilePicContainer>
            <TextField
                onChange={(e) => setFirstName(e.target.value)}
                variant="outlined"
                label="First name"
                placeholder="Enter first name"
                value={currentFirstName}
                fullWidth
            />
            <TextField
                onChange={(e) => setLastName(e.target.value)}
                variant="outlined"
                label="Last name"
                placeholder="Enter last name"
                value={currentLastName}
                fullWidth
            />
            <TextField
                variant="outlined"
                label="Email"
                placeholder="Enter an email"
                value={email}
                required
                fullWidth
                disabled
            />
            {showPasswordReset && (
                <StyledResetBox>
                    <StyledResetLink onClick={onPasswordReset}>Reset password</StyledResetLink>
                    <Tooltip
                        title="Reset your password if you would like to change your password or have forgotten it."
                        placement="top"
                    >
                        <SvgIcon fontSize="small" color="primary">
                            <InfoOutlinedIcon />
                        </SvgIcon>
                    </Tooltip>
                </StyledResetBox>
            )}
            <Box paddingBottom={1}>
                <Dropdown label="Current role" items={roles} selectedItem={currentRole} onSelect={handleChangeRole} />
            </Box>
            {showOtherRoleField && (
                <TextField
                    variant="outlined"
                    label="Custom role"
                    placeholder="Enter role"
                    value={currentCustomRole}
                    fullWidth
                    onChange={(e) => setCustomRole(e.target.value)}
                    required
                />
            )}
            <TextField
                onChange={(e) => setCompany(e.target.value)}
                variant="outlined"
                label="Company"
                placeholder="Enter company name"
                value={currentCompany}
                fullWidth
            />
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleUpdate} disabled={!isChanged} busy={loading}>
                    Update
                </Button>
            </DialogActions>
        </PageContainer>
    );
};

import { getApps as getAppsRpc } from '@avst-stitch/repository-lib/lib';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Response as Apps } from '@avst-stitch/repository-lib/lib/rpcs/getApps';
import { featureFlagsTopic$ } from '../store/config';
import { repositoryInvoker } from '../utils/repository';
import { Flatten } from '../utils/types';
export type { Apps };

const MONDAY_USER_APPS = [APP.MONDAY.NAME];

export const getApps = async (): Promise<Apps> => {
    const apps = await getAppsRpc(repositoryInvoker);

    const mondayUser = featureFlagsTopic$.value.mondayUser ?? false;

    return apps.filter((a) => !mondayUser || MONDAY_USER_APPS.includes(a.name));
};

export type App = Flatten<Apps>;

export type ConnectionType = App['connectionType'];

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export enum ServiceNowEventListenerWizardStage {
    CREATE_RECORD,
    CREATE_HTTP_METHOD,
    CONFIGURE_METHOD,
    CREATE_BUSINESS_RULE,
}

export interface ServiceNowWizardStepsProps {
    readonly stage: ServiceNowEventListenerWizardStage;
}

export const ServiceNowEventListenerWizardSteps: React.FC<ServiceNowWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={ServiceNowEventListenerWizardStage.CREATE_RECORD}>
                <StepLabel>Create REST message record</StepLabel>
            </Step>
            <Step key={ServiceNowEventListenerWizardStage.CREATE_HTTP_METHOD}>
                <StepLabel>Create HTTP method</StepLabel>
            </Step>
            <Step key={ServiceNowEventListenerWizardStage.CONFIGURE_METHOD}>
                <StepLabel>Configure HTTP method</StepLabel>
            </Step>
            <Step key={ServiceNowEventListenerWizardStage.CREATE_BUSINESS_RULE}>
                <StepLabel>Create business rule</StepLabel>
            </Step>
        </Stepper>
    );
};

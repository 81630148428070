import { DotNavigation } from '../dot-navigation';

export enum OnboardingWizardStage {
    GET_STARTED,
    CUSTOMIZE,
    SUPPORT_AND_COMMUNITY,
    CONNECT,
    PERSONALIZE_CONNECTIONS,
    INVITE_TEAMMATES,
    OPEN_APP,
}

export interface OnboardingWizardStepsProps {
    readonly stage: OnboardingWizardStage;
    onSetStage: (stage: OnboardingWizardStage) => void;
}

export const OnboardingWizardSteps: React.FC<OnboardingWizardStepsProps> = ({ stage, onSetStage }) => {
    return (
        <DotNavigation
            activeStep={stage}
            onSetActiveStep={onSetStage}
            stageEnum={OnboardingWizardStage}
        ></DotNavigation>
    );
};

import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { OrganisationsUtilizationPage } from '../../components/report/OrganisationsUtilizationReport';
import { organisationsUtilizationReportData$ } from '../../store/report/organisationsUtilization';

export const OrganisationsUtilizationPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const data = useObservableState(organisationsUtilizationReportData$);

    return (
        <OrganisationsUtilizationPage
            data={data}
            timePeriod={match.search.timePeriod ? (match.search.timePeriod as string).substring(1) : undefined}
            onSearch={(timePeriod) => {
                // Ugly hack to force loader to get called
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './teamsUtilization',
                            search: {
                                search: true,
                                timePeriod,
                            },
                        }),
                    100
                );
            }}
        />
    );
};

import { BehaviorSubject, map, Subject } from 'rxjs';
import { ApiHandler, createApiHandler, deleteApiHandler, saveApiHandler } from '../../data/api-handler';
import { appSelectorOpen$, loadingAppSelector$ } from '../apps';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import {
    selectedEnvironmentUid$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '.';
import { loadWorkspaceResourcesWhenEventIsEmitted } from './utils';
import { SaveApiHandlerEvent } from '../../components/workspace/api-handler-details';
import { InformativeError } from '../../utils/repository';
import { DeletionRequest } from './types';
import { loggedInUserConnections$ } from '../connections';
import { promptQuestion } from '../confirm';
import { prependEditorContentAction$ } from '../editor/editor';
import {
    saveWizardCreatedWorkspaceResourceAction$,
    updateWizardStepAction$,
    WizardState,
    wizardState$,
    WizardStep,
    wizardStep$,
    wizardWorkspaceDetails$,
} from '../wizard';

export const createApiHandlerAction$ = monitor('createApiHandlerAction$', new Subject<CreateApiHandler>());
export const apiHandlerCreatedAction$ = monitor('apiHandlerCreatedAction$', new Subject<string>());
export const navigateToApiHandlerAction$ = monitor('navigateToApiHandlerAction$', new Subject<string>());
export const saveApiHandlerAction$ = monitor('saveApiHandlerAction$', new Subject<SaveApiHandlerEvent>());
export const apiHandlerSavedAction$ = monitor('apiHandlerSavedAction$', new Subject<string>());
export const deleteApiHandlerAction$ = monitor('deleteApiHandlerAction$', new Subject<DeletionRequest>());
export const apiHandlerDeletedAction$ = monitor('apiHandlerDeletedAction$', new Subject<string>());
export const openApiHandlerImportDialogAction$ = monitor('openApiHandlerImportDialog$', new Subject<void>());
export const closeApiHandlerImportDialogAction$ = monitor('closeApiHandlerImportDialog$', new Subject<void>());
export const importApiHandlerDialogImportAction$ = monitor(
    'importDialogImportAction$',
    new Subject<{ uid: string; importName: string }>()
);

export const selectedApiHandlerErrors$ = monitor(
    'apiHandlerDetailsErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const selectedApiHandlerDetails$ = monitor(
    'selectedApiHandlerDetails$',
    new BehaviorSubject<ApiHandler | undefined>(undefined)
);
export const savingSelectedApiHandler$ = monitor('savingSelectedApiHandler$', new BehaviorSubject(false));
export const apiHandlersBeingDeleted$ = monitor(
    'apiHandlersBeingDeleted$',
    new BehaviorSubject<Record<string, boolean>>({})
);
export const apiHandlerImportDialogOpen$ = monitor('apiHandlerImportDialogOpen$', new BehaviorSubject(false));
export const apiHandlerImportDialogErrors$ = monitor(
    'apiHandlerImportDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

openApiHandlerImportDialogAction$.subscribe(() => {
    apiHandlerImportDialogErrors$.next(undefined);
    apiHandlerImportDialogOpen$.next(true);
});
closeApiHandlerImportDialogAction$.subscribe(() => apiHandlerImportDialogOpen$.next(false));

// eslint-disable-next-line sonarjs/cognitive-complexity
importApiHandlerDialogImportAction$.subscribe(({ uid, importName }) => {
    apiHandlerImportDialogErrors$.next(undefined);

    const apiHandler = selectedWorkspaceResources$.value.apiHandlers.find((ah) => ah.uid === uid);

    if (apiHandler) {
        if (importName.trim().length === 0) {
            apiHandlerImportDialogErrors$.next('Import Name is required.');
        } else if (!Number.isNaN(+importName.charAt(0))) {
            apiHandlerImportDialogErrors$.next('Import Name must not start with a number.');
        } else {
            if (!/^[A-Za-z0-9]*$/.test(importName)) {
                apiHandlerImportDialogErrors$.next('Import Name must only contain alphanumeric characters.');
            } else {
                const selectedResource = selectedWorkspaceSelectedResource$.value;

                if (selectedResource?.resourceType === 'SCRIPT') {
                    const script = selectedWorkspaceResources$.value.scripts.find(
                        (s) => s.uid === selectedResource.uid
                    );

                    if (script) {
                        const nested = script.name.split('/');

                        if (nested.length > 1) {
                            const backPaths = '../'.repeat(nested.length - 1);
                            prependEditorContentAction$.next({
                                scriptName: script.name,
                                content: `import ${importName.trim()} from '${backPaths}api/${apiHandler.path}';\n`,
                            });
                        } else {
                            prependEditorContentAction$.next({
                                scriptName: script.name,
                                content: `import ${importName.trim()} from './api/${apiHandler.path}';\n`,
                            });
                        }
                    }
                }

                apiHandlerImportDialogOpen$.next(false);
            }
        }
    } else {
        apiHandlerImportDialogErrors$.next('API Connection is required.');
    }
});

createApiHandlerAction$
    .pipe(
        map(async (event) => {
            loadingAppSelector$.next(true);

            try {
                const { uid } = await createApiHandler(
                    selectedWorkspaceUid$.value ?? '',
                    event.appUid,
                    event.apiHandlerTypeUid
                );

                apiHandlerCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while creating API Connection', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to create API Connection, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }

            loadingAppSelector$.next(false);
            appSelectorOpen$.next(false);
        })
    )
    .subscribe();

saveApiHandlerAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (event) => {
            const connection = loggedInUserConnections$.value.find((el) => el.uid === event.connectionUid);

            const initiateSelectedApiHandlersSave = async (): Promise<void> => {
                savingSelectedApiHandler$.next(true);
                selectedApiHandlerErrors$.next(undefined);

                try {
                    await saveApiHandler(
                        event.uid,
                        selectedEnvironmentUid$.value ?? '',
                        event.path,
                        event.connectionUid
                    );

                    const details = selectedApiHandlerDetails$.value;
                    if (details) {
                        selectedApiHandlerDetails$.next({
                            ...details,
                            ...event,
                        });
                    }

                    apiHandlerSavedAction$.next(event.uid);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'API Connection saved.',
                    });
                } catch (e) {
                    if (e instanceof InformativeError) {
                        selectedApiHandlerErrors$.next(e.message);
                    } else {
                        selectedApiHandlerErrors$.next(
                            'Failed to save API Connection, please try again, if the issue persists please contact support.'
                        );
                        console.error('Error while saving API Handler', e);
                    }
                }

                savingSelectedApiHandler$.next(false);
            };
            if (connection && connection.authorized) {
                await initiateSelectedApiHandlersSave();
            } else {
                promptQuestion({
                    message:
                        'Are you sure you want to save the API Connection without having authorized the related connection?',
                    onProceed: async () => await initiateSelectedApiHandlersSave(),
                });
            }
        })
    )
    .subscribe();

deleteApiHandlerAction$
    .pipe(
        map(async (event) => {
            const initiateApiConnectionDelete = async (): Promise<void> => {
                apiHandlersBeingDeleted$.next({
                    ...apiHandlersBeingDeleted$.value,
                    [event.uid]: true,
                });

                try {
                    const response = await deleteApiHandler(event.uid, event.ignoreWarnings);

                    if (!event.ignoreWarnings && response.warning) {
                        promptQuestion({
                            message: response.warning,
                            onProceed: () =>
                                deleteApiHandlerAction$.next({
                                    ...event,
                                    ignoreWarnings: true,
                                }),
                        });
                    } else if (!response.warning) {
                        apiHandlerDeletedAction$.next(event.uid);
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'API Connection deleted.',
                        });
                    }
                } catch (e) {
                    console.error('Error while deleting API Connection', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to delete API Connection, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }

                apiHandlersBeingDeleted$.next({
                    ...apiHandlersBeingDeleted$.value,
                    [event.uid]: false,
                });
            };

            if (!event.ignoreWarnings) {
                promptQuestion({
                    message: 'Are you sure you want to delete the API Connection?',
                    onProceed: async () => await initiateApiConnectionDelete(),
                });
            } else {
                await initiateApiConnectionDelete();
            }
        })
    )
    .subscribe();

apiHandlerCreatedAction$.subscribe((uid) => {
    if (
        selectedWorkspaceUid$.value === wizardWorkspaceDetails$.value?.workspaceUid &&
        wizardState$.value === WizardState.ACTIVE &&
        wizardStep$.value === WizardStep.ADD_API_CONNECTION
    ) {
        updateWizardStepAction$.next(WizardStep.SAVE_API_CONNECTION);
        saveWizardCreatedWorkspaceResourceAction$.next(uid);
    }
    navigateToApiHandlerAction$.next(uid);
});

apiHandlerSavedAction$.subscribe(() => {
    const details = selectedApiHandlerDetails$.value;
    if (details) {
        selectedApiHandlerDetails$.next({
            ...details,
            selectedSharedConnection: undefined,
        });
    }
});

loadWorkspaceResourcesWhenEventIsEmitted(apiHandlerCreatedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(apiHandlerSavedAction$);

export type CreateApiHandler = {
    appUid: string;
    apiHandlerTypeUid: string;
};

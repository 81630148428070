import {
    Box,
    Button,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
    OrganisationsUtilizationRequest,
    OrganisationsUtilizationData,
} from '../../data/report/organisationsUtilization';
import { formatBytesToString } from '../../utils/formatBytes';
import { PageContainer } from '../layout/PageComponents';
import { StyledTitleContainer } from './UsersWithWorkspacesReportPage';

export interface OrganisationsUtilizationPageProps extends OrganisationsUtilizationRequest {
    data?: OrganisationsUtilizationData;
    onSearch(timePeriod: string): void;
}

export const OrganisationsUtilizationPage: React.FC<OrganisationsUtilizationPageProps> = ({
    data,
    timePeriod = `${new Date().getFullYear()}.${new Date().getMonth() + 1}`,
    onSearch,
}) => {
    const [timePeriodFilterValue, setTimePeriodFilterValue] = useState('');

    useEffect(() => {
        setTimePeriodFilterValue(timePeriod);
    }, []);

    // Ugly hack to make sure the router does not treat the time period as a number
    const handleSearch = (): void => onSearch(`d${timePeriodFilterValue}`);

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledTitleContainer>
                <Typography variant="h3">Teams Utilization</Typography>
            </StyledTitleContainer>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Time Period"
                            variant="outlined"
                            value={timePeriodFilterValue}
                            onChange={(event) => setTimePeriodFilterValue(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            {data && (
                <>
                    <Box sx={{ paddingLeft: 2 }}>
                        <Typography>Total Invocations: {data.totalInvocations}</Typography>
                        <Typography>Total GB Seconds: {data.totalGbSeconds}</Typography>
                        <Typography>Teams: {data.organisations.length}</Typography>
                        {data.costs.map((c, i) => (
                            <Typography key={i}>
                                Cost for {c.service}: {c.amount}
                            </Typography>
                        ))}
                        <Typography>Total Cost: {data.totalCost}</Typography>
                        <Typography>Cost per Invocation: {data.costPerInvocation}</Typography>
                        <Typography>Cost per GB Second: {data.costPerGbSecond}</Typography>
                        <Typography>Currency: {data.costUnit}</Typography>
                    </Box>
                    {data.organisations.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Team ID</TableCell>
                                    <TableCell>Team Name</TableCell>
                                    <TableCell>Team Owner</TableCell>
                                    <TableCell>Script Invocations</TableCell>
                                    <TableCell>Script GB Seconds</TableCell>
                                    <TableCell>AVG GB Seconds</TableCell>
                                    <TableCell>Record Storage Capacity</TableCell>
                                    <TableCell>Cost (Invocations)</TableCell>
                                    <TableCell>Cost (GB Seconds)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.organisations.map((organisation) => (
                                    <TableRow id={organisation.details.uid} key={organisation.details.uid}>
                                        <TableCell>{organisation.details.uid}</TableCell>
                                        <TableCell>{organisation.details.name}</TableCell>
                                        <TableCell>{organisation.details.owner}</TableCell>
                                        <TableCell>{organisation.utilization.script.invocations}</TableCell>
                                        <TableCell>{organisation.utilization.script.gbSeconds}</TableCell>
                                        <TableCell>{organisation.utilization.script.avgGbSeconds}</TableCell>
                                        <TableCell>
                                            {formatBytesToString(organisation.recordStorageCapacity, 'B')}
                                        </TableCell>
                                        <TableCell>{organisation.cost.invocation}</TableCell>
                                        <TableCell>{organisation.cost.gbSeconds}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div>No Teams found</div>
                    )}
                </>
            )}
        </PageContainer>
    );
};

import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ProductIcon } from '../../icons/ProductIcon';
import { InfoIcon } from '../../icons/InfoIcon';

export interface QuickFilters {
    filters: {
        name: string;
        templateUids: string[];
        checked: boolean;
    }[];
    activeFiltersCount: number;
}

interface QuickFilterProps {
    filters: QuickFilters;
    onUpdate(app: string): void;
    onReset(): void;
}

const StyledQuickFilterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '90%',
    '& .quickFilterChecked': {
        outline: theme.constants.borderSelected,
    },
}));

const StyledQuickFilterItemBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    borderRadius: theme.constants.borderRadius,
    cursor: 'pointer',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: theme.spacing(0, 1, 1, 0),
    outline: `1px solid ${theme.palette.action.focus}`,
    padding: theme.spacing(0.5, 1),
}));

const StyledIconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    placeContent: 'center',
}));

const StyledBoxCounter = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    ...theme.typography.radiusCircle,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    height: 20,
    justifyContent: 'center',
    marginRight: theme.spacing(0.5),
    width: 20,
}));

const ResetStyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    justifySelf: 'end',
    paddingRight: theme.spacing(1.5),
    ':hover': {
        textDecoration: 'underline',
    },
}));

export const QuickFilter: React.FC<QuickFilterProps> = ({ filters, onUpdate, onReset }) => {
    return (
        <>
            <Box display="flex" alignItems="center" margin={(theme) => theme.spacing(2, 0, 0, 4)}>
                <InfoIcon
                    sx={{
                        marginRight: 1,
                    }}
                />
                <Typography color="text.secondary" noWrap>
                    <strong>Filter</strong> templates by selecting the app.
                </Typography>
            </Box>
            <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                padding={(theme) => theme.spacing(1.5, 0.5, 0)}
            >
                <StyledQuickFilterWrapper>
                    {filters.filters.map((filter, index) => (
                        <StyledQuickFilterItemBox
                            key={index}
                            onClick={() => onUpdate(filter.name)}
                            className={filter.checked ? 'quickFilterChecked' : ''}
                        >
                            <StyledIconBox>
                                <ProductIcon name={filter.name} />
                            </StyledIconBox>
                            <Typography
                                fontWeight={(theme) => theme.typography.fontWeightBold}
                                paddingRight={(theme) => theme.spacing(0.5)}
                                noWrap
                            >
                                {filter.name}
                            </Typography>
                            <Typography color="primary">{`[${filter.templateUids.length}]`}</Typography>
                        </StyledQuickFilterItemBox>
                    ))}
                </StyledQuickFilterWrapper>
                <Box display="flex" height="fit-content">
                    {filters.activeFiltersCount > 0 && (
                        <StyledBoxCounter>{filters.activeFiltersCount} </StyledBoxCounter>
                    )}
                    <ResetStyledTypography onClick={onReset}>Reset</ResetStyledTypography>
                </Box>
            </Box>
        </>
    );
};

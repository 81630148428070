import { useObservableState } from 'observable-hooks';
import {
    customRoleValidationError$,
    loggedInUserDetails$,
    resetUserPasswordAction$,
    updateUserDetailsAction$,
    userDetailsUpdating$,
} from '../../store/user';
import { Profile, UpdateProfileEvent } from '../../components/profile';
import { useNavigate } from 'react-location';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { industryRoles$ } from '../../store/roles';

export const ProfileContainer: React.FC = () => {
    const isUpdating = useObservableState(userDetailsUpdating$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const industryRoles = useObservableState(industryRoles$);
    const navigate = useNavigate();
    const [showOtherRoleField, setShowOtherRoleField] = useState(loggedInUserDetails?.customRole ? true : false);
    const validationError = useObservableState(customRoleValidationError$);

    const handleCancel = (): void => {
        navigate({ to: '/' });
    };

    const handleReset = (): void => {
        if (loggedInUserDetails?.email) {
            resetUserPasswordAction$.next(loggedInUserDetails.email);
        }
    };

    const handleUpdate = ({ firstName, lastName, company, roleUid, customRole }: UpdateProfileEvent): void => {
        const customRoleValue = checkIfCustomRole(roleUid) ? customRole : '';
        updateUserDetailsAction$.next({
            uid: loggedInUserDetails?.uid ?? '',
            email: loggedInUserDetails?.email ?? '',
            firstName,
            lastName,
            company,
            roleUid,
            customIndustryRole: customRoleValue,
        });
    };

    const handleChangeRole = (uid: string): void => {
        if (checkIfCustomRole(uid)) {
            setShowOtherRoleField(true);
        } else {
            setShowOtherRoleField(false);
        }
    };

    const checkIfCustomRole = (roleUid: string): boolean => {
        return industryRoles.some((r) => r.uid === roleUid && r.label === 'Other');
    };

    const isManualReg = useAuth0().user?.sub?.startsWith('auth0') ?? false;

    const remappedIndustryRoles = industryRoles?.map((r) => {
        return {
            value: r.uid,
            name: r.label,
        };
    });

    return (
        <Profile
            email={loggedInUserDetails?.email ?? ''}
            firstName={loggedInUserDetails?.firstName ?? ''}
            lastName={loggedInUserDetails?.lastName ?? ''}
            company={loggedInUserDetails?.company ?? ''}
            roleUid={loggedInUserDetails?.roleUid ?? ''}
            roles={remappedIndustryRoles}
            customRole={loggedInUserDetails?.customRole ?? ''}
            showOtherRoleField={showOtherRoleField}
            showPasswordReset={isManualReg}
            onInitiateAvatarUpload={() => alert('Functionality not yet implemented')}
            onPasswordReset={handleReset}
            onUpdate={handleUpdate}
            onCancel={handleCancel}
            onChangeRole={handleChangeRole}
            loading={isUpdating}
            validationError={validationError}
        />
    );
};

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface MondayEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    mondayUrl?: string;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const MondayEventListenerSetupDialog: React.FC<MondayEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    mondayUrl,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.MONDAY.NAME;

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle variant="h6">Event Listener setup instructions for {appName}</DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />

                            <DialogContentText variant="subtitle2">
                                You need to setup a webhook in your {appName} board in order to listen to events in
                                ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                {mondayUrl && (
                                    <li>
                                        Visit your {appName}{' '}
                                        <Link target="_blank" href={mondayUrl}>
                                            account
                                        </Link>{' '}
                                        page.
                                    </li>
                                )}
                                {!mondayUrl && <li>Open https://[YOUR_MONDAY.COM_ACCOUNT] URL in your browser.</li>}
                                <li>Open the board you wish to setup the Event Listener for.</li>
                                <li>
                                    On the top right corner, click on <strong>Integrate</strong>.
                                </li>
                                <li>
                                    Type in <strong>webhooks</strong> in the search bar and select{' '}
                                    <strong>webhooks</strong>.
                                </li>
                                <li>
                                    Select ONLY the following event to listen to:{' '}
                                    <strong>{eventTypeName}, send a webhook</strong> and click{' '}
                                    <strong>Add to board</strong>.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>Webhook URL</strong> field.
                                </li>
                                <li>
                                    Click <strong>Connect</strong>. At this point, {appName} will test the connection to
                                    this webhook url.
                                </li>
                                <li>
                                    If successful, an <strong>Add to board</strong> button will appear on the bottom
                                    right corner of the screen. Click on this to complete the setup.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Done</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    initiate,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface AtlassianCloudConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    cloudId?: string;
}

export const atlassianCloudManagedConnectionOpenDialogAction$ = monitor(
    'atlassianCloudManagedConnectionOpenDialogAction$',
    new Subject<AtlassianCloudConnectionDetails>()
);
export const atlassianCloudManageConnectionCloseDialogAction$ = monitor(
    'atlassianCloudManageConnectionCloseDialogAction$',
    new Subject<void>()
);
export const atlassianCloudSaveConnectionAction$ = monitor(
    'atlassianCloudSaveConnectionAction$',
    new Subject<string>()
);
export const atlassianCloudConnectionCreatedAction$ = monitor(
    'atlassianCloudConnectionCreatedAction$',
    new Subject<string>()
);
export const atlassianCloudConnectionSavedAction$ = monitor(
    'atlassianCloudConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const atlassianCloudManageConnectionInitiateAuthorizeAction$ = monitor(
    'atlassianCloudManageConnectionInitiateAuthorizeAction$',
    new Subject<string>()
);

export const atlassianCloudManageConnectionDialogOpen$ = monitor(
    'atlassianCloudManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const atlassianCloudConnectionSaving$ = monitor('atlassianCloudConnectionSaving$', new BehaviorSubject(false));
export const atlassianCloudManageConnectionAuthorizeLoading$ = monitor(
    'atlassianCloudManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const atlassianCloudManageConnectionDetails$ = monitor(
    'atlassianCloudManageConnectionDetails$',
    new BehaviorSubject<AtlassianCloudConnectionDetails | undefined>(undefined)
);
export const atlassianCloudManageConnectionDialogErrors$ = monitor(
    'atlassianCloudManageConnectionDialog',
    new BehaviorSubject<string | undefined>(undefined)
);

atlassianCloudManagedConnectionOpenDialogAction$.subscribe((details) => {
    atlassianCloudManageConnectionDialogOpen$.next(true);
    atlassianCloudManageConnectionDialogErrors$.next(undefined);
    atlassianCloudManageConnectionDetails$.next(details);
});

atlassianCloudManageConnectionCloseDialogAction$.subscribe(() => {
    atlassianCloudManageConnectionDialogOpen$.next(false);
    atlassianCloudManageConnectionDetails$.next(undefined);
});

atlassianCloudConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

atlassianCloudSaveConnectionAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (name) => {
            atlassianCloudConnectionSaving$.next(true);
            atlassianCloudManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = atlassianCloudManageConnectionDetails$.value ?? {};

            if (!connectionType) {
                throw Error('Atlassian Cloud Connector type not defined');
            }
            const connectionTypeUid = connectionType.uid;

            if (uid) {
                if (saveConnectionAllowed(uid)) {
                    await saveAtlassianCloudConnection(uid, name, connectionTypeUid);
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            await saveAtlassianCloudConnection(uid, name, connectionTypeUid);
                        },
                    });
                }
            } else {
                promptQuestion({
                    message: AYSYWTSAETCWHAI,
                    onProceed: async () => {
                        try {
                            const { uid } = await createConnection(
                                connectionType.uid,
                                name,
                                connectionType.apiHandlerTypes[0]?.uid ?? '',
                                connectionType.eventListenerTypes[0]?.uid ?? ''
                            );

                            atlassianCloudConnectionCreatedAction$.next(uid);
                            atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                            atlassianCloudManageConnectionCloseDialogAction$.next();
                            publishLocalFeedbackEventAction$.next({
                                level: 'SUCCESS',
                                message: 'Connector created.',
                            });
                            connectionCreatedFirstTimeAction$.next();
                        } catch (e) {
                            handleManageConnectionError(
                                e,
                                atlassianCloudManageConnectionDialogErrors$,
                                ATLASSIAN_CLOUD_APP
                            );
                        }
                    },
                });
            }
            atlassianCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

atlassianCloudManageConnectionInitiateAuthorizeAction$
    .pipe(
        map(async (name) => {
            atlassianCloudConnectionSaving$.next(true);
            atlassianCloudManageConnectionAuthorizeLoading$.next(true);
            atlassianCloudManageConnectionDialogErrors$.next(undefined);

            const baseUrl = configTopic$.value.connection?.atlassianCloud?.baseUrl;
            if (!baseUrl) {
                throw new Error('No Atlassian Cloud Connector URL configured in meta');
            }
            const initiateUrl = `${baseUrl}/initiate`;

            const { uid, connectionType } = atlassianCloudManageConnectionDetails$.value ?? {};

            if (!connectionType) {
                throw Error('Atlassian Cloud Connector type not defined');
            }
            const connectionTypeUid = connectionType.uid;

            const connectionTypeName = connectionType.name.toLowerCase().replaceAll(' ', '-');

            if (uid) {
                try {
                    await saveConnection(uid, name);
                    atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    const response = await initiate(
                        { connectionId: uid, connectionType: connectionTypeName },
                        initiateUrl
                    );
                    openConsentWindow(response.location, atlassianCloudManageConnectionAuthorizeLoading$);
                } catch (e) {
                    handleManageConnectionError(e, atlassianCloudManageConnectionDialogErrors$, ATLASSIAN_CLOUD_APP);
                    atlassianCloudManageConnectionAuthorizeLoading$.next(false);
                }
            } else {
                try {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    atlassianCloudConnectionCreatedAction$.next(uid);
                    atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    atlassianCloudManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    atlassianCloudConnectionSaving$.next(false);
                    const response = await initiate(
                        { connectionId: uid, connectionType: connectionTypeName },
                        initiateUrl
                    );
                    openConsentWindow(response.location, atlassianCloudManageConnectionAuthorizeLoading$);
                    connectionCreatedFirstTime$.next(true);
                } catch (e) {
                    handleManageConnectionError(e, atlassianCloudManageConnectionDialogErrors$, ATLASSIAN_CLOUD_APP);
                    atlassianCloudManageConnectionAuthorizeLoading$.next(false);
                }
            }

            atlassianCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(atlassianCloudConnectionSavedAction$);

const saveAtlassianCloudConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
        atlassianCloudManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, atlassianCloudManageConnectionDialogErrors$, ATLASSIAN_CLOUD_APP);
    }
};

const ATLASSIAN_CLOUD_APP = 'Atlassian Cloud';

import { Invoker } from '@avst-stitch/repository-lib/lib/types';
import { SESSION_ID } from '..';
import { configTopic$, sessionExpired$, showSessionExpiredWarning$, stitchSession$ } from '../store/config';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { selectedWorkspace$ } from '../store/workspace';
import { NotificationBannerDetails, notificationBannerDetails$ } from '../store/feedback';
import { getFetchOptions } from './fetch';
import { sleep } from './sleep';
import { WorkspaceLockDetails } from './types';

export class InformativeError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'InformativeError';
    }
}

export class PermissionError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'PermissionError';
    }
}

export class VerificationRequiredError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'VerificationRequiredError';
    }
}

export class CompilationError extends InformativeError {
    constructor(message: string) {
        super(message);
        this.name = 'CompilationError';
    }
}

export class BundlingError extends InformativeError {
    constructor(message: string) {
        super(message);
        this.name = 'BundlingError';
    }
}

const MAX_RETRIES = 3;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const repositoryInvoker: Invoker = async (command, request: unknown) => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    if (!process.env.STORYBOOK) {
        const refetch = async (command: string, request: unknown, retry = 0): Promise<unknown> => {
            const fetchOptions = getFetchOptions(
                {
                    Authorization: stitchSession$.value?.jwt ?? '',
                    'x-stitch-session-id': SESSION_ID,
                },
                request
            );
            const repositoryUrl = configTopic$.value.repository?.baseUrl;

            if (!repositoryUrl) {
                throw new Error('No repository base url configured in meta');
            }

            const response = await fetch(`${repositoryUrl}/rpc/${command.toUpperCase()}`, fetchOptions);
            const notificationMessage = response.headers.get('x-stitch-notification-message');

            if (notificationMessage) {
                notificationBannerDetails$.next({
                    message: notificationMessage,
                    level: mapNotificationLevel(response.headers.get('x-stitch-notification-level')),
                });
            } else if (notificationBannerDetails$.value) {
                notificationBannerDetails$.next(undefined);
            }

            if (!response.ok) {
                switch (response.status) {
                    case 400:
                        try {
                            const message = await response.text();
                            if (message) {
                                throw new InformativeError(message);
                            }
                        } catch (e) {
                            if (e instanceof InformativeError) {
                                throw e;
                            }
                            console.error('Error while processing error', e);
                        }
                        break;
                    case 401:
                        if (retry < MAX_RETRIES) {
                            return await refetch(command, request, ++retry);
                        } else {
                            showSessionExpiredWarning$.next(true);
                            sessionExpired$.next(true);
                            throw Error('User unauthorized');
                        }
                    case 402:
                        try {
                            const message = await response.text();
                            if (message) {
                                throw new VerificationRequiredError(message);
                            }
                        } catch (e) {
                            if (e instanceof VerificationRequiredError) {
                                throw e;
                            }
                            console.error('Error while processing 402 error', e);
                        }
                        break;
                    case 403:
                        try {
                            const message = await response.text();
                            if (message) {
                                throw new PermissionError(message);
                            }
                        } catch (e) {
                            if (e instanceof PermissionError) {
                                throw e;
                            }
                            console.error('Error while processing error', e);
                        }
                        break;
                    case 409:
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message: "Couldn't edit workspace because the workspace is being edited by someone else.",
                        });

                        response
                            .json()
                            .then((locked: WorkspaceLockDetails) => {
                                const selectedWorkspace = selectedWorkspace$.value;
                                if (selectedWorkspace) {
                                    selectedWorkspace$.next({ ...selectedWorkspace, locked });
                                }
                            })
                            .catch((e) =>
                                console.error('Failed to extract workspace lock information from 409 response', e)
                            );

                        throw new InformativeError('Workspace is locked.');
                    case 429:
                        await sleep(1000);
                        return await refetch(command, request, ++retry);
                    default:
                        throw Error(`Unexpected status while communicating with repository: ${response.status}`);
                }
            }

            const respBody = await response.text();

            if (respBody) {
                return JSON.parse(respBody);
            } else {
                return undefined;
            }
        };

        return await refetch(command, request);
    }
};

const mapNotificationLevel = (level: string | null): NotificationBannerDetails['level'] => {
    switch (level) {
        case 'DANGER':
            return 'error';
        case 'WARN':
            return 'warning';
        default:
            return 'info';
    }
};

import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { BitbucketIcon } from './BitbucketIcon';
import { JiraCloudIcon } from './JiraCloudIcon';
import { SlackIcon } from './SlackIcon';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ServiceNowIcon } from './ServiceNowIcon';
import { JiraOnPremiseIcon } from './JiraOnPremiseIcon';
import { ConfluenceIcon } from './ConfluenceIcon';
import { JiraServiceManagementIcon } from './JiraServiceManagementIcon';
import { GoogleSheetsIcon } from './GoogleSheetsIcon';
import { GenericIcon } from './GenericIcon';
import { GitlabIcon } from './GitlabIcon';
import { GithubIcon } from './GithubIcon';
import { MondayIcon } from './MondayIcon';
import { TempoIcon } from './TempoIcon';
import { GoogleCalendarIcon } from './GoogleCalendarIcon';
import { MicrosoftIcon } from './MicrosoftIcon';
import { ZoomIcon } from './ZoomIcon';
import { SalesforceIcon } from './SalesforceIcon';
import { StatuspageIcon } from './StatuspageIcon';
import { TrelloIcon } from './TrelloIcon';
import { OpsgenieIcon } from './OpsgenieIcon';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface ProductIconProps extends SvgIconProps {
    readonly name?: string;
}

export const ProductIcon: React.FC<ProductIconProps> = ({ name }) => {
    switch (name) {
        case APP.GENERIC.NAME:
            return <GenericIcon />;
        case APP.JIRA_CLOUD.NAME:
            return <JiraCloudIcon />;
        case APP.BITBUCKET_CLOUD.NAME:
        case APP.BITBUCKET_ON_PREMISE.NAME:
            return <BitbucketIcon />;
        case APP.JIRA_ON_PREMISE.NAME:
            return <JiraOnPremiseIcon />;
        case APP.CONFLUENCE_CLOUD.NAME:
        case APP.CONFLUENCE_ON_PREMISE.NAME:
            return <ConfluenceIcon />;
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD_ASSETS.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE_ASSETS.NAME:
            return <JiraServiceManagementIcon />;
        case APP.TRELLO.NAME:
            return <TrelloIcon />;
        case APP.SERVICENOW.NAME:
            return <ServiceNowIcon />;
        case APP.SLACK.NAME:
            return <SlackIcon />;
        case APP.GOOGLE_SHEETS.NAME:
            return <GoogleSheetsIcon />;
        case APP.GITHUB.NAME:
            return <GithubIcon />;
        case APP.MONDAY.NAME:
            return <MondayIcon />;
        case APP.GITLAB.NAME:
            return <GitlabIcon />;
        case APP.TEMPO_CLOUD.NAME:
        case APP.TEMPO_TIMESHEETS_ON_PREMISE.NAME:
        case APP.TEMPO_PLANNER_ON_PREMISE.NAME:
            return <TempoIcon />;
        case APP.GOOGLE_CALENDAR.NAME:
            return <GoogleCalendarIcon />;
        case APP.MICROSOFT.NAME:
            return <MicrosoftIcon />;
        case APP.ZOOM.NAME:
            return <ZoomIcon />;
        case APP.SALESFORCE.NAME:
            return <SalesforceIcon />;
        case APP.STATUSPAGE.NAME:
            return <StatuspageIcon />;
        case APP.OPSGENIE.NAME:
            return <OpsgenieIcon />;
        default:
            return <HelpOutlinedIcon />;
    }
};

import React, { useState } from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { featureFlagsTopic$ } from '../../store/config';
import { useObservableState } from 'observable-hooks';
import { Theme, useTheme } from '@mui/material/styles';

export interface ConsoleHeaderProps {
    onLiveFeedback?(checked: boolean): void;
    onFilterByEnvironment?(checked: boolean): void;
    onLogLevelChecked(logs: LogLevel[]): void;
    onClearLog(): void;
    filterByEnvironment: boolean;
    logLevels: LogLevel[];
    logLevelsCount: number;
}

interface LogLevel {
    level: string;
    checked: boolean;
}

interface LogLevelTypeIconProps {
    type: string;
}

const iconStyle1 = (theme: Theme) =>
    ({
        fontSize: theme.typography.h5.fontSize,
        marginRight: theme.spacing(1),
    } as const);

const iconStyle2 = (theme: Theme) =>
    ({
        ...iconStyle1(theme),
        color: theme.palette.text.primary,
        marginRight: theme.spacing(0.5),
    } as const);

const StyledFlexContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    display: 'flex',
    height: 28,
    paddingLeft: theme.spacing(1.3),
    width: '100%',
}));

const StyledTypography = styled(Typography)({
    display: 'flex',
});

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiFormControlLabel-root': {
        height: 28,
        margin: 0,
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
}));

const StyledFormGroupDropdown = styled(FormGroup)(({ theme }) => ({
    display: 'flex',
    justifyItems: 'center',
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    '& .MuiFormControlLabel-root': {
        height: 28,
        margin: 0,
    },
    ':hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    cursor: 'pointer',
}));

const StyledBoxCounter = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    ...theme.typography.radiusCircle,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: theme.typography.body2.fontSize,
    height: 18,
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    width: 18,
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    paddingRight: theme.spacing(0.8),
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

const StyledLine = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    borderColor: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderWidth: '0 1px 0 0',
    height: 18,
    margin: theme.spacing(1),
    width: 0,
}));

const StyledRoundedBox = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    cursor: 'pointer',
    height: 24,
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    '&:hover': {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        '& .MuiTypography-root': {
            color: theme.palette.secondary.main,
        },
    },
}));

const StyledDropdownBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    boxShadow: theme.constants.boxShadow,
    display: 'flex',
    flexDirection: 'column',
    height: 180,
    justifyContent: 'center',
    position: 'absolute',
    top: '28px',
    width: 140,
    zIndex: '1',
}));

const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
    ...iconStyle1(theme),
}));

const StyledDeleteSweepOutlinedIcon = styled(DeleteSweepOutlinedIcon)(({ theme }) => ({
    ...iconStyle1(theme),
}));

const StyledBugReportOutlinedIcon = styled(BugReportOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledWarningAmberOutlinedIcon = styled(WarningAmberOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledReportGmailerrorredOutlinedIcon = styled(ReportGmailerrorredOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const StyledCheckCircleOutlineOutlinedIcon = styled(CheckCircleOutlineOutlinedIcon)(({ theme }) => ({
    ...iconStyle2(theme),
}));

const LogLevelTypeIcon: React.FC<LogLevelTypeIconProps> = ({ type }) => {
    switch (type) {
        case 'Debug':
            return <StyledBugReportOutlinedIcon className="lightIcon" />;
        case 'Info':
            return <StyledInfoOutlinedIcon className="lightIcon" />;
        case 'Warn':
            return <StyledWarningAmberOutlinedIcon className="lightIcon" />;
        case 'Error':
            return <StyledReportGmailerrorredOutlinedIcon className="lightIcon" />;
        case 'Success':
            return <StyledCheckCircleOutlineOutlinedIcon className="lightIcon" />;
        default:
            return <HelpTwoToneIcon className="lightIcon" />;
    }
};

export const ConsoleHeader: React.FC<ConsoleHeaderProps> = ({
    onClearLog,
    onLiveFeedback,
    onFilterByEnvironment,
    logLevels,
    onLogLevelChecked,
    logLevelsCount,
    filterByEnvironment,
}) => {
    const theme = useTheme();

    const [logLevelDropdownOpen, setLogLevelDropdownOpen] = useState(false);
    const featureFlag = useObservableState(featureFlagsTopic$);

    const handleLogLevelChange = (updatedLevel: string): void => {
        const updatedLogLevels = logLevels.map((logType) => {
            if (logType.level === updatedLevel) {
                return { ...logType, checked: !logType.checked };
            }
            return logType;
        });

        onLogLevelChecked(updatedLogLevels);
    };

    const handleLogLevelDropdown = (): void => {
        setLogLevelDropdownOpen(!logLevelDropdownOpen);
    };

    const dropdownIsVisible = { display: logLevelDropdownOpen ? 'flex' : 'none' };
    const logLevelButtonHighlighted = logLevelDropdownOpen
        ? {
              borderColor: theme.palette.secondary.main,
              color: theme.palette.secondary.main,
              '& .MuiTypography-root': {
                  color: theme.palette.secondary.main,
              },
          }
        : {};
    return (
        <StyledFlexContainer>
            <StyledFormGroup>
                <Tooltip
                    placement="top"
                    title="When disabled console will only display feedback that originates from manually triggered invocations."
                    describeChild
                >
                    <FormControlLabel
                        sx={{ whiteSpace: 'nowrap' }}
                        control={
                            <StyledCheckbox
                                defaultChecked
                                onChange={(event) => onLiveFeedback?.(event.target.checked)}
                            />
                        }
                        label="Live Feedback"
                    />
                </Tooltip>
            </StyledFormGroup>
            {featureFlag.newConsole && (
                <StyledFormGroup>
                    <Tooltip
                        placement="top"
                        title="Feedback is only displayed for the environment that you have currently selected."
                        describeChild
                    >
                        <FormControlLabel
                            sx={{ whiteSpace: 'nowrap' }}
                            control={
                                <StyledCheckbox
                                    checked={filterByEnvironment}
                                    onChange={(event) => onFilterByEnvironment?.(event.target.checked)}
                                />
                            }
                            label="Filter by Environment"
                        />
                    </Tooltip>
                </StyledFormGroup>
            )}
            <StyledBox>
                <StyledLine></StyledLine>
            </StyledBox>
            <ClickAwayListener onClickAway={() => setLogLevelDropdownOpen(false)}>
                <StyledBox>
                    <StyledRoundedBox onClick={handleLogLevelDropdown} sx={logLevelButtonHighlighted}>
                        <StyledMenuIcon />
                        <StyledTypography noWrap>Log Level</StyledTypography>
                        {logLevelsCount > 0 && <StyledBoxCounter>{logLevelsCount}</StyledBoxCounter>}
                    </StyledRoundedBox>
                    <StyledDropdownBox sx={dropdownIsVisible}>
                        {logLevels.map((x, i) => (
                            <StyledFormGroupDropdown key={i}>
                                <FormControlLabel
                                    control={
                                        <StyledCheckbox
                                            checked={x.checked}
                                            onChange={() => handleLogLevelChange(x.level)}
                                        />
                                    }
                                    label={
                                        <StyledBox>
                                            <LogLevelTypeIcon type={x.level} />
                                            <Typography>{x.level}</Typography>
                                        </StyledBox>
                                    }
                                />
                            </StyledFormGroupDropdown>
                        ))}
                    </StyledDropdownBox>
                </StyledBox>
            </ClickAwayListener>
            <StyledBox>
                <StyledRoundedBox onClick={onClearLog}>
                    <StyledDeleteSweepOutlinedIcon />
                    <StyledTypography noWrap>Clear Log</StyledTypography>
                </StyledRoundedBox>
            </StyledBox>
        </StyledFlexContainer>
    );
};

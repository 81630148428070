import { BehaviorSubject, map, Subject } from 'rxjs';
import { monitor } from './monitor';
import { loadApps } from './apps';
import { getOnboardingDetails, OnboardingDetails, saveOnboardingResponses } from '../data/onboarding';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { InformativeError, PermissionError } from '../utils/repository';
import { OnboardingFinishedEvent, OnboardingResponses } from '../components/onboarding/OnboardingWizard';
import { getIndustryRoles } from '../data/user';
import { industryRoles$ } from './roles';
import { saveOrganizationDetails, sendOrganizationMemberInvite } from '../data/organization';
import { ITIPPCS } from '../i18n';

export const onboardingWizardOpen$ = monitor('onboardingWizardOpen$', new BehaviorSubject(false));
export const onboardingWizardDetails$ = monitor(
    'onboardingWizardDetails$',
    new BehaviorSubject<OnboardingDetails | undefined>(undefined)
);
export const updatingDefaultOrganization$ = monitor('updatingDefaultOrganization$', new BehaviorSubject(false));

export const openOnboardingWizardAction$ = monitor('openOnboardingWizardAction$', new Subject<void>());
export const closeOnboardingWizardAction$ = monitor('closeOnboardingWizardAction$', new Subject<void>());
export const sendOrganizationInvitesAction$ = monitor(
    'sendOrganizationInvitesAction$',
    new Subject<{ organizationUid: string; emails: string[] }>()
);
export const finishOnboardingAction$ = monitor('finishOnboardingAction$', new Subject<OnboardingFinishedEvent>());
export const saveOnboardingResponsesAction$ = monitor(
    'saveOnboardingResponsesAction$',
    new Subject<OnboardingResponses>()
);

openOnboardingWizardAction$
    .pipe(
        map(async () => {
            try {
                const [details, roles] = await Promise.all([getOnboardingDetails(), getIndustryRoles(), loadApps()]);
                onboardingWizardDetails$.next(details);
                industryRoles$.next(roles);
                onboardingWizardOpen$.next(true);
            } catch (e) {
                console.error(e);
            }
        })
    )
    .subscribe();

closeOnboardingWizardAction$.subscribe(() => {
    onboardingWizardOpen$.next(false);
    onboardingWizardDetails$.next(undefined);
});

finishOnboardingAction$
    .pipe(
        map(async ({ organization, emails, ignoreUpdateOrganization, ...details }) => {
            if (!ignoreUpdateOrganization) {
                updatingDefaultOrganization$.next(true);
                try {
                    await saveOrganizationDetails(organization.name, organization.uid);

                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Team updated.' + (emails.length ? ' Sending invites.' : ''),
                    });
                } catch (e) {
                    console.error('Failed to update team', e);

                    let message: string;

                    if (e instanceof InformativeError || e instanceof PermissionError) {
                        message = e.message;
                    } else {
                        message = 'Error occured while updating your personal team. ' + ITIPPCS;
                    }
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message,
                    });
                }
            }
            updatingDefaultOrganization$.next(false);
            saveOnboardingResponsesAction$.next(details);
            if (emails.length) {
                sendOrganizationInvitesAction$.next({ organizationUid: organization.uid, emails });
            }
        })
    )
    .subscribe();

sendOrganizationInvitesAction$
    .pipe(
        map(async (details) => {
            const successfulInvites: string[] = [];
            const failedInvites: string[] = [];

            for (const email of details.emails) {
                try {
                    await sendOrganizationMemberInvite({ email, organizationUid: details.organizationUid });
                    successfulInvites.push(email);
                } catch (e) {
                    console.error(`Failed to send email to ${email}`, e);
                    failedInvites.push(email);
                }
            }

            if (successfulInvites.length) {
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: `Sent invite to ${successfulInvites.join(', ')}.`,
                });
            }

            if (failedInvites.length) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to send invite to ${failedInvites.join(', ')}.`,
                });
            }
        })
    )
    .subscribe();

saveOnboardingResponsesAction$
    .pipe(
        map(async (event) => {
            closeOnboardingWizardAction$.next();
            try {
                await saveOnboardingResponses({
                    optinResearch: event.optinResearch,
                    personalization: event.personalization,
                    selectedApps: event.selectedApps,
                    roleUid: event.selectedRole,
                    customRole: event.selectedCustomRole,
                    scriptingFamiliarityUid: event.selectedScriptingFamiliarity,
                    sharingPreferenceUid: event.selectedSharingPreference,
                });
            } catch (e) {
                console.error('Failed to save onboarding responses', e);
            }
        })
    )
    .subscribe();

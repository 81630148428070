import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { ResourceTreeItem } from './ResourceTreeItem';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { TreeNode } from './';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import { ScriptTreeClosedIcon, ScriptTreeOpenIcon } from '../../icons/StyledMuiIcons';

interface ResourceTreeFolderProps {
    name: string;
    readOnlyMode?: boolean;
    fullPath: string;
    itemList: TreeNode[];
    externalTriggerBaseUrl?: string;
    selectedNode?: string;
    language?: string;
    onToggleExpand?: () => void;
    openHandler(uid: string): void;
    triggerHandler?(uid: string): void;
    deleteHandler(uid: string, fullPath?: string): void;
}

const StyledMuiAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    '&:before': {
        display: 'none',
    },
}));

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    minHeight: 0,
    padding: 0,
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        backgroundColor: theme.palette.background.default,
        zIndex: 2,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        height: '20px',
        width: '20px',
    },
    '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

const StyledMuiAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    marginTop: theme.spacing(-1),
}));

const StyledBoxSummarySection = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 1, 0.5, 1.5),
    width: '100%',
    position: 'relative',
    '&:after': {
        borderTop: `1px dashed ${theme.palette.action.active}`,
        content: '""',
        height: '70%',
        left: 22,
        position: 'absolute',
        top: 14,
        width: 28,
        zIndex: 1,
    },
}));
const StyledResourceNameContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.action.active,
        zIndex: 2,
    },
}));
const StyledResourceName = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight + ' !important',
    marginLeft: theme.spacing(0.5),
}));

const StyledList = styled(List)(({ theme }) => ({
    paddingLeft: theme.spacing(3.5),
    paddingBottom: 0,
    position: 'relative',
    '&:after': {
        borderLeft: `1px dashed ${theme.palette.action.active}`,
        content: '""',
        height: `calc(100% - ${theme.spacing(1)})`,
        left: 50,
        position: 'absolute',
        top: 0,
        width: 28,
        zIndex: 1,
    },
}));

export const ResourceTreeFolder: React.FC<ResourceTreeFolderProps> = ({
    name,
    readOnlyMode = false,
    fullPath,
    itemList,
    selectedNode,
    externalTriggerBaseUrl,
    language,
    onToggleExpand,
    openHandler,
    triggerHandler,
    deleteHandler,
}) => {
    const [expanded, setExpanded] = useState(readLocalStorage(fullPath, false));

    const handleExpand = (): void => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        saveLocalStorage(fullPath, expanded);
        setTimeout(() => {
            onToggleExpand?.();
        }, 300);
    }, [expanded]);

    return (
        <>
            <StyledMuiAccordion expanded={expanded} data-test-id={`resource-tree-${name}`}>
                <StyledBoxSummarySection onClick={handleExpand}>
                    <StyledMuiAccordionSummary
                        expandIcon={
                            expanded ? (
                                <ScriptTreeOpenIcon className="lightIcon" />
                            ) : (
                                <ScriptTreeClosedIcon className="lightIcon" />
                            )
                        }
                    >
                        <StyledResourceNameContainer>
                            <FolderIcon />
                            <StyledResourceName>{name}</StyledResourceName>
                        </StyledResourceNameContainer>
                    </StyledMuiAccordionSummary>
                </StyledBoxSummarySection>
                <StyledMuiAccordionDetails>
                    <StyledList>
                        {itemList.map((item, i) => {
                            return (
                                <ResourceTreeItem
                                    sectionName="Scripts"
                                    readOnlyMode={readOnlyMode}
                                    key={i}
                                    uid={item.uid}
                                    fullPath={item.fullPath}
                                    language={language}
                                    subItems={item.subItems}
                                    name={item.name}
                                    unsaved={item.unsaved}
                                    deleting={item.deleting}
                                    executing={item.executing}
                                    externalTriggerBaseUrl={externalTriggerBaseUrl}
                                    openHandler={openHandler}
                                    triggerHandler={triggerHandler}
                                    selectedNode={selectedNode}
                                    deleteHandler={deleteHandler}
                                />
                            );
                        })}
                    </StyledList>
                </StyledMuiAccordionDetails>
            </StyledMuiAccordion>
        </>
    );
};

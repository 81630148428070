import { useObservableState } from 'observable-hooks';
import { SelectBillingDetailsDialog } from '../../components/organization/SelectBillingDetailsDialog';
import {
    addBillingDetailsToOrganizationAction$,
    addBillingDetailsToOrganizationActionV2$,
    billingEntities$,
    closeSelectBillingDetailsDialogAction$,
    selectBillingDetailsDialogLoading$,
    selectBillingDetailsDialogOpen$,
    selectBillingDetailsDialogSaving$,
    selectBillingDetailsError$,
} from '../../store/billing-details';
import { featureFlagsTopic$ } from '../../store/config';

export const SelectBillingDetailsDialogContainer: React.FC = () => {
    const billingEntities = useObservableState(billingEntities$);
    const errors = useObservableState(selectBillingDetailsError$);
    const saving = useObservableState(selectBillingDetailsDialogSaving$);
    const loading = useObservableState(selectBillingDetailsDialogLoading$);
    const open = useObservableState(selectBillingDetailsDialogOpen$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    return (
        <SelectBillingDetailsDialog
            open={open}
            billingEntities={billingEntities}
            errors={errors}
            loading={loading}
            saving={saving}
            onCancel={() => closeSelectBillingDetailsDialogAction$.next()}
            onCreate={(event) =>
                featureFlags.salablePlan
                    ? addBillingDetailsToOrganizationActionV2$.next(event)
                    : addBillingDetailsToOrganizationAction$.next(event)
            }
        />
    );
};

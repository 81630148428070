import { useObservableState } from 'observable-hooks';
import { OnboardingWizard } from '../components/onboarding/OnboardingWizard';
import { apps$ } from '../store/apps';
import { loggedInUserDetails$ } from '../store/user';
import {
    onboardingWizardDetails$,
    onboardingWizardOpen$,
    finishOnboardingAction$,
    updatingDefaultOrganization$,
} from '../store/onboarding';
import { documentationUrl, slackCommunityUrl, supportPortalUrl } from '../utils/documentation';
import { industryRoles$ } from '../store/roles';

export const OnboardingWizardContainer: React.FC = () => {
    const { email, firstName, lastName } = useObservableState(loggedInUserDetails$) || {};
    const onboardingWizardOpen = useObservableState(onboardingWizardOpen$);
    const saving = useObservableState(updatingDefaultOrganization$);
    const apps = useObservableState(apps$);
    const roles = useObservableState(industryRoles$);
    const details = useObservableState(onboardingWizardDetails$);

    return (
        <OnboardingWizard
            apps={apps.map(({ uid, connectionType, name }) => ({
                uid,
                type: connectionType.name,
                name,
            }))}
            defaultOrganization={details?.defaultOrganization ?? { name: '', uid: '' }}
            documentationUrl={documentationUrl}
            onFinish={(event) => finishOnboardingAction$.next(event)}
            open={onboardingWizardOpen}
            roles={roles}
            saving={saving}
            scriptingFamiliarityOptions={details?.scriptingFamiliarityOptions ?? []}
            sharingPreferences={details?.sharingPreferenceOptions ?? []}
            slackCommunityUrl={slackCommunityUrl}
            supportPortalUrl={supportPortalUrl}
            user={{ email: email ?? '', firstName: firstName ?? '', lastName: lastName ?? '' }}
            // youtubeChannelUrl={youtubeChannelUrl}
        />
    );
};

import React from 'react';
import { Card, CardContent, Link, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { DialogCloseButton, DialogTitleSmall } from '../dialog/DialogComponents';
import { SRConnectIcon } from '../icons/SRConnectIcon';
import { handleKeyUp } from '../../utils/handleKeyUp';

interface ConnectAndSupportProps {
    open?: boolean;
    onClose(): void;
    content: {
        title: string;
        description: string;
        link: string;
        linkText: string;
    }[];
}

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    '& .MuiTypography-root': {
        marginBottom: theme.spacing(1),
    },
}));

export const ConnectAndSupportModal: React.FC<ConnectAndSupportProps> = ({ open = false, onClose, content }) => {
    const items = content.map((c, i) => {
        return (
            <StyledCard key={i}>
                <CardContent sx={{ paddingBottom: '0px !important' }}>
                    <Typography variant="subtitle2">{c.title}</Typography>
                    <Typography>{c.description}</Typography>
                    <Link href={c.link} target="_blank">
                        {c.linkText}
                    </Link>
                </CardContent>
            </StyledCard>
        );
    });

    return (
        <>
            <Dialog
                open={open}
                sx={{
                    '& .MuiDialog-paperScrollPaper': {
                        maxWidth: 'unset',
                        width: 620,
                    },
                }}
                onClose={onClose}
                onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogCloseButton onClick={onClose} />
                <DialogTitleSmall title="Let's Connect" icon={<SRConnectIcon />} />
                <DialogContent>{items}</DialogContent>
            </Dialog>
        </>
    );
};

import { APP } from '@avst-stitch/repository-lib/constants';
import { ConnectionType } from '../data/apps';
import { EventListener } from '../data/event-listener';
import { apps$ } from '../store/apps';
import { bitbucketCloudEventListenerSetupDialogOpenAction$ } from '../store/connection/bitbucket-cloud/setup-event-listener';
import { genericEventListenerSetupDialogOpenAction$ } from '../store/connection/generic/setup-event-listener';
import { serviceNowEventListenerSetupDialogOpenAction$ } from '../store/connection/servicenow/setup-event-listener';
import { slackEventListenerSetupDialogOpenAction$ } from '../store/connection/slack/setup-event-listener';
import { selectedEnvironmentUid$ } from '../store/workspace';
import { configTopic$, stitchSession$ } from '../store/config';
import { getFetchOptions } from './fetch';
import { atlassianOnPremiseEventListenerSetupDialogOpenAction$ } from '../store/connection/atlassian-on-premise/setup-event-listener';
import { atlassianCloudEventListenerSetupDialogOpenAction$ } from '../store/connection/atlassian-cloud/setup-event-listener';
import { mondayEventListenerSetupDialogOpenAction$ } from '../store/connection/monday/setup-event-listener';
import { gitlabEventListenerSetupDialogOpenAction$ } from '../store/connection/gitlab/setup-event-listener';
import { githubEventListenerSetupDialogOpenAction$ } from '../store/connection/github/setup-event-listener';
import { bitbucketOnPremiseEventListenerSetupDialogOpenAction$ } from '../store/connection/bitbucket-on-premise/setup-event-listener';
import { microsoftEventListenerSetupDialogOpenAction$ } from '../store/connection/microsoft/setup-event-listener';
import { zoomEventListenerSetupDialogOpenAction$ } from '../store/connection/zoom/setup-event-listener';
import { InformativeError } from './repository';
import { SESSION_ID } from '..';
import { salesforceEventListenerSetupDialogOpenAction$ } from '../store/connection/salesforce/setup-event-listener';
import { opsgenieEventListenerSetupDialogOpenAction$ } from '../store/connection/opsgenie/setup-event-listener';
import { statuspageEventListenerSetupDialogOpenAction$ } from '../store/connection/statuspage/setup-event-listener';

//eslint-disable-next-line sonarjs/cognitive-complexity
export const openEventListenerSetupDialog = (eventListener: EventListener, connectionType?: ConnectionType): void => {
    const eventListenerType = (apps$.value ?? [])
        .find((a) => a.uid === eventListener?.appUid)
        ?.connectionType.eventListenerTypes.find((elt) => elt.uid === eventListener?.eventListenerTypeUid);

    const eventTypeName =
        (eventListenerType?.eventTypes ?? []).find((et) => et.uid === eventListener?.eventTypeUid)?.name ??
        'UNKNOWN_EVENT';

    if (connectionType?.name) {
        switch (connectionType.name) {
            case APP.GENERIC.NAME:
                genericEventListenerSetupDialogOpenAction$.next({
                    urlPath: eventListener.urlId ?? '',
                });
                break;
            case APP.JIRA_CLOUD.NAME:
                atlassianCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira',
                });
                break;
            case APP.CONFLUENCE_CLOUD.NAME:
                atlassianCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Confluence',
                });
                break;
            case APP.JIRA_SERVICE_MANAGEMENT_CLOUD.NAME:
                atlassianCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira Service Management',
                });
                break;
            case APP.BITBUCKET_CLOUD.NAME:
                bitbucketCloudEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.SLACK.NAME:
                slackEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                    eventTypeName,
                    eventTypeCategory: eventListener.eventTypeCategory ?? '',
                });
                break;
            case APP.SERVICENOW.NAME:
                serviceNowEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.JIRA_ON_PREMISE.NAME:
                atlassianOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira',
                });
                break;
            case APP.MONDAY.NAME:
                mondayEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.GITLAB.NAME:
                gitlabEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.CONFLUENCE_ON_PREMISE.NAME:
                atlassianOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Confluence',
                });
                break;
            case APP.GITHUB.NAME:
                githubEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.BITBUCKET_ON_PREMISE.NAME:
                bitbucketOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.JIRA_SERVICE_MANAGEMENT_ON_PREMISE.NAME:
                atlassianOnPremiseEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    connectionType: 'Jira Service Management',
                });
                break;
            case APP.MICROSOFT.NAME:
                microsoftEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                    token: '', //we don't really need them so won't get them from db
                });
                break;
            case APP.ZOOM.NAME:
                zoomEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                    token: '',
                });
                break;
            case APP.SALESFORCE.NAME:
                salesforceEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.OPSGENIE.NAME:
                opsgenieEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    eventTypeName,
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            case APP.STATUSPAGE.NAME:
                statuspageEventListenerSetupDialogOpenAction$.next({
                    uid: eventListener.uid ?? '',
                    environmentUid: selectedEnvironmentUid$.value ?? '',
                    webhookUrl: eventListener?.urlId ?? '',
                });
                break;
            default:
                console.error('No setup instructions available for Connector type: ' + connectionType.name);
                alert('Event Listener setup instructions not available');
                break;
        }
    } else {
        console.error('Connector type not found so no setup instructions available');
        alert('Setup instructions not available');
    }
};

export const executeEventListener = async (
    environmentUid: string,
    eventListenerUid: string,
    request = {}
): Promise<void> => {
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );
    const triggerUrl = configTopic$.value.trigger?.listener?.executeUrl;

    if (!triggerUrl) {
        throw new Error('No trigger service url configured in meta');
    }

    const response = await fetch(`${triggerUrl}/${environmentUid}/${eventListenerUid}`, fetchOptions);

    if (!response.ok) {
        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while communicating with trigger service: ${response.status}`);
        }
    }

    const respBody = await response.json();
    if (respBody?.statusCode && respBody.statusCode >= 400) {
        if (respBody.value) {
            throw new InformativeError(respBody.value);
        } else {
            throw new Error(`Unexpected error while communicating with trigger service: ${respBody.statusCode}`);
        }
    }
};

import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import LinkIcon from '@mui/icons-material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { DialogAlert, DialogTitleSmall } from '../../dialog';
import { IconCircle } from '../../icon-circle';
import { ProductIcon } from '../../icons/ProductIcon';
import { pascalCase } from 'pascal-case';
import { handleKeyUp } from '../../../utils/handleKeyUp';

export interface ApiHandlerImportDialogProps {
    apiHandlers: {
        uid: string;
        appName: string;
        connectionName: string;
        path: string;
    }[];
    errors?: string;
    loading?: boolean;
    open?: boolean;
    saving?: boolean;
    onCancel(): void;
    onImport(uid: string, importName: string): void;
}

const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
        ...theme.typography.flexAlignCenter,
        maxWidth: '50ch',
    },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
    minWidth: 0,
}));

const StyledMenuItem = styled(MenuItem)({
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiListItemIcon-root': {
        minWidth: 0,
    },
});

const StyledPathDiv = styled('div')(({ theme }) => ({
    fontSize: theme.typography.subtitle2.fontSize,
}));

export const ApiHandlerImportDialog: React.FC<ApiHandlerImportDialogProps> = ({
    apiHandlers = [],
    errors,
    loading = false,
    open = false,
    saving = false,
    onCancel,
    onImport,
}) => {
    const [apiUid, setApiUid] = useState('');
    const [importName, setImportName] = useState('');
    const [prevAppName, setPrevAppName] = useState('');

    const adjustImportName = (name: string): string =>
        name.toLowerCase() === 'monday.com'
            ? 'Monday'
            : name.toLowerCase() === 'jira service management cloud'
            ? 'JSMCloud'
            : name.toLowerCase() === 'jira service management cloud assets'
            ? 'JSMCloudAssets'
            : pascalCase(name);

    useEffect(() => {
        if (apiUid) {
            const prevName = apiHandlers.find((x) => x.appName === prevAppName)?.appName;
            const appName = apiHandlers.find((x) => x.uid === apiUid)?.appName;
            const isChanged = pascalCase(`${prevName}`) !== importName ? true : false;

            if (appName && !prevAppName) {
                setImportName(adjustImportName(appName));
                setPrevAppName(appName);
            }

            if (appName && !isChanged) {
                setImportName(adjustImportName(appName));
                setPrevAppName(appName);
            }
        }
    }, [apiUid, open]);

    useEffect(() => {
        setApiUid(apiHandlers[0]?.uid ?? '');
        setPrevAppName('');
    }, [open]);

    const menuItems = apiHandlers.map((apiHandler) => {
        return (
            <StyledMenuItem key={apiHandler.uid} value={apiHandler.uid}>
                <StyledListItemIcon>
                    <ProductIcon name={apiHandler.appName} />
                </StyledListItemIcon>
                <div>
                    {apiHandler.connectionName}
                    <StyledPathDiv>{apiHandler.path}</StyledPathDiv>
                </div>
            </StyledMenuItem>
        );
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setImportName(e.target.value);
        setPrevAppName(e.target.value);
    };

    const canImport = !!apiUid && !!importName && !saving && !loading;

    return (
        <Dialog
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({
                    event,
                    enterCondition: canImport,
                    enterFn: () => onImport(apiUid, importName),
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleSmall title="Import API Connection" icon={<IconCircle icon={<LinkIcon />} />} />
            {loading ? (
                <AbsoluteCenteredLoadingSpinner />
            ) : (
                <>
                    {errors && <DialogAlert alertTitle="Error" severity="error" text={errors} />}
                    <DialogContent>
                        <FormControl>
                            <InputLabel id="api-connection">API Connection</InputLabel>
                            <StyledSelect
                                sx={
                                    apiUid === ''
                                        ? (theme) => ({ '.MuiSelect-select': { paddingBottom: theme.spacing(3.5) } })
                                        : {}
                                }
                                variant="outlined"
                                labelId="api-connection"
                                label="api-connection"
                                value={apiUid}
                                required
                                onChange={(e: SelectChangeEvent<unknown>): void => {
                                    setApiUid(e.target.value as string);
                                }}
                            >
                                {menuItems}
                            </StyledSelect>
                        </FormControl>
                        <FormControl>
                            <TextField
                                label="Import name"
                                onChange={handleChange}
                                placeholder="Enter a name"
                                value={importName}
                                required
                                variant="outlined"
                            ></TextField>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" data-hotspot="cancel" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button busy={saving} disabled={!canImport} onClick={() => onImport(apiUid, importName)}>
                            Import
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

import { getInvocations as getInvocationsRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as InvocationsRequest,
    Response as InvocationsResponse,
    InvocationRecord,
    InvocationWorkspace,
} from '@avst-stitch/repository-lib/lib/rpcs/getInvocations';
import { repositoryInvoker } from '../utils/repository';
export type { InvocationsRequest, InvocationsResponse, InvocationWorkspace };

export type Invocations = InvocationRecord[];

export const getInvocations = async (request: InvocationsRequest): Promise<InvocationsResponse> => {
    return await getInvocationsRpc(repositoryInvoker, request);
};

import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { Button } from '../../buttons/Button';
import { WorkspaceSelectorCard } from './WorkspaceSelectorCard';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import {
    PageContainer,
    PageUpperContainer,
    PageUpperGroup,
    PageTitle,
    PageViewButtonGroup,
    PageGridContainer,
    EmptyStateContainer,
    EmptyStateText,
    EmptyStateImg,
    PageSubTitle,
    StyledActionButton,
} from '../../layout';
import {
    SelectorTable,
    SelectorTitleIcon,
    SelectorViewButton,
    viewSelectedStyle,
} from '../../selector/SelectorComponents';
import {
    StyledIconCell,
    StyledShortCell,
    StyledTextCell,
    WorkspaceSelectorTableItem,
} from './WorkspaceSelectorTableItem';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Filter, FilterItemProps } from '../../filter/Filter';

interface WorkspaceSelectorProps {
    onCreateBlank(): void;
    onDelete(workspaceUid: string): void;
    onSelect(workspaceUid: string): void;
    onStartWizard(): void;
    workspaces?: {
        description?: string;
        environments: {
            name: string;
            uid: string;
            selected: boolean;
            deployment?: {
                uid: string;
                version: string;
            };
        }[];
        template: boolean;
        incoming?: string[];
        outgoing?: string[];
        orgName?: string;
        shared?: boolean;
        // status?: 'ACTIVE' | 'INACTIVE';
        title?: string;
        uid: string;
        users: {
            firstName?: string;
            lastName?: string;
            email: string;
            avatar?: string;
        }[];
    }[];
    showOrganizations?: boolean;
    onOrganizationFilterUpdate(organization: string): void;
    onTemplateFilterUpdate(displayTemplates: boolean): void;
    organizationsForFilterOptions?: { name: string }[];
    templatesForFilterOptions?: { name: string }[];
    onResetFilter(): void;
    activeFilters?: FilteringOptions;
    userCanWorkWithTemplates?: boolean;
}

export interface FilteringOptions {
    organizations: string[];
    templates: string[];
    count: number;
}

export const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({
    onCreateBlank,
    onDelete,
    onSelect,
    onStartWizard,
    workspaces = [],
    showOrganizations,
    onOrganizationFilterUpdate,
    onResetFilter,
    organizationsForFilterOptions,
    templatesForFilterOptions,
    onTemplateFilterUpdate,
    activeFilters = {
        organizations: [],
        templates: [],
        count: 0,
    },
    userCanWorkWithTemplates,
}) => {
    const [view, setView] = useState<'grid' | 'list'>(readLocalStorage('workspacesView', 'grid'));

    const handleViewChange = (view: 'grid' | 'list'): void => {
        setView(view);
        saveLocalStorage('workspacesView', view);
    };

    const items = workspaces.map((workspace) => {
        if (view === 'grid') {
            return (
                <WorkspaceSelectorCard
                    key={workspace.uid}
                    description={workspace.description}
                    environments={workspace.environments}
                    template={workspace.template}
                    incoming={workspace.incoming}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    outgoing={workspace.outgoing}
                    // status={workspace.status}
                    title={workspace.title}
                    uid={workspace.uid}
                    users={workspace.users}
                    orgName={workspace.orgName}
                    shared={workspace.shared}
                />
            );
        } else if (view === 'list') {
            return (
                <WorkspaceSelectorTableItem
                    key={workspace.uid}
                    description={workspace.description}
                    environments={workspace.environments}
                    template={workspace.template}
                    incoming={workspace.incoming}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    outgoing={workspace.outgoing}
                    // status={workspace.status}
                    title={workspace.title}
                    uid={workspace.uid}
                    users={workspace.users}
                    orgName={workspace.orgName}
                    shared={workspace.shared}
                    showOrganizations={showOrganizations}
                />
            );
        }
    });

    const tableTitles = (
        <TableRow>
            <StyledTextCell>Title</StyledTextCell>
            <StyledTextCell>Description</StyledTextCell>
            <StyledIconCell>
                <SelectorTitleIcon>
                    <DownloadOutlinedIcon />
                </SelectorTitleIcon>
                Incoming
            </StyledIconCell>
            <StyledIconCell>
                <SelectorTitleIcon>
                    <UploadOutlinedIcon />
                </SelectorTitleIcon>
                Outgoing
            </StyledIconCell>
            {showOrganizations && (
                <>
                    <StyledShortCell>Members</StyledShortCell>
                    <StyledTextCell>Team</StyledTextCell>
                </>
            )}
            <StyledShortCell>Environments</StyledShortCell>
            <StyledShortCell>Status</StyledShortCell>
            <StyledShortCell sx={{ textAlign: 'end' }}>Actions</StyledShortCell>
        </TableRow>
    );

    const createLabel = (): string => {
        const currentResultsLength = workspaces.length ?? 0;

        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };
    const remappedOrganizations = organizationsForFilterOptions?.map((o) => o.name) ?? [];
    const remappedTemplates = templatesForFilterOptions?.map((t) => t.name) ?? [];
    const filters: FilterItemProps[] = [
        {
            filterName: 'Team',
            options: remappedOrganizations,
            handler: onOrganizationFilterUpdate,
            activeFilters: activeFilters.organizations,
            icons: false,
        },
    ];

    if (userCanWorkWithTemplates) {
        filters.push({
            filterName: 'Template',
            options: remappedTemplates,
            handler: onTemplateFilterUpdate,
            activeFilters: activeFilters.templates,
            icons: false,
        });
    }

    return (
        <PageContainer>
            <PageUpperContainer>
                <PageTitle title="Workspaces" label={createLabel()} />
                {(items.length > 0 || activeFilters.count > 0) && (
                    <PageUpperGroup>
                        <PageViewButtonGroup>
                            <SelectorViewButton
                                icon="grid"
                                onClick={() => {
                                    if (view === 'list') {
                                        handleViewChange('grid');
                                    }
                                }}
                                sx={(theme) => {
                                    return view === 'grid' ? viewSelectedStyle(theme) : null;
                                }}
                            />

                            <SelectorViewButton
                                icon="list"
                                onClick={() => {
                                    if (view === 'grid') {
                                        handleViewChange('list');
                                    }
                                }}
                                sx={(theme) => {
                                    return view === 'list' ? viewSelectedStyle(theme) : null;
                                }}
                            />
                        </PageViewButtonGroup>
                        <Filter filters={filters} count={activeFilters.count} reset={onResetFilter} />
                        <Button onClick={onCreateBlank} startIcon={<AddIcon />}>
                            Create New
                        </Button>
                    </PageUpperGroup>
                )}
            </PageUpperContainer>
            {view === 'grid' && items.length > 0 && <PageGridContainer>{items}</PageGridContainer>}
            {view === 'list' && items.length > 0 && (
                <SelectorTable>
                    <TableHead>{tableTitles}</TableHead>
                    <TableBody>{items}</TableBody>
                </SelectorTable>
            )}
            {items.length === 0 && activeFilters.count === 0 && (
                <EmptyStateContainer>
                    <EmptyStateImg src={process.env.PUBLIC_URL + '/wizard-wave-slow.gif'} alt="Hello" />
                    <PageSubTitle variant="h6">Create your first Workspace</PageSubTitle>
                    <EmptyStateText>
                        Workspaces allow you to combine <strong>APIs</strong> of apps you wish to integrate with. Use{' '}
                        <strong>Event Listeners</strong> to react to events triggered from those apps, and write{' '}
                        <strong>Scripts</strong> for integration logic.
                    </EmptyStateText>
                    <StyledActionButton startIcon={<AddIcon />} onClick={onCreateBlank}>
                        New Workspace
                    </StyledActionButton>
                    <EmptyStateText>Not sure where to start?</EmptyStateText>
                    <Button variant="outlined" onClick={onStartWizard}>
                        Step-by-step setup
                    </Button>
                </EmptyStateContainer>
            )}
            {items.length === 0 && activeFilters.count > 0 && (
                <Typography marginTop={4}>No workspaces found</Typography>
            )}
        </PageContainer>
    );
};

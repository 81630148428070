import { useObservableState } from 'observable-hooks';
import { GenericApiConnectionConfigureDialog } from '../../../components/connections/generic/ApiConnectionConfigureDialog';
import {
    genericAppConnectionSaving$,
    genericAppManageConnectionDetails$,
    genericAppSetupDialogErrors$,
    genericAppSetupDialogOpen$,
    genericAppSetupCloseDialogAction$,
    genericAppSetupAuthorizeAction$,
} from '../../../store/connection/generic/setup-connection';

export const GenericAppConfigureDialog: React.FC = () => {
    const open = useObservableState(genericAppSetupDialogOpen$);
    const saving = useObservableState(genericAppConnectionSaving$);
    const details = useObservableState(genericAppManageConnectionDetails$);
    const errors = useObservableState(genericAppSetupDialogErrors$);

    return (
        <GenericApiConnectionConfigureDialog
            open={open}
            onCancel={() => genericAppSetupCloseDialogAction$.next()}
            headers={details?.headers}
            baseUrl={details?.instanceUrl ?? ''}
            saving={saving}
            errors={errors}
            onSave={(appInfoProps) => genericAppSetupAuthorizeAction$.next(appInfoProps)}
        />
    );
};

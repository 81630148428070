import React from 'react';
import { styled } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { DashboardInstructionsCard } from './DashboardInstructionsCard';
import { DashboardTemplateCard } from './DashboardTemplateCard';
import { KnowledgeBaseCard } from './KnowledgeBaseCard';
import { AccountUsageCard } from './AccountUsageCard';
import { Button } from '../buttons/Button';
import { Link } from 'react-location';
import { PageContainer, PageTitle } from '../layout';
import Grid from '@mui/material/Unstable_Grid2';
import { dashboardCardStyles } from './DashboardComponents';

interface DashboardProps {
    useRouter?: boolean;
    embeddedVideo?: string;
    knowledgeBaseArticles?: {
        description: string;
        title: string;
        url: string;
    }[];
    onBlankSetup: () => void;
    onStartWizard: () => void;
    onTemplateSelect: (uid: string) => void;
    templates?: {
        description?: string;
        incomingApps?: string[];
        outgoingApps?: string[];
        name: string;
        uid: string;
    }[];
    onEdit: (uid: string) => void;
    onMoreTemplates?: () => void;
    onLearnMore?: (url?: string) => void;
    userCanWorkWithTemplates: boolean;
}

// dashboard content built using MUI grid 2, see this for how to use xs https://mui.com/material-ui/react-grid2/

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    minWidth: 800,
    maxWidth: 1400,
}));

const StyledTitleGrid = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(2.5),
    '& span': {
        marginRight: theme.spacing(1),
    },
}));

const StyledItem = styled('div')(() => ({
    // do not put any width constraints on the grid items, let the Grid xs do the sizing
    minHeight: 220,
    height: '100%',
}));

const StyledIFrame = styled('iframe')(({ theme }) => ({
    ...dashboardCardStyles,
    borderRadius: theme.constants.borderRadius,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    height: 'inherit',
    '& > span': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

const SubTitle: React.FC<TypographyProps> = ({ children }) => {
    return (
        <Typography component="h3" variant="subtitle1">
            {children}
        </Typography>
    );
};

const disabledMixin = { opacity: '38%' };

const stepByStepInstructions = 'Not sure where to start?';
const blankSetupInstructions = 'Feeling confident?';

export const Dashboard: React.FC<DashboardProps> = ({
    useRouter = true,
    embeddedVideo,
    knowledgeBaseArticles = [],
    onBlankSetup,
    onStartWizard,
    onTemplateSelect,
    templates = [],
    onEdit,
    onMoreTemplates,
    onLearnMore,
    userCanWorkWithTemplates,
}) => {
    const displayedKnowledgeBaseArticles = knowledgeBaseArticles.map((article, index) => {
        return { ...article, index };
    });

    const displayedTemplatesGrid = templates.map((t, i) => {
        return (
            i < 3 && (
                <Grid xs={4}>
                    <StyledItem>
                        <DashboardTemplateCard
                            key={t.uid}
                            onSelect={() => onTemplateSelect(t.uid)}
                            uid={t.uid}
                            description={t.description}
                            incoming={t.incomingApps}
                            outgoing={t.outgoingApps}
                            title={t.name}
                            onEdit={onEdit}
                            userCanWorkWithTemplates={userCanWorkWithTemplates}
                        />
                    </StyledItem>
                </Grid>
            )
        );
    });

    return (
        <>
            <PageContainer>
                <PageTitle title="Dashboard" />
                <StyledGrid container spacing={2}>
                    {/* top row items */}
                    <Grid xs={4}>
                        <StyledItem>
                            <StyledIFrame
                                src={embeddedVideo}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </StyledItem>
                    </Grid>
                    <Grid xs={4}>
                        <StyledItem>
                            <DashboardInstructionsCard
                                color="dark"
                                icon={<AutoFixNormalIcon />}
                                image={process.env.PUBLIC_URL + '/wizard-steps-slow-crop.gif'}
                                instructions={stepByStepInstructions}
                                onContinue={onStartWizard}
                                title="Step-by-step setup"
                            />
                        </StyledItem>
                    </Grid>
                    <Grid xs={4}>
                        <StyledItem>
                            <DashboardInstructionsCard
                                color="light"
                                icon={<EmojiObjectsIcon />}
                                image={process.env.PUBLIC_URL + '/wizard-think-slow-crop.gif'}
                                instructions={blankSetupInstructions}
                                onContinue={onBlankSetup}
                                title="Blank setup"
                            />
                        </StyledItem>
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    {/* middle row title and view more - last grid xs to match the last grid xs in the next row */}
                    <StyledTitleGrid xs={8}>
                        <SubTitle>Templates</SubTitle>
                    </StyledTitleGrid>
                    <StyledTitleGrid xs={4} textAlign="right">
                        {useRouter && (
                            <Link to="/templates" title="More Templates" onClick={onMoreTemplates}>
                                <StyledButton endIcon={<ArrowForwardIcon />} variant="text">
                                    More Templates
                                </StyledButton>
                            </Link>
                        )}
                    </StyledTitleGrid>
                    {/* middle row items */}
                    {displayedTemplatesGrid}
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    {/* bottom row titles - xs should match the bottom row grid */}
                    <StyledTitleGrid xs={8}>
                        <SubTitle>
                            <span style={disabledMixin}>Account Usage</span> Coming soon
                        </SubTitle>
                    </StyledTitleGrid>
                    <StyledTitleGrid xs={4}>
                        <SubTitle>Knowledge Base</SubTitle>
                    </StyledTitleGrid>
                    {/* bottom row items */}
                    <Grid xs={8}>
                        <StyledItem>
                            <AccountUsageCard />
                        </StyledItem>
                    </Grid>
                    <Grid xs={4}>
                        <StyledItem>
                            <KnowledgeBaseCard articles={displayedKnowledgeBaseArticles} onLearnMore={onLearnMore} />
                        </StyledItem>
                    </Grid>
                </StyledGrid>
            </PageContainer>
        </>
    );
};

import React, { useEffect, useRef } from 'react';
import { ResourceTreeSection } from './ResourceTreeSection';
import { styled, useTheme } from '@mui/material';
import { CenteredLoadingSpinner } from '../../loading/CenteredLoadingSpinner';
import Typography from '@mui/material/Typography';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Box from '@mui/material/Box';
import { ResourceManagerOffsets, Wizard, WizardState, WizardStep } from '../../../store/wizard';
import { SelectedResource } from '../../../store/workspace';
import { ScriptSaveIcon } from '../../icons/StyledMuiIcons';

export interface ResourceTreeProps {
    loading: boolean; // Show spinner in middle of screen when resource tree is loading
    open?: boolean;
    readOnlyMode?: boolean;
    readmeFile: {
        uid: string;
        name: string;
        unsaved: boolean;
        deleting?: boolean;
    };
    scripts: {
        uid: string; // Unique ID
        name: string; // Script name
        unsaved: boolean; // Indicates if the script has unsaved changes
        deleting?: boolean;
    }[];
    apiHandlers: {
        // Called like this in BE, but should be called as API Connections in FE
        uid: string; // Unique ID
        // Use to determine which icon to show in front of it. Current values will be 'Slack' and 'Jira Cloud'.
        appName?: string;
        warning: boolean; // Whether to show warning incidator
        path?: string;
        connectionName?: string; // Connection that is referenced
        deleting?: boolean;
        remnantEnvironments: {
            environmentName: string; // Environment name
            deploymentVersion: string; // Deployment version this environment is targeting
        }[];
    }[];
    eventListeners: {
        uid: string; // Unique ID
        // Use to determine which icon to show in front of it. Current values will be 'Slack' and 'Jira Cloud'.
        appName?: string;
        eventTypeName?: string; // Event type this EventListener is listening to
        connectionName?: string; // Connection that is referenced
        urlId?: string;
        scriptName?: string;
        warning: boolean;
        deleting?: boolean;
        remnantEnvironments: {
            environmentName: string; // Environment name
            deploymentVersion: string; // Deployment version this environment is targeting
        }[];
        inactive?: boolean;
    }[];
    scheduledTriggers: {
        uid: string;
        warning: boolean; // Whether to show warning indicator
        deleting?: boolean;
        cronExpression?: string;
        cronDescription?: string; // Human readable description, show it only when hovered over
        scriptName?: string;
        remnantEnvironments: {
            environmentName: string;
            deploymentVersion: string;
        }[];
    }[];
    externalTriggerBaseUrl?: string;
    selectedNode?: SelectedResource;
    selectedWorkspaceLanguage?: string;
    showScheduledTriggers?: boolean;
    wizard?: Wizard;
    workspaceUid: string;
    onNewScheduledTrigger(): void;
    onOpenScheduledTrigger(uid: string): void;
    onScheduledTriggerDelete(uid: string): void;
    onNewScript(): void; // When new script was clicked
    onNewApiHandler(): void; // When new API Connection was clicked
    onNewEventListener(): void; // When new Event Listener was clicked
    onOpenScript(uid: string): void; // When Script node was clicked on
    onOpenReadmeFile(uid?: string): void; // When Readme file was clicked on
    onOpenApiHandler(uid: string): void; // When ApiHandler node was clicked on
    onOpenEventListener(uid: string): void; // When EventListener node was clicked on
    // When Script was triggered (should have play button next to label in the node, missing in wfs)
    onSetOffsets?(offsets: ResourceManagerOffsets): void;
    onTriggerScript(uid: string): void;
    // When EventListener was triggered (should have play button next to label in the node, missing in wfs)
    onTriggerEventListener(uid: string): void;
    //deletion function, by adding trashcan icon in front of the play icon, or as the last icon for ApiHandlers.
    onScriptDelete(uid: string): void;
    onApiHandlerDelete(uid: string): void;
    onEventListenerDelete(uid: string): void;
    onApiHandlerPathCopy(uid: string): void;
    onEventListenerUrlCopy(uid: string): void;
}

interface Script {
    deleting?: boolean;
    executing?: boolean;
    name?: string;
    uid?: string;
    unsaved?: boolean;
}

export interface TreeNode extends Script {
    fullPath?: string;
    subItems?: TreeNode[];
}

export const sideBarWidth = 32;

const StyledMainArea = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    width: '100%',
}));

const StyledResourceTreeContainer = styled('div')(() => ({}));

const StyledSideBarMain = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: sideBarWidth,
}));

const StyledSectionsArea = styled('div')(({ theme }) => ({
    paddingLeft: sideBarWidth,
    position: 'relative',
    '&:after': {
        borderLeft: `1px dashed ${theme.palette.action.active}`,
        content: '""',
        height: `calc(100% - ${theme.spacing(2.5)})`,
        left: `calc(${theme.spacing(2.5)} + 1px + ${sideBarWidth}px)`,
        position: 'absolute',
        top: theme.spacing(1),
        width: '1px',
        zIndex: 1,
    },
}));

const StyledReadmeArea = styled(Box)(() => ({
    minWidth: 0,
    paddingLeft: sideBarWidth,
    position: 'relative',
    width: '100%',
}));

const StyledReadmeButton = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    cursor: 'pointer',
    padding: theme.spacing(0.5, 2, 0.75, 1.5),
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        '& .MuiSvgIcon-root': {
            opacity: 1,
        },
    },
}));

const StyledReadmeFileText = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowLine,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(0, 1),
}));

const StyledTextSnippetOutlinedIcon = styled(TextSnippetOutlinedIcon)(({ theme }) => ({
    borderRadius: theme.spacing(0),
    color: theme.palette.success.main,
    height: 16,
    width: 16,
}));

const StyledTitleArea = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    padding: theme.spacing(1.75, 1.5, 2, 1.5 + sideBarWidth / 8),
    position: 'relative',
    '& .MuiSvgIcon-root': {
        color: theme.palette.info.main,
        height: 20,
        marginRight: theme.spacing(1),
        transform: 'scaleY(-1)',
        width: 20,
    },
}));

export const ResourceTree: React.FC<ResourceTreeProps> = ({
    open = true,
    loading = true,
    readOnlyMode = false,
    readmeFile,
    scripts,
    apiHandlers,
    eventListeners,
    selectedNode,
    selectedWorkspaceLanguage,
    externalTriggerBaseUrl,
    wizard,
    scheduledTriggers,
    showScheduledTriggers,
    workspaceUid,
    onNewScheduledTrigger,
    onOpenScheduledTrigger,
    onScheduledTriggerDelete,
    onNewScript,
    onNewApiHandler,
    onNewEventListener,
    onOpenScript,
    onOpenReadmeFile,
    onOpenApiHandler,
    onOpenEventListener,
    onSetOffsets,
    onTriggerScript,
    onTriggerEventListener,
    onScriptDelete,
    onApiHandlerDelete,
    onEventListenerDelete,
    onApiHandlerPathCopy,
    onEventListenerUrlCopy,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scriptsRef = useRef<HTMLDivElement>(null);
    const apiConnectionsRef = useRef<HTMLDivElement>(null);
    const eventListenersRef = useRef<HTMLDivElement>(null);

    const theme = useTheme();

    useEffect(() => {
        handleToggleSectionExpand();
    });

    const handleToggleSectionExpand = (): void => {
        const getOffset = (ref: React.RefObject<HTMLDivElement> | null): number => {
            return ref?.current?.getBoundingClientRect().y ?? 0; // default offset if all else fails
        };
        onSetOffsets?.({
            container: getOffset(containerRef),
            scripts: getOffset(scriptsRef),
            apiConnections: getOffset(apiConnectionsRef),
            eventListeners: getOffset(eventListenersRef),
        });
    };

    const handleDelete = (uid: string, fullPath?: string): void => {
        if (fullPath) {
            const splitPath = fullPath.split('/');
            splitPath.forEach(() => {
                localStorage.removeItem(splitPath.join('/'));
                splitPath.pop();
            });
        }
        onScriptDelete(uid);
    };

    const createTreeNode = (scriptName: string, path: string, script: Script, tree: TreeNode[]): void => {
        const splitPath = scriptName.split('/');
        const name = splitPath.shift() || '';
        const fullPath = path ? path + '/' + name : name;
        if (splitPath.length < 1) {
            tree.push(script);
        } else {
            const folder = tree.find((folder) => folder.name === name && folder.subItems);

            if (!folder) {
                tree.push({ fullPath: fullPath, name, subItems: [] });
            }
            const newScript = { ...script, fullPath, name: splitPath[0] };

            const child = tree[tree.length - 1];

            if (child) {
                createTreeNode(splitPath.join('/'), fullPath, newScript, child.subItems || []);
            }
        }
    };

    const handleFolders = (scripts: Script[]): TreeNode[] => {
        const tree: TreeNode[] = [];
        scripts.forEach((script) => {
            const scriptName = script.name || '';
            createTreeNode(scriptName, '', script, tree);
        });
        return tree;
    };

    const sortTree = (tree: TreeNode[]): TreeNode[] => {
        tree.sort((left, right) => {
            const leftIsFolder = !!left.subItems;
            const rightIsFolder = !!right.subItems;
            if (leftIsFolder && rightIsFolder) {
                const leftName = left.name || '';
                const rightName = right.name || '';
                return leftName.localeCompare(rightName);
            }
            return leftIsFolder ? -1 : rightIsFolder ? 1 : 0;
        });
        tree.forEach((el) => {
            if (el.subItems) {
                sortTree(el.subItems);
            }
        });
        return tree;
    };

    const tree = sortTree(handleFolders(scripts));
    const warning = !apiHandlers.length && !eventListeners.length && !scheduledTriggers.length && !scripts.length;
    const workspaceMatchWizard = wizard?.workspaceDetails?.workspaceUid === workspaceUid;

    const readmeSelectedStyle =
        selectedNode?.uid === readmeFile?.uid
            ? {
                  backgroundColor: theme.palette.action.selected,
                  '& .MuiSvgIcon-root': {
                      opacity: 1,
                  },
              }
            : {};

    return open ? (
        <>
            {loading ? (
                <CenteredLoadingSpinner />
            ) : (
                <StyledResourceTreeContainer>
                    <StyledMainArea data-test-id="resource-tree" ref={containerRef}>
                        <StyledSideBarMain />
                        <StyledTitleArea>
                            <AccountTreeOutlinedIcon />
                            <Typography variant="subtitle1" component="h2" color="text.secondary">
                                Resource Manager
                            </Typography>
                        </StyledTitleArea>
                        {readmeFile && (
                            <StyledReadmeArea>
                                <StyledReadmeButton
                                    sx={readmeSelectedStyle}
                                    onClick={() => {
                                        onOpenReadmeFile(readmeFile?.uid);
                                    }}
                                >
                                    <StyledTextSnippetOutlinedIcon />
                                    <StyledReadmeFileText>{'README'}</StyledReadmeFileText>
                                    {!readOnlyMode && readmeFile.unsaved && <ScriptSaveIcon />}
                                </StyledReadmeButton>
                            </StyledReadmeArea>
                        )}
                        <StyledSectionsArea>
                            <ResourceTreeSection
                                innerRef={scriptsRef}
                                name="Scripts"
                                readOnlyMode={readOnlyMode}
                                itemList={tree}
                                externalTriggerBaseUrl={externalTriggerBaseUrl}
                                wizardFocus={
                                    workspaceMatchWizard &&
                                    wizard?.state === WizardState.ACTIVE &&
                                    wizard?.step === WizardStep.CREATE_SCRIPT
                                }
                                createNewHandler={onNewScript}
                                onToggleExpand={handleToggleSectionExpand}
                                openHandler={onOpenScript}
                                triggerHandler={onTriggerScript}
                                selectedNode={selectedNode?.uid}
                                language={selectedWorkspaceLanguage}
                                deleteHandler={handleDelete}
                            />
                            <ResourceTreeSection
                                innerRef={apiConnectionsRef}
                                name="API Connections"
                                readOnlyMode={readOnlyMode}
                                itemList={apiHandlers}
                                externalTriggerBaseUrl={externalTriggerBaseUrl}
                                wizardFocus={
                                    workspaceMatchWizard &&
                                    wizard?.state === WizardState.ACTIVE &&
                                    wizard?.step === WizardStep.ADD_API_CONNECTION
                                }
                                wizardIncompleteNode={wizard?.createdUnsavedResource}
                                warning={warning}
                                createNewHandler={onNewApiHandler}
                                onToggleExpand={handleToggleSectionExpand}
                                openHandler={onOpenApiHandler}
                                selectedNode={selectedNode?.uid}
                                deleteHandler={onApiHandlerDelete}
                                pathCopyHandler={onApiHandlerPathCopy}
                            />
                            <ResourceTreeSection
                                innerRef={eventListenersRef}
                                name="Event Listeners"
                                readOnlyMode={readOnlyMode}
                                itemList={eventListeners}
                                externalTriggerBaseUrl={externalTriggerBaseUrl}
                                wizardFocus={
                                    workspaceMatchWizard &&
                                    wizard?.state === WizardState.ACTIVE &&
                                    wizard?.step === WizardStep.ADD_EVENT_LISTENER
                                }
                                wizardIncompleteNode={wizard?.createdUnsavedResource}
                                warning={warning}
                                createNewHandler={onNewEventListener}
                                onToggleExpand={handleToggleSectionExpand}
                                openHandler={onOpenEventListener}
                                triggerHandler={onTriggerEventListener}
                                selectedNode={selectedNode?.uid}
                                deleteHandler={onEventListenerDelete}
                                urlCopyHandler={onEventListenerUrlCopy}
                            />
                            {showScheduledTriggers && (
                                <ResourceTreeSection
                                    name="Scheduled Triggers"
                                    readOnlyMode={readOnlyMode}
                                    itemList={scheduledTriggers}
                                    externalTriggerBaseUrl={externalTriggerBaseUrl}
                                    warning={warning}
                                    createNewHandler={onNewScheduledTrigger}
                                    openHandler={onOpenScheduledTrigger}
                                    selectedNode={selectedNode?.uid}
                                    deleteHandler={onScheduledTriggerDelete}
                                />
                            )}
                        </StyledSectionsArea>
                    </StyledMainArea>
                </StyledResourceTreeContainer>
            )}
        </>
    ) : null;
};

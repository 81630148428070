import { useAuth } from '../hooks/auth';
import { AppMain } from '../components/layout';
import { wrapAsync } from '../utils/react';
import { useObservableState, useSubscription } from 'observable-hooks';
import { acceptInviteAction$, loggedInUserDetails$, stopImpersonationAction$ } from '../store/user';
import { useBeforeunload } from 'react-beforeunload';
import { useLocation, useNavigate } from 'react-location';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { ConsentDialog } from '../components/consent/ConsentDialog';
import { getLoggedUserDetails } from '../data/user';
import { VerifyAccountDialog } from '../components/verify-account/VerifyAccountDialog';
import { isUserVerified, updateUserConsent, verifyAccount } from '../utils/manageAccount';
import {
    featureFlagsTopic$,
    configTopic$,
    sessionExpired$,
    showSessionExpiredWarning$,
    loadTokens$,
} from '../store/config';
import { hotjar } from 'react-hotjar';
import { selectedWorkspace$ } from '../store/workspace';
import { HtmlHead } from '../components/htmlMeta/Head';
import { navigateAction$ } from '../store/navigation';
import { workspaceHasUnsavedChanges } from '../store/workspace/utils';
import { openConnectAndSupportModalAction$ } from '../store/connectAndSupport';
import { sessionLockAcquired } from '..';
import { openOnboardingWizardAction$ } from '../store/onboarding';
import { themeMode$ } from '../store/theme';
import { feedbackConnectionStatus$, notificationBannerDetails$ } from '../store/feedback';
import { brandChangeModalOpen$ } from '../store/brandChange';
import { readLocalStorage, saveLocalStorage } from '../utils/localStorage';
import { segmentAnalyticsTrack } from '../data/segment-analytics';

interface AppMainContainerProps {
    rootPath: string;
}

export const AppMainContainer: React.FC<AppMainContainerProps> = ({ rootPath }) => {
    const [isConsentBusy, setConsentBusy] = useState(false);
    const [isRetryBusy, setRetryBusy] = useState(false);
    const { operations } = useAuth();
    const sessionExpired = useObservableState(sessionExpired$);
    const showSessionExpiredWarning = useObservableState(showSessionExpiredWarning$);
    const userUsedLogin: boolean = readLocalStorage('userUsedLogin', false);
    const isLoginEvent: boolean = readLocalStorage('isLoginEvent', false);

    const location = useLocation();
    const navigate = useNavigate();

    useSubscription(navigateAction$, (to) => navigate({ to }));

    useBeforeunload((event) => {
        if (workspaceHasUnsavedChanges()) {
            event.preventDefault();
            return 'Workspaces has unsaved changes, are you sure you want to quit?';
        }

        try {
            if (sessionLockAcquired) {
                sessionStorage.removeItem('STITCH_SESSION_PING');
            }
        } catch (e) {
            console.error('Failed to remove session lock', e);
        }
    });

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.current.pathname,
        });
    }, [location.current.pathname]);

    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const featureFlags = useObservableState(featureFlagsTopic$);
    const config = useObservableState(configTopic$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const termsAccepted = loggedInUserDetails?.termsAccepted;
    const accountVerified = loggedInUserDetails?.accountVerified;
    const inviteId = new URLSearchParams(window.location.search).get('inviteId');
    const isConnectedToFeedback = useObservableState(feedbackConnectionStatus$);
    const notificationBanner = useObservableState(notificationBannerDetails$);

    const handleLogOut = async (): Promise<void> => {
        await operations.logout({ redirectUrl: window.location.origin });
    };

    const handleAgreeConsent = async (): Promise<void> => {
        setConsentBusy(true);
        await updateUserConsent(true);
        const userDetails = await getLoggedUserDetails();
        if (inviteId) {
            acceptInviteAction$.next(inviteId);
        }
        loggedInUserDetails$.next(userDetails);
        setConsentBusy(false);
        if (featureFlags.organizations) {
            openOnboardingWizardAction$.next();
        }
    };

    const handleRetry = async (): Promise<void> => {
        setRetryBusy(true);

        const userVerifiedInAuth0 = await isUserVerified();
        if (userVerifiedInAuth0) {
            await verifyAccount();
            setRetryBusy(false);
            const userDetails = await getLoggedUserDetails();
            loggedInUserDetails$.next(userDetails);
        } else {
            setRetryBusy(false);
        }
    };

    useEffect(() => {
        if (loggedInUserDetails && loggedInUserDetails.uid) {
            if (config.analytics?.hotjar && hotjar.initialized()) {
                hotjar.identify(loggedInUserDetails.uid, {
                    uid: loggedInUserDetails.uid,
                });
            }
            if (config.analytics?.segment?.writeKey && isLoginEvent) {
                localStorage.removeItem('isLoginEvent');
                segmentAnalyticsTrack('User Logged In', {
                    userId: loggedInUserDetails.uid,
                });
            }
        }
    }, [loggedInUserDetails?.uid]);

    if (loggedInUserDetails && accountVerified === false) {
        return (
            <VerifyAccountDialog
                onRetry={wrapAsync(handleRetry)}
                onCancel={wrapAsync(handleLogOut)}
                isRetryBusy={isRetryBusy}
            />
        );
    }

    if (loggedInUserDetails && !termsAccepted) {
        return (
            <ConsentDialog
                onAgree={wrapAsync(handleAgreeConsent)}
                onDisagree={wrapAsync(handleLogOut)}
                isConsentBusy={isConsentBusy}
            />
        );
    }

    if (userUsedLogin) {
        brandChangeModalOpen$.next(true);
        saveLocalStorage('userUsedLogin', false);
        saveLocalStorage('userHasSeenBanner', true);
    }

    const title = selectedWorkspace ? `ScriptRunner Connect - ${selectedWorkspace?.name}` : 'ScriptRunner Connect';

    return (
        <>
            <HtmlHead name={title} />
            <AppMain
                isConnectedToFeedback={isConnectedToFeedback}
                impersonating={loggedInUserDetails?.impersonating}
                onStopImpersonation={() => stopImpersonationAction$.next()}
                notificationBanner={notificationBanner}
                rootPath={rootPath}
                credentials={{
                    firstName: loggedInUserDetails?.firstName ?? '',
                    lastName: loggedInUserDetails?.lastName ?? '',
                    email: loggedInUserDetails?.email ?? '',
                }}
                showSessionExpiredWarning={showSessionExpiredWarning}
                sessionExpired={sessionExpired}
                showDashboard={!featureFlags.mondayUser}
                showOrganizations={featureFlags.organizations}
                onLogOut={wrapAsync(handleLogOut)}
                onOpenConnectAndSupportModal={() => openConnectAndSupportModalAction$.next()}
                onThemeSwitch={(mode) => themeMode$.next(mode)}
                onSetLoadTokens={(loadTokens) => loadTokens$.next(loadTokens)}
            />
        </>
    );
};

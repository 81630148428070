import { BehaviorSubject, distinct, filter, from, map, mergeMap, Subject, toArray } from 'rxjs';
import { Package, ThirdPartyPackage } from '../../components/workspace/package-manager';
import { getWorkspacePackages, saveWorkspacePackages, WorkspacePackages } from '../../data/package-manager';
import { fetchPackageFromNpmRegistry } from '../../npm/npm';
import { InformativeError } from '../../utils/repository';
import { apps$ } from '../apps';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';

interface ImplicitlyIncludedPackage {
    name: string;
    version: string;
}

interface AddThirdPartyPackageEvent {
    name: string;
}

interface SaveWorkspacePackagesEvent {
    workspaceUid: string;
    packages: WorkspacePackages;
}

export const PACKAGE_MANAGER_CORE_PACKAGES = [
    '@stitch-it/runtime',
    '@sr-connect/convert',
    '@sr-connect/record-storage',
    '@sr-connect/trigger',
];
const PACKAGE_MANAGER_TRUSTED_THIRD_PARTY_PACKAGES = [
    'dayjs',
    'validator',
    'ulid',
    'fast-xml-parser',
    'gql-query-builder',
    'yaml',
    'entities',
    'promise-throttle-all',
    'slack-block-builder',
    '@types/validator',
];

export const openPackageManagerAction$ = monitor('openPackageManagerAction$', new Subject<void>());
export const closePackageManagerAction$ = monitor('closePackageManagerAction$', new Subject<void>());
export const packageManagerOpen$ = monitor('packageManagerOpen$', new BehaviorSubject(false));

export const packageManagerManagedAPIs$ = monitor('packageManagerManagedAPIs$', new BehaviorSubject<Package[]>([]));
export const packageManagerCorePackages$ = monitor('packageManagerCorePackages$', new BehaviorSubject<Package[]>([]));
export const packageManagerThirdPartyPackages$ = monitor(
    'packageManagerThirdPartyPackages$',
    new BehaviorSubject<ThirdPartyPackage[]>([])
);

export const implicitlyIncludedPackages$ = monitor(
    'implicitlyIncludedPackages$',
    new BehaviorSubject<ImplicitlyIncludedPackage[]>([])
);

export const savingPackageManager$ = monitor('savingPackageManager$', new BehaviorSubject(false));
export const saveWorkspacePackagesAction$ = monitor(
    'saveWorkspacePackagesAction$',
    new Subject<SaveWorkspacePackagesEvent>()
);

export const addThirdPartyPackageAction$ = monitor(
    'addThirdPartyPackageAction$',
    new Subject<AddThirdPartyPackageEvent>()
);

export const selectedWorkspacePackages$ = monitor(
    'selectedWorkspacePackages$',
    new BehaviorSubject<WorkspacePackages>([])
);
export const selectedWorkspaceImplicitPackages$ = monitor(
    'selectedWorkspaceImplicitPackages$',
    new BehaviorSubject<WorkspacePackages>([])
);

export const packageManagerAddThirdPartyPackageErrors$ = monitor(
    'packageManagerAddThirdPartyPackageErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

openPackageManagerAction$.subscribe(() => {
    packageManagerOpen$.next(true);
    packageManagerAddThirdPartyPackageErrors$.next(undefined);
    savingPackageManager$.next(false);
});

closePackageManagerAction$.subscribe(() => packageManagerOpen$.next(false));

saveWorkspacePackagesAction$
    .pipe(
        map(async (event) => {
            savingPackageManager$.next(true);
            packageManagerAddThirdPartyPackageErrors$.next(undefined);

            try {
                await saveWorkspacePackages(event.workspaceUid, event.packages);
                const selectedWorkspacePackages = await getWorkspacePackages(event.workspaceUid);

                selectedWorkspacePackages$.next(selectedWorkspacePackages);
                closePackageManagerAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Workspace packages saved.',
                    noToast: true,
                });
            } catch (e) {
                savingPackageManager$.next(false);
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                        noToast: true,
                    });
                } else {
                    console.error('Failed to save workspace packages', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message:
                            'Failed to save workspace packages, please try again, if the issue persists please contact support.',
                        noToast: true,
                    });
                }
            }
        })
    )
    .subscribe();

addThirdPartyPackageAction$
    .pipe(
        map(async (event) => {
            savingPackageManager$.next(true);
            packageManagerAddThirdPartyPackageErrors$.next(undefined);
            const packages = packageManagerThirdPartyPackages$.value;

            try {
                if (!packages.find((pkg) => pkg.name === event.name)) {
                    const response = await fetchPackageFromNpmRegistry(event.name);
                    const parentPackage = packages.find((pkg) => `@types/${pkg.name}` === response.name);
                    const childPackage = packages.find(
                        (pkg) => pkg.name.startsWith('@types/') && pkg.name.slice(7) === response.name
                    );

                    if (parentPackage) {
                        packageManagerThirdPartyPackages$.next(
                            packages.map((pkg) => {
                                if (pkg.name === parentPackage.name) {
                                    return {
                                        ...pkg,
                                        childPackage: {
                                            name: response.name,
                                            selectedVersion: response['dist-tags'].latest,
                                            versions: Object.keys(response.versions),
                                            description: response.description,
                                            selected: true,
                                        },
                                    };
                                }
                                return pkg;
                            })
                        );
                    } else if (childPackage) {
                        packageManagerThirdPartyPackages$.next([
                            ...packages.filter((pkg) => pkg.name !== childPackage.name),
                            {
                                name: response.name,
                                selectedVersion: response['dist-tags'].latest,
                                versions: Object.keys(response.versions),
                                description: response.description,
                                thirdParty: true,
                                selected: true,
                                verified: false,
                                childPackage: {
                                    name: childPackage.name,
                                    selectedVersion: childPackage.selectedVersion,
                                    versions: childPackage.versions,
                                    description: childPackage.description,
                                    selected: childPackage.selected,
                                },
                            },
                        ]);
                    } else {
                        packageManagerThirdPartyPackages$.next([
                            ...packages,
                            {
                                name: response.name,
                                selectedVersion: response['dist-tags'].latest,
                                versions: Object.keys(response.versions),
                                description: response.description,
                                thirdParty: true,
                                selected: true,
                                verified: false,
                            },
                        ]);
                    }
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    packageManagerAddThirdPartyPackageErrors$.next(e.message);
                } else {
                    console.error('Failed to add third party package', e);
                    packageManagerAddThirdPartyPackageErrors$.next(
                        'Failed to add third party package to workspace, please try again, if the issue persists please contact support'
                    );
                }
            }

            savingPackageManager$.next(false);
        })
    )
    .subscribe();

apps$
    .pipe(
        filter((apps) => apps.length > 0),
        map((apps) =>
            apps.flatMap((app) =>
                app.connectionType.apiHandlerTypes.flatMap((apiHandlerType) =>
                    apiHandlerType.apiHandlerLibraries.map((apiHandlerLibrary) => apiHandlerLibrary.name)
                )
            )
        ),
        mergeMap((packages) =>
            from(packages).pipe(
                distinct((pkg) => pkg),
                mergeMap(fetchPackageFromNpmRegistry),
                map((pkg) => ({
                    name: pkg.name,
                    selectedVersion: pkg['dist-tags'].latest,
                    latestVersion: pkg['dist-tags'].latest,
                    versions: Object.keys(pkg.versions),
                    description: pkg.description,
                })),
                toArray()
            )
        )
    )
    .subscribe((pkgs) => packageManagerManagedAPIs$.next(pkgs));

apps$
    .pipe(
        filter((apps) => apps.length > 0),
        map((apps) =>
            apps.flatMap((app) =>
                app.connectionType.eventListenerTypes.flatMap((eventListenerType) =>
                    eventListenerType.eventListenerLibraries.map((eventListenerLibrary) => eventListenerLibrary.name)
                )
            )
        ),
        mergeMap((packages) =>
            from([...packages, ...PACKAGE_MANAGER_CORE_PACKAGES]).pipe(
                distinct((pkg) => pkg),
                mergeMap(fetchPackageFromNpmRegistry),
                map((pkg) => {
                    const isCore = PACKAGE_MANAGER_CORE_PACKAGES.includes(pkg.name);
                    return {
                        name: pkg.name,
                        selectedVersion: pkg['dist-tags'].latest,
                        latestVersion: pkg['dist-tags'].latest,
                        versions: Object.keys(pkg.versions),
                        description: pkg.description,
                        locked: isCore,
                        selected: isCore,
                    };
                }),
                toArray()
            )
        )
    )
    .subscribe((pkgs) => packageManagerCorePackages$.next(pkgs));

selectedWorkspacePackages$
    .pipe(
        map((pkgs) => pkgs.filter((pkg) => pkg.type === 'THIRD_PARTY')),
        mergeMap((pkgs) =>
            from([...PACKAGE_MANAGER_TRUSTED_THIRD_PARTY_PACKAGES, ...pkgs.map((pkg) => pkg.name)]).pipe(
                distinct((pkg) => pkg),
                mergeMap(fetchPackageFromNpmRegistry),
                map((pkg) => {
                    const selectedPkg = pkgs.find((slcPkg) => slcPkg.name === pkg.name);
                    const versions = Object.keys(pkg.versions);
                    return {
                        name: pkg.name,
                        selectedVersion: selectedPkg?.version ?? pkg['dist-tags'].latest,
                        versions,
                        description: pkg.description,
                        thirdParty: true,
                        selected: !!selectedPkg,
                        verified: !!PACKAGE_MANAGER_TRUSTED_THIRD_PARTY_PACKAGES.find((name) => name === pkg.name),
                    };
                }),
                toArray(),
                map((pkgs) => {
                    const thirdPartyPackages: Record<string, ThirdPartyPackage> = {};
                    pkgs.forEach((pkg) => {
                        if (pkg.name.startsWith('@types/')) {
                            const parentPackage = thirdPartyPackages[pkg.name.slice(7)];
                            if (parentPackage) {
                                thirdPartyPackages[parentPackage.name] = {
                                    ...parentPackage,
                                    childPackage: {
                                        name: pkg.name,
                                        selectedVersion: pkg.selectedVersion,
                                        versions: pkg.versions,
                                        description: pkg.description,
                                        selected: pkg.selected,
                                    },
                                };
                            } else {
                                thirdPartyPackages[pkg.name] = pkg;
                            }
                        } else {
                            const childPackage = thirdPartyPackages[`@types/${pkg.name}`];
                            if (childPackage) {
                                thirdPartyPackages[pkg.name] = {
                                    ...pkg,
                                    childPackage: {
                                        name: childPackage.name,
                                        selectedVersion: childPackage.selectedVersion,
                                        versions: childPackage.versions,
                                        description: childPackage.description,
                                        selected: childPackage.selected,
                                    },
                                };
                                delete thirdPartyPackages[`@types/${pkg.name}`];
                            } else {
                                thirdPartyPackages[pkg.name] = pkg;
                            }
                        }
                    });

                    return Object.values(thirdPartyPackages);
                })
            )
        )
    )
    .subscribe((pkgs) => packageManagerThirdPartyPackages$.next(pkgs));

import { LoaderFn, UnloaderFn } from 'react-location';
import { getWorkspace } from '../data/workspace';
import { LocationGenerics } from '../router';
import { loadApps } from '../store/apps';
import { Language } from '../store/editor/types';
import {
    exitWorkspaceAction$,
    ignoreNextWorkspaceExit$,
    selectedWorkspace$,
    selectedWorkspaceUid$,
    selectEnvironmentAction$,
    selectWorkspaceAction$,
} from '../store/workspace';
import { loadWorkspaces } from '../store/workspace/utils';
import { initEditor, resetEditor } from './editor';
import { resetAiAssistanceChatAction$ } from '../store/workspace/ai-assistance';
import { loadLoggedInUserOrganizations } from '../store/organizations';
import { getBillingEntities } from '../data/billing-details';
import { billingEntities$ } from '../store/billing-details';
import { loggedInUserDetails$ } from '../store/user';
import { loadErrorPage } from '../store/error';

export const workspaceLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    resetAiAssistanceChatAction$.next();
    try {
        const [workspace, billingEntities] = await Promise.all([
            getWorkspace(routeMatch.params.workspaceUid ?? '', !loggedInUserDetails$.value?.impersonating),
            getBillingEntities(),
            loadWorkspaces(false),
            loadApps(),
            loadLoggedInUserOrganizations(),
        ]);

        selectEnvironmentAction$.next(routeMatch.params.environmentUid);
        selectWorkspaceAction$.next(routeMatch.params.workspaceUid);
        selectedWorkspace$.next(workspace);
        billingEntities$.next(billingEntities);

        initEditor(workspace.language as Language);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load workspace.',
        });

        throw e;
    }

    return {};
};

export const workspaceUnloader: UnloaderFn<LocationGenerics> = () => {
    if (ignoreNextWorkspaceExit$.value) {
        ignoreNextWorkspaceExit$.next(false);
    } else {
        exitWorkspaceAction$.next(selectedWorkspaceUid$.value ?? '');
    }

    resetEditor();
};

import { styled } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

interface Option {
    category?: string | null;
    name: string;
    type?: string;
    uid: string;
}

interface EventListenerSelectProps {
    disabled?: boolean;
    labelName: string;
    optionList: Option[];
    selectedOption?: string;
    handleSelect?: (optionValue: string) => void;
}

const StyledTypography = styled(Typography)(() => ({
    display: 'inline',
}));

const StyledMenuItem = styled(MenuItem)({
    width: '300px',
    '& .MuiListItemIcon-root': {
        minWidth: 0,
    },
});

export const EventListenerSelect: React.FC<EventListenerSelectProps> = ({
    disabled = false,
    labelName = 'eventlistenerselect',
    optionList,
    selectedOption,
    handleSelect,
}) => {
    const remapOptionList = (): JSX.Element[] => {
        const grouped = optionList.reduce<Record<string, Option[]>>((acc, curr) => {
            const category = curr.category ?? 'Basic';
            if (!acc[category]) {
                acc[category] = [];
            }
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            acc[category]!.push(curr);
            return acc;
        }, {});

        const elementList: JSX.Element[] = [];
        let i = 0;
        Object.keys(grouped).forEach((category) => {
            if (!(Object.keys(grouped).length === 1 && category === 'Basic')) {
                elementList.push(<ListSubheader key={i++}>{category}</ListSubheader>);
            }
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const options = grouped[category]!.map((option) => (
                <StyledMenuItem key={i++} value={option.uid}>
                    <StyledTypography noWrap>{option.name}</StyledTypography>
                </StyledMenuItem>
            ));
            elementList.push(...options);
        });

        return elementList;
    };

    const handleChange = (e: SelectChangeEvent<string>): void => {
        handleSelect?.(e.target.value);
    };

    // html form labels cannot have spaces, and lowercase is good practice
    const labelId = labelName.replace(/\s/g, '').toLowerCase() + '-label';

    return (
        <>
            <InputLabel id={labelId} required>
                {labelName}
            </InputLabel>
            <Select
                required
                label={labelName}
                labelId={labelId}
                variant="outlined"
                value={selectedOption}
                onChange={handleChange}
                disabled={disabled}
            >
                {remapOptionList()}
            </Select>
        </>
    );
};

import { LoaderFn } from 'react-location';
import { getOrganizationPlan, getOrganizationUsage } from '../../data/organization';
import { getOrganisationDetails } from '../../data/report/organisationDetails';
import { LocationGenerics } from '../../router';
import { publishLocalFeedbackEventAction$ } from '../../store/feedback';
import {
    selectedOrganisationDetails$,
    selectedOrganisationPlanUsage$,
    selectedOrganisationPlan$,
    selectedOrganisationPlanLimits$,
} from '../../store/report/organisations';
import { InformativeError, PermissionError } from '../../utils/repository';

export const organisationDetailsReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const organisationUid = routeMatch.params.teamUid!;

        const [details, plan, usage] = await Promise.all([
            getOrganisationDetails({
                organisationUid,
            }),
            getOrganizationPlan(organisationUid),
            getOrganizationUsage(organisationUid),
        ]);

        selectedOrganisationDetails$.next(details);
        selectedOrganisationPlan$.next(plan);
        selectedOrganisationPlanUsage$.next(usage);

        selectedOrganisationPlanLimits$.next({
            period: details.planPeriod as 'MONTHLY' | 'ANNUALLY',
            cost: {
                monthly: details.monthlyCost,
                annually: details.monthlyCost,
            },
            connectorsLimit: details.connectorsLimit,
            invocationsLimit: details.invocationsLimit,
            egressCallsBurstLimit: details.egressCallsBurstLimit,
            egressCallsRefillRate: details.egressCallsRefillRate,
            privateApiCallsBurstLimit: details.privateApiCallsBurstLimit,
            privateApiCallsRefillRate: details.privateApiCallsRefillRate,
            recordStorageApiCallsBurstLimit: details.recordStorageApiCallsBurstLimit,
            recordStorageApiCallsRefillRate: details.recordStorageApiCallsRefillRate,
            recordStorageCapacityLimit: details.recordStorageCapacityLimit,
            scriptInvocationsBurstLimit: details.scriptInvocationsBurstLimit,
            scriptInvocationsRefillRate: details.scriptInvocationsRefillRate,
            triggerScriptApiCallsBurstLimit: details.triggerScriptApiCallsBurstLimit,
            triggerScriptApiCallsRefillRate: details.triggerScriptApiCallsRefillRate,
        });
    } catch (e) {
        if (e instanceof PermissionError || e instanceof InformativeError) {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: e.message,
            });
        } else {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message:
                    'Error occurred while loading team details or usage. Please try again, if the issue persists please contact support.',
            });
        }
    }

    return {};
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from './auth/AuthProvider';
import reportWebVitals from './reportWebVitals';
import { setup } from './setup';
import { generate } from 'short-uuid';
import './index.css';

const removeLoadingSplash = (): void => {
    const el = document.getElementById('loading');
    if (el) {
        el.className = 'loaded';
        setTimeout(() => {
            el.remove();
        }, 500);
    }
};

const canAccessLocalStorage = (): boolean => {
    try {
        localStorage.getItem('Test');
        return true;
    } catch (err) {
        return false;
    }
};

export let sessionLockAcquired = false;

const SESSION_PING_INTERVAL = 250;

const getSessionId = (): string => {
    try {
        const lastPing = +(sessionStorage.getItem('STITCH_SESSION_PING') ?? '0');
        if (lastPing < Date.now() - SESSION_PING_INTERVAL * 2) {
            let sessionId = sessionStorage.getItem('STITCH_SESSION_ID');
            sessionStorage.setItem('STITCH_SESSION_PING', Date.now().toString());

            if (!sessionId) {
                sessionId = generate();
                sessionStorage.setItem('STITCH_SESSION_ID', sessionId);
            }

            sessionLockAcquired = true;

            return sessionId;
        } else {
            console.warn('Session lock in place, generating new session ID');
            return generate();
        }
    } catch (e) {
        console.error('Failed to acquire past session ID', e);
        return generate();
    }
};

export const SESSION_ID = getSessionId();

setInterval(() => {
    try {
        if (sessionStorage.getItem('STITCH_SESSION_PING') !== null) {
            sessionStorage.setItem('STITCH_SESSION_PING', Date.now().toString());
        }
    } catch (e) {
        console.error('Error while updating session ping', e);
    }
}, SESSION_PING_INTERVAL);

const getNode = (): [JSX.Element, boolean] => {
    if (!canAccessLocalStorage()) {
        return [
            <div style={{ margin: '8px' }}>
                Browser configuration is currently not supported. Please enable cookies and website data or try another
                browser.
            </div>,
            false,
        ];
    }

    return [
        <React.StrictMode>
            <AuthProvider>
                <App />
            </AuthProvider>
        </React.StrictMode>,
        true,
    ];
};
(async () => {
    const [node, passed] = getNode();

    if (passed) {
        await setup();
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReactDOM.createRoot(document.getElementById('root')!).render(node);
    removeLoadingSplash();
})().catch((e) => {
    if (!process.env.STORYBOOK) {
        console.log('Failed to render app', e);
        alert('Failed to initiate app, please contact support.');
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

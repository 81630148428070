interface NpmRegistryPackage {
    name: string;
    description?: string;
    versions: Record<string, unknown>;
    'dist-tags': {
        latest: string;
    };
}

export const NPM_REGISTRY_URL = 'https://registry.npmjs.org';
const npmRegistryPackageCache = new Map<string, NpmRegistryPackage>();

export const fetchPackageFromNpmRegistry = async (name: string): Promise<NpmRegistryPackage> => {
    const packageData = npmRegistryPackageCache.get(name);

    if (!packageData) {
        const response = await fetch(`${NPM_REGISTRY_URL}/${name}`);

        if (!response.ok) {
            console.error('Error while fetching package from npm registry');

            const message = await response.text();
            if (message) {
                throw new Error(message);
            } else {
                throw Error(`Unexpected error while fetching package from npm registry: ${response.status}`);
            }
        }

        const respBody = await response.text();
        if (!respBody) {
            throw new Error('Missing response body from fetching package from npm registry');
        }

        const packageResponse = JSON.parse(respBody);

        if (!isValidNPMRegistryPackage(packageResponse)) {
            throw new Error('Invalid NPM Registry package');
        }

        npmRegistryPackageCache.set(name, packageResponse);

        return packageResponse;
    }
    return packageData;
};

const isValidNPMRegistryPackage = <T extends NpmRegistryPackage>(pkg: T): pkg is T =>
    !!pkg.name && !!pkg['dist-tags']?.latest && Object.keys(pkg.versions ?? {}).length > 0;

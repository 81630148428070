import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { Invocations, InvocationsRequest, InvocationWorkspace } from '../../data/reporting';
import { ReportingFilters, ReportingPageFilters } from './ReportingPageFilters';
import { ReportingPageTable } from './ReportingPageTable';
import { PageContainer, PageTitle } from '../layout';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';

interface ReportingProps {
    invocations: Invocations;
    nextToken?: string;
    workspaces?: Record<string, InvocationWorkspace>;
    isLoading: boolean;
    errors?: string;
    filters: ReportingFilters;
    onQueryInvocations(request: InvocationsRequest): void;
    onSearchInvocations(request: InvocationsRequest): void;
    onSearch(request: ReportingFilters): void;
}

export const tableFilters = [
    'Invocation ID',
    'Team',
    'Workspace Owner',
    'Environment',
    'Script',
    'Trigger Type',
    'Duration',
    'Logs',
    'HTTP Logs',
    'Invocation started',
    'Execution Status',
];

export const Reporting: React.FC<ReportingProps> = ({
    invocations,
    filters,
    nextToken,
    workspaces,
    isLoading,
    errors,
    onQueryInvocations,
    onSearchInvocations,
    onSearch,
}) => {
    const defaultFilters = [
        'Invocation ID',
        'Team',
        'Script',
        'Trigger Type',
        'Duration',
        'Logs',
        'HTTP Logs',
        'Invocation started',
        'Execution Status',
    ];

    const [selectedFilters, setSelectedFilters] = useState<string[]>(
        readLocalStorage('selectedFilters', defaultFilters)
    );

    const handleUpdateFilters = (e: SelectChangeEvent<unknown>): void => {
        const filters = e.target.value as string[];
        filters.sort((a, b) => tableFilters.indexOf(a) - tableFilters.indexOf(b));
        setSelectedFilters(filters);
    };

    useEffect(() => {
        saveLocalStorage('selectedFilters', selectedFilters);
    }, [selectedFilters]);

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <PageTitle title="Reporting" />
            <ReportingPageFilters
                invocationsLength={invocations?.length || 0}
                filters={filters}
                onSearchInvocations={onSearchInvocations}
                onSearch={onSearch}
                onUpdate={handleUpdateFilters}
                tableFilters={tableFilters}
                selectedFilters={selectedFilters}
                workspaces={workspaces}
            />
            <ReportingPageTable
                invocations={invocations}
                filters={filters}
                nextToken={nextToken}
                isLoading={isLoading}
                errors={errors}
                onQueryInvocations={onQueryInvocations}
                selectedFilters={selectedFilters}
            />
        </PageContainer>
    );
};

import { styled } from '@mui/material/styles';
import { Box, FormControl, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import {
    ChangeOrganisationPlanRequest,
    OrganisationDetails,
    OrganisationPlanLimits,
} from '../../data/report/organisationDetails';
import { AbsoluteCenteredLoadingSpinner } from '../loading/AbsoluteCenteredLoadingSpinner';
import { StyledTitleContainer } from './UsersWithWorkspacesReportPage';
import { PageContainer } from '../layout/PageComponents';
import { Alert } from '../alert/Alert';
import {
    changeOrganisationPlanLimitsAction$,
    changeOrganisationPlanLimitsActionV2$,
    getOrganisationPlanLimitsAction$,
} from '../../store/report/organisations';
import { OrganizationPlan, OrganizationUsage } from '../../data/organization';
import { formatBytesToString } from '../../utils/formatBytes';
import { Button } from '../buttons/Button';

export interface OrganisationsReportPageProps {
    organisation?: OrganisationDetails;
    plan?: OrganizationPlan;
    usage?: OrganizationUsage;
    limits?: OrganisationPlanLimits;
    limitsLoading?: boolean;
    limitsError?: string;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

const ORGANISATION_PLANS = ['Silver', 'Gold', 'Platinum', 'Suspended', 'Custom'];

export const OrganisationDetailsReportPage: React.FC<OrganisationsReportPageProps> = ({
    organisation,
    plan,
    usage,
    limits,
    limitsLoading = false,
    limitsError,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [organisationPlan, setOrganisationPlan] = useState(organisation?.plan);
    const [organisationPlanPeriod, setOrganisationPlanPeriod] = useState(organisation?.planPeriod);
    const [organisationPlanMonthlyCost, setOrganisationPlanMonthlyCost] = useState(organisation?.monthlyCost);
    const [salablePlanUid, setSalablePlanUid] = useState('');
    const [organisationPlanLimits, setOrganisationPlanLimits] = useState(limits);

    useEffect(() => {
        if (limits) {
            setOrganisationPlanLimits(limits);
            const period = organisationPlanPeriod?.toLowerCase() ?? 'monthly';
            const monthlyCost = period === 'monthly' ? limits.cost.monthly : limits.cost.annually;

            setOrganisationPlanMonthlyCost(monthlyCost);
        }
    }, [limits]);

    useEffect(() => {
        if (organisation) {
            setOrganisationPlan(organisation.plan);
            setOrganisationPlanPeriod(organisation.planPeriod);
        }
    }, [organisation]);

    const handleOrganisationPlanChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const plan = e.target.value;
        setOrganisationPlan(plan);

        if (organisation) {
            getOrganisationPlanLimitsAction$.next({ plan, organisationUid: organisation.uid });
        }
    };

    const handleOrganisationPlanPeriodChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setOrganisationPlanPeriod(e.target.value);
        if (organisationPlan && organisationPlan !== 'custom') {
            const period = e.target.value.toLowerCase();
            const monthlyCost =
                period === 'monthly' ? organisationPlanLimits?.cost.monthly : organisationPlanLimits?.cost.annually;
            if (monthlyCost) {
                setOrganisationPlanMonthlyCost(monthlyCost);
            }
        }
    };

    const handleLimitChange = (value: number, field: string): void => {
        setOrganisationPlanLimits((current) => {
            if (current) {
                return {
                    ...current,
                    [field]: value,
                };
            }
            return current;
        });
    };

    const handleOnPlanChange = (): void => {
        if (organisation && plan?.salablePlan && !!salablePlanUid) {
            changeOrganisationPlanLimitsActionV2$.next({
                organizationUid: organisation.uid,
                salablePlanUid,
            });
        } else if (organisation && organisationPlanLimits) {
            // eslint-disable-next-line no-unused-vars
            const { cost, ...limits } = organisationPlanLimits;
            changeOrganisationPlanLimitsAction$.next({
                ...limits,
                plan: (organisationPlan ?? organisation.plan) as ChangeOrganisationPlanRequest['plan'],
                period: (
                    organisationPlanPeriod ?? organisation.planPeriod
                ).toUpperCase() as ChangeOrganisationPlanRequest['period'],
                monthlyCost: organisationPlanMonthlyCost ?? organisation.monthlyCost,
                organizationUid: organisation.uid,
            });
        }
    };

    const isNotCustomPlan = organisationPlan !== 'custom' || (organisationPlan === 'custom' && !!plan?.salablePlan);
    const isSamePlan =
        organisation?.planPeriod === organisationPlanPeriod &&
        organisation?.monthlyCost === organisationPlanMonthlyCost &&
        organisation?.connectorsLimit === organisationPlanLimits?.connectorsLimit &&
        organisation?.invocationsLimit === organisationPlanLimits?.invocationsLimit &&
        organisation?.recordStorageCapacityLimit === organisationPlanLimits?.recordStorageCapacityLimit &&
        organisation?.scriptInvocationsBurstLimit === organisationPlanLimits?.scriptInvocationsBurstLimit &&
        organisation?.scriptInvocationsRefillRate === organisationPlanLimits?.scriptInvocationsRefillRate &&
        organisation?.egressCallsBurstLimit === organisationPlanLimits?.egressCallsBurstLimit &&
        organisation?.egressCallsRefillRate === organisationPlanLimits?.egressCallsRefillRate &&
        organisation?.recordStorageApiCallsBurstLimit === organisationPlanLimits?.recordStorageApiCallsBurstLimit &&
        organisation?.recordStorageApiCallsRefillRate === organisationPlanLimits?.recordStorageApiCallsRefillRate &&
        organisation?.triggerScriptApiCallsBurstLimit === organisationPlanLimits?.triggerScriptApiCallsBurstLimit &&
        organisation?.triggerScriptApiCallsBurstLimit === organisationPlanLimits?.triggerScriptApiCallsBurstLimit &&
        organisation?.privateApiCallsBurstLimit === organisationPlanLimits?.privateApiCallsBurstLimit &&
        organisation?.privateApiCallsRefillRate === organisationPlanLimits?.privateApiCallsRefillRate;

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledTitleContainer>
                <Typography variant="h3">Team Details</Typography>
            </StyledTitleContainer>
            {organisation && (
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>
                        <strong>ID:</strong> {organisation.uid}
                    </Typography>
                    <Typography>
                        <strong>Name:</strong> {organisation.name}
                    </Typography>
                    {organisation.description && (
                        <Typography>
                            <strong>Description:</strong> {organisation.description}
                        </Typography>
                    )}
                    <Typography>
                        <strong>Salable Plan:</strong> {plan?.salablePlan ? 'Yes' : 'No'}
                    </Typography>
                    <Typography>
                        <strong>Created By:</strong> {organisation.createdBy}
                    </Typography>
                    <Typography>
                        <strong>Plan Name:</strong> {organisation.plan}
                    </Typography>
                    <Typography>
                        <strong>Plan Period:</strong> {organisation.planPeriod}
                    </Typography>
                    <Typography>
                        <strong>Plan Version:</strong> {organisation.planVersion}
                    </Typography>
                    <Typography>
                        <strong>Billing Details:</strong> {organisation.billingDetails}
                    </Typography>
                    <Typography>
                        <strong>Workspaces:</strong>{' '}
                        {organisation.workspaces.length > 0 ? organisation.workspaces.join(', ') : 'No workspaces'}
                    </Typography>
                    <Typography>
                        <strong>Members:</strong>{' '}
                        {organisation.members.length > 0 ? organisation.members.join(', ') : 'No members'}
                    </Typography>
                    <Typography>
                        <strong>Member Invites:</strong>{' '}
                        {organisation.memberInvites.length > 0
                            ? organisation.memberInvites.join(', ')
                            : 'No member invites'}
                    </Typography>
                </Box>
            )}
            {organisationPlan && (
                <Box sx={{ paddingLeft: 2, paddingTop: 3 }}>
                    <Typography variant="h4">Change team plan (0 = disabled, -1 = unlimited)</Typography>
                    <FormControl sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <TextField
                            select
                            label={'Team Plan'}
                            value={organisationPlan}
                            disabled={!!plan?.salablePlan}
                            size="small"
                            onChange={handleOrganisationPlanChange}
                        >
                            {ORGANISATION_PLANS.map((plan, idx) => (
                                <MenuItem key={idx} value={plan.toLowerCase()}>
                                    {plan}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <TextField
                            select
                            label={'Team Plan Period'}
                            value={organisationPlanPeriod}
                            disabled={!!plan?.salablePlan}
                            size="small"
                            onChange={handleOrganisationPlanPeriodChange}
                        >
                            <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>
                            <MenuItem value={'ANNUALLY'}>ANNUALLY</MenuItem>
                        </TextField>
                    </FormControl>
                    {limitsLoading ? (
                        <AbsoluteCenteredLoadingSpinner />
                    ) : (
                        <>
                            {limitsError && <StyledAlert severity="error" alertTitle="Error" text={limitsError} />}
                            {organisationPlanLimits && (
                                <Box sx={{ paddingTop: 2 }}>
                                    <Box>
                                        <StyledTextField
                                            label="Monthly Cost"
                                            type="number"
                                            size="small"
                                            disabled={!!plan?.salablePlan}
                                            value={organisationPlanMonthlyCost}
                                            onChange={(e) => setOrganisationPlanMonthlyCost(+e.target.value)}
                                        />
                                        <StyledTextField
                                            label="Connectors"
                                            type="number"
                                            size="small"
                                            disabled={isNotCustomPlan}
                                            value={organisationPlanLimits.connectorsLimit}
                                            onChange={(e) => handleLimitChange(+e.target.value, 'connectorsLimit')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {usage?.connectors.consumed ?? 0} of
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <StyledTextField
                                            label="Invocations"
                                            type="number"
                                            size="small"
                                            disabled={isNotCustomPlan}
                                            value={organisationPlanLimits.invocationsLimit}
                                            onChange={(e) => handleLimitChange(+e.target.value, 'invocationsLimit')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {usage?.invocations.consumed ?? 0} of
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <StyledTextField
                                            label="Record Storage Capacity"
                                            type="number"
                                            size="small"
                                            disabled={isNotCustomPlan}
                                            value={organisationPlanLimits.recordStorageCapacityLimit}
                                            onChange={(e) =>
                                                handleLimitChange(+e.target.value, 'recordStorageCapacityLimit')
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {formatBytesToString(usage?.recordStorage.consumed ?? 0, 'B')}{' '}
                                                        of
                                                    </InputAdornment>
                                                ),
                                                endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                                            }}
                                        />
                                    </Box>

                                    <OrganisationPlanLimitTextfieldPair
                                        title="Script Invocations"
                                        field="scriptInvocations"
                                        burstLimitValue={organisationPlanLimits.scriptInvocationsBurstLimit}
                                        refillRateValue={organisationPlanLimits.scriptInvocationsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Egress Calls"
                                        field="egressCalls"
                                        burstLimitValue={organisationPlanLimits.egressCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.egressCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Record Storage Api Calls"
                                        field="recordStorageApiCalls"
                                        burstLimitValue={organisationPlanLimits.recordStorageApiCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.recordStorageApiCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Trigger Script Api Calls"
                                        field="triggerScriptApiCalls"
                                        burstLimitValue={organisationPlanLimits.triggerScriptApiCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.triggerScriptApiCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Private Api Calls"
                                        field="privateApiCalls"
                                        burstLimitValue={organisationPlanLimits.privateApiCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.privateApiCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    {plan?.salablePlan && (
                                        <Box>
                                            {plan?.salablePlan && (
                                                <FormControl>
                                                    <StyledTextField
                                                        label="Salable Plan to Update"
                                                        type="text"
                                                        size="small"
                                                        value={salablePlanUid}
                                                        onChange={(e) => setSalablePlanUid(e.target.value)}
                                                    />
                                                </FormControl>
                                            )}
                                        </Box>
                                    )}
                                    <Box>
                                        <Button
                                            disabled={plan?.salablePlan ? !salablePlanUid : isSamePlan}
                                            onClick={handleOnPlanChange}
                                        >
                                            Change Plan
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            )}
        </PageContainer>
    );
};

interface OrganisationPlanLimitTextfieldPairProps {
    title: string;
    field: string;
    burstLimitValue?: number;
    refillRateValue?: number;
    disabled?: boolean;
    onChange?: (value: number, field: string) => void;
}

export const OrganisationPlanLimitTextfieldPair: React.FC<OrganisationPlanLimitTextfieldPairProps> = ({
    title,
    field,
    burstLimitValue,
    refillRateValue,
    disabled = false,
    onChange,
}) => {
    return (
        <Box>
            <Typography variant="h4" sx={{ paddingBottom: 2 }}>
                {title}
            </Typography>
            <StyledTextField
                label="Burst Limit"
                type="number"
                size="small"
                value={burstLimitValue}
                disabled={disabled}
                onChange={(e) => onChange?.(+e.target.value, `${field}BurstLimit`)}
            />
            <StyledTextField
                label="Refill Rate"
                type="number"
                size="small"
                value={refillRateValue}
                disabled={disabled}
                onChange={(e) => onChange?.(+e.target.value, `${field}RefillRate`)}
            />
        </Box>
    );
};

import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Button } from '../buttons/Button';
import { Dropdown } from '../dropdown/Dropdown';
import { IconCircle } from '../icon-circle';
import { InfoIcon } from '../icons/InfoIcon';
import { DialogAlert, DialogTitleSmall, StyledDialogInstructions } from '../dialog/DialogComponents';
import { handleKeyUp } from '../../utils/handleKeyUp';

interface AddWorkspaceToOrgDialogProps {
    errors?: string;
    open?: boolean;
    saving?: boolean;
    organizations: {
        value: string;
        name: string;
    }[];
    selectedOrganizationUid: string;
    onCreateNewOrganization(): void;
    onSelectOrganization(organizationUid: string): void;
    onCancel(): void;
}

export const AddWorkspaceToOrgDialog: React.FC<AddWorkspaceToOrgDialogProps> = ({
    errors,
    open = false,
    organizations = [],
    saving = false,
    selectedOrganizationUid,
    onCancel,
    onCreateNewOrganization,
    onSelectOrganization,
}) => {
    const [currentlySelectedOrganizationUid, setCurrentlySelectedOrganizationUid] = useState(selectedOrganizationUid);

    const canConfirm = !saving && !!currentlySelectedOrganizationUid;

    useEffect(() => {
        setCurrentlySelectedOrganizationUid(selectedOrganizationUid);
    }, [open]);

    return (
        <Dialog
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({
                    event,
                    enterCondition: canConfirm,
                    enterFn: () => onSelectOrganization(currentlySelectedOrganizationUid),
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleSmall title="Share" icon={<IconCircle icon={<PersonAddAltIcon />} />} />

            <DialogAlert
                severity="info"
                alertTitle="Privacy and collaboration"
                text="Adding a workspace to a team shares the workspace and connectors with team admins and potentially others depending on the share settings you select."
            />
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            <DialogContent>
                <StyledDialogInstructions sx={{ alignItems: 'flex-start' }}>
                    <InfoIcon />
                    <Typography>
                        To share a workspace, you must add the workspace to a team. In the next screen you can decide
                        who to share it with within this team.
                    </Typography>
                </StyledDialogInstructions>
                <Dropdown
                    label="Associate with a team"
                    items={organizations}
                    onCreateNew={onCreateNewOrganization}
                    selectedItem={currentlySelectedOrganizationUid}
                    onSelect={(uid) => setCurrentlySelectedOrganizationUid(uid)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} variant="outlined">
                    Cancel
                </Button>
                <Button
                    busy={saving}
                    disabled={!canConfirm}
                    variant="contained"
                    onClick={() => onSelectOrganization(currentlySelectedOrganizationUid)}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { DialogAlert } from '../../dialog/DialogComponents';

const StyledHeaderContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'left',
}));

const StyledInformationContainer = styled('div')(({ theme }) => ({
    margin: theme.spacing(1, 0),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledDisclaimerContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    color: theme.palette.warning.main,
    textAlign: 'left',
}));

const StyledDialogAlert = styled(DialogAlert)(({ theme }) => ({
    minWidth: 'fit-content',
    marginBottom: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    textAlign: 'left',
    '& .MuiAlertTitle-root': {
        fontSize: '0.8rem',
        color: theme.palette.common.black,
    },
    '& .MuiAlert-icon': {
        color: theme.palette.common.black,
    },
}));

export const OrganizationInvoicePlanInformation: React.FC = () => {
    return (
        <StyledInformationContainer>
            <StyledHeaderContainer>
                <Typography className="extra-bold">How do invoice payments work?</Typography>
            </StyledHeaderContainer>
            <Divider />
            <StyledDialogAlert
                severity="success"
                text="By submitting this form you will trigger a request for an invoice and our Team will be in touch if any additional information is required."
                alertTitle="1. Invoice request submitted to the ScriptRunner Connect Team"
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path
                            d="M5.55 18.9969L2 15.4469L3.4 14.0469L5.525 16.1719L9.775 11.9219L11.175 13.3469L5.55 18.9969ZM5.55 10.9969L2 7.44688L3.4 6.04688L5.525 8.17188L9.775 3.92188L11.175 5.34688L5.55 10.9969ZM13 16.9969V14.9969H22V16.9969H13ZM13 8.99688V6.99688H22V8.99688H13Z"
                            fill="black"
                            fill-opacity="0.87"
                        />
                    </svg>
                }
            />
            <Divider variant="middle" />
            <StyledDialogAlert
                severity="success"
                text="An invoice with a detailed breakdown of the charges will be sent to you by email after your purchase."
                alertTitle="2. Receive invoice by email"
                icon={<ReceiptLongOutlinedIcon fontSize="inherit" />}
            />
            <Divider variant="middle" />
            <StyledDialogAlert
                severity="success"
                text="Take the time to check your invoice to make sure all the information is correct and matches your purchase."
                alertTitle="3. Review and verify"
                icon={<PreviewOutlinedIcon fontSize="inherit" />}
            />
            <Divider variant="middle" />
            <StyledDialogAlert
                severity="success"
                text="Once you have reviewed the invoice, you can make payment using the payment instructions provided."
                alertTitle="4. Make payment"
                icon={<MonetizationOnOutlinedIcon fontSize="inherit" />}
            />
            <Divider variant="middle" />
            <StyledDisclaimerContainer>
                <Typography>
                    <b>Disclaimer:</b> Please ensure that you complete payment by the due date to avoid any interruption
                    to your service.
                </Typography>
            </StyledDisclaimerContainer>
        </StyledInformationContainer>
    );
};

import { LoaderFn } from 'react-location';
import { getOrganisationsWithUtilization } from '../../data/report/organisationsUtilization';
import { LocationGenerics } from '../../router';
import { organisationsUtilizationReportData$ } from '../../store/report/organisationsUtilization';

export const organisationsUtilizationReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const organisations = await getOrganisationsWithUtilization({
        timePeriod: routeMatch.search.timePeriod ? (routeMatch.search.timePeriod as string).substring(1) : undefined,
    });

    organisationsUtilizationReportData$.next(organisations);

    return {};
};

import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '../../buttons/Button';
import { Dropdown } from '../../dropdown/Dropdown';
import { InfoIcon } from '../../icons/InfoIcon';
import { PageContainer, PageSubTitle } from '../../layout';

export interface ReadOnlyApiHandlerDetailsProps {
    path?: string;
}

const StyledInnerContainer = styled('div')(({ theme }) => ({
    maxWidth: '340px',
    paddingTop: theme.spacing(1),
}));

const StyledInputContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1.5),
}));

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    height: 20.125,
    lineHeight: 1.5,
    marginRight: 0,
    padding: 0,
    '& p': {
        alignItems: 'center',
        color: theme.palette.text.disabled,
        display: 'flex',
        height: 20.125,
    },
}));

export const ReadOnlyApiHandlerDetails: React.FC<ReadOnlyApiHandlerDetailsProps> = ({ path }) => {
    const PATH_TOOLTIP =
        'Path is a unique identifier and is also used to import API Connection in Scripts for consumption.';
    const CONNECTION_TOOLTIP =
        'Connector is used to make sure proper authentication headers are passed along when you use API Connection.';

    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => setCurrentPath(path ?? ''), [path]);

    return (
        <>
            <PageContainer data-test-id="api-handler-details" sx={{ bgcolor: 'background.paper' }}>
                <PageSubTitle>API Connection</PageSubTitle>
                <StyledInnerContainer>
                    <StyledInputContainer>
                        <TextField
                            required
                            InputProps={{
                                startAdornment: <StyledInputAdornment position="start">./api/</StyledInputAdornment>,
                            }}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& .MuiInputBase-input': {
                                    height: 20.125,
                                    lineHeight: 1.5,
                                    padding: '0 !important',
                                },
                            }}
                            variant="outlined"
                            label="Path"
                            placeholder="path"
                            value={currentPath}
                            disabled={true}
                        />
                        <Tooltip title={PATH_TOOLTIP} placement="top">
                            <StyledInfoIcon />
                        </Tooltip>
                    </StyledInputContainer>
                    <StyledInputContainer>
                        <Dropdown required disabled={true} items={[]} label="Uses Connector" />
                        <Tooltip title={CONNECTION_TOOLTIP} placement="top">
                            <StyledInfoIcon />
                        </Tooltip>
                    </StyledInputContainer>
                    <DialogActions>
                        <Button disabled={true} variant="outlined" data-hotspot="cancel">
                            Cancel
                        </Button>
                        <Button disabled={true}>Save</Button>
                    </DialogActions>
                </StyledInnerContainer>
            </PageContainer>
        </>
    );
};

import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { BillingDetailsScreen } from '../../components/organization/organization-details/BillingDetailsScreen';
import { BillingDetailsCardPlanOverview } from '../../components/organization/organization-details/BillingDetailsCardPlanOverview';
import { BillingDetailsInvoicePlanOverview } from '../../components/organization/organization-details/BillingDetailsInvoicePlanOverview';
import {
    changeOrganizationBillingDetailsAction$,
    organizationBillingDetails$,
    organizationBillingDetailsUpdateCancelledAction$,
    organizationBillingDetailsUpdatedAction$,
    organizationBillingEntityPermissionError$,
    savingOrganizationBillingDetails$,
    selectedOrganizationUid$,
    selectedOrganizationPlan$,
    organizationBillingDetailsCustomerPortal$,
} from '../../store/organization';
import { getBasePath } from '../../utils/path';

export const OrganizationBillingDetailsContainer: React.FC = () => {
    const plan = useObservableState(selectedOrganizationPlan$);
    const { billingEntities, selectedBillingEntityUid } = useObservableState(organizationBillingDetails$) || {};

    if (plan?.salablePlan) {
        if (plan.paymentType === 'CARD') {
            const customerPortalUrl = useObservableState(organizationBillingDetailsCustomerPortal$);

            return (
                <BillingDetailsCardPlanOverview
                    onManage={customerPortalUrl ? () => window.open(customerPortalUrl, '_blank') : undefined}
                />
            );
        } else {
            const selectedBillingEntity = billingEntities?.find((entity) => entity.uid === selectedBillingEntityUid);
            return <BillingDetailsInvoicePlanOverview plan={plan} billingEntity={selectedBillingEntity} />;
        }
    }

    const savingBillingDetails = useObservableState(savingOrganizationBillingDetails$);
    const selectedOrgUid = useObservableState(selectedOrganizationUid$);
    const billingDetailsPermissionError = useObservableState(organizationBillingEntityPermissionError$);
    const navigate = useNavigate();

    useSubscription(organizationBillingDetailsUpdatedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/team/${selectedOrgUid}/billing` }), 100);
    });

    useSubscription(organizationBillingDetailsUpdateCancelledAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${selectedOrgUid}/billing` }), 100);
    });

    return (
        <BillingDetailsScreen
            billingEntities={billingEntities ?? []}
            plan={plan}
            selectedBillingEntity={selectedBillingEntityUid ?? ''}
            saving={savingBillingDetails}
            error={billingDetailsPermissionError}
            onSave={(billingEntityUid: string) => {
                changeOrganizationBillingDetailsAction$.next({
                    organizationUid: selectedOrgUid ?? '',
                    billingEntityUid,
                });
            }}
            onEdit={(uid) => navigate({ to: `${getBasePath()}billing/${uid}` })}
            onCancel={() => organizationBillingDetailsUpdateCancelledAction$.next()}
        />
    );
};

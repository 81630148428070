import { OrgAvatarContainer } from '../../avatar';
import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Button } from '../../buttons/Button';
import { Dropdown } from '../../dropdown/Dropdown';
import Box from '@mui/system/Box';
import { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import { DialogAlert } from '../../dialog';
import { StyledImageBox, StyledImageTextBox, StyledOrgDetailsContainer } from '../OrganizationComponents';
import { PageItalicText } from '../../layout';

export interface EditOrganizationProps {
    userCanUpdateDetails: boolean;
    avatar?: string;
    errors?: string;
    name: string;
    description?: string;
    selectedIndustryType?: string;
    industryTypes: {
        value: string;
        name: string;
    }[];
    updating: boolean;
    onAvatarUpload(): void;
    onUpdate(event: OrgDetailsUpdateEvent): void;
    onCancel(): void;
}

export interface OrgDetailsUpdateEvent {
    name: string;
    description?: string;
    selectedIndustryType?: string;
}

const StyledContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
}));

const StyledButtonsBox = styled(DialogActions)(() => ({
    width: 300,
}));

export const EditOrganization: React.FC<EditOrganizationProps> = ({
    userCanUpdateDetails,
    avatar,
    name,
    errors,
    description,
    selectedIndustryType,
    industryTypes,
    updating,
    onAvatarUpload,
    onUpdate,
    onCancel,
}) => {
    const [currentName, setCurrentName] = useState(name);
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentIndustryType, setCurrentIndustryType] = useState(selectedIndustryType);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (
            currentName !== name ||
            currentDescription !== description ||
            currentIndustryType !== selectedIndustryType
        ) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [currentName, currentDescription, currentIndustryType]);

    const handleChangeType = (uid: string): void => {
        setCurrentIndustryType(uid);
    };

    const handleUpdate = (): void => {
        onUpdate({
            name: currentName,
            description: currentDescription,
            selectedIndustryType: currentIndustryType,
        });
        setIsChanged(false);
    };

    return (
        <StyledContainer>
            <Typography p={2}>Manage your team's details below.</Typography>
            {errors && <DialogAlert severity="error" text={errors} alertTitle="Error" />}
            <StyledOrgDetailsContainer>
                {avatar ? (
                    <StyledImageBox size={52}>
                        <img src={`data:image/jpeg;base64,${avatar}`} width={52} height={52} alt="" />
                    </StyledImageBox>
                ) : (
                    <OrgAvatarContainer name={name} style="round" />
                )}
                {userCanUpdateDetails && (
                    <StyledImageTextBox>
                        <Link onClick={onAvatarUpload}>Upload image/logo</Link>
                        <PageItalicText>File type .PNG, .JPEG (max file size 2MBs)</PageItalicText>
                    </StyledImageTextBox>
                )}
            </StyledOrgDetailsContainer>
            <TextField
                disabled={!userCanUpdateDetails}
                id="name-text-field"
                onChange={(e) => setCurrentName(e.target.value)}
                variant="outlined"
                label="Organisation name"
                placeholder="Enter a name"
                value={currentName}
                fullWidth
                required
            />
            <TextField
                disabled={!userCanUpdateDetails}
                onChange={(e) => setCurrentDescription(e.target.value)}
                variant="outlined"
                label="Description"
                placeholder="Enter a description"
                value={currentDescription}
                fullWidth
                multiline
                maxRows={2}
            />
            {industryTypes.length > 0 && (
                <Box>
                    <Dropdown
                        disabled={!userCanUpdateDetails}
                        label="Industry type"
                        items={industryTypes}
                        selectedItem={currentIndustryType}
                        onSelect={handleChangeType}
                    />
                </Box>
            )}
            <StyledButtonsBox>
                <Button
                    disabled={!isChanged || updating || !userCanUpdateDetails}
                    onClick={onCancel}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleUpdate}
                    disabled={!isChanged || !currentName || updating || !userCanUpdateDetails}
                    busy={updating}
                >
                    Update
                </Button>
            </StyledButtonsBox>
        </StyledContainer>
    );
};

import { useObservableState, useSubscription } from 'observable-hooks';
import { Dashboard } from '../components/dashboard';
import {
    editTemplateAction$,
    navigateToWorkspaceFromTemplateEditAction$,
    navigateToReadOnlyTemplateFromViewTemplateAction$,
    templates$,
    viewTemplateAction$,
} from '../store/templates';
import { loggedInUserDetails$ } from '../store/user';
import { newWorkspaceFromTemplate$, openNewBlankWorkspaceAction$ } from '../store/workspaces';
import { getBasePath } from '../utils/path';
import { useNavigate } from 'react-location';
import { updateWizardStepAction$, WizardStep } from '../store/wizard';
import { segmentAnalyticsTrack } from '../data/segment-analytics';

export const DashboardContainer: React.FC = () => {
    const templates = useObservableState(templates$);
    const navigate = useNavigate();

    const filteredTemplates = templates.length > 3 ? templates.slice(0, 3) : templates;
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const userCanWorkWithTemplates = !!(loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates);

    useSubscription(navigateToWorkspaceFromTemplateEditAction$, (template) =>
        navigate({ to: `${getBasePath()}workspace/${template.workspaceUid}/environment/${template.environmentUid}` })
    );

    useSubscription(navigateToReadOnlyTemplateFromViewTemplateAction$, (templateUid) =>
        navigate({ to: `${getBasePath()}template/${templateUid}` })
    );

    return (
        <Dashboard
            embeddedVideo="https://www.youtube.com/embed/Sx1_LImeJzc"
            knowledgeBaseArticles={[
                {
                    title: 'Working principle',
                    description: 'How Stitch-It works.',
                    url: 'https://docs.adaptavist.com/src/get-started/working-principle',
                },
                {
                    title: 'Runtime',
                    description: 'How your code gets executed.',
                    url: 'https://docs.adaptavist.com/src/scripting/runtime',
                },
                {
                    title: 'Scripting',
                    description: 'What are the benefits of JavaScript/TypeScript.',
                    url: 'https://docs.adaptavist.com/src/scripting',
                },
                {
                    title: 'Managed APIs',
                    description: 'How Managed APIs can increase developer experience and productivity.',
                    url: 'https://docs.adaptavist.com/src/managed-apis',
                },
                {
                    title: 'Editor',
                    description: 'How to enjoy writing code by taking advantage of the Editor features.',
                    url: 'https://docs.adaptavist.com/src/scripting/editor',
                },
                {
                    title: 'Connectors',
                    description: 'How Connectors can help you build integrations easier and more securely.',
                    url: 'https://docs.adaptavist.com/src/connectors',
                },
                {
                    title: 'API Connections',
                    description: 'How to talk to external services.',
                    url: 'https://docs.adaptavist.com/src/workspaces/api-connections',
                },
                {
                    title: 'Generic API Connection',
                    description:
                        'How to use Generic API Connection to connect to any service that is not supported by ScriptRunner Connect',
                    url: 'https://docs.adaptavist.com/src/workspaces/api-connections/generic-connector',
                },
                {
                    title: 'Event Listeners',
                    description: 'How to listen events originating from external services.',
                    url: 'https://docs.adaptavist.com/src/workspaces/event-listeners',
                },
                {
                    title: 'Generic HTTP Events',
                    description:
                        'How to use Generic Event Listener to listen events from any service, build your own APIs or basic UIs.',
                    url: 'https://docs.adaptavist.com/src/workspaces/event-listeners/generic-http-events',
                },
                {
                    title: 'Scheduled Triggers',
                    description: 'How to run scripts periodically.',
                    url: 'https://docs.adaptavist.com/src/workspaces/scheduled-triggers',
                },
                {
                    title: 'Deployments and Environments',
                    description: 'How Deployments and Environments work.',
                    url: 'https://docs.adaptavist.com/src/workspaces/deployments-and-environments',
                },
                {
                    title: 'Package Manager',
                    description:
                        'How to manually control your Workspace packages or how to pull in third party NPM packages.',
                    url: 'https://docs.adaptavist.com/src/workspaces/package-manager',
                },
                {
                    title: 'HTTP Logs',
                    description: 'How to troubleshoot API calls by inspecting HTTP logs.',
                    url: 'https://docs.adaptavist.com/src/event-logs-and-reports/http-logs',
                },
                {
                    title: 'API for Record Storage',
                    description: 'How to store data using simple key-value storage.',
                    url: 'https://docs.adaptavist.com/src/scripting/record-storage',
                },
                {
                    title: 'API for Triggering Scripts',
                    description: 'How to trigger scripts programmatically',
                    url: 'https://docs.adaptavist.com/src/scripting/triggering-scripts',
                },
            ]}
            templates={filteredTemplates}
            onStartWizard={() => updateWizardStepAction$.next(WizardStep.START)}
            onBlankSetup={() => {
                newWorkspaceFromTemplate$.next(undefined);
                openNewBlankWorkspaceAction$.next('blank');
            }}
            userCanWorkWithTemplates={userCanWorkWithTemplates}
            onTemplateSelect={(templateUid) => {
                const template = templates.find((temp) => temp.uid === templateUid);
                if (template) {
                    viewTemplateAction$.next(templateUid);
                }
            }}
            onEdit={(selectedTemplateUid) => {
                editTemplateAction$.next(selectedTemplateUid);
            }}
            onMoreTemplates={() => segmentAnalyticsTrack('DashboardViewMoreTemplates Clicked')}
            onLearnMore={(url) =>
                segmentAnalyticsTrack('DashboardKB Accessed', {
                    url,
                })
            }
        />
    );
};

import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { AbsoluteCenteredLoadingSpinner } from '../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../buttons/Button';
import { DialogAlert, DialogTitleSmall } from '../dialog/DialogComponents';
import { InfoIcon } from '../icons/InfoIcon';
import { IconCircle } from '../icon-circle';
import { autoFocus } from '../../utils/autoFocus';
import { handleKeyUp } from '../../utils/handleKeyUp';

interface NewDeploymentDialogProps {
    currentVersion?: string;
    environments?: {
        name: string;
        uid: string;
    }[]; // If the array is empty, show no environments section.
    errors?: string;
    loading?: boolean;
    newVersion?: string;
    open?: boolean;
    saving?: boolean;
    onCancel: () => void;
    onDeploy: (event: OnDeployEvent) => void;
}

export interface OnDeployEvent {
    label?: string;
    newVersion: string;
    selectedEnvironmentUids: string[]; // UIDs of selected environments
}

const StyledCheckboxGroup = styled(FormControl)(({ theme }) => ({
    width: 300,
    marginBottom: theme.spacing(1),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    display: 'block',
    marginBottom: theme.spacing(2),
    width: 300,
}));

export const NewDeploymentDialog: React.FC<NewDeploymentDialogProps> = ({
    currentVersion,
    environments = [],
    errors,
    loading = false,
    newVersion = '',
    open = false,
    saving = false,
    onCancel,
    onDeploy,
}) => {
    const firstEnvironment = environments[0];
    const defaultSelectedEnvironments = environments.length > 1 && firstEnvironment ? [firstEnvironment.uid] : [];

    const inputRef = useRef<HTMLInputElement>(null);

    const [label, setLabel] = useState('');
    const [selectedEnvironments, setSelectedEnvironments] = useState<string[]>(defaultSelectedEnvironments);
    const [version, setVersion] = useState('');

    useEffect(() => {
        setLabel('');
        setSelectedEnvironments(defaultSelectedEnvironments);
        setVersion(newVersion);
        autoFocus(inputRef);
    }, [open, newVersion]);

    const onSubmit = (): void => {
        if (selectedEnvironments.length === 0 && environments.length === 1 && firstEnvironment) {
            onDeploy({ label, newVersion: version, selectedEnvironmentUids: [firstEnvironment.uid] });
        } else {
            onDeploy({ label, newVersion: version, selectedEnvironmentUids: selectedEnvironments });
        }
    };

    const onSelectEnvironment = (environmentUid: string): void => {
        if (!selectedEnvironments.includes(environmentUid)) {
            setSelectedEnvironments([...selectedEnvironments, environmentUid]);
        } else {
            setSelectedEnvironments(selectedEnvironments.filter((uid) => uid !== environmentUid));
        }
    };

    const checkboxes = environments.map((environment) => {
        return (
            <FormControlLabel
                checked={selectedEnvironments.includes(environment.uid)}
                control={<Checkbox value={environment.uid} />}
                key={environment.uid}
                label={environment.name}
                onChange={() => onSelectEnvironment(environment.uid)}
            />
        );
    });

    const hasVersionChanged = version !== currentVersion;
    const isEnvironmentSelectionValid = environments.length > 1 ? selectedEnvironments.length !== 0 : true;
    const canSubmit = !!version && !saving && !loading && hasVersionChanged && isEnvironmentSelectionValid;

    return (
        <Dialog
            open={open}
            onKeyUp={(event) => handleKeyUp({ event, enterCondition: canSubmit, enterFn: onSubmit, escFn: onCancel })}
        >
            <DialogTitleSmall
                icon={
                    <IconCircle
                        icon={
                            <FileDownloadOutlinedIcon
                                sx={{
                                    transform: 'rotate(180deg)',
                                }}
                            />
                        }
                    />
                }
                title="New Deployment"
                tooltipElement={
                    <Tooltip title="Learn more about Deployments and Environments" placement="top">
                        <InfoIcon
                            onClick={() =>
                                window.open(
                                    'https://docs.adaptavist.com/src/workspaces/deployments-and-environments',
                                    '_blank'
                                )
                            }
                        />
                    </Tooltip>
                }
            />
            {loading ? (
                <AbsoluteCenteredLoadingSpinner />
            ) : (
                <>
                    {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                    <DialogContent>
                        {currentVersion && <StyledFormLabel>Current version: {currentVersion}</StyledFormLabel>}
                        <TextField
                            label="New version"
                            onChange={(event) => {
                                setVersion(event.target.value);
                            }}
                            placeholder="Enter a new version"
                            value={version}
                            variant="outlined"
                            required
                        ></TextField>
                        <TextField
                            label="Label"
                            inputRef={inputRef}
                            onChange={(event) => {
                                setLabel(event.target.value);
                            }}
                            placeholder="Enter a label"
                            value={label}
                            variant="outlined"
                        ></TextField>
                        {environments.length > 1 ? (
                            <StyledCheckboxGroup>
                                <StyledFormLabel>Deploy to the following environments:</StyledFormLabel>
                                <FormGroup>{checkboxes}</FormGroup>
                            </StyledCheckboxGroup>
                        ) : null}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" data-hotspot="cancel" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button busy={saving} disabled={!canSubmit} onClick={onSubmit}>
                            Deploy
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../buttons/Button';
import { OpenInNewLink } from '../../link/OpenInNewLink';
import { ConnectionModalSecretTextField, ConnectionModalTextField } from '../../textfield/ConnectionModalTextField';
import { OpsgenieWizardStage, OpsgenieWizardSteps, StageType } from './OpsgenieWizardSteps';
import { DialogAlert, DialogTitleSmall } from '../../dialog';
import { OpsgenieIcon } from '../../icons/OpsgenieIcon';
import { testUrl } from '../../../utils/connection';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from '@mui/system/styled';

export interface OpsgenieClientInfo {
    apiKey: string;
    region: 'EU' | 'US';
    instanceUrl: string;
}

export interface OpsgenieConnection {
    clientInfo: OpsgenieClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: OpsgenieWizardStage;
    setStage: (stage: OpsgenieWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
}

export interface onSaveProps {
    apiKey: string;
    region: 'EU' | 'US';
    instanceUrl: string;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3.5),
    '&:first-of-type': {
        marginTop: theme.spacing(2),
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}));

const StageContent: StageType = (props) => {
    const [apiKey, setApiKey] = useState(props.clientInfo.apiKey);
    const [region, setRegion] = useState(props.clientInfo.region);
    const [instanceUrl, setInstanceUrl] = useState(props.clientInfo.instanceUrl);
    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);
    const [testingUrl, setTestingUrl] = useState<boolean>(false);

    const OPSGENIE_ADD_API_PATH = '/settings/integration/add/API/';

    const generateSettingsUrl = (): string => {
        return instanceUrl.endsWith(OPSGENIE_ADD_API_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + OPSGENIE_ADD_API_PATH
            : instanceUrl + OPSGENIE_ADD_API_PATH;
    };

    switch (props.stage) {
        case OpsgenieWizardStage.CHOOSEINSTANCEREGION:
            return (
                <>
                    <DialogContent>
                        <StyledFormControl>
                            <DialogContentText>Choose the region of your Opsgenie instance</DialogContentText>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={region}
                                onChange={(e) => setRegion(e.target.value as onSaveProps['region'])}
                            >
                                <StyledFormControlLabel value="EU" control={<Radio />} label="EU" />
                                <StyledFormControlLabel value="US" control={<Radio />} label="US" />
                            </RadioGroup>
                        </StyledFormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={() => props.setStage(OpsgenieWizardStage.ADDURL)}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case OpsgenieWizardStage.ADDURL:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>Please provide your Opsgenie instance url.</DialogContentText>
                        <ConnectionModalTextField
                            label="Enter the Opsgenie URL"
                            value={instanceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(OpsgenieWizardStage.CHOOSEINSTANCEREGION);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                setTestingUrl(true);
                                if (await testUrl(instanceUrl)) {
                                    setInstanceUrl(instanceUrl);
                                    props.setStage(OpsgenieWizardStage.CREATE);
                                    setBadUrlAttempted(false);
                                } else {
                                    props.setError('Please enter a valid URL starting with "https://"');
                                    setBadUrlAttempted(true);
                                }
                                setTestingUrl(false);
                            }}
                            busy={testingUrl}
                            disabled={!instanceUrl || badUrlAttempted}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case OpsgenieWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have an API Integration, skip the steps below and click next"
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateSettingsUrl()}>Create new API integration</OpenInNewLink>{' '}
                                page in Opsgenie and copy <strong>API Key</strong>.
                            </li>
                            <li>Give your integration a meaningful name.</li>
                            <li>
                                Make sure that <b>Read Access</b>, <b>Create and Update Access</b>, <b>Delete Access</b>{' '}
                                and <b>Enabled</b> settings are checked.
                            </li>
                            <li>
                                Click <strong>Save Integration</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(OpsgenieWizardStage.ADDURL);
                            }}
                        >
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(OpsgenieWizardStage.INPUT_API_KEY)}>Next</Button>
                    </DialogActions>
                </>
            );
        case OpsgenieWizardStage.INPUT_API_KEY:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Paste previously copied <strong>API Key</strong> into the form below. <br />
                            </li>
                            <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                        </DialogContentText>
                        <ConnectionModalSecretTextField
                            label="API Key"
                            value={apiKey}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setApiKey(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(OpsgenieWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!apiKey}
                            onClick={() =>
                                props.onSave({
                                    apiKey,
                                    region,
                                    instanceUrl,
                                })
                            }
                        >
                            Done
                        </Button>
                    </DialogActions>
                </>
            );
    }
};

export const OpsgenieConnectionAppConfigureDialog: React.FC<OpsgenieConnection> = (props) => {
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleSmall title="Configure Connector" variant="h6" icon={<OpsgenieIcon />} />
            {props.errors && error}
            <OpsgenieWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};

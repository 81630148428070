import { useObservableState } from 'observable-hooks';
import { useEffect, useState } from 'react';
import { CenteredLoadingSpinner } from '../../components/loading/CenteredLoadingSpinner';
import { DefaultWorkspaceView } from '../../components/workspace/DefaultWorkspaceView';
import { loadingWorkspaceResources$, selectedWorkspaceResources$ } from '../../store/workspace';
import { getSanitizedParsedMarkdown } from '../../store/workspace/readme';
import { getUnsavedReadmeFileCached } from '../../store/workspace/utils';
import { selectedReadOnlyTemplate$ } from '../../store/templates';

export const DefaultReadOnlyTemplateViewContainer: React.FC = () => {
    const { apiHandlers, eventListeners, scripts, readmeFile } = useObservableState(selectedWorkspaceResources$);
    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const blank = !apiHandlers.length && !eventListeners.length && !scripts.length;

    const [parsedContent, setParsedContent] = useState<string>('');
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const loadReadmeFileContent = async (readmeFileUid: string): Promise<void> => {
            const file = await getUnsavedReadmeFileCached(readmeFileUid, selectedReadOnlyTemplate?.workspaceUid ?? '');
            setParsedContent(getSanitizedParsedMarkdown(file.content));
            setLoading(false);
        };
        if (!loadingWorkspaceResources && readmeFile?.uid) {
            setLoading(true);
            loadReadmeFileContent(readmeFile?.uid);
        }
    }, [loadingWorkspaceResources]);

    return isLoading || loadingWorkspaceResources ? (
        <CenteredLoadingSpinner />
    ) : (
        <DefaultWorkspaceView parsedContent={parsedContent} blank={blank} />
    );
};

import { BehaviorSubject, map, Subject } from 'rxjs';
import { ignoreNextWorkspaceExit$, selectedWorkspaceUid$ } from '.';
import { EnvironmentDeploymentMapping } from '../../components/workspace/manage-environment-deployment-targets-dialog';
import { getWorkspaceDeployments, saveEnvironmentDeploymentPointers } from '../../data/deployment';
import { createWorkspaceEnvironment, switchWorkspaceEnvironment } from '../../data/environment';
import { InformativeError } from '../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { selectedWorkspaceDeployments$ } from './deployment';
import { loadWorkspaceEnvironmentsWhenEventIsEmitted, loadWorkspaceResourcesWhenEventIsEmitted } from './utils';

export const openCreateNewWorkspaceEnvironmentDialogAction$ = monitor(
    'openCreateNewWorkspaceEnvironmentDialogAction$',
    new Subject<void>()
);
export const closeCreateNewWorkspaceEnvironmentDialogAction$ = monitor(
    'closeCreateNewWorkspaceEnvironmentDialogAction$',
    new Subject<void>()
);
export const createNewWorkspaceEnvironmentAction$ = monitor('createNewEnvironmentAction$', new Subject<string>());
export const newWorkspaceEnvironmentCreatedAction$ = monitor(
    'newWorkspaceEnvironmentCreatedAction$',
    new Subject<string>()
);
export const switchWorkspaceEnvironmentAction$ = monitor('switchWorkspaceEnvironmentAction$', new Subject<string>());
export const navigateToDifferentWorkspaceEnvironmentAction$ = monitor(
    'navigateToDifferentWorkspaceEnvironmentAction$',
    new Subject<string>()
);
export const openManageEnvironmentDeploymentTargetsDialogAction$ = monitor(
    'openManageEnvironmentDeploymentTargetsDialogAction$',
    new Subject<void>()
);
export const closeManageEnvironmentDeploymentTargetsDialogAction$ = monitor(
    'closeManageEnvironmentDeploymentTargetsDialogAction$',
    new Subject<void>()
);
export const saveEnvironmentDeploymentTargetsAction$ = monitor(
    'saveEnvironmentDeploymentTargetsAction$',
    new Subject<EnvironmentDeploymentMapping[]>()
);
export const environmentDeploymentTargetsSavedAction$ = monitor(
    'environmentDeploymentTargetsSavedAction$',
    new Subject<void>()
);

export const createNewWorkspaceEnvironmentDialogOpen$ = monitor(
    'createNewWorkspaceEnvironmentDialogOpen$',
    new BehaviorSubject(false)
);
export const createNewWorkspaceEnvironmentDialogErrors$ = monitor(
    'createNewWorkspaceEnvironmentDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const creatingNewWorkspaceEnvironment$ = monitor('creatingNewWorkspaceEnvironment$', new BehaviorSubject(false));
export const switchingWorkspaceEnvironment$ = monitor('switchingWorkspaceEnvironment$', new BehaviorSubject(false));
export const manageEnvironmentDeploymentTargetsDialogOpen$ = monitor(
    'manageEnvironmentDeploymentTargetsDialogOpen$',
    new BehaviorSubject(false)
);
export const manageEnvironmentDeploymentTargetsDialogLoading$ = monitor(
    'manageEnvironmentDeploymentTargetsDialogLoading$',
    new BehaviorSubject(false)
);
export const manageEnvironmentDeploymentTargetsDialogErrors$ = monitor(
    'manageEnvironmentDeploymentTargetsDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const savingEnvironmentDeploymentTargets$ = monitor(
    'savingEnvironmentDeploymentTargets$',
    new BehaviorSubject(false)
);

openCreateNewWorkspaceEnvironmentDialogAction$.subscribe(() => createNewWorkspaceEnvironmentDialogOpen$.next(true));
closeCreateNewWorkspaceEnvironmentDialogAction$.subscribe(() => createNewWorkspaceEnvironmentDialogOpen$.next(false));

openManageEnvironmentDeploymentTargetsDialogAction$
    .pipe(
        map(async () => {
            manageEnvironmentDeploymentTargetsDialogOpen$.next(true);
            manageEnvironmentDeploymentTargetsDialogLoading$.next(true);
            manageEnvironmentDeploymentTargetsDialogErrors$.next(undefined);

            try {
                const workspaceDeployments = await getWorkspaceDeployments(selectedWorkspaceUid$.value ?? '');
                selectedWorkspaceDeployments$.next(workspaceDeployments);
            } catch (e) {
                console.error('Failed to load deployments', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message:
                        'Failed to load Deployments, please try again, if the issue persists please contact support',
                });
                manageEnvironmentDeploymentTargetsDialogOpen$.next(false);
            }

            manageEnvironmentDeploymentTargetsDialogLoading$.next(false);
        })
    )
    .subscribe();

closeManageEnvironmentDeploymentTargetsDialogAction$.subscribe(() =>
    manageEnvironmentDeploymentTargetsDialogOpen$.next(false)
);

createNewWorkspaceEnvironmentAction$
    .pipe(
        map(async (name) => {
            creatingNewWorkspaceEnvironment$.next(true);
            createNewWorkspaceEnvironmentDialogErrors$.next(undefined);

            try {
                const { uid } = await createWorkspaceEnvironment(selectedWorkspaceUid$.value ?? '', name);

                closeCreateNewWorkspaceEnvironmentDialogAction$.next();
                newWorkspaceEnvironmentCreatedAction$.next(uid);

                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Environment created.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    createNewWorkspaceEnvironmentDialogErrors$.next(e.message);
                } else {
                    console.error('Error while creating new Environment', e);
                    createNewWorkspaceEnvironmentDialogErrors$.next(
                        'Failed to create new Environment, please try again, if the issue persists please contact support.'
                    );
                }
            }

            creatingNewWorkspaceEnvironment$.next(false);
        })
    )
    .subscribe();

newWorkspaceEnvironmentCreatedAction$.subscribe((uid) => switchWorkspaceEnvironmentAction$.next(uid));

switchWorkspaceEnvironmentAction$
    .pipe(
        map(async (uid) => {
            switchingWorkspaceEnvironment$.next(true);

            try {
                await switchWorkspaceEnvironment(selectedWorkspaceUid$.value ?? '', uid);

                ignoreNextWorkspaceExit$.next(true);
                navigateToDifferentWorkspaceEnvironmentAction$.next(uid);

                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Environment switched.',
                });
            } catch (e) {
                console.error('Failed to switch environment', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message:
                        'Failed to switch Environment, please try again, if the issue persists please contact support',
                });
            }

            switchingWorkspaceEnvironment$.next(false);
        })
    )
    .subscribe();

saveEnvironmentDeploymentTargetsAction$
    .pipe(
        map(async (event) => {
            savingEnvironmentDeploymentTargets$.next(true);
            manageEnvironmentDeploymentTargetsDialogErrors$.next(undefined);

            try {
                await saveEnvironmentDeploymentPointers(selectedWorkspaceUid$.value ?? '', event);

                environmentDeploymentTargetsSavedAction$.next();
                closeManageEnvironmentDeploymentTargetsDialogAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Environment Deployment targets saved.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    manageEnvironmentDeploymentTargetsDialogErrors$.next(e.message);
                } else {
                    console.error('Error while saving Environment Deployment targets', e);
                    manageEnvironmentDeploymentTargetsDialogErrors$.next(
                        'Failed to create saving Environment Deployment targets, please try again, if the issue persists please contact support.'
                    );
                }
            }

            savingEnvironmentDeploymentTargets$.next(false);
        })
    )
    .subscribe();

loadWorkspaceEnvironmentsWhenEventIsEmitted(environmentDeploymentTargetsSavedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(environmentDeploymentTargetsSavedAction$);

import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Cable from '@mui/icons-material/Cable';
import { Button } from '../buttons/Button';
import { DialogAlert, DialogTitleSmall } from '../dialog';
import { DisabledGoogleIcon } from '../icons/DisabledGoogleIcon';
import { GoogleIcon } from '../icons/GoogleIcon';
import { IconCircle } from '../icon-circle';
import { handleKeyUp } from '../../utils/handleKeyUp';

export interface ConnectionProps {
    readonly authorized?: boolean;
    readonly authorizeLoading?: boolean;
    readonly connectionType?: string;
    readonly customAuthorizeButtonLabel?: string;
    readonly disabled?: boolean;
    readonly errors?: string;
    readonly icon: JSX.Element;
    readonly instanceId?: string;
    readonly instanceUrl?: string;
    readonly name?: string;
    readonly open?: boolean;
    readonly saving?: boolean;
    readonly onAuthorize: (name: string) => void;
    readonly onCancel: () => void;
    readonly onSave: (name: string) => void;
}

const StyledCard = styled(Card)(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    width: 55,
    height: 55,
    alignItems: 'center',
    fontSize: 40,
    borderRadius: 12,
    margin: theme.spacing(1, 2, 2, 0),
    position: 'relative',
    '&:hover': {
        outline: theme.constants.borderSelected,
    },
    '& .MuiSvgIcon-root.productIcon': {
        height: 32,
        width: 32,
    },
}));

const StyledLink = styled(Link)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
}));

const AuthorizedCircle = styled('span')(({ theme }) => ({
    height: 8,
    width: 8,
    backgroundColor: theme.palette.success.light,
    borderRadius: '50%',
    position: 'absolute',
    left: 4,
    top: 4,
    zIndex: 1,
}));

const StyledGoogleButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontWeight: 500,
    padding: theme.spacing(0.75, 2),
    '& .MuiSvgIcon-root': {
        fontSize: '18px !important',
    },
    '& .MuiButton-startIcon': {
        margin: theme.spacing(0, 1.5, 0, 0),
    },
}));

const StyledRightGrid = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(0.5),
}));

export const ConnectionDialog: React.FC<ConnectionProps> = ({
    authorized = false,
    authorizeLoading = false,
    connectionType,
    customAuthorizeButtonLabel,
    errors,
    icon,
    instanceId,
    instanceUrl,
    name = '',
    open = false,
    saving = false,
    onAuthorize,
    onCancel,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [connectionName, setConnectionName] = useState(name);

    useEffect(() => setConnectionName(name), [open]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setConnectionName(e.target.value);
    };

    const error = <DialogAlert severity="error" text={errors} alertTitle="Error" />;

    const authorizeButtonLabel = authorized
        ? customAuthorizeButtonLabel
            ? `Re-${customAuthorizeButtonLabel}`
            : 'Re-authorize'
        : customAuthorizeButtonLabel ?? 'Authorize';

    const authorizedTooltip = authorized ? connectionType + ' - Authorized' : connectionType + ' - Not Authorized';
    const googleButtonLabel = authorized ? 'Sign In & re-authorize with Google' : 'Sign In & authorize with Google';
    const canAuthorize = !saving && !authorizeLoading && !!connectionName;

    return (
        <Dialog
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({
                    event,
                    enterCondition: canAuthorize,
                    enterFn: () => (authorized ? onSave(connectionName) : onAuthorize(connectionName)),
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleSmall variant="h6" title="Manage Connector" icon={<IconCircle icon={<Cable />} />} />
            {errors && error}
            <DialogContent>
                <Grid container direction="row">
                    <Grid>
                        <Tooltip title={authorizedTooltip} placement="top">
                            {authorized ? (
                                <StyledCard sx={{ outline: (theme) => `1px solid ${theme.palette.success.light}` }}>
                                    <AuthorizedCircle />
                                    {icon}
                                </StyledCard>
                            ) : (
                                <StyledCard>{icon}</StyledCard>
                            )}
                        </Tooltip>
                    </Grid>
                    <StyledRightGrid container direction="column" item sm={5} md={10}>
                        <TextField
                            variant="outlined"
                            label="Connector name"
                            placeholder="Name your Connector"
                            required
                            value={connectionName}
                            onChange={handleChange}
                        />
                        {authorized && instanceUrl && (
                            <DialogContentText>
                                <>
                                    Service URL:
                                    <StyledLink href={instanceUrl} target="_blank" rel="noreferrer">
                                        {instanceUrl}
                                    </StyledLink>
                                </>
                            </DialogContentText>
                        )}
                        {authorized && instanceId && (
                            <DialogContentText>
                                <>Service ID: {instanceId}</>
                            </DialogContentText>
                        )}
                        <div>
                            {connectionType?.includes('Google') ? (
                                <StyledGoogleButton
                                    onClick={() => onAuthorize(connectionName)}
                                    startIcon={!authorizeLoading ? <GoogleIcon /> : <DisabledGoogleIcon />}
                                    variant="outlined"
                                    busy={authorizeLoading}
                                    sx={{
                                        backgroundColor: authorizeLoading
                                            ? (theme) => theme.palette.action.disabledBackground + '!important'
                                            : undefined,
                                    }}
                                    disabled={!canAuthorize}
                                >
                                    {googleButtonLabel}
                                </StyledGoogleButton>
                            ) : (
                                <Button
                                    onClick={() => onAuthorize(connectionName)}
                                    variant={authorized ? 'outlined' : 'contained'}
                                    busy={authorizeLoading}
                                    disabled={!canAuthorize}
                                >
                                    {authorizeButtonLabel}
                                </Button>
                            )}
                        </div>
                    </StyledRightGrid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    variant={authorized ? 'contained' : 'outlined'}
                    onClick={() => onSave(connectionName)}
                    busy={saving}
                    disabled={!canAuthorize}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

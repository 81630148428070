import { LoaderFn } from 'react-location';
import { getInvocationDataUrl, InvocationLogs } from '../data/invocationLogs';
import { LocationGenerics } from '../router';
import { InvocationLog, selectedInvocationId$, selectedInvocationLogs$ } from '../store/invocationlogs';
import { getMessageForArgs } from '@avst-stitch/invocation-lib/runtime';
import { ulid } from 'ulid';
import { InformativeError } from '../utils/repository';
import { loadErrorPage } from '../store/error';

export const invocationLogsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    selectedInvocationId$.next(undefined);
    selectedInvocationLogs$.next([]);

    try {
        const invocationId = routeMatch.params.invocationId;
        const workspaceUid = routeMatch.params.workspaceUid;

        if (invocationId && workspaceUid) {
            selectedInvocationId$.next(invocationId);
            const invocationLogsUrl = await getInvocationDataUrl({
                workspaceUid,
                invocationId,
                dataType: 'invocationLogs',
            });
            const response = await fetch(invocationLogsUrl);

            if (!response.ok) {
                throw new InformativeError('Failed to fetch invocation logs');
            }

            const invocationLogsStringified = await response.text();
            const invocationLogs = JSON.parse(invocationLogsStringified) as InvocationLogs;

            const logs = invocationLogs.invocationLogs.map((entry) => {
                if (entry.largePayload) {
                    return {
                        id: entry.id,
                        timestamp: entry.timestamp,
                        severity: entry.severity,
                        message: 'Message is too large to be displayed, click here to view.',
                        largePayload: true,
                    };
                }

                return {
                    id: entry.id,
                    timestamp: entry.timestamp,
                    severity: entry.severity,
                    message: getMessageForArgs(entry.args),
                };
            }) as InvocationLog[];

            if (invocationLogs.httpLogsUrl) {
                logs.push({
                    id: ulid(),
                    severity: 'debug',
                    linkUrl: invocationLogs.httpLogsUrl,
                    message: 'View HTTP logs',
                });
            }

            selectedInvocationLogs$.next(logs);
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load invocation logs.',
        });

        throw e;
    }

    return {};
};

import { LoaderFn } from 'react-location';
import { getUsersWithWorkspaces } from '../../data/report/usersWithWorkspaces';
import { LocationGenerics } from '../../router';
import { usersWithWorkspaces$ } from '../../store/report/usersWithWorkspaces';

export const usersWithWorkspacesReportLoader: LoaderFn<LocationGenerics> = async () => {
    const users = await getUsersWithWorkspaces();
    usersWithWorkspaces$.next(users);

    return {};
};

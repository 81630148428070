import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Alert } from '../../alert/Alert';
import { Button } from '../../buttons/Button';
import { DialogAlert, DialogTitleSmall } from '../../dialog/DialogComponents';
import { IconCircle } from '../../icon-circle';
import { InfoIcon } from '../../icons/InfoIcon';
import { Package } from './Package';
import { SearchBox } from '../../search/SearchBox';
import { searchByName } from '../../../utils/searchByName';
import { PACKAGE_MANAGER_CORE_PACKAGES } from '../../../store/workspace/package-manager';
import { handleKeyUp } from '../../../utils/handleKeyUp';
import Link from '@mui/material/Link';

export interface BasePackage {
    name: string;
    selectedVersion: string;
}

export interface Package extends BasePackage {
    childPackage?: Pick<Package, 'name' | 'selectedVersion' | 'description' | 'link' | 'versions' | 'selected'>;
    description?: string;
    latestVersion?: string;
    link?: string;
    locked?: boolean;
    saved?: boolean;
    selected?: boolean;
    thirdParty?: boolean;
    versions: string[];
}

export interface ThirdPartyPackage extends Package {
    verified: boolean;
}

interface PackageManagerProperties {
    corePackages: Package[];
    errors?: string;
    loading?: boolean;
    managedAPIs: Package[];
    open?: boolean;
    saving?: boolean;
    thirdPartyPackages: ThirdPartyPackage[];
    workspaceLocked?: boolean;
    onAddThirdPartyPackage(name: string): void;
    onCancel(): void;
    onSave(corePackages: BasePackage[], managedAPIs: BasePackage[], thirdPartyPackages: BasePackage[]): void;
}

const StyledAddArea = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paperScrollPaper': {
        backgroundColor: theme.palette.background.default,
        maxWidth: 'unset',
        minHeight: 240,
        width: 920,
    },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    marginRight: theme.spacing(3),
    '& .MuiButton-root:not(:first-child)': {
        marginLeft: theme.spacing(2),
    },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
}));

const StyledPanelHeader = styled('div')(({ theme }) => ({
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
}));

const StyledResults = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    height: '22px',
    fontSize: theme.typography.body2.fontSize,
    padding: 0,
}));

const StyledSearchBoxContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    '& .MuiButton-root': {
        marginLeft: theme.spacing(1),
    },
}));

const StyledSearchPackageArea = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: theme.constants.boxShadow,
    display: 'inline-block',
    padding: theme.spacing(2),
    '& h5': {
        marginBottom: theme.spacing(0.5),
    },
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
    height: '26px',
    minHeight: '26px',
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.text.secondary,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: '24px',
    minHeight: 'unset',
    padding: 0,
    '&.Mui-selected': {
        color: theme.palette.text.primary,
    },
    '&:not(:first-child)': {
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledTabPanel = styled(TabPanel)(() => ({}));

const StyledDiv = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const NPM_PACKAGE_URL = 'https://www.npmjs.com/package';

export const PackageManager: React.FC<PackageManagerProperties> = ({
    corePackages = [],
    errors,
    loading = false,
    managedAPIs = [],
    open = false,
    saving = false,
    thirdPartyPackages = [],
    workspaceLocked = false,
    onAddThirdPartyPackage,
    onCancel,
    onSave,
}) => {
    const [tabValue, setTabValue] = useState('1');
    const [currentCorePackages, setCurrentCorePackages] = useState(corePackages);
    const [currentManagedAPIs, setCurrentManagedAPIs] = useState(managedAPIs);
    const [currentThirdPartyPackages, setCurrentThirdPartyPackages] = useState(thirdPartyPackages);
    const [searchTerm, setSearchTerm] = useState('');
    const [newPackageSearchTerm, setNewPackageSearchTerm] = useState('');

    const theme = useTheme();

    useEffect(() => {
        setCurrentCorePackages(corePackages);
    }, [corePackages]);

    useEffect(() => {
        setCurrentManagedAPIs(managedAPIs);
    }, [managedAPIs]);

    useEffect(() => {
        setCurrentThirdPartyPackages(thirdPartyPackages);
    }, [thirdPartyPackages]);

    const handleSelectCorePackage = (selected: boolean, selectedVersion: string, name: string): void => {
        const newPackages = currentCorePackages.map((cp) => {
            if (cp.name === name) {
                return {
                    name: name,
                    selectedVersion: selectedVersion,
                    versions: cp.versions,
                    description: cp.description,
                    latestVersion: cp.latestVersion,
                    link: cp.link,
                    locked: cp.locked,
                    selected: selected,
                };
            }
            return cp;
        });
        setCurrentCorePackages(newPackages);
    };

    const handleSelectManagedAPI = (selected: boolean, selectedVersion: string, name: string): void => {
        const newPackages = currentManagedAPIs.map((ma) => {
            if (ma.name === name) {
                return {
                    name: name,
                    selectedVersion: selectedVersion,
                    versions: ma.versions,
                    description: ma.description,
                    latestVersion: ma.latestVersion,
                    link: ma.link,
                    locked: ma.locked,
                    selected: selected,
                };
            }
            return ma;
        });
        setCurrentManagedAPIs(newPackages);
    };

    const handleSelectThirdPartyPackage = (selected: boolean, selectedVersion: string, name: string): void => {
        setCurrentThirdPartyPackages((thirdPartyPackages) =>
            thirdPartyPackages.map((tpp) => {
                if (tpp.name === name) {
                    return {
                        ...tpp,
                        name: name,
                        selectedVersion: selectedVersion,
                        selected: selected,
                        thirdParty: true,
                    };
                } else if (tpp.childPackage?.name === name) {
                    return {
                        ...tpp,
                        childPackage: {
                            ...tpp.childPackage,
                            selectedVersion: selectedVersion,
                            selected: selected,
                            thirdParty: true,
                        },
                    };
                }
                return tpp;
            })
        );
    };

    const handleSave = (): void => {
        const selectedCorePackages = currentCorePackages
            .filter((cp) => cp.selected)
            .map((cp) => ({
                name: cp.name,
                selectedVersion: cp.selectedVersion,
            }));

        const selectedManagedAPIs = currentManagedAPIs
            .filter((ma) => ma.selected)
            .map((ma) => ({
                name: ma.name,
                selectedVersion: ma.selectedVersion,
            }));

        const selectedThirdPartyPackages = currentThirdPartyPackages
            .filter((tpp) => tpp.selected)
            .flatMap((tpp) => {
                const returnPackages = [
                    { name: tpp.name, selectedVersion: tpp.selectedVersion, verified: tpp.verified },
                ];
                if (tpp.childPackage?.selected) {
                    returnPackages.push({
                        name: tpp.childPackage.name,
                        selectedVersion: tpp.childPackage.selectedVersion,
                        verified: tpp.verified,
                    });
                }
                return returnPackages;
            });

        onSave(selectedCorePackages, selectedManagedAPIs, selectedThirdPartyPackages);
    };

    const handleAddThirdPartyPackage = (): void => {
        onAddThirdPartyPackage(newPackageSearchTerm);
        setNewPackageSearchTerm('');
    };

    const createLabel = (): string => {
        let currentResultsLength: number;

        switch (tabValue) {
            case '1':
                currentResultsLength = filteredCorePackages.length;
                break;
            case '2':
                currentResultsLength = filteredManagedAPIs.length;
                break;
            case '3':
                currentResultsLength = filteredThirdPartyPackages.length;
                break;
            default:
                currentResultsLength = 0;
                break;
        }

        return currentResultsLength === 1 ? currentResultsLength + ' result' : currentResultsLength + ' results';
    };

    const filteredCorePackages = searchByName(currentCorePackages, searchTerm);
    const filteredManagedAPIs = searchByName(currentManagedAPIs, searchTerm);
    const filteredThirdPartyPackages = searchByName(currentThirdPartyPackages, searchTerm);

    const corePackagesList = filteredCorePackages
        .filter((cp) => cp.name !== '@stitch-it/runtime')
        .sort((cp1, cp2) => {
            if (PACKAGE_MANAGER_CORE_PACKAGES.includes(cp1.name) && !PACKAGE_MANAGER_CORE_PACKAGES.includes(cp2.name)) {
                return -1;
            } else if (
                PACKAGE_MANAGER_CORE_PACKAGES.includes(cp2.name) &&
                !PACKAGE_MANAGER_CORE_PACKAGES.includes(cp1.name)
            ) {
                return 1;
            }
            return cp1.name.localeCompare(cp2.name);
        })
        .map((cp) => {
            return (
                <Package
                    {...cp}
                    locked={cp.locked || workspaceLocked}
                    link={`${NPM_PACKAGE_URL}/${cp.name}`}
                    key={cp.name}
                    onSelect={handleSelectCorePackage}
                />
            );
        });

    const managedAPIsList = filteredManagedAPIs
        .sort((ma1, ma2) => ma1.name.localeCompare(ma2.name))
        .map((ma) => {
            return (
                <Package
                    {...ma}
                    locked={ma.locked || workspaceLocked}
                    link={`${NPM_PACKAGE_URL}/${ma.name}`}
                    key={ma.name}
                    onSelect={handleSelectManagedAPI}
                />
            );
        });

    const thirdPartyPackagesList = filteredThirdPartyPackages
        .sort((tpp1, tpp2) => {
            if (tpp1.verified && !tpp2.verified) {
                return -1;
            } else if (!tpp1.verified && tpp2.verified) {
                return 1;
            }
            return tpp1.name.localeCompare(tpp2.name);
        })
        .map((tpp) => {
            const childPackage = tpp.childPackage
                ? { ...tpp.childPackage, link: `${NPM_PACKAGE_URL}/${tpp.childPackage.name}` }
                : undefined;
            return (
                <Package
                    {...tpp}
                    locked={tpp.locked || workspaceLocked}
                    childPackage={childPackage}
                    link={`${NPM_PACKAGE_URL}/${tpp.name}`}
                    key={tpp.name}
                    thirdParty={true}
                    onSelect={handleSelectThirdPartyPackage}
                />
            );
        });

    const canSave = !saving && !loading && !workspaceLocked;
    const infoDisclaimer = (
        <>
            Adding an unverified third party package may not work because of API incompatibilities with the ScriptRunner
            Connect runtime. You can read more about it{' '}
            <Link target="_blank" href="https://docs.adaptavist.com/src/scripting/runtime">
                here
            </Link>
            .
        </>
    );

    return (
        <StyledDialog
            open={open}
            onKeyUp={(event) => handleKeyUp({ event, enterCondition: canSave, enterFn: handleSave, escFn: onCancel })}
        >
            <DialogTitleSmall
                title="Package Manager"
                icon={<IconCircle icon={<AllInboxOutlinedIcon />} background={theme.palette.background.paper} />}
                tooltipElement={
                    <Tooltip title="Learn more about Package Manager" placement="top">
                        <InfoIcon
                            sx={(theme) => ({
                                cursor: 'pointer',
                                '&:hover': { color: theme.palette.primary.main },
                            })}
                            onClick={() =>
                                window.open('https://docs.adaptavist.com/src/workspaces/package-manager', '_blank')
                            }
                        />
                    </Tooltip>
                }
            />

            {loading ? (
                <AbsoluteCenteredLoadingSpinner />
            ) : (
                <>
                    {workspaceLocked && (
                        <DialogAlert
                            severity="warning"
                            text="This workspace is currently locked and packages cannot be edited"
                            alertTitle="Warning"
                            sx={{ marginTop: -2 }}
                        />
                    )}
                    <TabContext value={tabValue}>
                        <StyledTabList onChange={(_, value: string) => setTabValue(value)}>
                            <StyledTab label="Core Packages" value="1" />
                            <StyledTab label="Managed APIs" value="2" />
                            <StyledTab label="Third Party Packages" value="3" />
                        </StyledTabList>
                        <StyledPanelHeader>
                            <StyledResults label={createLabel()} />
                            <SearchBox onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
                        </StyledPanelHeader>
                        <StyledDialogContent>
                            <StyledTabPanel value="1">{corePackagesList}</StyledTabPanel>
                            <StyledTabPanel value="2">{managedAPIsList}</StyledTabPanel>
                            <StyledTabPanel value="3">{thirdPartyPackagesList}</StyledTabPanel>
                        </StyledDialogContent>
                    </TabContext>
                    {tabValue === '3' ? (
                        <StyledAddArea>
                            {errors && (
                                <StyledDiv>
                                    <Alert severity="error" text={errors} alertTitle="Error" />
                                </StyledDiv>
                            )}
                            <DialogAlert text={infoDisclaimer} severity="info" />
                            <StyledSearchPackageArea>
                                <Typography variant="subtitle2">Add an unverified NPM Package</Typography>
                                <StyledSearchBoxContainer>
                                    <SearchBox
                                        onChange={(event) => setNewPackageSearchTerm(event.target.value)}
                                        value={newPackageSearchTerm}
                                        onKeyUp={(event) => {
                                            if (
                                                newPackageSearchTerm &&
                                                !saving &&
                                                !workspaceLocked &&
                                                event.key === 'Enter'
                                            ) {
                                                handleAddThirdPartyPackage();
                                            }
                                        }}
                                    />
                                    <Button
                                        disabled={!newPackageSearchTerm || saving || workspaceLocked}
                                        busy={saving}
                                        onClick={handleAddThirdPartyPackage}
                                    >
                                        Add
                                    </Button>
                                </StyledSearchBoxContainer>
                            </StyledSearchPackageArea>
                        </StyledAddArea>
                    ) : null}
                    <StyledDialogActions>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button onClick={handleSave} disabled={!canSave} busy={saving}>
                            Save
                        </Button>
                    </StyledDialogActions>
                </>
            )}
        </StyledDialog>
    );
};

import { useObservableState, useSubscription } from 'observable-hooks';
import { useMonaco } from '../../components/editor/useMonaco';
import { ScriptDetails } from '../../components/workspace/ScriptDetails';
import {
    currentTypeDeclarationsLoaded$,
    editorAlertMessage$,
    legacyPackageImports$,
    legacyPackagesDialogOpen$,
    prependEditorContentAction$,
    renameEditorAction$,
    totalTypeDeclarationsLoaded$,
} from '../../store/editor/editor';
import { selectedReadOnlyTemplate$ } from '../../store/templates';
import { selectedWorkspace$ } from '../../store/workspace';
import { openApiHandlerImportDialogAction$ } from '../../store/workspace/api-handler';
import {
    saveScriptAction$,
    renameScriptAction$,
    scriptsBeingSaved$,
    scriptHasUnsavedChanges$,
    selectedScriptContentChangedAction$,
    selectedScriptNameChangedAction$,
    selectedScriptUid$,
    unsavedScriptDetails$,
    savedScriptDetails$,
    triggerScriptAction$,
    editorDisableReadOnlyMode$,
    editorReadOnly$,
    userDisabledEditorReadOnly$,
    scriptExecutionInProgress$,
    shortcutsPopupOpen$,
    shortcutsPopupVisible$,
} from '../../store/workspace/script';
import { publishLocalFeedbackEventAction$ } from '../../store/feedback';
import { legacyImportsRegex } from '../../utils/script';
import { loggedInUserDetails$ } from '../../store/user';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ScriptDetailsContainer: React.FC = () => {
    const selectedScriptUid = useObservableState(selectedScriptUid$);
    const savingScript = useObservableState(scriptsBeingSaved$);
    const scriptHasUnsavedChanges = useObservableState(scriptHasUnsavedChanges$);
    const editorReadOnly = useObservableState(editorReadOnly$);
    const userDisabledEditorReadOnly = useObservableState(userDisabledEditorReadOnly$);
    const currentTypeDeclarationsLoaded = useObservableState(currentTypeDeclarationsLoaded$);
    const totalTypeDeclarationsToLoad = useObservableState(totalTypeDeclarationsLoaded$);
    const editorAlertMessage = useObservableState(editorAlertMessage$);
    const monaco = useMonaco();
    const triggeringScript = useObservableState(scriptExecutionInProgress$);
    const shortcutsPopupOpen = useObservableState(shortcutsPopupOpen$);
    const shortcutsPopupVisible = useObservableState(shortcutsPopupVisible$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const legacyImports = useObservableState(legacyPackageImports$);
    const legacyPackagesDialogOpen = useObservableState(legacyPackagesDialogOpen$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    const readOnlyTemplateMode = !!selectedReadOnlyTemplate?.templateUid;
    const workspaceLocked = !!selectedWorkspace?.locked;

    const handleContentChange = (content: string): void => {
        selectedScriptContentChangedAction$.next(content);
    };

    const handlePackageNameReplacement = (content: string): void => {
        if (legacyImports) {
            const updatedContent = legacyImports.reduce((acc, curr) => {
                const updatedLine = curr.replace('stitch-it', 'sr-connect');
                acc = acc.replace(curr, updatedLine);
                return acc;
            }, content);

            if (monaco) {
                const uri = monaco.Uri.file(`/${selectedScript?.name}.ts`);
                const model = monaco.editor.getModel(uri);
                if (model) {
                    model.setValue(updatedContent);
                }
            }
        }
    };

    const handleScriptNameChange = (name: string): void => {
        const otherNames = Object.values(savedScriptDetails$.value).map((script) => script.name);

        if (!otherNames.includes(name)) {
            const oldName = selectedSavedScript?.name;

            if (oldName && name && oldName !== name) {
                renameEditorAction$.next({ name: oldName, newName: name });
            }
            selectedScriptNameChangedAction$.next(name);
            renameScriptAction$.next();
        } else {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: 'Failed to rename script, because script with such name already exists.',
            });
        }
    };

    const selectedScript = unsavedScriptDetails$.value[selectedScriptUid ?? ''];
    const selectedSavedScript = savedScriptDetails$.value[selectedScriptUid ?? ''];
    const hasUnsavedChanges = scriptHasUnsavedChanges[selectedScriptUid ?? ''];
    const handleScriptSave = (): void => {
        saveScriptAction$.next();

        if (!selectedScript?.content.match(legacyImportsRegex)) {
            legacyPackageImports$.next(null);
        }
    };

    const handleLegacyPackagesDialogOpen = (isOpen: boolean): void => {
        legacyPackagesDialogOpen$.next(isOpen);
    };

    const handleCopyImports = (imports: string): void => {
        navigator.clipboard.writeText(imports);
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Import statement(s) copied to clipboard (paste it into your script).',
        });
    };

    const handleTriggerScript = (uid: string): void => triggerScriptAction$.next(uid);

    useSubscription(prependEditorContentAction$, ({ scriptName, content }) => {
        if (monaco) {
            const uri = monaco.Uri.file(`/${scriptName}.ts`);
            const model = monaco.editor.getModel(uri);

            if (model) {
                model.setValue(content + model.getValue());
            }
        }
    });

    const editorHeaderMessage =
        currentTypeDeclarationsLoaded > 0
            ? `Loading type declarations: ${Math.round(
                  totalTypeDeclarationsToLoad > 0
                      ? (currentTypeDeclarationsLoaded * 100) / totalTypeDeclarationsToLoad
                      : 0
              )}% (${currentTypeDeclarationsLoaded}/${totalTypeDeclarationsToLoad})`
            : editorAlertMessage;

    return (
        <ScriptDetails
            scriptName={selectedScript?.name ?? ''}
            scriptUid={selectedScriptUid ?? ''}
            readOnlyTemplateMode={readOnlyTemplateMode}
            workspaceLocked={workspaceLocked}
            content={selectedScript?.content ?? ''}
            saving={savingScript[selectedScriptUid ?? ''] ?? false}
            editorReadOnly={!userDisabledEditorReadOnly && editorReadOnly}
            editorAlertMessage={editorHeaderMessage}
            hasUnsavedChanges={!!hasUnsavedChanges}
            shortcutsPopupOpen={shortcutsPopupOpen}
            shortcutsPopupVisible={shortcutsPopupVisible && !readOnlyTemplateMode}
            onContentChange={handleContentChange}
            onScriptNameChange={handleScriptNameChange}
            onShortcutsPopupClose={() => shortcutsPopupOpen$.next(false)}
            onShortcutsPopupOpen={() => shortcutsPopupOpen$.next(true)}
            onSave={handleScriptSave}
            onTriggerScript={handleTriggerScript}
            onEditorDisableReadOnly={() => editorDisableReadOnlyMode$.next()}
            onImportApiHandler={() => openApiHandlerImportDialogAction$.next()}
            executing={triggeringScript[selectedScriptUid ?? ''] ?? false}
            onPackageNameReplacement={handlePackageNameReplacement}
            legacyImports={legacyImports}
            onLegacyPackagesDialogOpen={handleLegacyPackagesDialogOpen}
            legacyPackagesDialogOpen={legacyPackagesDialogOpen}
            onCopyImports={handleCopyImports}
            impersonating={!!loggedInUserDetails?.impersonating}
        />
    );
};

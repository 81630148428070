import { concat, from, Observable, of, OperatorFunction, pipe, combineLatest } from 'rxjs';
import { filter, mergeMap, tap, toArray, distinctUntilKeyChanged, groupBy, reduce, map } from 'rxjs/operators';
import { fetchContent } from '../../jsdelivr/fetchContent';
import { getPackageJson, getTypeDeclaration$ } from '../../jsdelivr/jsdelivr';
import { isValidPackage, hasError, hasContent } from '../../jsdelivr/guards';
import { LoadedPackage, PackageError, PackageSelector, TypeDeclaration } from '../../jsdelivr/types';
import {
    activeDependencies$,
    libsEditorAction$,
    fetchPackagesAction$,
    totalTypeDeclarationsLoaded$,
    currentTypeDeclarationsLoaded$,
    editorAlertMessage$,
} from './editor';
// import { toast } from 'react-toastify';
import { editorReadOnly$ } from '../workspace/script';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { gt } from 'semver';
import { bundleNewWorkspaceScriptsAction$, newWorkspaceFromTemplate$ } from '../workspaces';

// let toastId: string | number | undefined = undefined;

combineLatest(currentTypeDeclarationsLoaded$, totalTypeDeclarationsLoaded$).subscribe(([current, total]) => {
    if (total > 0) {
        // const progress = current / total;
        // const message = `Loading type declarations: ${Math.round((current * 100) / total)}% (${current}/${total})`;
        // if (toastId) {
        //     toast.update(toastId, {
        //         render: message,
        //         progress,
        //     });
        // } else {
        //     toastId = toast.loading(message, {
        //         type: toast.TYPE.INFO,
        //         progress,
        //     });
        // }

        completeIfReady(current, total);
    }
});

fetchPackagesAction$
    .pipe(
        filter((packages) => packages.length > 0),
        tap(() => {
            editorAlertMessage$.next('Preparing to load type declarations...');
            editorReadOnly$.next(true);
        }),
        mergeMap((packages) =>
            from(packages).pipe(
                mergeMap(loadPackageAndDependencies),
                tap((packageSelector) => {
                    if (hasError(packageSelector)) {
                        console.error(
                            `Failed to load package: ${packageSelector.name}@${packageSelector.version}`,
                            packageSelector.error
                        );
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message: `Failed to load package: ${packageSelector.name}@${packageSelector.version}. Editor might not be able to display correct feedback as a result, try refreshing the browser, if the issue persists please contact support.`,
                            noToast: true,
                        });
                    }
                }),
                filter(isValidPackage),
                groupBy((pkg) => pkg.name),
                mergeMap((group$) => group$.pipe(reduce((acc, pkg) => (gt(pkg.version, acc.version) ? pkg : acc)))),
                toArray()
            )
        ),
        tap((pkgs) => {
            const reduced = pkgs.reduce<Record<string, string>>((acc, pkg) => {
                const currentVersion = acc[pkg.name];
                if (!currentVersion || gt(pkg.version, currentVersion)) {
                    acc[pkg.name] = pkg.version;
                }
                return acc;
            }, {});
            activeDependencies$.next(reduced);
        }),
        mergeMap((pkgs) => from(pkgs).pipe(distinctUntilKeyChanged('name'), loadTypeDeclarations(), toArray())),
        filter((pkgs) => pkgs.length > 0)
    )
    .subscribe((decs) => {
        const current = currentTypeDeclarationsLoaded$.value;
        const total = totalTypeDeclarationsLoaded$.value;

        completeIfReady(current, total);
        libsEditorAction$.next({ libs: decs, overwrite: true });
        if (newWorkspaceFromTemplate$.value?.name && newWorkspaceFromTemplate$.value.createFromTemplateUid) {
            bundleNewWorkspaceScriptsAction$.next();
        }
    });

const completeIfReady = (current: number, total: number): void => {
    if (current >= total) {
        // toast.dismiss(toastId);
        // toastId = undefined;
        totalTypeDeclarationsLoaded$.next(0);
        currentTypeDeclarationsLoaded$.next(0);
        editorAlertMessage$.next(undefined);
        editorReadOnly$.next(false);
    } else if (editorReadOnly$.value === false) {
        editorReadOnly$.next(true);
    }
};

/**
 * Load package data
 */
const loadPackage = async (selector: PackageSelector): Promise<LoadedPackage | PackageError> => {
    const packageJson = await getPackageJson(selector);

    if (hasError(packageJson)) {
        return packageJson;
    }

    return {
        ...selector,
        ...packageJson,
        filter: selector.filter,
    };
};

const loadPackageAndDependencies = (selector: PackageSelector): Observable<LoadedPackage | PackageError> =>
    from(loadPackage(selector)).pipe(
        mergeMap((pkg) =>
            !hasError(pkg) && pkg.dependencies
                ? concat(
                      // return the package
                      of(pkg),
                      // and its dependencies recursively...
                      from(Object.entries(pkg.dependencies)).pipe(
                          map(
                              ([name, version]) =>
                                  (version.startsWith('^') || version.startsWith('~')
                                      ? [name, version.slice(1)]
                                      : [name, version]) as [string, string]
                          ),
                          mergeMap(([name, version]) => loadPackageAndDependencies({ name, version }))
                      )
                  )
                : of(pkg)
        )
    );

const loadTypeDeclarations = (concurrent = 10): OperatorFunction<LoadedPackage, TypeDeclaration> =>
    pipe(
        mergeMap(getTypeDeclaration$()),
        tap(() => totalTypeDeclarationsLoaded$.next(totalTypeDeclarationsLoaded$.value + 1)),
        mergeMap(fetchContent, concurrent),
        tap(() => currentTypeDeclarationsLoaded$.next(currentTypeDeclarationsLoaded$.value + 1)),
        tap((typeDefinition) => {
            if (hasError(typeDefinition)) {
                console.error(`Failed to load type definition: ${typeDefinition.filePath}`, typeDefinition.error);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load type declaration: ${typeDefinition.name}${typeDefinition.path}. Editor might not be able to display correct feedback as a result, try refreshing the browser, if the issue persists please contact support.`,
                    noToast: true,
                });
            }
        }),
        filter(hasContent)
    );

import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import { gt } from 'semver';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { Dropdown } from '../../dropdown/Dropdown';
import Tooltip from '@mui/material/Tooltip';

export interface PackageProperties {
    name: string;
    selectedVersion: string;
    latestVersion?: string;
    description?: string;
    link?: string;
    versions: string[];
    selected?: boolean;
    locked?: boolean;
    saved?: boolean;
    thirdParty?: boolean;
    verified?: boolean;
    childPackage?: Pick<
        PackageProperties,
        'name' | 'selectedVersion' | 'description' | 'link' | 'versions' | 'selected'
    >;
    onSelect: (selected: boolean, version: string, name: string) => void;
}

const StyledBadge = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: '2px',
    fontSize: theme.typography.body2.fontSize,
    height: '21px',
    marginRight: theme.spacing(2),
    padding: 0,
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    padding: 0,
}));

const StyledLink = styled(Link)(() => ({
    width: 'fit-content',
}));

const StyledPackage = styled('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: theme.constants.boxShadow,
    display: 'flex',
    height: '80px',
    width: '100%',
    '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
    },
}));

const StyledChildPackage = styled('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: theme.constants.boxShadow,
    display: 'flex',
    height: '70px',
    width: '95%',
}));

const StyledChildPackageContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
}));

const StyledSelectContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    '& .MuiInputBase-root': {
        width: '98px',
    },
}));

const StyledTextContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    ...theme.typography.overflowBlock,
}));

export const Package: React.FC<PackageProperties> = ({
    name,
    selectedVersion,
    latestVersion,
    versions,
    description,
    link,
    childPackage,
    locked = false,
    selected = false,
    saved = false,
    thirdParty = false,
    verified = false,
    onSelect,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [parentChecked, setParentChecked] = useState(selected);
    const [currentlySelectedVersion, setCurrentlySelectedVersion] = useState(
        saved ? selectedVersion : selectedVersion === latestVersion ? 'latest' : selectedVersion
    );
    const [childChecked, setChildChecked] = useState(!!childPackage?.selected);
    const [currentlySelectedChildVersion, setCurrentlySelectedChildVersion] = useState(
        childPackage?.selectedVersion ?? ''
    );

    const handleParentCheckChange = (checked: boolean): void => {
        setParentChecked(checked);
        setChildChecked(checked);
    };

    const handleChildCheckChange = (checked: boolean): void => {
        setChildChecked(checked);
    };

    useEffect(() => {
        onSelect(parentChecked, currentlySelectedVersion, name);
    }, [parentChecked, currentlySelectedVersion]);

    useEffect(() => {
        if (childPackage) {
            onSelect(childChecked, currentlySelectedChildVersion, childPackage.name);
        }
    }, [childChecked, currentlySelectedChildVersion]);

    useEffect(() => {
        if (childPackage) {
            setChildChecked(!!childPackage.selected);
            setCurrentlySelectedChildVersion(childPackage.selectedVersion);
        }
    }, [childPackage]);

    const dropdownItems = [
        ...(latestVersion ? [{ name: 'latest', value: 'latest' }] : []),
        ...versions
            .map((version) => ({ name: version, value: version }))
            .sort((v1, v2) => (gt(v1.value, v2.value) ? -1 : 1)),
    ];

    const childDropdownItems = (childPackage?.versions ?? [])
        .map((version) => ({ name: version, value: version }))
        .sort((v1, v2) => (gt(v1.value, v2.value) ? -1 : 1));

    return (
        <>
            <StyledPackage>
                <StyledCheckbox
                    disabled={locked}
                    checked={parentChecked}
                    onChange={(_e, checked) => handleParentCheckChange(checked)}
                />
                <StyledTextContainer sx={!description ? { justifyContent: 'center' } : null}>
                    {link ? (
                        <StyledLink href={link} target="_blank">
                            <Typography variant="subtitle2">{name}</Typography>
                        </StyledLink>
                    ) : (
                        <Typography variant="subtitle2">{name}</Typography>
                    )}
                    {description && (
                        <Tooltip title={description}>
                            <Typography noWrap>{description}</Typography>
                        </Tooltip>
                    )}
                </StyledTextContainer>
                <StyledSelectContainer>
                    {thirdParty ? (
                        <StyledBadge
                            sx={{
                                backgroundColor: verified ? 'success.main' : 'action.disabledBackground',
                                color: verified ? 'primary.contrastText' : 'text.primary',
                            }}
                            label={verified ? 'Verified' : 'Unverified'}
                        ></StyledBadge>
                    ) : null}
                    <Dropdown
                        onSelect={(version) => setCurrentlySelectedVersion(version)}
                        selectedItem={currentlySelectedVersion}
                        label="Version"
                        items={dropdownItems}
                        size="small"
                    ></Dropdown>
                </StyledSelectContainer>
            </StyledPackage>
            {childPackage && (
                <StyledChildPackageContainer>
                    <StyledChildPackage>
                        <StyledCheckbox
                            disabled={locked}
                            checked={childChecked}
                            onChange={(_event, checked) => handleChildCheckChange(checked)}
                        />
                        <StyledTextContainer sx={!childPackage.description ? { justifyContent: 'center' } : null}>
                            {childPackage.link ? (
                                <StyledLink href={childPackage.link} target="_blank">
                                    <Typography variant="subtitle2">{childPackage.name}</Typography>
                                </StyledLink>
                            ) : (
                                <Typography variant="subtitle2">{childPackage.name}</Typography>
                            )}
                            {childPackage.description && (
                                <Tooltip title={childPackage.description}>
                                    <Typography noWrap>{childPackage.description}</Typography>
                                </Tooltip>
                            )}
                        </StyledTextContainer>
                        <StyledSelectContainer>
                            {thirdParty ? (
                                <StyledBadge
                                    sx={{
                                        backgroundColor: verified ? 'success.main' : 'action.disabledBackground',
                                        color: verified ? 'primary.contrastText' : 'text.primary',
                                    }}
                                    label={verified ? 'Verified' : 'Unverified'}
                                ></StyledBadge>
                            ) : null}
                            <Dropdown
                                onSelect={(version) => setCurrentlySelectedChildVersion(version)}
                                selectedItem={currentlySelectedChildVersion}
                                label="Version"
                                items={childDropdownItems}
                                size="small"
                            ></Dropdown>
                        </StyledSelectContainer>
                    </StyledChildPackage>
                </StyledChildPackageContainer>
            )}
        </>
    );
};

import { LoaderFn } from 'react-location';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { LocationGenerics } from '../router';
import { loadApps } from '../store/apps';
import { loadTemplates } from '../store/templates';
import { loadErrorPage } from '../store/error';

export const templatesLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        await Promise.all([loadTemplates(), loadApps()]);
        segmentAnalyticsTrack('TemplateListing Viewed');
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Templates',
            genericMessage: 'Failed to load templates.',
        });

        throw e;
    }

    return {};
};

import { BehaviorSubject, Subject, map } from 'rxjs';
import {
    acceptOrganizationMemberInvite,
    getLoggedUserDetails,
    startImpersonation,
    stopImpersonation,
    updateUserDetails,
} from '../data/user';
import { UserDetails } from '../hooks/auth';
import { resetAccountPassword } from '../utils/manageAccount';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { saveLocalStorage } from '../utils/localStorage';
import { getFetchOptions } from '../utils/fetch';
import { configTopic$ } from './config';
import { InformativeError, PermissionError } from '../utils/repository';
import { getBasePath } from '../utils/path';
import { promptMessage } from './confirm';

export interface UserUpdatePayload {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    roleUid: string;
    customIndustryRole: string;
}

interface AnonUser {
    uid: string;
    ip?: string;
    originUid?: string;
    utmMedium?: string;
    utmSource?: string;
    utmCampaign?: string;
    referrerSite?: string;
}

export interface StartImpersonationEvent {
    impersonateeUserUid: string;
    password: string;
}

export const loggedInUserDetails$ = monitor('loggedInUserDetails$', new BehaviorSubject<UserDetails | null>(null));
export const updateUserDetailsAction$ = monitor('updateUserDetailsAction$', new Subject<UserUpdatePayload>());
export const customRoleValidationError$ = monitor('customRoleValidationError$', new BehaviorSubject<string>(''));
export const userDetailsUpdating$ = monitor('userDetailsUpdating$', new BehaviorSubject<boolean>(false));
export const resetUserPasswordAction$ = monitor('resetUserPasswordAction$', new Subject<string>());
export const acceptInviteAction$ = monitor('acceptInviteAction$', new Subject<string>());
export const registerAnonUserAction$ = monitor('registerAnonUserAction$', new Subject<AnonUser>());
export const unregisterAnonUserAction$ = monitor('unregisterAnonUserAction$', new Subject<string>());
export const startImpersonationAction$ = monitor(
    'initiateImpersonationAction$',
    new Subject<StartImpersonationEvent>()
);
export const stopImpersonationAction$ = monitor('stopImpersonationAction$', new Subject<void>());
export const askImpersonationPasswordAction$ = monitor('askImpersonationPasswordAction$', new Subject<string>());
export const closeImpersonationPasswordDialogAction$ = monitor(
    'closeImpersonationPasswordDialogAction$',
    new Subject<void>()
);
export const askImpersonationPasswordDialogOpen$ = monitor(
    'askImpersonationPasswordDialogOpen$',
    new BehaviorSubject(false)
);
export const selectedImpersonateeUid$ = monitor(
    'selectedImpersonateeUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const impersonationPasswordError$ = monitor(
    'impersonationPasswordError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const askImpersonationPasswordDialogSaving$ = monitor(
    'askImpersonationPasswordDialogSaving$',
    new BehaviorSubject(false)
);

unregisterAnonUserAction$
    .pipe(
        map(async (stitchInitialUid) => {
            try {
                const fetchOptions = getFetchOptions({}, { uid: stitchInitialUid });
                const url = configTopic$.value.trigger?.unregisterAnonUser;
                if (!url) {
                    throw new Error('No unregister url configured in meta');
                }
                await fetch(url, fetchOptions);
                saveLocalStorage('stitchInitialUid', 'N/A');
            } catch (err) {
                console.error('Could not unregister user', err);
            }
        })
    )
    .subscribe();

registerAnonUserAction$
    .pipe(
        map(async (anonUser) => {
            try {
                const fetchOptions = getFetchOptions({}, anonUser);
                const url = configTopic$.value.trigger?.registerAnonUser;
                if (!url) {
                    throw new Error('No register user url configured in meta');
                }
                const response = await fetch(url, fetchOptions);
                if (!response.ok) {
                    console.warn('Could not initiate new user');
                    localStorage.removeItem('stitchInitialUid');
                }
            } catch (err) {
                console.error('Could not initiate new user', err);
            }
        })
    )
    .subscribe();

acceptInviteAction$
    .pipe(
        map(async (inviteId) => {
            try {
                const { organizationName } = await acceptOrganizationMemberInvite(inviteId);
                promptMessage(`Successfully joined team: ${organizationName}`);
            } catch (e) {
                const errorMessage = e instanceof InformativeError ? `. ${e.message}` : '';

                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message:
                        `Could not join team ${errorMessage}. Click on the invite link again ` +
                        'and if the issue persists please contact support.',
                    toastOptions: {
                        autoClose: false,
                    },
                });
            }
        })
    )
    .subscribe();

updateUserDetailsAction$
    .pipe(
        map(async (updateData) => {
            userDetailsUpdating$.next(true);
            try {
                const response = await updateUserDetails(updateData);
                if (response) {
                    customRoleValidationError$.next(response);
                } else {
                    customRoleValidationError$.next('');
                    const userDetails = await getLoggedUserDetails();
                    loggedInUserDetails$.next(userDetails);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Profile details successfully updated.',
                    });
                }
            } catch {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error occurred while updating profile.',
                });
            }
            userDetailsUpdating$.next(false);
        })
    )
    .subscribe();

resetUserPasswordAction$
    .pipe(
        map(async (email) => {
            await resetAccountPassword(email);
        })
    )
    .subscribe();

startImpersonationAction$
    .pipe(
        map(async ({ impersonateeUserUid, password }) => {
            impersonationPasswordError$.next('');
            askImpersonationPasswordDialogSaving$.next(true);
            try {
                await startImpersonation(impersonateeUserUid, password);
                closeImpersonationPasswordDialogAction$.next();
                window.location.replace(`${window.location.origin}${getBasePath()}`);
            } catch (e) {
                if (e instanceof PermissionError) {
                    console.error('Error starting impersonation:', e);
                    impersonationPasswordError$.next(e.message);
                } else {
                    console.error('Error when starting impersonation session', e);
                    impersonationPasswordError$.next('Error occurred while trying to start impersonation session.');
                }
            }
            askImpersonationPasswordDialogSaving$.next(false);
        })
    )
    .subscribe();

stopImpersonationAction$
    .pipe(
        map(async () => {
            try {
                await stopImpersonation();
                window.location.replace(`${window.location.origin}${getBasePath()}`);
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error occurred while trying to stop impersonation session.',
                });
            }
        })
    )
    .subscribe();

askImpersonationPasswordAction$.subscribe((impersonateeUserUid) => {
    selectedImpersonateeUid$.next(impersonateeUserUid);
    askImpersonationPasswordDialogOpen$.next(true);
});

closeImpersonationPasswordDialogAction$.subscribe(() => {
    askImpersonationPasswordDialogOpen$.next(false);
    selectedImpersonateeUid$.next(undefined);
    askImpersonationPasswordDialogSaving$.next(false);
    impersonationPasswordError$.next(undefined);
});

import React from 'react';
import { Link, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AbsoluteCenteredLoadingSpinner } from '../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../buttons/Button';
import { InfoIcon } from '../icons/InfoIcon';
import { Apps } from '../../data/apps';
import { APP } from '@avst-stitch/repository-lib/constants';
import { AppCard } from '../app-card';
import { PageGridContainer } from '../layout';
import DialogActions from '@mui/material/DialogActions';
import { StyledDialogTitle } from '../dialog';

interface AppSelectorProps {
    loading?: boolean; // Show centered MUI spinner and nothing else when true
    apps?: Apps;
    onSelectApp(uid: string): void; // When App was selected
    onCancel(): void;
    open?: boolean;
    title?: string;
    tooltip?: string;
    showGenericAppHint?: boolean;
    hideMicrosoftConnector?: boolean;
    mode: 'EVENT_LISTENER' | 'API_HANDLER' | 'CONNECTOR';
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paperScrollPaper': {
        position: 'relative',
        margin: 0,
        maxWidth: '1280px',
        maxHeight: '100%',
        minHeight: '390px',
        width: '100%',
        borderRadius: 0,
        [theme.breakpoints.up('sm')]: {
            borderRadius: theme.constants.borderRadius,
            margin: theme.spacing(6),
            maxHeight: '90vh',
        },
    },
}));

const StyledDialogFooter = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    padding: theme.spacing(1, 1, 2.5),
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    marginBottom: theme.spacing(-0.5),
    marginLeft: theme.spacing(1),
}));

export const AppSelector: React.FC<AppSelectorProps> = ({
    apps = [],
    loading = false,
    onCancel,
    open = false,
    onSelectApp,
    title,
    tooltip,
    mode,
    showGenericAppHint = true,
    hideMicrosoftConnector = false,
}) => {
    const gridItems = apps
        .filter(
            (a) =>
                !(hideMicrosoftConnector && a.name === APP.MICROSOFT.NAME) &&
                ((mode === 'API_HANDLER' && a.connectionType.apiHandlerTypes.length > 0) ||
                    (mode === 'EVENT_LISTENER' &&
                        a.connectionType.eventListenerTypes.length > 0 &&
                        a.name !== 'Tempo Timesheets On-Premise' &&
                        a.name !== 'Tempo Planner On-Premise' &&
                        a.name !== 'Jira Service Management On-Premise Assets') ||
                    (mode === 'CONNECTOR' &&
                        a.name !== 'Tempo Timesheets On-Premise' &&
                        a.name !== 'Tempo Planner On-Premise' &&
                        a.name !== 'Jira Service Management On-Premise' &&
                        a.name !== 'Jira Service Management On-Premise Assets'))
        )
        .map((app) => {
            return (
                <AppCard
                    key={app.uid}
                    description=""
                    title={app.name}
                    type={app.name}
                    onSelect={onSelectApp}
                    uid={app.uid}
                />
            );
        });

    const handleGenericAppSelection = (): void => {
        const genericApp = apps.find((a) => a.name === APP.GENERIC.NAME);

        if (genericApp) {
            onSelectApp(genericApp.uid);
        }
    };
    return (
        <>
            <StyledDialog open={open}>
                <StyledDialogTitle>
                    <Typography component="h2" variant="h6">
                        {title}
                    </Typography>
                    {tooltip && (
                        <StyledTooltip title={tooltip}>
                            <InfoIcon />
                        </StyledTooltip>
                    )}
                </StyledDialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        <PageGridContainer sx={(theme) => ({ overflow: 'auto', padding: theme.spacing(3, 2) })}>
                            {gridItems}
                        </PageGridContainer>
                        {showGenericAppHint && (
                            <StyledDialogFooter>
                                <Typography variant="subtitle2">
                                    Can’t find the App you need in this list? Consider using the{' '}
                                    <Link onClick={handleGenericAppSelection}>Generic App</Link> instead and request or
                                    upvote the apps you need on our{' '}
                                    <Link target="_blank" href="https://scriptrunnerconnect.nolt.io/top">
                                        feedback board!
                                    </Link>
                                </Typography>
                            </StyledDialogFooter>
                        )}
                        <DialogActions>
                            <Button onClick={onCancel} variant="outlined" data-hotspot="cancel">
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                )}
            </StyledDialog>
        </>
    );
};

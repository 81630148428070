import { useObservableState } from 'observable-hooks';
import {
    organizationBillingDetails$,
    selectedOrganizationPlan$,
    selectedOrganizationUid$,
} from '../../store/organization';
import {
    changeOrganizationPlanAction$,
    changeOrganizationPlanActionV2$,
    changeOrganizationPlanConfirmPurchaseDialogError$,
    changeOrganizationPlanConfirmPurchaseDialogLoading$,
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$,
    closeChangeOrganizationPlanConfirmPurchaseDialogAction$,
    changeOrganizationPlanConfirmPurchaseDialogOpen$,
} from '../../store/organization/changeOrganizationPlan';
import { featureFlagsTopic$ } from '../../store/config';
import { ChangeOrganizationPlanConfirmPurchaseDialog } from '../../components/organization/change-organization-plan-wizard/ChangeOrganizationPlanConfirmPurchaseDialog';

export const ChangeOrganizationPlanConfirmPurchaseContainer: React.FC = () => {
    const open = useObservableState(changeOrganizationPlanConfirmPurchaseDialogOpen$);
    const loading = useObservableState(changeOrganizationPlanConfirmPurchaseDialogLoading$);
    const error = useObservableState(changeOrganizationPlanConfirmPurchaseDialogError$);
    const nextPlan = useObservableState(changeOrganizationPlanConfirmPurchaseDialogNextPlan$);
    const currentPlan = useObservableState(selectedOrganizationPlan$);
    const orgUid = useObservableState(selectedOrganizationUid$);
    const billingDetails = useObservableState(organizationBillingDetails$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    if (!currentPlan || !nextPlan) {
        return null;
    }

    const handelConfirm = (): void => {
        if (featureFlags.salablePlan) {
            changeOrganizationPlanActionV2$.next({
                plan: nextPlan,
                organizationUid: orgUid || '',
                existingBillingEntityUid: billingDetails?.selectedBillingEntityUid,
                originalReferrer: window.location.origin,
            });
        } else {
            closeChangeOrganizationPlanConfirmPurchaseDialogAction$.next();
            changeOrganizationPlanAction$.next({
                plan: nextPlan,
                organizationUid: orgUid || '',
                selectedBillingEntityUid: billingDetails?.selectedBillingEntityUid,
            });
        }
    };

    const billingEmail = billingDetails?.billingEntities.find(
        (be) => be.uid === billingDetails.selectedBillingEntityUid
    )?.contactEmail;

    return (
        <ChangeOrganizationPlanConfirmPurchaseDialog
            open={open}
            currentPaymentType={currentPlan.paymentType}
            billingEmail={billingEmail}
            nextPlan={nextPlan}
            errors={error}
            loading={loading}
            onCancel={() => closeChangeOrganizationPlanConfirmPurchaseDialogAction$.next()}
            onConfrim={handelConfirm}
        />
    );
};

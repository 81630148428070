import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export interface MicrosoftConnection {
    clientInfo: ClientInfo;
    name: string;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: MicrosoftWizardStage;
    setStage: (stage: MicrosoftWizardStage) => void;
    onSave: (props: ClientInfo) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
    adminConsentUrl: string;
}

export interface ClientInfo {
    clientId: string;
    clientSecret: string;
    tenantId: string;
}

export type InstanceType = 'FULLY-MANAGED' | 'SELF-MANAGED';

export enum MicrosoftWizardStage {
    CHOOSEINSTANCETYPE,
    CREATE,
    CONSENT,
    CONFIGURE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface MicrosoftWizardStepsProps {
    readonly stage: MicrosoftWizardStage;
    readonly instanceType: 'FULLY-MANAGED' | 'SELF-MANAGED';
}

export const MicrosoftWizardSteps: React.FC<MicrosoftWizardStepsProps> = ({
    stage,
    instanceType = 'FULLY-MANAGED',
}) => {
    return instanceType === 'SELF-MANAGED' ? (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={MicrosoftWizardStage.CHOOSEINSTANCETYPE}>
                <StepLabel>Choose instance type</StepLabel>
            </Step>
            <Step key={MicrosoftWizardStage.CREATE}>
                <StepLabel>Create Application</StepLabel>
            </Step>
            <Step key={MicrosoftWizardStage.CONFIGURE}>
                <StepLabel>Configure Application</StepLabel>
            </Step>
            <Step key={MicrosoftWizardStage.DETAILS}>
                <StepLabel>Enter Details</StepLabel>
            </Step>
            <Step key={MicrosoftWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    ) : (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={MicrosoftWizardStage.CHOOSEINSTANCETYPE}>
                <StepLabel>Choose instance type</StepLabel>
            </Step>
            <Step key={MicrosoftWizardStage.CONSENT}>
                <StepLabel>Obtain Administrator Consent</StepLabel>
            </Step>
            <Step key={MicrosoftWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { AbsoluteCenteredLoadingSpinner } from '../loading/AbsoluteCenteredLoadingSpinner';
import { AvatarContainer } from '../avatar';
import { Button } from '../buttons/Button';
import { IconCircle } from '../icon-circle';
import { DialogAlert, DialogTitleSmall } from '../dialog';
import { StyledUserContainer, StyledImageBox, StyledNameAndEmailContainer } from './OrganizationComponents';
import { handleKeyUp } from '../../utils/handleKeyUp';

type SharingState = 'PRIVATE' | 'PUBLIC';

interface ShareWorkspaceDialogProps {
    adminUsers: {
        email: string;
        avatar?: string; // Base64, same logic as elsewhere
        firstName?: string;
        lastName?: string;
    }[];
    canShare?: boolean;
    open?: boolean;
    organizationUsers: {
        email: string;
        avatar?: string; // Base64, same logic as elsewhere
        firstName?: string;
        lastName?: string;
    }[];
    loading?: boolean; // Display center spinner
    saving?: boolean; // Disable create button and show the usual spinner
    sharingState?: SharingState;
    workspaceLocked?: boolean;
    onCancel(): void;
    onShare(sharingState: SharingState): void;
}

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
}));

const StyledMemberAvatarAndCredentialsContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

const StyledNameTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowLine,
    color: theme.palette.text.primary,
    width: 400,
}));

const StyledEmailTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    ...theme.typography.overflowLine,
}));

export const ShareWorkspaceDialog: React.FC<ShareWorkspaceDialogProps> = ({
    adminUsers = [],
    canShare = false,
    loading = false,
    open = false,
    organizationUsers = [],
    saving = false,
    sharingState = 'PRIVATE',
    workspaceLocked = false,
    onCancel,
    onShare,
}) => {
    const [currentSharingState, setCurrentSharingState] = useState<SharingState>(sharingState);

    useEffect(() => {
        setCurrentSharingState(sharingState);
    }, [open, sharingState]);

    const label =
        currentSharingState === 'PRIVATE'
            ? 'Following team administrators can access this workspace:'
            : 'Any following member of the team can access this workspace:';

    const users = currentSharingState === 'PRIVATE' ? adminUsers : organizationUsers;
    const hasChanged = currentSharingState !== sharingState;
    const shareDisabled = saving || workspaceLocked || !canShare || !hasChanged || loading;

    return (
        <Dialog
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({
                    event,
                    enterCondition: !shareDisabled,
                    enterFn: () => onShare(currentSharingState),
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleSmall title="Share" icon={<IconCircle icon={<PersonAddAltIcon />} />} />
            {loading ? (
                <AbsoluteCenteredLoadingSpinner />
            ) : (
                <>
                    <DialogContent>
                        {workspaceLocked && (
                            <DialogAlert
                                alertTitle="Information"
                                text="Workspace is currently locked and sharing settings cannot be edited."
                                severity="info"
                            />
                        )}
                        {!canShare && (
                            <DialogAlert
                                alertTitle="Information"
                                text="Only a team administrator or above can change workspace sharing settings."
                                severity="info"
                            />
                        )}
                        <StyledRadioGroup
                            name="controlled-radio-buttons-group"
                            value={currentSharingState}
                            onChange={(e) => setCurrentSharingState(e.target.value as SharingState)}
                        >
                            <FormControlLabel
                                value="PRIVATE"
                                control={<Radio />}
                                label="Admins only"
                                disabled={!canShare}
                                sx={{
                                    '&>.MuiTypography-root':
                                        currentSharingState === 'PRIVATE' ? { fontWeight: 500 } : { fontWeight: 400 },
                                }}
                            />

                            <FormControlLabel
                                value="PUBLIC"
                                control={<Radio />}
                                disabled={!canShare}
                                label="All members"
                                sx={{
                                    '&>.MuiTypography-root':
                                        currentSharingState === 'PUBLIC' ? { fontWeight: 500 } : { fontWeight: 400 },
                                }}
                            />
                        </StyledRadioGroup>
                        <DialogContentText>{label}</DialogContentText>
                        {users.map((user, i) => {
                            return (
                                <StyledUserContainer key={i}>
                                    <StyledMemberAvatarAndCredentialsContainer>
                                        {user.avatar ? (
                                            <StyledImageBox>
                                                <img
                                                    src={`data:image/jpeg;base64,${user.avatar}`}
                                                    height={30}
                                                    width={30}
                                                    alt=""
                                                />
                                            </StyledImageBox>
                                        ) : (
                                            <Box paddingRight={1}>
                                                <AvatarContainer
                                                    size="small"
                                                    credentials={{
                                                        firstName: user.firstName ?? '',
                                                        lastName: user.lastName ?? '',
                                                        email: user.email,
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        <StyledNameAndEmailContainer>
                                            {(user.firstName || user.lastName) && (
                                                <StyledNameTypography variant="subtitle2">
                                                    {user.firstName}&nbsp;
                                                    {user.lastName}
                                                </StyledNameTypography>
                                            )}
                                            <StyledEmailTypography variant="body1">{user.email}</StyledEmailTypography>
                                        </StyledNameAndEmailContainer>
                                    </StyledMemberAvatarAndCredentialsContainer>
                                </StyledUserContainer>
                            );
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()} variant="outlined">
                            Cancel
                        </Button>
                        <Button busy={saving} disabled={shareDisabled} onClick={() => onShare(currentSharingState)}>
                            Save
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

import React from 'react';
import { styled } from '@mui/material';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { StyledCard } from './DashboardComponents';
import Grid from '@mui/material/Unstable_Grid2';

interface AccountUsageCardProps {}

const StyledAccountCard = styled(StyledCard)(({ theme }) => ({
    // do not put any width constraints on the card containers, let the parent Grid xs do the sizing
    backgroundColor: theme.palette.background.paper,
    cursor: 'default',
    padding: theme.spacing(1.5, 1, 1),
    '&:hover': {
        outline: theme.constants.borderHidden,
    },
}));

const StyledSubscriptionDataRow = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(2),
    minWidth: 240,
    width: '25%',
    '& p': {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(0.5),
    },
}));

const StyledList = styled(List)(({ theme }) => ({
    color: theme.palette.text.disabled,
    fontSize: theme.typography.body2.fontSize,
    padding: 0,
    textAlign: 'right',
    '& > li': {
        height: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(0.5),
        },
    },
}));

const StyledGraph = styled('div')(({ theme }) => ({
    // eslint-disable-next-line sonarjs/no-duplicate-string
    borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
    borderLeft: `1px solid ${theme.palette.action.disabledBackground}`,
    minWidth: 300,
    padding: theme.spacing(0.75, 2, 2),
    position: 'relative',
    width: '90%',
}));

const StyledGraphLine = styled('div')(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    borderTop: `5px solid ${theme.palette.action.focus}`,
    height: 0,
    width: '90%',
    '&:not(:nth-child(4))': {
        marginBottom: theme.spacing(2.85),
    },
}));

const StyledScale = styled('div')(() => ({
    bottom: 0,
    display: 'flex',
    left: 0,
    position: 'absolute',
    height: 6,
    width: '100%',
}));

const StyledScalePoint = styled('div')(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.action.disabledBackground}`,
    height: '100%',
    width: 16,
}));

export const AccountUsageCard: React.FC<AccountUsageCardProps> = () => {
    const scalePoints: JSX.Element[] = [];

    for (let i = 0; i < 40; i++) {
        const scalePoint = <StyledScalePoint key={i} />;
        scalePoints.push(scalePoint);
    }

    return (
        <StyledAccountCard elevation={1}>
            <Grid container spacing={0}>
                <Grid xs={3} db={2}>
                    &nbsp;
                </Grid>
                <Grid xs={9} db={10}>
                    <StyledSubscriptionDataRow>
                        <div>
                            <Typography variant="body2">Plan</Typography>
                            <Typography variant="body1">Free</Typography>
                        </div>
                        <div>
                            <Typography variant="body2">Monthly limit</Typography>
                            <Typography variant="body1">0</Typography>
                        </div>
                        <div>
                            <Typography variant="body2">Cost</Typography>
                            <Typography variant="body1">$0.00</Typography>
                        </div>
                    </StyledSubscriptionDataRow>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={3} db={2}>
                    <StyledList>
                        <li>Workspaces</li>
                        <li>Connectors</li>
                        <li>Scripts executed</li>
                        <li>API calls</li>
                    </StyledList>
                </Grid>
                <Grid xs={9} db={10}>
                    <StyledGraph>
                        <StyledGraphLine />
                        <StyledGraphLine />
                        <StyledGraphLine />
                        <StyledGraphLine />
                        <StyledScale>{scalePoints}</StyledScale>
                    </StyledGraph>
                </Grid>
            </Grid>
        </StyledAccountCard>
    );
};

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { Button } from '../../buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface GithubEventListenerSetupDialogProps {
    eventTypeName: string;
    onClose: () => void;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
}

export const GithubEventListenerSetupDialog: React.FC<GithubEventListenerSetupDialogProps> = ({
    eventTypeName,
    onClose,
    open = false,
    webhookBaseUrl,
    webhookUrlId,
}) => {
    const appName = APP.GITHUB.NAME;
    let isGithubApp = false;
    if (
        eventTypeName === 'Github App Authorization' ||
        eventTypeName === 'Installation' ||
        eventTypeName === 'Installation Repositories' ||
        eventTypeName === 'Marketplace Purchase' ||
        eventTypeName === 'Repository Dispatch' ||
        eventTypeName === 'Security Advisory' ||
        eventTypeName === 'Workflow Dispatch'
    ) {
        isGithubApp = true;
    }

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle variant="h6">Event Listener setup instructions for {appName}</DialogTitle>

                <DialogContent>
                    <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                    <DialogContentText variant="subtitle2">
                        You need to setup a webhook in your {appName} repository, organization or app in order to listen
                        to events in ScriptRunner Connect.
                    </DialogContentText>
                    <DialogContentText component="ol">
                        <li>Copy the URL above.</li>
                        <li>
                            Open{' '}
                            <Link target="_blank" href="https://www.github.com">
                                https://www.github.com
                            </Link>{' '}
                            in your browser.
                        </li>
                        {isGithubApp ? (
                            <>
                                <li>Navigate to GitHub App settings you wish to setup the Event Listener for.</li>
                                <li>
                                    Scroll to <strong>Webhook</strong> section in the <strong>General</strong> tab.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>Webhook URL</strong> field.
                                </li>
                                <li>
                                    Make sure that the checkbox <strong>Active</strong> is checked.
                                </li>
                                <li>
                                    Click on <strong>Save changes</strong> button.
                                </li>
                                {eventTypeName === 'Github App Authorization' ? (
                                    <li>
                                        You don't need to subscribe for <strong>{eventTypeName}</strong> event.
                                    </li>
                                ) : eventTypeName === 'Marketplace Purchase' ? (
                                    <>
                                        <li>
                                            Ensure your app can receive all webhook payloads sent by GitHub. If you are
                                            not sure, what kind of permissions you need for the event, please check the
                                            official{' '}
                                            <Link
                                                target="_blank"
                                                href="https://docs.github.com/en/developers/webhooks-and-events/webhooks/webhook-events-and-payloads#marketplace_purchase"
                                            >
                                                documentation
                                            </Link>
                                            .
                                        </li>
                                        <li>
                                            Click on <strong>Save changes</strong>.
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            Click on <strong>Permissions & Events</strong> tab on the left menu.
                                        </li>
                                        <li>
                                            Select access permissions for your app according to the event type you've
                                            picked. If you are not sure, what kind of permissions you need for the
                                            event, please check the official{' '}
                                            <Link
                                                target="_blank"
                                                href="https://docs.github.com/en/developers/webhooks-and-events/webhooks/webhook-events-and-payloads"
                                            >
                                                documentation
                                            </Link>
                                            .
                                        </li>
                                        <li>
                                            Scroll to <strong>Subscribe to events</strong> section and check ONLY the
                                            following event to listen to: <strong>{eventTypeName}</strong>.
                                        </li>
                                        <li>
                                            Click on <strong>Save changes</strong>.
                                        </li>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <li>
                                    Navigate to the Repository or Organization you wish to setup the event listener for
                                    and click on <strong>Settings</strong>.
                                </li>
                                <li>
                                    Select <strong>Webhooks</strong> tab on the left side menu.
                                </li>
                                <li>
                                    Click on <strong>Add webhook</strong>.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>Payload URL</strong> field.
                                </li>
                                <li>
                                    In the <strong>Content type</strong> dropdown choose{' '}
                                    <strong>application/json</strong>.
                                </li>
                                <li>
                                    Select the option <strong>Let me select individual events</strong>.
                                </li>
                                <li>
                                    Check ONLY the following event to listen to: <strong>{eventTypeName}</strong>.
                                </li>
                                <li>
                                    Make sure that the checkbox <strong>Active</strong> is checked.
                                </li>
                                <li>
                                    Click on <strong>Add webhook</strong>.
                                </li>
                                <li>
                                    If successful, you should see your webhook appear in the <strong>Webhooks</strong>{' '}
                                    list.
                                </li>
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Done</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { keyframes, styled, Theme, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { ResourceTreeItem, StyledBoxFlexRow, StyledWarningText } from './ResourceTreeItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CodeIcon from '@mui/icons-material/Code';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { sideBarWidth } from '.';
import { TreeNode } from './';
import { ScriptTreeClosedIcon, ScriptTreeOpenIcon, ScriptWarningIcon } from '../../icons/StyledMuiIcons';
import { InfoIcon } from '../../icons/InfoIcon';

interface ResourceTreeSectionProps {
    innerRef?: React.RefObject<HTMLDivElement>;
    name: string;
    readOnlyMode?: boolean;
    itemList: {
        uid?: string;
        name?: string;
        fullPath?: string;
        unsaved?: boolean;
        executing?: boolean;
        deleting?: boolean;
        appName?: string;
        warning?: boolean;
        path?: string;
        urlId?: string;
        scriptName?: string;
        subItems?: TreeNode[];
        cronExpression?: string;
        cronDescription?: string;
        eventTypeName?: string;
        connectionName?: string;
        remnantEnvironments?: {
            environmentName: string;
            deploymentVersion: string;
        }[];
        inactive?: boolean;
    }[];
    externalTriggerBaseUrl?: string;
    wizardIncompleteNode?: string;
    selectedNode?: string;
    language?: string;
    warning?: boolean;
    wizardFocus?: boolean;
    onToggleExpand?(): void;
    createNewHandler(): void;
    openHandler(uid: string): void;
    triggerHandler?(uid: string): void;
    deleteHandler(uid: string, fullPath?: string): void;
    pathCopyHandler?(uid: string): void;
    urlCopyHandler?(uid: string): void;
}

const StyledAddButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.radiusCircle,
    backgroundColor: 'transparent',
    height: 28,
    marginRight: theme.spacing(1.5),
    width: 28,
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        height: 20,
        width: 20,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

const StyledBoxSummarySection = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    cursor: 'pointer',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
}));

const StyledList = styled(List)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    position: 'relative',
}));

const StyledMuiAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    '&:before': {
        display: 'none',
    },
}));

const StyledMuiAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    marginTop: theme.spacing(-1),
    padding: 0,
}));

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    minHeight: 0,
    paddingLeft: theme.spacing(1.5),
    '& .MuiAccordionSummary-content': {
        margin: theme.spacing(1, 0),
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        backgroundColor: theme.palette.background.default,
        zIndex: 2,
    },
    '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

const StyledResourceIcon = styled('div')(({ theme }) => ({
    borderRadius: theme.constants.radiusCircle,
    height: 24,
    left: `calc(-${sideBarWidth / 2}px - 12px)`,
    position: 'absolute',
    top: theme.spacing(0.9),
    width: 24,
    '& .MuiSvgIcon-root': {
        height: 18,
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 18,
    },
}));

const StyledResourceName = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
}));

const StyledResourceNameContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginLeft: theme.spacing(1),
}));

export const ResourceTreeSection: React.FC<ResourceTreeSectionProps> = ({
    innerRef,
    name,
    readOnlyMode = false,
    itemList,
    selectedNode,
    language,
    externalTriggerBaseUrl,
    warning = false,
    wizardFocus = false,
    wizardIncompleteNode,
    onToggleExpand,
    createNewHandler,
    openHandler,
    triggerHandler,
    deleteHandler,
    pathCopyHandler,
    urlCopyHandler,
}) => {
    const [expanded, setExpand] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        setTimeout(() => {
            onToggleExpand?.();
        }, 300);
    }, [expanded]);

    const handleExpand = (): void => {
        setExpand(!expanded);
    };

    const handleCreateNew = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        createNewHandler();
    };

    interface TextWarnings {
        createWarningText?: string;
        tooltipText?: string;
        warningText?: string;
    }

    const handleTextWarnings = (): TextWarnings => {
        const result: TextWarnings = {};

        switch (name) {
            case 'Scripts':
                result.tooltipText = `Scripts allow you to describe your business logic.`;
                result.createWarningText = 'Create new Script';
                break;
            case 'API Connections':
                result.tooltipText = 'API Connections allow you to call APIs of external services.';
                result.createWarningText = 'Create new API Connection';
                result.warningText = 'Complete API Connection setup';
                break;
            case 'Event Listeners':
                result.tooltipText = `Event Listeners allow you to listen to events triggered by external services.`;
                result.createWarningText = 'Create new Event Listener';
                result.warningText = 'Complete Event Listener setup';
                break;
            case 'Scheduled Triggers':
                result.tooltipText = `Scheduled Triggers allow you to trigger scripts based on a schedule.`;
                result.createWarningText = 'Create new Scheduled Trigger';
                result.warningText = 'Complete Scheduled Trigger setup';
                break;
        }
        return result;
    };

    const warnings = handleTextWarnings();

    interface ResourceInfo {
        icon?: JSX.Element;
    }

    const handleResourceIconInfo = (): ResourceInfo => {
        const info: ResourceInfo = {};

        switch (name) {
            case 'Scripts':
                info.icon = <CodeIcon />;
                break;
            case 'API Connections':
                info.icon = <UploadOutlinedIcon />;
                break;
            case 'Event Listeners':
                info.icon = <DownloadOutlinedIcon />;
                break;
            case 'Scheduled Triggers':
                info.icon = <ScheduleOutlinedIcon />;
                break;
        }
        return info;
    };

    const iconInfo = handleResourceIconInfo();

    const pulse = keyframes({
        '0%': {
            boxShadow: `0 0 0 0 ${theme.palette.secondary.light}`,
            opacity: 1,
        },
        '70%': {
            boxShadow: `0 0 0 10px ${theme.palette.secondary.light}`,
            opacity: 0,
        },
        '100%': {
            boxShadow: `0 0 0 50px ${theme.palette.secondary.light}`,
            opacity: 0,
        },
    });

    const wizardFocusStyle = (theme: Theme): SystemStyleObject<Theme> => {
        return {
            '&:before': {
                animation: `${pulse} 1.5s infinite`,
                borderRadius: theme.constants.radiusCircle,
                content: '""',
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
            },
            border: `2px solid ${theme.palette.secondary.main}`,
            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            },
            '&:hover .MuiSvgIcon-root': {
                color: theme.palette.secondary.main,
            },
        };
    };

    // no spaces in ids
    const ariaId = name.replace(/\s/g, '').toLowerCase() + '-aria';

    return (
        <>
            <StyledMuiAccordion expanded={expanded} data-test-id={`resource-tree-${ariaId}`}>
                <StyledBoxSummarySection onClick={handleExpand} ref={innerRef}>
                    <StyledMuiAccordionSummary
                        expandIcon={
                            expanded ? (
                                <ScriptTreeOpenIcon
                                    sx={{
                                        color: theme.palette.text.primary,
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            ) : (
                                <ScriptTreeClosedIcon
                                    sx={{
                                        color: theme.palette.text.primary,
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            )
                        }
                    >
                        <Tooltip
                            title={expanded ? `Collapse ${name} Section` : `Expand ${name} Section`}
                            placement="top-start"
                            describeChild
                        >
                            <StyledResourceNameContainer>
                                <StyledResourceIcon>{iconInfo.icon}</StyledResourceIcon>
                                <StyledResourceName>{name}</StyledResourceName>
                            </StyledResourceNameContainer>
                        </Tooltip>
                        <Tooltip title={warnings.tooltipText ?? ''} placement="top" role="button">
                            <InfoIcon sx={{ ml: 1 }} />
                        </Tooltip>
                    </StyledMuiAccordionSummary>
                    {!readOnlyMode && (
                        <Tooltip title={warnings.createWarningText ?? ''} placement="top" role="button">
                            <StyledAddButton sx={wizardFocus ? wizardFocusStyle : null} onClick={handleCreateNew}>
                                <AddCircleRoundedIcon />
                            </StyledAddButton>
                        </Tooltip>
                    )}
                </StyledBoxSummarySection>
                <StyledMuiAccordionDetails>
                    <StyledList>
                        {name !== 'Scripts' && warning && !readOnlyMode && (
                            <li>
                                <StyledBoxFlexRow>
                                    <ScriptWarningIcon />
                                    <StyledWarningText>{warnings.createWarningText}</StyledWarningText>
                                </StyledBoxFlexRow>
                            </li>
                        )}
                        {itemList.map((item, i) => {
                            return (
                                <ResourceTreeItem
                                    key={i}
                                    readOnlyMode={readOnlyMode}
                                    fullPath={item.fullPath}
                                    sectionName={name}
                                    uid={item.uid}
                                    subItems={item.subItems}
                                    name={item.name}
                                    appName={item.appName}
                                    warning={item.warning}
                                    warningText={warnings.warningText}
                                    path={item.path}
                                    urlId={item.urlId}
                                    scriptName={item.scriptName}
                                    cronExpression={item.cronExpression}
                                    cronDescription={item.cronDescription}
                                    language={language}
                                    unsaved={item.unsaved}
                                    executing={item.executing}
                                    deleting={item.deleting}
                                    eventType={item.eventTypeName}
                                    connectionName={item.connectionName}
                                    remnantEnvironments={item.remnantEnvironments}
                                    externalTriggerBaseUrl={externalTriggerBaseUrl}
                                    wizardIncomplete={wizardIncompleteNode === item.uid}
                                    inactive={item.inactive}
                                    onToggleExpand={onToggleExpand}
                                    openHandler={openHandler}
                                    triggerHandler={triggerHandler}
                                    selectedNode={selectedNode}
                                    deleteHandler={deleteHandler}
                                    pathCopyHandler={pathCopyHandler}
                                    urlCopyHandler={urlCopyHandler}
                                />
                            );
                        })}
                    </StyledList>
                </StyledMuiAccordionDetails>
            </StyledMuiAccordion>
        </>
    );
};

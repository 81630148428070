import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SaveIcon from '@mui/icons-material/Save';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

// gathering common icons and styling in one file

const StyledSaveIcon = styled(SaveIcon)(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: theme.typography.h6.fontSize,
    margin: theme.spacing(0, 0.5),
}));

const StyledWarningIcon = styled(WarningOutlinedIcon)(({ theme }) => ({
    color: theme.palette.warning.light,
    fontSize: theme.typography.subtitle1.fontSize,
}));

// scripts save icon
export const ScriptSaveIcon: React.FC<SvgIconProps> = (props) => {
    return <StyledSaveIcon {...props} />;
};

export const ScriptTreeOpenIcon: React.FC<SvgIconProps> = (props) => {
    return <IndeterminateCheckBoxOutlinedIcon {...props} />;
};

export const ScriptTreeClosedIcon: React.FC<SvgIconProps> = (props) => {
    return <AddBoxOutlinedIcon {...props} />;
};

// warning icon - all sections
export const ScriptWarningIcon: React.FC<SvgIconProps> = (props) => {
    return <StyledWarningIcon {...props} />;
};

import { BehaviorSubject, map, Subject } from 'rxjs';
import { saveConnectionInstance } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    atlassianCloudConnectionSavedAction$,
    atlassianCloudConnectionSaving$,
    atlassianCloudManageConnectionAuthorizeLoading$,
    atlassianCloudManageConnectionDetails$,
    atlassianCloudManageConnectionDialogErrors$,
} from './manage-connection';

export const atlassianCloudInstanceSelectionOpen$ = monitor(
    'atlassianCloudInstanceSelectionOpen$',
    new BehaviorSubject(false)
);
export const atlassianCloudInstanceSelectionSaving$ = monitor(
    'atlassianCloudInstanceSelectionSaving$',
    new BehaviorSubject(false)
);
export const atlassianCloudInstanceSelectionInstances$ = monitor(
    'atlassianCloudInstanceSelectionInstances$',
    new BehaviorSubject<{ id: string; url: string }[] | undefined>(undefined)
);
export const atlassianCloudInstanceSelectionCloseDialogAction$ = monitor(
    'atlassianCloudInstanceSelectionCloseDialogAction$',
    new Subject<void>()
);
export const atlassianCloudSaveInstanceAction$ = monitor(
    'atlassianCloudSaveInstanceAction$',
    new Subject<{ id: string; url: string }>()
);

atlassianCloudInstanceSelectionCloseDialogAction$.subscribe(() => {
    atlassianCloudInstanceSelectionOpen$.next(false);
    atlassianCloudInstanceSelectionInstances$.next(undefined);
});

atlassianCloudSaveInstanceAction$
    .pipe(
        map(async ({ id: cloudId, url: instanceUrl }) => {
            atlassianCloudInstanceSelectionSaving$.next(true);
            atlassianCloudManageConnectionDialogErrors$.next(undefined);

            const { uid, name, connectionType } = atlassianCloudManageConnectionDetails$.value ?? {};

            if (!uid) {
                throw Error('Atlassian Cloud Connector not created');
            }

            if (!connectionType) {
                throw Error('Atlassian Cloud Connector type not defined');
            }

            try {
                await saveConnectionInstance(uid, instanceUrl, cloudId);

                // Update details
                atlassianCloudManageConnectionDetails$.next({
                    connectionType,
                    name,
                    uid,
                    cloudId,
                    instanceUrl,
                });
                atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                atlassianCloudInstanceSelectionCloseDialogAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Connector authorized.',
                });
            } catch (e) {
                atlassianCloudInstanceSelectionCloseDialogAction$.next();
                if (e instanceof InformativeError) {
                    atlassianCloudManageConnectionDialogErrors$.next(e.message);
                } else {
                    atlassianCloudManageConnectionDialogErrors$.next(
                        'Failed to authorize Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while authorizing Atlassian Cloud Connector', e);
                }
            }

            atlassianCloudInstanceSelectionSaving$.next(false);
            atlassianCloudConnectionSaving$.next(false);
            atlassianCloudManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

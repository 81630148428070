import { useObservableState } from 'observable-hooks';
import { BasePackage, PackageManager } from '../../components/workspace/package-manager';
import { selectedWorkspace$, selectedWorkspaceUid$ } from '../../store/workspace';
import {
    closePackageManagerAction$,
    packageManagerOpen$,
    savingPackageManager$,
    packageManagerManagedAPIs$,
    packageManagerCorePackages$,
    packageManagerThirdPartyPackages$,
    saveWorkspacePackagesAction$,
    selectedWorkspacePackages$,
    implicitlyIncludedPackages$,
    addThirdPartyPackageAction$,
    packageManagerAddThirdPartyPackageErrors$,
} from '../../store/workspace/package-manager';

export const PackageManagerContainer: React.FC = () => {
    const open = useObservableState(packageManagerOpen$);
    const errors = useObservableState(packageManagerAddThirdPartyPackageErrors$);
    const saving = useObservableState(savingPackageManager$);
    const workspaceUid = useObservableState(selectedWorkspaceUid$);
    const packageManagerManagedAPIs = useObservableState(packageManagerManagedAPIs$);
    const packageManagerCorePackages = useObservableState(packageManagerCorePackages$);
    const thirdPartyPackages = useObservableState(packageManagerThirdPartyPackages$);
    const selectedWorkspacePackages = useObservableState(selectedWorkspacePackages$);
    const implicitlyIncludedPackages = useObservableState(implicitlyIncludedPackages$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    const selectedManagedAPIPackages = selectedWorkspacePackages.filter((pkg) => pkg.type === 'MANAGED_API');
    const managedAPIs = packageManagerManagedAPIs.map((pkg) => {
        let newPkg = { ...pkg };

        const selectedManagedAPIPackage = selectedManagedAPIPackages.find((slcPkg) => slcPkg.name === pkg.name);
        if (selectedManagedAPIPackage) {
            newPkg = { ...newPkg, selectedVersion: selectedManagedAPIPackage.version, selected: true, saved: true };
        }

        if (implicitlyIncludedPackages.find((impPkg) => impPkg.name === pkg.name)) {
            newPkg = { ...newPkg, selected: true, locked: true };
        }

        return newPkg;
    });

    const selectedCorePackages = selectedWorkspacePackages.filter((pkg) => pkg.type === 'CORE');
    const corePackages = packageManagerCorePackages.map((pkg) => {
        let newPkg = { ...pkg };

        const selectedCorePackage = selectedCorePackages.find((slcPkg) => slcPkg.name === pkg.name);
        if (selectedCorePackage) {
            newPkg = { ...newPkg, selectedVersion: selectedCorePackage.version, selected: true, saved: true };
        }
        if (implicitlyIncludedPackages.find((impPkg) => impPkg.name === pkg.name)) {
            newPkg = { ...newPkg, selected: true, locked: true };
        }

        return newPkg;
    });

    const handleSave = (
        corePackages: BasePackage[],
        managedAPIs: BasePackage[],
        thirdPartyPackages: BasePackage[]
    ): void => {
        if (workspaceUid) {
            const changes = [
                ...corePackages.map((pkg) => ({ name: pkg.name, version: pkg.selectedVersion, type: 'CORE' })),
                ...managedAPIs.map((pkg) => ({ name: pkg.name, version: pkg.selectedVersion, type: 'MANAGED_API' })),
                ...thirdPartyPackages.map((pkg) => ({
                    name: pkg.name,
                    version: pkg.selectedVersion,
                    type: 'THIRD_PARTY',
                })),
            ].filter(
                (pkg) =>
                    !implicitlyIncludedPackages.find(
                        (includedPkg) => includedPkg.name === pkg.name && includedPkg.version === pkg.version
                    )
            );

            saveWorkspacePackagesAction$.next({ workspaceUid, packages: changes });
        }
    };

    return (
        <PackageManager
            open={open}
            saving={saving}
            errors={errors}
            corePackages={corePackages}
            managedAPIs={managedAPIs}
            workspaceLocked={!!selectedWorkspace?.locked}
            thirdPartyPackages={thirdPartyPackages}
            onCancel={() => closePackageManagerAction$.next()}
            onSave={handleSave}
            onAddThirdPartyPackage={(name) => addThirdPartyPackageAction$.next({ name })}
        />
    );
};

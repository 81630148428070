import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '../../buttons/Button';
import { AppCard } from '../../app-card';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import {
    PageContainer,
    PageUpperContainer,
    PageTitle,
    PageUpperGroup,
    PageViewButtonGroup,
    PageGridContainer,
    EmptyStateContainer,
    EmptyStateText,
    EmptyStateImg,
    PageSubTitle,
    StyledActionButton,
} from '../../layout';
import { SelectorViewButton, viewSelectedStyle, SelectorTable } from '../../selector/SelectorComponents';
import { TableBody, TableHead, TableRow } from '@mui/material';
import {
    ConnectorSelectorTableItem,
    StyledIconCell,
    StyledShortCell,
    StyledTextCell,
} from './ConnectionSelectorTableItem';

interface ConnectionSelectorProps {
    onCreateNew(): void;
    onDelete(connectionUid: string): void;
    onSelect(connectionUid: string): void;
    connections?: {
        authorized?: boolean;
        description?: string;
        title: string;
        type: string;
        uid: string;
    }[];
}

export const ConnectionSelector: React.FC<ConnectionSelectorProps> = ({
    onCreateNew,
    onDelete,
    onSelect,
    connections = [],
}) => {
    const [view, setView] = useState<'grid' | 'list'>(readLocalStorage('connectorsView', 'grid'));

    const handleViewChange = (view: 'grid' | 'list'): void => {
        setView(view);
        saveLocalStorage('connectorsView', view);
    };

    const items = connections.map((connection) => {
        if (view === 'grid') {
            return (
                <AppCard
                    key={connection.uid}
                    authorized={connection.authorized}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    type={connection.type}
                    title={connection.title}
                    uid={connection.uid}
                />
            );
        } else if (view === 'list') {
            return (
                <ConnectorSelectorTableItem
                    key={connection.uid}
                    authorized={connection.authorized}
                    onDelete={onDelete}
                    onSelect={onSelect}
                    type={connection.type}
                    title={connection.title}
                    uid={connection.uid}
                />
            );
        }
    });

    const tableTitles = (
        <TableRow>
            <StyledTextCell>Title</StyledTextCell>
            <StyledIconCell>App</StyledIconCell>
            <StyledIconCell>Authorized</StyledIconCell>
            <StyledShortCell sx={{ textAlign: 'end' }}>Actions</StyledShortCell>
        </TableRow>
    );

    const createLabel = (): string => {
        const currentResultsLength = connections.length ?? 0;
        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };

    return (
        <PageContainer>
            <PageUpperContainer>
                <PageTitle title="Connectors" label={createLabel()} />
                {items.length > 0 && (
                    <PageUpperGroup>
                        <PageViewButtonGroup>
                            <SelectorViewButton
                                icon="grid"
                                onClick={() => {
                                    if (view === 'list') {
                                        handleViewChange('grid');
                                    }
                                }}
                                sx={(theme) => {
                                    return view === 'grid' ? viewSelectedStyle(theme) : null;
                                }}
                            />
                            <SelectorViewButton
                                icon="list"
                                onClick={() => {
                                    if (view === 'grid') {
                                        handleViewChange('list');
                                    }
                                }}
                                sx={(theme) => {
                                    return view === 'list' ? viewSelectedStyle(theme) : null;
                                }}
                            />
                        </PageViewButtonGroup>
                        <Button onClick={onCreateNew} startIcon={<AddIcon />}>
                            Create New
                        </Button>
                    </PageUpperGroup>
                )}
            </PageUpperContainer>
            {view === 'grid' && items.length > 0 && <PageGridContainer>{items}</PageGridContainer>}
            {view === 'list' && items.length > 0 && (
                <SelectorTable>
                    <TableHead>{tableTitles}</TableHead>
                    <TableBody className="padme">{items}</TableBody>
                </SelectorTable>
            )}
            {items.length === 0 && (
                <EmptyStateContainer>
                    <EmptyStateImg src={process.env.PUBLIC_URL + '/wizard-wave-slow.gif'} alt="Hello" />
                    <PageSubTitle variant="h6">Add your first Connector</PageSubTitle>
                    <EmptyStateText>
                        A <strong>Connector</strong> is a link between ScriptRunner Connect and a third-party service
                        that ScriptRunner Connect manages on your behalf.
                    </EmptyStateText>
                    <StyledActionButton startIcon={<AddIcon />} onClick={onCreateNew}>
                        Add new connector
                    </StyledActionButton>
                    <EmptyStateText>Not sure where to start?</EmptyStateText>
                    <Button
                        variant="outlined"
                        onClick={() => window.open('https://docs.adaptavist.com/src/connectors', '_blank')}
                    >
                        View Documentation
                    </Button>
                </EmptyStateContainer>
            )}
        </PageContainer>
    );
};

import { useObservableState } from 'observable-hooks';
import { NewWorkspaceDialog } from '../../components/workspace/NewWorkspaceDialog';
import { featureFlagsTopic$ } from '../../store/config';
import { loggedInUserDetails$ } from '../../store/user';
import {
    cancelEditWorkspaceAction$,
    workspaceBeingEditted$,
    editWorkspaceDialogOpen$,
    openWorkspacePreviewDialogErrors$,
    savingExistingWorkspace$,
    updateWorkspaceDetailsAction$,
    loadingWorkspaceFormDetails$,
    workspaceFormDetails$,
} from '../../store/workspaces';

export const EditWorkspaceDialogContainer: React.FC = () => {
    const editWorkspaceDialogOpen = useObservableState(editWorkspaceDialogOpen$);
    const workspace = useObservableState(workspaceBeingEditted$);
    const savingExistingWorkspace = useObservableState(savingExistingWorkspace$);
    const openWorkspacePreviewDialogErrors = useObservableState(openWorkspacePreviewDialogErrors$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const loadingWorkspaceFormDetails = useObservableState(loadingWorkspaceFormDetails$);
    const workspaceFormDetails = useObservableState(workspaceFormDetails$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    const templateOption =
        !!loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates && !workspace?.createdFromTemplate;

    return (
        <NewWorkspaceDialog
            name={workspace?.name ?? ''}
            description={workspace?.description}
            open={editWorkspaceDialogOpen}
            saving={savingExistingWorkspace}
            errors={openWorkspacePreviewDialogErrors}
            onCancel={() => cancelEditWorkspaceAction$.next()}
            templateSelectorDisabled={!templateOption}
            isTemplate={workspace?.template}
            organizations={workspaceFormDetails?.organizations}
            selectedOrganizationUid={workspaceFormDetails?.selectedOrganizationUid}
            organizationSelectorDisabled={
                featureFlags.organizations && workspaceFormDetails?.organizationDropdownDisabled
            }
            defaultOrganizationUid={workspaceFormDetails?.defaultOrganizationUid ?? ''}
            editMode={true}
            isWorkspaceOwner={workspaceFormDetails?.isWorkspaceOwner}
            onSave={(details) => {
                const wasTemplate = workspace?.template;
                const isTemplate = !!details.template ?? false;
                updateWorkspaceDetailsAction$.next({
                    uid: workspace?.uid ?? '',
                    name: details.name,
                    description: details.description,
                    template: details.template ?? false,
                    newTemplate: !wasTemplate && isTemplate,
                    organizationUid: details.selectedOrganizationUid ?? '',
                });
            }}
            loading={loadingWorkspaceFormDetails}
        />
    );
};

import { FeedbackEvent } from '@avst-stitch/feedback-lib';
import { useObservableState } from 'observable-hooks';
import { Console } from '../../components/workspace/Console';
import { getJSXElementForFeedbackEvent } from '../../feedback';
import { selectedEnvironmentUid$, selectedWorkspaceUid$ } from '../../store/workspace';
import {
    changeLiveFeedbackSwitchAction$,
    changeFilterByEnvironmentSwitchAction$,
    clearWorkspaceFeedbackAction$,
    displayLiveFeedback$,
    filterByEnvironment$,
    filteredFeedback$,
} from '../../store/workspace/feedback';
import { getFilterKey } from '../../utils/feedback';

export const ConsoleContainer: React.FC = () => {
    const feedback = useObservableState(filteredFeedback$);
    const displayLiveFeedback = useObservableState(displayLiveFeedback$);
    const filterByEnvironment = useObservableState(filterByEnvironment$);

    const getWorkspaceFeedback = (workspaceUid: string): FeedbackEvent<Record<string, unknown>>[] => {
        const workspaceFeedback: FeedbackEvent<Record<string, unknown>>[] = [];

        for (const key of Object.keys(feedback)) {
            if (key.startsWith(workspaceUid) && feedback[key]) {
                workspaceFeedback.push(...(feedback[key] ?? []));
            }
        }

        return workspaceFeedback;
    };

    const filteredFeedback = (
        filterByEnvironment
            ? feedback[getFilterKey(selectedWorkspaceUid$.value ?? '', selectedEnvironmentUid$.value ?? '')] ?? []
            : getWorkspaceFeedback(selectedWorkspaceUid$.value ?? '')
    )
        .filter((e) => displayLiveFeedback || e.triggerType === 'MANUAL' || !e.invocationId)
        .sort((a, b) => a.time - b.time);

    return (
        <Console
            logs={filteredFeedback.map((f) => ({
                level: f.level,
                message: getJSXElementForFeedbackEvent(f),
            }))}
            filterByEnvironment={filterByEnvironment}
            onClear={() => clearWorkspaceFeedbackAction$.next()}
            onChangeLiveFeedbackSwitch={(enabled) => changeLiveFeedbackSwitchAction$.next(enabled)}
            onChangeFilterByEnvironment={(enabled) => changeFilterByEnvironmentSwitchAction$.next(enabled)}
        />
    );
};

import { LoaderFn } from 'react-location';
import { getOrganizationBillingEntities, getOrganizationPlan } from '../../data/organization';
import { LocationGenerics } from '../../router';
import { organizationBillingDetails$, selectedOrganizationPlan$ } from '../../store/organization';
import { changeOrganizationPlanValidationError$ } from '../../store/organization/changeOrganizationPlan';
import { loadLoggedInUserOrganizations } from '../../store/organizations';
import { loadErrorPage } from '../../store/error';

export const organizationPlanLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const orgUid = routeMatch.params.teamUid!;

    try {
        const [plan, billingEntities] = await Promise.all([
            getOrganizationPlan(orgUid),
            getOrganizationBillingEntities(orgUid),
            loadLoggedInUserOrganizations(),
        ]);

        selectedOrganizationPlan$.next(plan);
        organizationBillingDetails$.next(billingEntities);
        changeOrganizationPlanValidationError$.next(undefined);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load team plan.',
        });

        throw e;
    }

    return {};
};

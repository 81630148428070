import { useObservableState } from 'observable-hooks';
import { OrganizationPlanSuccessStateDialog } from '../../components/organization/OrganizationPlanSuccessStateDialog';
import {
    organizationPlanSuccessStateDialogOpen$,
    organizationPlanSuccessStateContent$,
    closeOrganizationPlanSuccessStateDialogAction$,
} from '../../store/organization/changeOrganizationPlan';

export const OrganizationPlanSuccessStateContainer: React.FC = () => {
    const organizationPlanSuccessStateDialogOpen = useObservableState(organizationPlanSuccessStateDialogOpen$);
    const organizationPlanSuccessStateContent = useObservableState(organizationPlanSuccessStateContent$);

    return (
        <OrganizationPlanSuccessStateDialog
            open={organizationPlanSuccessStateDialogOpen}
            successState={organizationPlanSuccessStateContent?.state ?? 'create'}
            onCancel={() => closeOrganizationPlanSuccessStateDialogAction$.next()}
            tier={organizationPlanSuccessStateContent?.tier ?? ''}
            lastTier={organizationPlanSuccessStateContent?.lastTier}
        />
    );
};

import { bundle } from '../bundler/bundle';
import { BundledFile } from '../bundler/types';
import { compile } from '../monaco/ts-service/compile';
import { CompiledOutput, MonacoScript } from '../monaco/ts-service/types';
import { configTopic$, featureFlagsTopic$, stitchSession$ } from '../store/config';
import { activeDependencies$, updateEditorDependenciesAction$ } from '../store/editor/editor';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../store/workspace';
import { BundlingError } from './repository';
import { getUnsavedScriptWithUidCached } from './script';
import { ScriptWithUid } from './types';
import { getFetchOptions } from './fetch';
import { SESSION_ID } from '..';

interface GetBundledOutputProps {
    scriptUid?: string;
    manual?: boolean;
    externallyTriggerableScriptUids?: string[];
    publishCompileError?: boolean;
}

export const getBundledOutputsFromSelectedWorkspace = async (
    props?: GetBundledOutputProps
): Promise<void | BundledFile[]> => {
    const scriptUids = selectedWorkspaceResources$.value.scripts.map((script) => script.uid);
    const newScriptUid = props?.scriptUid && !scriptUids.includes(props.scriptUid) ? [props.scriptUid] : [];

    const scripts = await Promise.all([...scriptUids, ...newScriptUid].map(getUnsavedScriptWithUidCached));
    return await compileAndBundle(scripts, props);
};

const compileAndBundle = async (
    scripts: ScriptWithUid[],
    props?: GetBundledOutputProps
): Promise<void | BundledFile[]> => {
    const compiledOutput = await compile({ scripts, publishCompileError: props?.publishCompileError });
    const monacoScripts = compiledOutput
        .map((output) => ({ uid: output.uid, name: output.name, uri: output.uri }))
        .filter((output): output is MonacoScript => !!output.uid);

    updateEditorDependenciesAction$.next({ scripts: monacoScripts });

    return getBundledOutput(compiledOutput, props);
};

const getBundledOutput = async (
    compiledOutput: CompiledOutput[],
    props?: GetBundledOutputProps
): Promise<void | BundledFile[]> => {
    try {
        const workspaceUid = selectedWorkspaceUid$.value;
        const bundleWithUnoptimizedSkypackImports = featureFlagsTopic$.value.bundleWithUnoptimizedSkypackImports;
        const bundleWithEsbuild = featureFlagsTopic$.value.bundleWithEsbuild;
        const dependencies = activeDependencies$.value;

        if (bundleWithEsbuild && workspaceUid) {
            const compiledFiles = compiledOutput.map((compiledScript) => {
                const codeFile = compiledScript.compiled.files.find((f) => f.name.endsWith('.js'));

                if (!codeFile) {
                    throw Error(
                        `Code file was not found: ${compiledScript.compiled.files.map((f) => f.name).join(', ')}`
                    );
                }

                const name = compiledScript.name.replace(/^\//, '').replace(/\.ts$/, '');

                return {
                    code: codeFile.text,
                    name,
                    uid: compiledScript.uid,
                };
            });

            await bundleInBackend({
                workspaceUid,
                dependencies,
                compiledFiles,
                scriptUid: props?.scriptUid,
                externallyTriggerableScriptUids: props?.externallyTriggerableScriptUids,
                manual: props?.manual,
            });
        } else {
            return await bundle({ compiledOutput, dependencies, bundleWithUnoptimizedSkypackImports });
        }
    } catch (e) {
        const errorMessage = (e as Error).message ?? (e as object).toString();
        const message = errorMessage
            ? `Bundling error: ${errorMessage}`
            : "Bundling failed, check your scripts for syntax errors, if you can't figure it out feel free to contact support.";

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message,
            noToast: true,
        });

        throw new BundlingError(message);
    }
};

interface BundleRequest {
    workspaceUid: string;
    dependencies: Record<string, string>;
    compiledFiles: { name: string; code: string; uid?: string }[];
    scriptUid?: string;
    manual?: boolean;
    externallyTriggerableScriptUids?: string[];
}

export const bundleInBackend = async (request: BundleRequest): Promise<void> => {
    const baseUrl = configTopic$.value.bundle?.baseUrl;
    if (!baseUrl) {
        throw new Error('No bundle url configured in meta');
    }

    const bundleUrl = `${baseUrl}/bundle`;
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );

    const response = await fetch(bundleUrl, fetchOptions);
    if (!response.ok) {
        const errorResponse = await response.text();
        try {
            const parsedError = JSON.parse(errorResponse);
            if ('code' in parsedError && 'error' in parsedError && typeof parsedError.error === 'string') {
                throw parsedError.error;
            } else {
                throw errorResponse;
            }
        } catch (errorMessage) {
            if (errorMessage && typeof errorMessage === 'string') {
                throw new Error(errorMessage);
            } else {
                throw new Error(`Unexpected error while using bundling service: ${response.status}`);
            }
        }
    }
};

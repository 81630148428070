import { useObservableState } from 'observable-hooks';
import { ConnectAndSupportModal } from '../components/connect-and-support';
import { closeConnectAndSupportModalAction$, connectAndSupportModalOpen$ } from '../store/connectAndSupport';
import {
    documentationUrl,
    feedbackBoardUrl,
    slackCommunityUrl,
    supportPortalUrl,
    // youtubeChannelUrl,
} from '../utils/documentation';

export const ConnectAndSupportContainer: React.FC = () => {
    const open = useObservableState(connectAndSupportModalOpen$);
    return (
        <ConnectAndSupportModal
            open={open}
            onClose={() => closeConnectAndSupportModalAction$.next()}
            content={[
                {
                    title: 'Slack Community',
                    description: 'Stay up to date with the latest announcements, and benefit from premium support.',
                    link: slackCommunityUrl,
                    linkText: 'Join Slack',
                },
                {
                    title: 'Feedback Board',
                    description:
                        'Help us identify what we should build next. Vote on existing ideas or suggest new ones!',
                    link: feedbackBoardUrl,
                    linkText: 'See Nolt',
                },
                {
                    title: 'Support Portal',
                    description: "Report issues, make suggestions, and more. Whatever you need, we're here to help!",
                    link: supportPortalUrl,
                    linkText: 'Contact Us',
                },
                {
                    title: 'ScriptRunner Connect Product Documentation',
                    description: 'A growing collection of technical and how to docs to help you use the app.',
                    link: documentationUrl,
                    linkText: 'Visit Documentation',
                },
                // {
                //     title: 'YouTube Tutorials',
                //     description: 'If you prefer more visual guides, we have great videos on our Youtube library.',
                //     link: youtubeChannelUrl,
                //     linkText: 'Visit Youtube Playlist',
                // },
            ]}
        />
    );
};

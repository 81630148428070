/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FeedbackRenderer } from './types';
import { getMessageForArgs } from '@avst-stitch/invocation-lib/runtime';
import { Log } from '@avst-stitch/invocation-lib/types/Log';
import { ExecutionFinished } from '@avst-stitch/invocation-lib/types/ExecutionFinished';
import { InvocationScheduled } from '@avst-stitch/invocation-lib/types/InvocationScheduled';
import React from 'react';
import { Link } from '@mui/material';
import { abortInvocation } from '../utils/trigger';
import { wrapAsync } from '../utils/react';
import { getBasePath } from '../utils/path';

const TAB = '\u00A0\u00A0\u00A0\u00A0';

const RUNTIME_INVOCATION_SCHEDULED_FEEDBACK_RENDERER: FeedbackRenderer<InvocationScheduled> = {
    type: '@avst-stitch/runtime-events/InvocationScheduled',
    getJSXElement: (event) => {
        return (
            <span>
                {event.message && <FormattedMessage message={event.message} />}
                {event.payload?.abortInvocationUrlPath && (
                    <>
                        <br />
                        <strong>
                            <Link onClick={wrapAsync(() => abortInvocation(event.payload?.abortInvocationUrlPath!))}>
                                Abort Invocation
                            </Link>
                        </strong>
                    </>
                )}
            </span>
        );
    },
};

const RUNTIME_EXECUTION_FINISHED_RENDERER: FeedbackRenderer<ExecutionFinished> = {
    type: '@avst-stitch/runtime-events/ExecutionFinished',
    getJSXElement: (event) => {
        return (
            <div>
                <span>Script Execution Finished</span>
                {(event.payload?.time ?? 0) > 0 && (
                    <div>
                        Execution duration: <strong>{event.payload?.time}ms</strong>
                    </div>
                )}
                {event.message && <div>{event.message}</div>}
                <div>
                    Invocation ID: <strong>{event.invocationId}</strong>
                </div>
                {event.payload?.httpLogsUrl && (
                    <Link href={`${getBasePath()}${event.payload.httpLogsUrl}`} target="_blank">
                        View HTTP logs
                    </Link>
                )}
            </div>
        );
    },
};

const RUNTIME_FEEDBACK_RENDERER: FeedbackRenderer<Log> = {
    type: '@avst-stitch/runtime-events/Log',
    getJSXElement: (event) => {
        const message = getMessageForArgs(event.payload?.args ?? []);
        return (
            <span>
                {event.payload?.payloadUrl ? (
                    <Link href={`${getBasePath()}${event.payload.payloadUrl}`} target="_blank">
                        Message is too large to be displayed, click here to view.
                    </Link>
                ) : event.payload?.type === 'SYSTEM_LOG' ? (
                    <strong>
                        <FormattedMessage message={message} />
                    </strong>
                ) : (
                    <FormattedMessage message={message} />
                )}
            </span>
        );
    },
};

export const FEEDBACK_RENDERERS: FeedbackRenderer[] = [
    RUNTIME_INVOCATION_SCHEDULED_FEEDBACK_RENDERER,
    RUNTIME_EXECUTION_FINISHED_RENDERER,
    RUNTIME_FEEDBACK_RENDERER,
];

export const FormattedMessage: React.FC<{ message: string }> = ({ message }) => {
    const lines = message?.replace(/\t/g, TAB).split('\n') ?? [];
    const last = lines.length - 1;
    return (
        <>
            {lines.map((line, i) => (
                <React.Fragment key={i}>
                    {line}
                    {i !== last ? <br /> : null}
                </React.Fragment>
            ))}
        </>
    );
};

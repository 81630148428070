import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import {
    SalesforceEventListenerWizardStage,
    SalesforceEventListenerWizardSteps,
} from './SalesforceEventListenerWizardSteps';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { useEffect, useState } from 'react';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface SalesforceEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const SalesforceEventListenerSetupDialog: React.FC<SalesforceEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const [stage, setStage] = useState(SalesforceEventListenerWizardStage.CREATE_OUTBOUND_MESSAGE);

    useEffect(() => {
        setStage(SalesforceEventListenerWizardStage.CREATE_OUTBOUND_MESSAGE);
    }, [open]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getStageContent = (): JSX.Element => {
        const lightningUrl = (
            setupBaseUrl && setupBaseUrl.endsWith('/')
                ? setupBaseUrl.substring(0, setupBaseUrl.length - 1)
                : setupBaseUrl ?? ''
        ).replace('.my.salesforce.com', '.lightning.force.com');
        switch (stage) {
            case SalesforceEventListenerWizardStage.CREATE_OUTBOUND_MESSAGE:
                return (
                    <Dialog
                        maxWidth="md"
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(SalesforceEventListenerWizardStage.CREATE_AUTOMATED_FLOW),
                                escFn: onClose,
                            })
                        }
                    >
                        <SalesforceEventListenerWizardSteps stage={stage} />
                        <DialogTitle variant="h6">Event Listener setup instructions for Salesforce</DialogTitle>
                        {loading ? (
                            <AbsoluteCenteredLoadingSpinner />
                        ) : (
                            <>
                                <DialogContent>
                                    <DialogContentText variant="subtitle2">
                                        You need to setup a webhook in your Salesforce instance in order to listen to
                                        events in ScriptRunner Connect.
                                    </DialogContentText>
                                    <ReadOnlyTextField
                                        label="Webhook URL"
                                        value={`${webhookBaseUrl}/${webhookUrlId}`}
                                    />
                                    <DialogContentText component="ol">
                                        <li>Copy the URL above.</li>
                                        {setupBaseUrl && (
                                            <li>
                                                Visit your{' '}
                                                <Link
                                                    target="_blank"
                                                    href={`${lightningUrl}/lightning/setup/WorkflowOutboundMessaging/home`}
                                                >
                                                    Outbound Messages
                                                </Link>{' '}
                                                page.
                                            </li>
                                        )}
                                        {!setupBaseUrl && (
                                            <li>
                                                Open
                                                https://[LIGHTNING_SALESFORCE_INSTANCE]/lightning/setup/WorkflowOutboundMessaging/home
                                                URL in your browser
                                            </li>
                                        )}
                                        <li>
                                            Click <b>New Outbound Message</b>.
                                        </li>
                                        <li>
                                            Select the object that has the fields you want to be included in your
                                            message and click <b>Next</b>.
                                        </li>
                                        <li>Give your message a meaningful name.</li>
                                        <li>
                                            Paste the copied URL into the <b>Endpoint URL</b> field.
                                        </li>
                                        <li>
                                            Under <b>fields to send</b> add fields that you want to include in the
                                            event.
                                        </li>
                                        <li>
                                            Click <b>Save</b>.
                                        </li>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="outlined" onClick={() => onClose()}>
                                        Cancel
                                    </Button>

                                    <Button
                                        onClick={() =>
                                            setStage(SalesforceEventListenerWizardStage.CREATE_AUTOMATED_FLOW)
                                        }
                                    >
                                        Next
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                );

            case SalesforceEventListenerWizardStage.CREATE_AUTOMATED_FLOW:
                return (
                    <Dialog open={open} onKeyUp={(event) => handleKeyUp({ event, enterFn: onClose, escFn: onClose })}>
                        <SalesforceEventListenerWizardSteps stage={stage} />
                        <DialogTitle variant="h4">Event Listener setup instructions for Salesforce</DialogTitle>
                        <DialogContent>
                            <DialogContentText component="ol" start={9}>
                                {setupBaseUrl && (
                                    <li>
                                        Visit your{' '}
                                        <Link target="_blank" href={`${lightningUrl}/lightning/setup/Flows/home`}>
                                            Flows
                                        </Link>{' '}
                                        page.
                                    </li>
                                )}
                                {!setupBaseUrl && (
                                    <li>
                                        Open https://[LIGHTNING_SALESFORCE_INSTANCE]/lightning/setup/Flows/home URL in
                                        your browser
                                    </li>
                                )}
                                <li>
                                    Click <b>New Flow</b>.
                                </li>
                                <li>
                                    Select <b>Record-Triggered Flow</b> and click <b>Create</b>.
                                </li>
                                <li>
                                    Select the object whose records trigger the flow when they're created, updated, or
                                    deleted.
                                </li>
                                <li>Configure the trigger.</li>
                                <li>Set Entry Conditions are optional.</li>
                                <li>
                                    Under <b>Optimize the Flow for</b> select <b>Actions and Related Records</b>.
                                </li>
                                <li>
                                    Click <b>Done</b>.
                                </li>
                                <li>
                                    Click <b>+</b>(plus) icon under <b>Run immediately</b> label.
                                </li>
                                <li>
                                    Select <b>Action</b>, then select <b>Outbound Message</b> from the list of actions
                                    to the left.
                                </li>
                                <li>
                                    Click on Action search field and select newly created outbound message from the
                                    dropdown. Give it meaningful Label name and click <b>Done</b>.
                                </li>
                                <li>
                                    Click <b>Save</b> on the top right. Give it meaningful Label name and click{' '}
                                    <b>Save</b>.
                                </li>
                                <li>
                                    Click <b>Activate</b>.
                                </li>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(SalesforceEventListenerWizardStage.CREATE_OUTBOUND_MESSAGE)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => onClose()}>Done</Button>
                        </DialogActions>
                    </Dialog>
                );
        }
    };
    return getStageContent();
};

import { Observable, Subject } from 'rxjs';

interface ObservableEvent {
    readonly action: string;
    readonly value?: unknown;
    readonly type: 'next' | 'error' | 'complete';
}

export const eventEmittedAction$ = new Subject<ObservableEvent>();

export const monitor = <T extends Observable<unknown>>(action: string, observable: T): T => {
    observable.subscribe({
        next: (value) =>
            eventEmittedAction$.next({
                action,
                value,
                type: 'next',
            }),
        error: (value) =>
            eventEmittedAction$.next({
                action,
                value,
                type: 'error',
            }),
        complete: () =>
            eventEmittedAction$.next({
                action,
                type: 'complete',
            }),
    });

    return observable;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(global as any).setupStoreConsole = (): void => {
    eventEmittedAction$.subscribe(({ action, type, value }) => console.log(`${action} ${type}`, value));
};

const setupStoreDevTools = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        console.warn('Redux Dev Tools not available');
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__.connect({
        features: {
            pause: true, // start/pause recording of dispatched actions
            lock: false, // lock/unlock dispatching actions and side effects
            persist: false, // persist states on page reloading
            export: false, // export history of actions in a file
            import: false, // import history of actions from a file
            jump: false, // jump back and forth (time travelling)
            skip: false, // skip (cancel) actions
            reorder: false, // drag and drop actions in the history list
            dispatch: false, // dispatch custom actions or action creators
            test: false, // generate tests for the selected actions
        },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    devTools.subscribe((message: any) => {
        if (message?.type === 'DISPATCH' && message.payload?.type === 'COMMIT') {
            devTools.init({});
        }
    });

    let appState: Record<string, unknown>;

    eventEmittedAction$.subscribe(({ action, type, value }) => {
        const actionName = `${action} ${type}`;

        switch (type) {
            case 'next':
            case 'error':
                appState = {
                    ...appState,
                    [action]: value,
                };
                break;
            case 'complete':
                break;
        }

        devTools.send({ type: `${actionName}` }, appState[action]);
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(global as any).setupStoreDevTools = setupStoreDevTools;

if (
    process.env.NODE_ENV === 'development' ||
    window.location.hostname.startsWith('stg') ||
    window.location.hostname.includes('staff.adaptavist.com')
) {
    console.debug('Setting up dev tools');
    setupStoreDevTools();
}

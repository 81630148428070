import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface ConnectionModalTextFieldProps {
    value?: string;
    onUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    disabled?: boolean;
}

const StyledTextFieldDiv = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    width: 340,
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

export const ConnectionModalTextField: React.FC<ConnectionModalTextFieldProps> = ({ value, onUpdate, label }) => {
    return (
        <StyledTextFieldDiv>
            <TextField
                fullWidth
                variant="outlined"
                value={value}
                onChange={onUpdate}
                label={label}
                placeholder={label}
            />
            {value !== '' && <StyledCheckIcon color="success" />}
        </StyledTextFieldDiv>
    );
};

export const ConnectionModalSecretTextField: React.FC<ConnectionModalTextFieldProps> = ({
    value,
    onUpdate,
    label,
    disabled = false,
}) => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = (): void => {
        setVisible(!visible);
    };

    const preventDefault = (event: React.MouseEvent): void => {
        event.preventDefault();
    };
    return (
        <StyledTextFieldDiv>
            <TextField
                disabled={disabled}
                fullWidth
                variant="outlined"
                value={value}
                onChange={onUpdate}
                label={label}
                placeholder={label}
                // {...props}
                type={visible ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip
                                title={visible ? 'Hide secret' : 'Show secret'}
                                aria-label={visible ? 'hide' : 'show'}
                            >
                                <IconButton
                                    aria-label="toggle visibility"
                                    onClick={toggleVisibility}
                                    onMouseDown={preventDefault}
                                    size="large"
                                >
                                    {visible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
            />
            {value !== '' && <StyledCheckIcon color="success" />}
        </StyledTextFieldDiv>
    );
};

import { useObservableState } from 'observable-hooks';
import { useNavigate, useSearch } from 'react-location';
import { Reporting } from '../components/reporting';
import { ReportingFilters } from '../components/reporting/ReportingPageFilters';
import {
    invocations$,
    invocationsReportLoading$,
    invocationsReportErrors$,
    queryInvocationsAction$,
    searchInvocationsAction$,
} from '../store/report/invocations';

export const ReportingContainer: React.FC = () => {
    const navigate = useNavigate();
    const search = useSearch();
    const invocations = useObservableState(invocations$);
    const isLoading = useObservableState(invocationsReportLoading$);
    const errors = useObservableState(invocationsReportErrors$);

    return (
        <Reporting
            invocations={invocations.invocations}
            nextToken={invocations.nextToken}
            workspaces={invocations.workspaces}
            isLoading={isLoading}
            errors={errors}
            filters={
                {
                    invocationId: search.invocationId,
                    invocationIdComparator: search.invocationIdComparator,
                    workspaces: search.workspaces,
                    organizations: search.organizations,
                    environment: search.environment,
                    environmentComparator: search.environmentComparator,
                    script: search.script,
                    scriptComparator: search.scriptComparator,
                    executionStatuses: search.executionStatuses,
                    triggerTypes: search.triggerTypes,
                    duration: search.duration,
                    durationComparator: search.durationComparator,
                    logCount: search.logCount,
                    logCountComparator: search.logCountComparator,
                    httpLogCount: search.httpLogCount,
                    httpLogCountComparator: search.httpLogCountComparator,
                    from: search.from,
                    to: search.to,
                    orderByField: search.orderByField,
                    orderByDirection: search.orderByDirection,
                } as ReportingFilters
            }
            onQueryInvocations={(request) => queryInvocationsAction$.next(request)}
            onSearchInvocations={(request) => searchInvocationsAction$.next(request)}
            onSearch={(request) => {
                navigate({
                    search: {
                        ...request,
                    },
                });
            }}
        />
    );
};

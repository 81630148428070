// ** using constants file while we migrate to Figma for design tokens ** //
// only import these into horizonTheme.ts - not for direct import //

// brand colours - accessible version
export const SRBrand = '#ea0049';
export const SRBrandContrast = '#ffffff';

// Light Theme (lt) theme constants
const LightTextDisabled = 'rgba(0, 0, 0, 0.38)';
const LightTextSecondary = 'rgba(0, 0, 0, 0.60)';
const LightTextPrimary = 'rgba(0, 0, 0, 0.87)';

// Dark Theme (Dt) theme constants
const DarkTextPrimary = '#ffffff';
const DarkTextSecondary = 'rgba(255, 255, 255, 0.7)';
const DarkTextDisabled = 'rgba(255, 255, 255, 0.5)';

// if a colour is not stated it is using the default MUI shade or being calculated by the theme
// e.g. primary, secondary and some status colours - light, dark and contrastText are set by tonalOffset and contrastThreshold

// Light Theme (Lt) theme colours
export const LtBackgroundDefault = '#fafbff';
export const LtBackgroundPaper = '#ffffff'; // do not set to contrast text
export const LtPrimaryMain = '#3747D3';
export const LtSecondaryMain = '#64298B';
export const LtTextSecondary = LightTextSecondary;
export const LtTextDisabled = LightTextDisabled;
export const LtActionActive = 'rgba(0, 0, 0, 0.54)';
export const LtErrorMain = '#d32f2f';
export const LtInfoContrastText = DarkTextPrimary;
export const LtInfoMain = '#0288d1';
export const LtSuccessContrastText = DarkTextPrimary;
export const LtSuccessMain = '#2e7d32';
export const LtWarningContrastText = LightTextPrimary;
export const LtWarningMain = '#ed6c02';
// Stitch light custom colours
export const LtSetupLight = 'rgb(133, 97, 197)';
export const LtSetupMain = '#673AB7';
export const LtSetupDark = 'rgb(72, 40, 128)';
export const LtSetupContrastText = DarkTextPrimary;
export const LtKnowledgeLight = '#ECF3F4';
export const LtKnowledgeMain = '#D6EBEA';
export const LtKnowledgeDark = '#80CBC4';
export const LtKnowledgeContrastText = LightTextPrimary;
export const LtConnectionsLight = '#E6DBE8';
export const LtConnectionsMain = '#DBCADE';
export const LtConnectionsDark = '#B497BC';
export const LtConnectionsContrastText = LightTextPrimary;

// Dark Theme (Dt) theme colours
export const DtBackgroundDefault = '#37474F';
export const DtBackgroundPaper = '#263238'; // do not set to contrast text
// export const DtBackgroundPaper = '#121212'; // do not set to contrast text
export const DtPrimaryMain = '#90caf9'; // only set here for use by constants
export const DtTextPrimary = DarkTextPrimary;
export const DtTextSecondary = DarkTextSecondary;
export const DtTextDisabled = DarkTextDisabled;
// Stitch dark custom colours
//
// font properties
export const FontFamilyBase =
    'Helvetica Neue, -apple-system, BlinkMacSystemFont, Arial, Roboto, Ubuntu, system-ui, sans-serif';
export const FontSizeSmall = '12';
export const FontSizeBody = '14';
export const FontSizeBase = '16';
export const FontSizeH6 = '18';
export const FontSizeH5 = '20';
export const FontSizeH4 = '23';
export const FontSizeH3 = '26';
export const FontSizeH2 = '29';
export const FontSizeH1 = '32';
export const FontSizeXlarge = '42';
export const FontSizeXxlarge = '48';
// export const FontSizeLarge = '26';
// export const FontSizeH1 = '30';
// export const FontSizeH2 = '24';
// export const FontSizeH3 = '20';
// export const FontSizeH4 = '16';

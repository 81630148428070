import { styled, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';

export const StyledCronGenerator = styled('div')(() => ({}));

const bgStyles = (theme: Theme) =>
    ({
        //backgroundColor: theme.palette.action.selected,
        backgroundColor: theme.palette.background.default,
        opacity: 1,
    } as const);

//Replicate Bootstrap styles with a couple of adjustments from figma. Theme not applicable besides margins.
export const StyledCronWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    minWidth: 600,
    '& .cron_builder': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.constants.borderRadius,
        padding: 0,
        '& .nav-tabs': {
            display: 'flex',
            justifyContent: 'flex-start',
            listStyle: 'none',
            margin: theme.spacing(1, 0, 0),
            padding: 0,
            position: 'relative',
            top: 1,
            '& .nav-item': {
                fontWeight: theme.typography.fontWeightBold,
                '& .nav-link': {
                    borderTopLeftRadius: theme.constants.borderRadius,
                    borderTopRightRadius: theme.constants.borderRadius,
                    color: theme.palette.text.secondary,
                    display: 'inline-block',
                    padding: theme.spacing(1, 1, 0.5, 1),
                    position: 'relative',
                    width: '100%',
                    '&:hover': {
                        // color: theme.palette.primary.main,
                        backgroundColor: theme.palette.action.hover,
                    },
                    '&.active': {
                        // color: theme.palette.text.primary,
                        ...bgStyles(theme),
                        border: '1px solid ' + theme.palette.action.selected,
                        borderBottom: theme.constants.borderHidden,
                    },
                },
            },
        },
        '& .cron_builder_bordering': {
            ...bgStyles(theme),
            border: '0 none',
            borderTop: '1px solid ' + theme.palette.action.selected,
            borderRadius: theme.constants.borderRadius,
            padding: theme.spacing(3, 2, 1),
            textAlign: 'left',
        },
        '& .container-fluid .row': {
            display: 'flex',
            '& .col-sm-6': {
                flex: '0 0 50%',
            },
        },
        '&.cron_builder': {
            '& .tab-content, .well, .tab-pane.active': {
                ...bgStyles(theme),
                border: '0 none',
                boxShadow: 'unset',
            },
            //hiding the two last options of the 'Monthly' tab
            '& .tab-pane > .well-small:nth-child(3), & .well-small:nth-child(4)': {
                display: 'none',
            },
        },
    },
}));

export const StyledDetails = styled('div')(() => ({
    width: 600,
}));

export const StyledExpression = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    margin: theme.spacing(1, 0, 2, 1),
}));

export const StyledScheduledDate = styled('div')(({ theme }) => ({
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    height: 24,
    padding: theme.spacing(0.5),
    width: 356,
    '& .MuiSvgIcon-root': {
        height: 15,
        marginRight: theme.spacing(0.5),
        width: 15,
    },
}));

export const StyledScheduledDatesSection = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(8),
}));

export const StyledScheduledTriggerDetails = styled('div')(() => ({
    display: 'flex',
}));

export const StyledScheduledDatesTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(1, 0),
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
}));

import { filter, map, Observable, withLatestFrom } from 'rxjs';
import {
    loadingWorkspaceEnvironments$,
    loadingWorkspaceResources$,
    selectedEnvironmentUid$,
    selectedWorkspaceDeployments$,
    selectedWorkspaceEnvironments$,
    selectedWorkspaceReadOnlyMode$,
    selectedWorkspaceResources$,
    selectedWorkspaceUid$,
} from '.';
import { getWorkspaceDeployments } from '../../data/deployment';
import { getWorkspacePackages } from '../../data/package-manager';
import { getReadmeFile, ReadmeFile } from '../../data/readme';
import { getWorkspaceEnvironments, getWorkspaceResources } from '../../data/workspace';
import { getLoggedInUserWorkspaces } from '../../data/workspaces';
import { addScriptModulesEditorAction$, updateApiModulesEditorAction$ } from '../editor/editor';
import { ApiHandlerModel } from '../editor/types';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import {
    activeFilters$,
    filteredWorkspaces$,
    loggedInUserWorkspaces$,
    refreshWorkspacesAndReapplyFiltersAction$,
} from '../workspaces';
import { selectedWorkspacePackages$ } from './package-manager';
import { readmeFileHasUnsavedChanges$, savedReadmeFileDetails$, unsavedReadmeFileDetails$ } from './readme';
import { externallyTriggerableScripts$, scriptHasUnsavedChanges$ } from './script';

export const loadWorkspaceResourcesWhenEventIsEmitted = (observable: Observable<unknown>): void => {
    observable
        .pipe(
            withLatestFrom(selectedWorkspaceUid$, selectedEnvironmentUid$),
            map(async ([, workspaceUid, environmentUid]) => {
                if (workspaceUid && environmentUid) {
                    await loadWorkspaceResources(workspaceUid, environmentUid);
                }
            })
        )
        .subscribe();
};

export const loadWorkspaceEnvironmentsWhenEventIsEmitted = (observable: Observable<unknown>): void => {
    observable
        .pipe(
            withLatestFrom(selectedWorkspaceUid$, selectedWorkspaceReadOnlyMode$),
            filter(
                ([, selectedWorkspaceUid, selectedWorkspaceReadOnlyMode]) =>
                    !!selectedWorkspaceUid && !selectedWorkspaceReadOnlyMode
            ),
            map(async ([, workspaceUid]) => {
                if (workspaceUid) {
                    await loadWorkspaceEnvironments(workspaceUid);
                }
            })
        )
        .subscribe();
};

export const loadWorkspaceDeploymentsWhenEventIsEmitted = (observable: Observable<unknown>): void => {
    observable
        .pipe(
            withLatestFrom(selectedWorkspaceUid$, selectedWorkspaceReadOnlyMode$),
            filter(
                ([, selectedWorkspaceUid, selectedWorkspaceReadOnlyMode]) =>
                    !!selectedWorkspaceUid && !selectedWorkspaceReadOnlyMode
            ),
            map(async ([, workspaceUid]) => {
                if (workspaceUid) {
                    await loadWorkspaceDeployments(workspaceUid);
                }
            })
        )
        .subscribe();
};

export const loadWorkspaces = async (bypassCache = true, source?: 'workspaces'): Promise<void> => {
    if (loggedInUserWorkspaces$.value.length === 0 || bypassCache) {
        const workspaces = await getLoggedInUserWorkspaces(source);

        loggedInUserWorkspaces$.next(workspaces);
        if (activeFilters$.value.count === 0) {
            filteredWorkspaces$.next(workspaces);
        }
    }
    refreshWorkspacesAndReapplyFiltersAction$.next();
};

export const loadWorkspaceResources = async (workspaceUid: string, environmentUid: string): Promise<void> => {
    try {
        loadingWorkspaceResources$.next(true);

        const selectedWorkspaceReadOnlyMode = selectedWorkspaceReadOnlyMode$.value;

        const workspaceResources = await getWorkspaceResources(workspaceUid, environmentUid);
        const eventListeners = workspaceResources.eventListeners
            .map((listener) => listener.script?.uid)
            .filter((listener): listener is string => !!listener);
        const scheduledTriggers = workspaceResources.scheduledTriggers
            .map((trigger) => trigger.script?.uid)
            .filter((trigger): trigger is string => !!trigger);

        externallyTriggerableScripts$.next(Array.from(new Set([...eventListeners, ...scheduledTriggers])));
        const workspacePackages = selectedWorkspaceReadOnlyMode ? [] : await getWorkspacePackages(workspaceUid);

        selectedWorkspaceResources$.next(workspaceResources);
        selectedWorkspacePackages$.next(workspacePackages);

        const apiHandlerModels = workspaceResources.apiHandlers
            .map((apiHandler) => {
                const library = apiHandler.libraries.find((lib) => lib.name.startsWith('@managed-api/'));
                if (library?.name && library?.namespace) {
                    return {
                        path: `api/${apiHandler.path ?? 'index'}`,
                        handlerId: apiHandler.uid,
                        spec: {
                            apiModule: library.name,
                            namespace: library.namespace,
                        },
                    };
                }
                return null;
            })
            .filter((apiHandler): apiHandler is ApiHandlerModel => !!apiHandler);

        updateApiModulesEditorAction$.next({ apiHandlers: apiHandlerModels });
        addScriptModulesEditorAction$.next(workspaceResources.scripts);
    } catch (e) {
        console.error('Failed to load workspace resources', e);

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                'Failed to load workspace resources, try refreshing the browser, if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });
    }
    loadingWorkspaceResources$.next(false);
};

export const loadWorkspaceEnvironments = async (workspaceUid: string): Promise<void> => {
    try {
        loadingWorkspaceEnvironments$.next(true);

        const workspaceEnvironments = await getWorkspaceEnvironments(workspaceUid);

        selectedWorkspaceEnvironments$.next(workspaceEnvironments);
    } catch (e) {
        console.error('Failed to load workspace environments', e);

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                'Failed to load workspace environments, try refreshing the browser, if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });
    }
    loadingWorkspaceEnvironments$.next(false);
};

const loadWorkspaceDeployments = async (workspaceUid: string): Promise<void> => {
    try {
        const workspaceDeployments = await getWorkspaceDeployments(workspaceUid);

        selectedWorkspaceDeployments$.next(workspaceDeployments);
    } catch (e) {
        console.error('Failed to load workspace deployments', e);

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                'Failed to load workspace deployments, try refreshing the browser, if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });
    }
};

export const workspaceHasUnsavedChanges = (): boolean => {
    const scripts = selectedWorkspaceResources$.value.scripts;
    const readmeFile = selectedWorkspaceResources$.value.readmeFile;
    const readmeFileUid = readmeFile?.uid ?? getDefaultReadmeFileUid();

    const hasUnsavedScripts = scripts.some((script) => scriptHasUnsavedChanges$.value[script.uid]);
    const hasUnsavedReadmeFile = !!readmeFileHasUnsavedChanges$.value[readmeFileUid];

    return hasUnsavedScripts || hasUnsavedReadmeFile;
};

export const getDefaultReadmeFileUid = (): string => `TMP-${selectedWorkspaceUid$.value}`;

export const getDefaultReadmeFile = (): ReadmeFile => {
    return {
        name: 'README.md',
        content: `## Title for your workspace\nThis is a markdown syntax, learn more about it [here](https://www.markdownguide.org/basic-syntax/).`,
    };
};

export const getUnsavedReadmeFileCached = async (fileUid: string, workspaceUid: string): Promise<ReadmeFile> => {
    let readmeFile = unsavedReadmeFileDetails$.value[fileUid];

    if (!readmeFile) {
        readmeFile = await getReadmeFile(fileUid, workspaceUid);

        savedReadmeFileDetails$.next({ ...savedReadmeFileDetails$.value, [fileUid]: readmeFile });
        unsavedReadmeFileDetails$.next({ ...unsavedReadmeFileDetails$.value, [fileUid]: readmeFile });
    }

    return readmeFile;
};

export const getUnsavedDefaultReadmeFileCached = (fileUid: string): void => {
    const readmeFile = unsavedReadmeFileDetails$.value[fileUid];

    if (!readmeFile) {
        const defaultReadme = getDefaultReadmeFile();

        savedReadmeFileDetails$.next({ ...savedReadmeFileDetails$.value, [fileUid]: defaultReadme });
        unsavedReadmeFileDetails$.next({ ...unsavedReadmeFileDetails$.value, [fileUid]: defaultReadme });
    }
};

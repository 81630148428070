import { useObservableState } from 'observable-hooks';
import { useEffect, useState } from 'react';
import { CenteredLoadingSpinner } from '../../components/loading/CenteredLoadingSpinner';
import { DefaultWorkspaceView } from '../../components/workspace/DefaultWorkspaceView';
import { openAppSelectorAction$ } from '../../store/apps';
import { resourceManagerOffsets$, wizardState$, wizardStep$, wizardWorkspaceDetails$ } from '../../store/wizard';
import { loadingWorkspaceResources$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../store/workspace';
import { createApiHandlerAction$ } from '../../store/workspace/api-handler';
import { createEventListenerAction$ } from '../../store/workspace/event-listener';
import { getSanitizedParsedMarkdown } from '../../store/workspace/readme';
import { createNewScriptAction$ } from '../../store/workspace/script';
import { getUnsavedReadmeFileCached } from '../../store/workspace/utils';

export const DefaultWorkspaceViewContainer: React.FC = () => {
    const { apiHandlers, eventListeners, scripts, readmeFile } = useObservableState(selectedWorkspaceResources$);
    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const blank = !apiHandlers.length && !eventListeners.length && !scripts.length;
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);
    const wizardWorkspaceDetails = useObservableState(wizardWorkspaceDetails$);
    const resourceManagerOffsets = useObservableState(resourceManagerOffsets$);

    const [parsedContent, setParsedContent] = useState<string>('');
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const loadReadmeFileContent = async (readmeFileUid: string): Promise<void> => {
            const file = await getUnsavedReadmeFileCached(readmeFileUid, selectedWorkspaceUid ?? '');
            setParsedContent(getSanitizedParsedMarkdown(file.content));
            setLoading(false);
        };
        if (!loadingWorkspaceResources && readmeFile?.uid) {
            setLoading(true);
            loadReadmeFileContent(readmeFile?.uid);
        }
    }, [loadingWorkspaceResources]);

    return isLoading || loadingWorkspaceResources ? (
        <CenteredLoadingSpinner />
    ) : (
        <DefaultWorkspaceView
            resourceManagerOffsets={resourceManagerOffsets}
            parsedContent={parsedContent}
            blank={blank}
            workspaceUid={selectedWorkspaceUid}
            wizard={{ state: wizardState, step: wizardStep, workspaceDetails: wizardWorkspaceDetails }}
            onCreateNewApiConnection={() =>
                openAppSelectorAction$.next({
                    nextAction: (app) =>
                        createApiHandlerAction$.next({
                            appUid: app.uid,
                            apiHandlerTypeUid: app.connectionType.apiHandlerTypes[0]?.uid ?? '',
                        }),
                    mode: 'API_HANDLER',
                })
            }
            onCreateNewEventListener={() =>
                openAppSelectorAction$.next({
                    nextAction: (app) =>
                        createEventListenerAction$.next({
                            appUid: app.uid,
                            eventListenerTypeUid: app.connectionType.eventListenerTypes[0]?.uid ?? '',
                        }),
                    mode: 'EVENT_LISTENER',
                })
            }
            onCreateNewScript={() => createNewScriptAction$.next()}
        />
    );
};

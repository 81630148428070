import { useObservableState } from 'observable-hooks';
import { ErrorPage } from '../components/error-page';
import { errorPageDetails$ } from '../store/error';
import { loadTokens$, sessionExpired$, showSessionExpiredWarning$ } from '../store/config';

export const ErrorPageContainer: React.FC = () => {
    const errorPageDetails = useObservableState(errorPageDetails$);
    const sessionExpired = useObservableState(sessionExpired$);
    const showSessionExpiredWarning = useObservableState(showSessionExpiredWarning$);

    const showRefreshSessionWarning = sessionExpired && showSessionExpiredWarning;

    return (
        <ErrorPage
            error={errorPageDetails?.errorMessage}
            background={errorPageDetails?.background}
            title={errorPageDetails?.pageTitle}
            skipRefreshMessage={errorPageDetails?.skipRefreshMessage}
            showDashboardLink={errorPageDetails?.showDashboardLink}
            skipRefreshMessageOverride={errorPageDetails?.skipRefreshMessageOverride}
            showRefreshSessionWarning={showRefreshSessionWarning}
            onSetLoadTokens={(loadTokens) => loadTokens$.next(loadTokens)}
        />
    );
};

import { styled } from '@mui/material/styles';
import React from 'react';
import Link from '@mui/material/Link';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { toolbarHeight } from './ScriptDetails';

interface ShortcutsPopupProps {
    onClose(): void;
    open?: boolean;
    shortcuts?: Shortcut[];
}

export interface Shortcut {
    combinations: string[];
    text?: string;
}

const StyledCombination = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    textDecoration: 'underline dotted',
    textUnderlineOffset: 2,
    ':hover': {
        color: theme.palette.primary.contrastText,
        textDecoration: 'underline solid',
    },
}));

const StyledMinimizeButton = styled(IconButton)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
        height: 20,
        width: 20,
    },
}));

const StyledPopup = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.primary,
    borderRadius: theme.constants.borderRadius,
    bottom: `calc(${toolbarHeight}px + ${theme.spacing(1)})`,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 2),
    position: 'absolute',
    right: theme.spacing(3),
    minWidth: 466,
    zIndex: 1300,
}));

const StyledPopupContent = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    letterSpacing: 1.5,
    paddingBottom: theme.spacing(2),
}));

const StyledPopupFirstRow = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
}));

const StyledPopupRow = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1),
    },
}));

const StyledPopupTitleContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
        marginRight: theme.spacing(1),
    },
}));

const StyledShortcutBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.primary,
    borderRadius: theme.constants.borderRadius,
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5),
}));

const StyledSubscript = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    paddingTop: theme.spacing(1),
}));

const StyledYoutubeIcon = styled('img')(({ theme }) => ({
    height: 20,
    marginRight: theme.spacing(2),
}));

const helpLink = 'https://docs.adaptavist.com/src/scripting/editor';
const videoUrl = 'https://www.youtube.com/watch?v=orjEJbwGwpc&list=PLTfQ5lMxqu9q9E2qW-H3VvYoHy9mTNJU7';

export const ShortcutsPopup: React.FC<ShortcutsPopupProps> = ({ onClose, open = true, shortcuts = [] }) => {
    const popupRows = shortcuts.map((sc, index) => {
        return (
            <StyledPopupRow key={'row' + index}>
                {sc.combinations.map((c, i) => {
                    return (
                        <StyledCombination key={'combination' + i}>
                            <StyledShortcutBox>
                                <Typography variant="subtitle2" fontWeight={700}>
                                    {c}
                                </Typography>
                            </StyledShortcutBox>
                            {i < sc.combinations.length - 1 && (
                                <Typography sx={{ marginRight: 0.5 }} fontWeight={500}>
                                    or
                                </Typography>
                            )}
                        </StyledCombination>
                    );
                })}
                <Typography noWrap fontWeight={500}>
                    {sc.text}
                </Typography>
            </StyledPopupRow>
        );
    });

    return open ? (
        <StyledPopup>
            <StyledPopupContent>
                <StyledPopupFirstRow>
                    <StyledPopupTitleContainer>
                        <KeyboardAltOutlinedIcon />
                        <Typography variant="subtitle1">Shortcuts</Typography>
                    </StyledPopupTitleContainer>
                    <StyledMinimizeButton onClick={onClose} title="Close">
                        <RemoveIcon />
                    </StyledMinimizeButton>
                </StyledPopupFirstRow>
                {popupRows}
            </StyledPopupContent>
            <StyledSubscript>
                <StyledYoutubeIcon
                    src={process.env.PUBLIC_URL + '/youtube-icon.svg'}
                    alt="Youtube icon"
                ></StyledYoutubeIcon>
                <Typography>
                    Watch the{' '}
                    <StyledLink target="_blank" href={videoUrl}>
                        video
                    </StyledLink>
                    , or learn more about the <strong>Editor</strong>{' '}
                    <StyledLink target="_blank" href={helpLink}>
                        here
                    </StyledLink>
                </Typography>
            </StyledSubscript>
        </StyledPopup>
    ) : null;
};

import { useObservableState } from 'observable-hooks';
import { CreateOrganizationWizardDialog } from '../../components/organization/create-organization-wizard/CreateOrganizationWizardDialog';
import { billingEntities$ } from '../../store/billing-details';
import {
    closeCreateOrganizationWizardDialogAction$,
    createOrganizationAction$,
    createOrganizationActionV2$,
    createOrganizationValidationError$,
    createOrganizationWizardDialogOpen$,
    createOrganizationWizardSaving$,
    loggedInUserOrganizations$,
} from '../../store/organizations';
import { loggedInUserDetails$ } from '../../store/user';
import { featureFlagsTopic$ } from '../../store/config';

export const CreateOrganizationWizardContainer: React.FC = () => {
    const createOrganizationWizardDialogOpen = useObservableState(createOrganizationWizardDialogOpen$);
    const saving = useObservableState(createOrganizationWizardSaving$);
    const errors = useObservableState(createOrganizationValidationError$);
    const organizations = useObservableState(loggedInUserOrganizations$);
    const billingEntities = useObservableState(billingEntities$);
    const userDetails = useObservableState(loggedInUserDetails$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    const freeOrganizations = organizations.filter((o) => o.planName === 'Free plan');
    const freeOrganizationsLeft =
        userDetails?.maxFreeTeams !== undefined ? userDetails.maxFreeTeams - freeOrganizations.length : 0;

    return (
        <CreateOrganizationWizardDialog
            billingEntities={billingEntities}
            errors={errors}
            freeOrganizationsLeft={freeOrganizationsLeft}
            disabledPaidTiers={featureFlags.disabledPaidTiers}
            open={createOrganizationWizardDialogOpen}
            onAvatarUpload={() => alert('Feature not yet implemented')}
            onCancel={() => closeCreateOrganizationWizardDialogAction$.next()}
            onCreate={(event) =>
                featureFlags.salablePlan
                    ? createOrganizationActionV2$.next(event)
                    : createOrganizationAction$.next(event)
            }
            saving={saving}
            isSalablePlan={!!featureFlags.salablePlan}
        />
    );
};

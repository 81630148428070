import React from 'react';
import { styled } from '@mui/material';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Search from '@mui/icons-material/Search';

const StyledSearchBox = styled(InputBase)(({ theme }) => ({
    border: '0.5px solid ' + theme.palette.action.disabled,
    borderRadius: theme.constants.borderRadius,
    height: 'unset',
    padding: theme.spacing(0.75, 1),
    width: '30ch',
    '& .MuiInputBase-input': {
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

export const SearchBox: React.FC<InputBaseProps> = (props) => {
    return <StyledSearchBox {...props} startAdornment={<Search />} placeholder="Search"></StyledSearchBox>;
};

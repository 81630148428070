import { getReadmeFile as getReadmeFileRpc } from '@avst-stitch/repository-lib/lib';
import { saveReadmeFile as saveReadmeFileRpc } from '@avst-stitch/repository-lib/lib';
import { Response as ReadmeFile } from '@avst-stitch/repository-lib/lib/rpcs/getReadmeFile';
import { Response as SavedReadmeFile } from '@avst-stitch/repository-lib/lib/rpcs/saveReadmeFile';
import { repositoryInvoker } from '../utils/repository';
export type { ReadmeFile, SavedReadmeFile };

export const getReadmeFile = async (uid: string, workspaceUid: string): Promise<ReadmeFile> => {
    return await getReadmeFileRpc(repositoryInvoker, {
        uid,
        workspaceUid,
    });
};

export const saveReadmeFile = async (workspaceUid: string, content: string, uid?: string): Promise<SavedReadmeFile> => {
    return await saveReadmeFileRpc(repositoryInvoker, {
        workspaceUid,
        content,
        uid,
    });
};

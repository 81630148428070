import { BehaviorSubject, delay, map, tap, Subject } from 'rxjs';
import {
    changeOrganizationPlan,
    ChangeOrganizationPlanRequest,
    changeOrganizationPlanV2,
    ChangeOrganizationPlanRequestV2,
    reactivateOrganizationPlan,
    ReactivateOrganizationPlanRequest,
} from '../../data/organization';
import { InformativeError } from '../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { billingEntities$, openSelectBillingDetailsDialogAction$ } from '../billing-details';
import { openSalableCheckoutWindow } from '../utils';
import { ChangeOrganizationPlanWizardDialogEvent } from '../../components/organization/change-organization-plan-wizard/ChangeOrganizationPlanWizardDialog';
import { Plan } from '../../components/organization/create-organization-wizard/CreateOrganizationWizardDialog';
import { getBillingEntities } from '../../data/billing-details';
import { sleep } from '../../utils/sleep';
import { organizationPlanChangedAction$, selectedOrganizationPlan$, selectedOrganizationUid$ } from '../organization';

interface ChangeOrganizationPlanEvent extends ChangeOrganizationPlanRequest {
    selectedBillingEntityUid?: string;
}

interface OrganizationPlanSuccessStateContent {
    state: 'create' | 'upgrade' | 'downgrade';
    lastTier?: string;
    tier: string;
}

export const changeOrganizationPlanAction$ = monitor(
    'changeOrganizationPlanAction$',
    new Subject<ChangeOrganizationPlanEvent>()
);

export const changeOrganizationPlanActionV2$ = monitor(
    'changeOrganizationPlanActionV2$',
    new Subject<ChangeOrganizationPlanRequestV2>()
);

export const organizationPlanSuccessStateDialogOpen$ = monitor(
    'organizationPlanSuccessStateDialogOpen$',
    new BehaviorSubject(false)
);

export const organizationPlanSuccessStateContent$ = monitor(
    'organizationPlanSuccessStateContent$',
    new BehaviorSubject<OrganizationPlanSuccessStateContent | undefined>(undefined)
);

export const openOrganizationPlanSuccessStateDialogAction$ = monitor(
    'openOrganizationPlanSuccessStateDialogAction$',
    new Subject<OrganizationPlanSuccessStateContent>()
);

export const closeOrganizationPlanSuccessStateDialogAction$ = monitor(
    'closeOrganizationPlanSuccessStateDialogAction$',
    new Subject<void>()
);

export const closeChangeOrganizationPlanWizardDialogAction$ = monitor(
    'closeChangeOrganizationPlanWizardDialogAction$',
    new Subject<void>()
);
export const openChangeOrganizationPlanWizardDialogAction$ = monitor(
    'openChangeOrganizationPlanWizardDialogAction$',
    new Subject<Plan>()
);
export const changeOrganizationPlanWizardDialogOpen$ = monitor(
    'changeOrganizationPlanWizardDialogOpen$',
    new BehaviorSubject<boolean>(false)
);
export const changeOrganizationPlanWizardDialogError$ = monitor(
    'changeOrganizationPlanWizardDialogError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const changeOrganizationPlanWizardDialogSaving$ = monitor(
    'changeOrganizationPlanWizardDialogSaving$',
    new BehaviorSubject<boolean>(false)
);
export const changeOrganizationPlanWizardDialogLoading$ = monitor(
    'changeOrganizationPlanWizardDialogLoading$',
    new BehaviorSubject(false)
);
export const changeOrganizationPlanWizardSelectedPlan$ = monitor(
    'changeOrganizationPlanWizardSelectedPlan$',
    new BehaviorSubject<Plan | undefined>(undefined)
);
export const addChangePlanDetailsToOrganizationActionV2$ = monitor(
    'addChangePlanDetailsToOrganizationActionV2$',
    new Subject<ChangeOrganizationPlanWizardDialogEvent>()
);

export const organizationPlanUpdating$ = monitor('organizationPlanUpdating$', new BehaviorSubject<boolean>(false));

export const changeOrganizationPlanValidationError$ = monitor(
    'changeOrganizationPlanValidationError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const changeOrganizationPlanConfirmationDialogOpen$ = monitor(
    'changeOrganizationPlanConfirmationDialogOpen$',
    new BehaviorSubject(false)
);

export const openChangeOrganizationPlanConfirmationDialogAction$ = monitor(
    'openChangeOrganizationPlanConfirmationDialogAction$',
    new Subject<Plan>()
);

export const closeChangeOrganizationPlanConfirmationDialogAction$ = monitor(
    'closeChangeOrganizationPlanConfirmationDialogAction$',
    new Subject<void>()
);

export const changeOrganizationPlanConfirmPurchaseDialogOpen$ = monitor(
    'changeOrganizationPlanConfirmPurchaseDialogOpen$',
    new BehaviorSubject(false)
);

export const changeOrganizationPlanConfirmPurchaseDialogLoading$ = monitor(
    'changeOrganizationPlanConfirmPurchaseDialogLoading$',
    new BehaviorSubject(false)
);

export const changeOrganizationPlanConfirmPurchaseDialogError$ = monitor(
    'changeOrganizationPlanConfirmPurchaseDialogError$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const changeOrganizationPlanConfirmPurchaseDialogNextPlan$ = monitor(
    'changeOrganizationPlanConfirmPurchaseDialogNextPlan$',
    new BehaviorSubject<Plan | undefined>(undefined)
);

export const openChangeOrganizationPlanConfirmPurchaseDialogAction$ = monitor(
    'openChangeOrganizationPlanConfirmPurchaseDialogAction$',
    new Subject<Plan>()
);

export const closeChangeOrganizationPlanConfirmPurchaseDialogAction$ = monitor(
    'closeChangeOrganizationPlanConfirmPurchaseDialogAction$',
    new Subject<void>()
);

export const resumeOrganizationPlanAction$ = monitor(
    'resumeOrganizationPlanAction$',
    new Subject<ReactivateOrganizationPlanRequest>()
);

const PLAN_CHANGED = 'Plan changed.';
const GENERIC_PLAN_CHANGE_ERROR =
    'Error occurred while changing plan. Please try again, if the issue persists please contact support.';

changeOrganizationPlanAction$
    .pipe(
        map(async (event) => {
            //Checking if not suspending, not downgrading to free tier, but missing billing details
            if (event.plan && event.plan.tier !== 'free' && !event.selectedBillingEntityUid) {
                openSelectBillingDetailsDialogAction$.next(event.plan);
            } else {
                try {
                    organizationPlanUpdating$.next(true);
                    changeOrganizationPlanValidationError$.next(undefined);
                    await changeOrganizationPlan(event);
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: event.plan ? PLAN_CHANGED : 'Plan suspended',
                    });
                    organizationPlanUpdating$.next(false);
                    organizationPlanChangedAction$.next();
                } catch (e) {
                    organizationPlanUpdating$.next(false);
                    if (e instanceof InformativeError) {
                        changeOrganizationPlanValidationError$.next(e.message);
                    } else {
                        console.error('Failed to change plan', e);
                        changeOrganizationPlanValidationError$.next(GENERIC_PLAN_CHANGE_ERROR);
                    }
                }
            }
        })
    )
    .subscribe();

const PLAN_TIERS = ['free', 'silver', 'gold', 'platinum'];

changeOrganizationPlanActionV2$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (event) => {
            const currentPlan = selectedOrganizationPlan$.value;
            if (
                event.plan &&
                ((currentPlan && !currentPlan.salablePlan) ||
                    (currentPlan?.paymentType === 'FREE' && event.plan.tier !== 'free'))
            ) {
                closeChangeOrganizationPlanConfirmPurchaseDialogAction$.next();
                openChangeOrganizationPlanWizardDialogAction$.next(event.plan);
            } else {
                try {
                    organizationPlanUpdating$.next(true);
                    changeOrganizationPlanConfirmPurchaseDialogLoading$.next(true);
                    changeOrganizationPlanConfirmPurchaseDialogError$.next(undefined);
                    const checkout = await changeOrganizationPlanV2({
                        ...event,
                        paymentMethod: currentPlan?.paymentType,
                    });
                    if (checkout?.checkoutUrl) {
                        openSalableCheckoutWindow(
                            checkout.checkoutUrl,
                            [organizationPlanUpdating$, changeOrganizationPlanConfirmPurchaseDialogLoading$],
                            [changeOrganizationPlanValidationError$, changeOrganizationPlanConfirmPurchaseDialogError$]
                        );
                    } else {
                        await sleep(5000);
                        closeChangeOrganizationPlanConfirmPurchaseDialogAction$.next();
                        organizationPlanUpdating$.next(false);
                        organizationPlanChangedAction$.next();
                        openOrganizationPlanSuccessStateDialogAction$.next({
                            tier: event.plan?.tier ?? '',
                            state:
                                PLAN_TIERS.indexOf(event.plan?.tier ?? '') < PLAN_TIERS.indexOf(currentPlan?.tier ?? '')
                                    ? 'downgrade'
                                    : 'upgrade',
                            lastTier: currentPlan?.tier,
                        });
                    }
                } catch (e) {
                    organizationPlanUpdating$.next(false);
                    changeOrganizationPlanConfirmPurchaseDialogLoading$.next(false);
                    if (e instanceof InformativeError) {
                        changeOrganizationPlanConfirmPurchaseDialogError$.next(e.message);
                    } else {
                        console.error('Failed to change plan', e);
                        changeOrganizationPlanConfirmPurchaseDialogError$.next(GENERIC_PLAN_CHANGE_ERROR);
                    }
                }
            }
        })
    )
    .subscribe();

openOrganizationPlanSuccessStateDialogAction$.subscribe((event) => {
    organizationPlanSuccessStateDialogOpen$.next(true);
    organizationPlanSuccessStateContent$.next(event);
});

closeOrganizationPlanSuccessStateDialogAction$
    .pipe(
        tap(() => organizationPlanSuccessStateDialogOpen$.next(false)),
        delay(300),
        tap(() => organizationPlanSuccessStateContent$.next(undefined))
    )
    .subscribe();

openChangeOrganizationPlanWizardDialogAction$
    .pipe(
        map(async (plan) => {
            changeOrganizationPlanWizardDialogError$.next(undefined);
            changeOrganizationPlanWizardDialogLoading$.next(true);
            changeOrganizationPlanWizardSelectedPlan$.next(plan);
            changeOrganizationPlanWizardDialogOpen$.next(true);
            try {
                const billingEntities = await getBillingEntities();
                billingEntities$.next(billingEntities);
                changeOrganizationPlanWizardDialogLoading$.next(false);
            } catch (e) {
                let errorMessage: string;
                if (e instanceof InformativeError) {
                    errorMessage = e.message;
                } else {
                    console.error('Failed to load billing entities', e);
                    errorMessage =
                        'Failed to load billing entities, try refreshing the browser, if the issue persists please contact support.';
                }
                changeOrganizationPlanWizardDialogOpen$.next(false);
                changeOrganizationPlanWizardDialogLoading$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: errorMessage,
                });
            }
        })
    )
    .subscribe();

closeChangeOrganizationPlanWizardDialogAction$.subscribe(() => {
    changeOrganizationPlanWizardDialogOpen$.next(false);
    changeOrganizationPlanWizardDialogError$.next(undefined);
    changeOrganizationPlanWizardDialogSaving$.next(false);
    changeOrganizationPlanWizardDialogLoading$.next(false);
    changeOrganizationPlanWizardSelectedPlan$.next(undefined);
});

addChangePlanDetailsToOrganizationActionV2$
    .pipe(
        map(async (event) => {
            const currentPlan = selectedOrganizationPlan$.value;
            changeOrganizationPlanWizardDialogError$.next(undefined);
            changeOrganizationPlanWizardDialogSaving$.next(true);
            try {
                const checkout = await changeOrganizationPlanV2({
                    ...event,
                    organizationUid: selectedOrganizationUid$.value ?? '',
                    originalReferrer: window.location.origin,
                });

                if (checkout?.checkoutUrl) {
                    openSalableCheckoutWindow(
                        checkout.checkoutUrl,
                        [changeOrganizationPlanWizardDialogSaving$],
                        [changeOrganizationPlanWizardDialogError$]
                    );
                } else {
                    await sleep(5000);
                    changeOrganizationPlanWizardDialogSaving$.next(false);
                    closeChangeOrganizationPlanWizardDialogAction$.next();
                    organizationPlanChangedAction$.next();
                    openOrganizationPlanSuccessStateDialogAction$.next({
                        tier: event.plan?.tier ?? '',
                        state:
                            PLAN_TIERS.indexOf(event.plan?.tier ?? '') < PLAN_TIERS.indexOf(currentPlan?.tier ?? '')
                                ? 'downgrade'
                                : 'upgrade',
                        lastTier: currentPlan?.tier,
                    });
                }
            } catch (e) {
                changeOrganizationPlanWizardDialogSaving$.next(false);
                if (e instanceof InformativeError) {
                    changeOrganizationPlanWizardDialogError$.next(e.message);
                } else {
                    console.error('Failed to upgrade plan', e);
                    changeOrganizationPlanWizardDialogError$.next(GENERIC_PLAN_CHANGE_ERROR);
                }
            }
        })
    )
    .subscribe();

openChangeOrganizationPlanConfirmationDialogAction$.subscribe((plan) => {
    changeOrganizationPlanConfirmationDialogOpen$.next(true);
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$.next(plan);
});

closeChangeOrganizationPlanConfirmationDialogAction$.subscribe(() => {
    changeOrganizationPlanConfirmationDialogOpen$.next(false);
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$.next(undefined);
});

openChangeOrganizationPlanConfirmPurchaseDialogAction$.subscribe((plan) => {
    changeOrganizationPlanConfirmPurchaseDialogOpen$.next(true);
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$.next(plan);
    changeOrganizationPlanConfirmPurchaseDialogLoading$.next(false);
    changeOrganizationPlanConfirmPurchaseDialogError$.next(undefined);
});

closeChangeOrganizationPlanConfirmPurchaseDialogAction$.subscribe(() => {
    changeOrganizationPlanConfirmPurchaseDialogOpen$.next(false);
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$.next(undefined);
    changeOrganizationPlanConfirmPurchaseDialogLoading$.next(false);
    changeOrganizationPlanConfirmPurchaseDialogError$.next(undefined);
});

resumeOrganizationPlanAction$
    .pipe(
        map(async (event) => {
            try {
                const plan = selectedOrganizationPlan$.value;
                if (plan) {
                    organizationPlanUpdating$.next(true);
                    changeOrganizationPlanValidationError$.next(undefined);
                    await reactivateOrganizationPlan(event);
                    organizationPlanUpdating$.next(false);
                    organizationPlanChangedAction$.next();
                }
            } catch (e) {
                organizationPlanUpdating$.next(false);
                if (e instanceof InformativeError) {
                    changeOrganizationPlanValidationError$.next(e.message);
                } else {
                    console.error('Failed to reactivate plan', e);
                    changeOrganizationPlanValidationError$.next(
                        'Error occurred while reactivating plan. Please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();

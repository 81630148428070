import { keyframes, styled, useTheme } from '@mui/material';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';
import { ProductIcon } from '../../icons/ProductIcon';
import LinkIcon from '@mui/icons-material/Link';
import CodeIcon from '@mui/icons-material/Code';
import BoltIcon from '@mui/icons-material/Bolt';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import LanguageIcon from '@mui/icons-material/Language';
import { APP } from '@avst-stitch/repository-lib/constants';
import { Theme } from '@mui/material/styles';
import { TreeNode } from '.';
import { ResourceTreeFolder } from './ResourceTreeFolder';
import { ScriptSaveIcon, ScriptWarningIcon } from '../../icons/StyledMuiIcons';

interface ResourceTreeItemProps {
    uid?: string;
    readOnlyMode?: boolean;
    name?: string;
    fullPath?: string;
    unsaved?: boolean;
    executing?: boolean;
    cronDescription?: string;
    cronExpression?: string;
    deleting?: boolean;
    appName?: string;
    wizardIncomplete?: boolean;
    warning?: boolean;
    subItems?: TreeNode[];
    path?: string;
    urlId?: string;
    eventType?: string;
    connectionName?: string;
    remnantEnvironments?: {
        environmentName: string;
        deploymentVersion: string;
    }[];
    scriptName?: string;
    language?: string;
    selectedNode?: string;
    warningText?: string;
    sectionName?: string;
    externalTriggerBaseUrl?: string;
    inactive?: boolean;
    onToggleExpand?: () => void;
    openHandler(uid: string): void;
    deleteHandler(uid: string, fullPath?: string): void;
    triggerHandler?(uid: string): void;
    pathCopyHandler?(uid: string): void;
    urlCopyHandler?(uid: string): void;
}

// ** reusable styles for one place editing ** //
// flexbox
const flexStyles = () =>
    ({
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'row',
        minWidth: 0,
        position: 'relative',
        width: '100%',
    } as const);

// right hand icons
const iconRightStyles = (theme: Theme) =>
    ({
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5.fontSize,
        justifySelf: 'end',
        marginRight: theme.spacing(0.5),
        opacity: 0,
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    } as const);

// text titles
const textTitleStyles = (theme: Theme) =>
    ({
        color: theme.palette.text.primary,
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(0, 1),
    } as const);

// resource remnants
const remnantStyles = (theme: Theme) =>
    ({
        color: theme.palette.text.disabled,
        fontStyle: 'italic',
        opacity: 0.87,
        '.MuiTypography-root': {
            color: theme.palette.secondary.main,
        },
    } as const);

// ** styled components ** //
// flex row
export const StyledBoxFlexRow = styled(Box)(() => ({
    ...flexStyles(),
    alignItems: 'center',
}));

// flex column
const StyledBoxFlexColumn = styled(Box)(() => ({
    ...flexStyles(),
    flexDirection: 'column',
}));

// list item - all sections
const StyledListItem = styled(ListItem)(({ theme }) => ({
    ...flexStyles(),
    cursor: 'pointer',
    flexDirection: 'column' as const,
    padding: theme.spacing(0.5, 1, 0.5, 1.75),
    '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        '& .MuiSvgIcon-root': {
            opacity: 1,
        },
    },
}));

// play button - all sections
const StyledPlayButtonWrap = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    marginRight: theme.spacing(0.25),
    width: theme.typography.h4.fontSize,
}));

// play button icon - all sections
const StyledPlayButtonIcon = styled(PlayCircleOutlineRoundedIcon)(({ theme }) => ({
    ...iconRightStyles(theme),
}));

// delete button - all sections
// eslint-disable-next-line sonarjs/no-identical-functions
const StyledDeleteButtonIcon = styled(DeleteOutlineIcon)(({ theme }) => ({
    ...iconRightStyles(theme),
    cursor: 'pointer',
}));

// delete action - all sections
const StyledBusyDeletingIcon = styled((props) => <CircularProgress size={16} {...props} />)(({ theme }) => ({
    justifySelf: 'end',
    marginRight: theme.spacing(0.8),
}));

// scripts title icon e.g. typescript
const StyledCodeIcon = styled('img')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: theme.typography.htmlFontSize,
    marginLeft: 1,
    width: theme.typography.htmlFontSize,
    zIndex: 2,
}));

// scripts title text
const StyledScriptsTitleText = styled(Typography)(({ theme }) => ({
    ...textTitleStyles(theme),
}));

// connections title text
const StyledConnectionsTitleText = styled(Typography)(({ theme }) => ({
    ...textTitleStyles(theme),
    padding: theme.spacing(0, 1, 0, 0.75),
}));

// connection title icon
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 0,
    '& .MuiSvgIcon-root': {
        fontSize: theme.typography.subtitle1.fontSize,
    },
}));

// connections subtitle copy icon
const StyledContentCopyIcon = styled(ContentCopyIcon)(({ theme }) => ({
    ...iconRightStyles(theme),
    fontSize: theme.typography.subtitle1.fontSize,
    cursor: 'pointer',
}));

// connections and events subtitle box
const StyledSubtitleBox = styled(Box)(({ theme }) => ({
    ...flexStyles(),
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    width: '80%',
}));

// scripts subtitle link icon
const StyledLinkIcon = styled(LinkIcon)(({ theme }) => ({
    fontSize: theme.typography.h5.fontSize,
}));

// scheduled triggers clock icon
const StyledScheduleIcon = styled(ScheduleIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: theme.typography.h5.fontSize,
}));

// scheduled triggers cron expression
const StyledCronExpression = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(0.5),
}));

// scheduled triggers script icon
const StyledScriptIcon = styled(CodeIcon)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
}));

// connections and events subtitle text
const StyledSubtitleText = styled(Typography)(({ theme }) => ({
    ...textTitleStyles(theme),
    color: theme.palette.primary.dark,
}));

// connections and events warning text
export const StyledWarningText = styled(Typography)(({ theme }) => ({
    ...textTitleStyles(theme),
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
}));

// event title text
const StyledEventsTitleText = styled(Typography)(({ theme }) => ({
    ...textTitleStyles(theme),
    padding: theme.spacing(0, 1, 0, 0.5),
}));

// event subtitle icon
const StyledEventTypeIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 0,
    '& .MuiSvgIcon-root': {
        fontSize: theme.typography.body1.fontSize,
    },
}));

// event type?
const StyledLinkScriptIcon = styled(CodeIcon)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
}));

// event type
const StyledBoltIcon = styled(BoltIcon)(({ theme }) => ({
    fontSize: theme.typography.h5.fontSize,
}));

// event type?
const StyledLanguageIcon = styled(LanguageIcon)(({ theme }) => ({
    fontSize: theme.typography.h5.fontSize,
}));

// script and listeners loading
const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    height: theme.typography.h5.fontSize + ' !important',
    position: 'absolute',
    width: theme.typography.h5.fontSize + ' !important',
}));

export const ResourceTreeItem: React.FC<ResourceTreeItemProps> = ({
    uid = '',
    readOnlyMode = false,
    cronDescription,
    cronExpression,
    appName,
    name,
    fullPath,
    unsaved,
    wizardIncomplete,
    executing,
    deleting,
    warning,
    path,
    urlId,
    connectionName,
    eventType,
    remnantEnvironments,
    selectedNode,
    warningText,
    sectionName,
    scriptName,
    subItems,
    language,
    externalTriggerBaseUrl,
    inactive,
    onToggleExpand,
    openHandler,
    triggerHandler,
    deleteHandler,
    pathCopyHandler,
    urlCopyHandler,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const handleOpenNode = (): void => {
        openHandler(uid);
    };

    const handleTriggerNode = (e: React.MouseEvent<SVGSVGElement>): void => {
        e.stopPropagation();
        if (triggerHandler) triggerHandler(uid);
    };

    const handleDeleteNode = (e: React.MouseEvent<SVGSVGElement>): void => {
        e.stopPropagation();
        deleteHandler(uid, fullPath);
    };

    const handleApiHandlerPathCopy = (e: React.MouseEvent<SVGSVGElement>): void => {
        e.stopPropagation();
        if (pathCopyHandler) {
            pathCopyHandler(uid);
        }
    };

    const handleEventListenerUrlCopy = (e: React.MouseEvent<SVGSVGElement>): void => {
        e.stopPropagation();
        if (urlCopyHandler) {
            urlCopyHandler(uid);
        }
    };

    const isRemnant = remnantEnvironments && remnantEnvironments.length > 0;

    const theme = useTheme();

    const pulse = keyframes({
        '0%': {
            opacity: 0.2,
        },
        '50%': {
            backgroundColor: theme.palette.secondary.light,
            opacity: 0,
        },
        '100%': {
            opacity: 0.2,
        },
    });

    const wizardIncompleteStyle = {
        '&:before': {
            animation: `${pulse} 1.5s infinite ease`,
            backgroundColor: theme.palette.secondary.light,
            content: '""',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
        },
    };

    const selected = selectedNode === uid;

    const selectedStyle = {
        '& .MuiSvgIcon-root': {
            opacity: 1,
        },
    };
    const playButton = (
        <StyledPlayButtonIcon
            onClick={handleTriggerNode}
            sx={() => {
                return {
                    color: executing ? (theme) => theme.palette.text.disabled : undefined,
                };
            }}
        />
    );

    return (
        <>
            {sectionName === 'Scripts' &&
                (subItems ? (
                    <ResourceTreeFolder
                        key={fullPath}
                        readOnlyMode={readOnlyMode}
                        name={name || ''}
                        fullPath={fullPath || ''}
                        language={language}
                        externalTriggerBaseUrl={externalTriggerBaseUrl}
                        selectedNode={selectedNode}
                        itemList={subItems}
                        onToggleExpand={onToggleExpand}
                        openHandler={openHandler}
                        triggerHandler={triggerHandler}
                        deleteHandler={deleteHandler}
                    />
                ) : (
                    <StyledListItem key={uid} onClick={handleOpenNode} sx={selected ? selectedStyle : null}>
                        <StyledBoxFlexRow>
                            <StyledBoxFlexRow>
                                <StyledCodeIcon
                                    src={process.env.PUBLIC_URL + `/${language !== 'js' ? 'ts' : 'js'}.png`}
                                    alt={(language ? language : 'Typescript') + ' icon'}
                                />
                                <StyledScriptsTitleText noWrap>{name}</StyledScriptsTitleText>
                                {!readOnlyMode && unsaved && <ScriptSaveIcon />}
                            </StyledBoxFlexRow>
                            <>
                                {!readOnlyMode && !warning && !deleting && (
                                    <StyledPlayButtonWrap>
                                        <Tooltip title="Trigger Script Manually" placement="top">
                                            {playButton}
                                        </Tooltip>
                                        {executing && <StyledLoadingSpinner />}
                                    </StyledPlayButtonWrap>
                                )}
                            </>
                            {isRemnant && deleting ? (
                                <StyledBusyDeletingIcon aria-label="Deleting process" />
                            ) : (
                                !readOnlyMode && (
                                    <Tooltip title="Delete" placement="top">
                                        <StyledDeleteButtonIcon onClick={handleDeleteNode} />
                                    </Tooltip>
                                )
                            )}
                        </StyledBoxFlexRow>
                    </StyledListItem>
                ))}
            {sectionName === 'API Connections' && (
                <StyledListItem
                    key={uid}
                    onClick={handleOpenNode}
                    sx={selected ? selectedStyle : wizardIncomplete ? wizardIncompleteStyle : null}
                >
                    <StyledBoxFlexRow>
                        <StyledBoxFlexColumn>
                            <StyledBoxFlexRow sx={isRemnant ? remnantStyles : null}>
                                <Tooltip title={appName ?? ''} placement="top">
                                    <StyledListItemIcon role="img">
                                        <ProductIcon name={appName ?? ''} />
                                    </StyledListItemIcon>
                                </Tooltip>
                                <StyledConnectionsTitleText noWrap>
                                    {readOnlyMode ? appName : connectionName ?? 'New API Connection'}
                                </StyledConnectionsTitleText>
                            </StyledBoxFlexRow>
                            {path && (
                                <Tooltip title={`./api/${path}`} placement="top-start">
                                    <StyledSubtitleBox sx={isRemnant ? remnantStyles : null}>
                                        <StyledLinkIcon />
                                        <StyledSubtitleText noWrap>{path}</StyledSubtitleText>
                                    </StyledSubtitleBox>
                                </Tooltip>
                            )}
                            {!readOnlyMode && warning && !isRemnant && (
                                <StyledSubtitleBox>
                                    <ScriptWarningIcon />
                                    <StyledWarningText noWrap>{warningText}</StyledWarningText>
                                </StyledSubtitleBox>
                            )}
                        </StyledBoxFlexColumn>
                        {!readOnlyMode && !isRemnant && !deleting && path && (
                            <Tooltip title="Copy import statement" placement="top">
                                <StyledContentCopyIcon onClick={handleApiHandlerPathCopy} />
                            </Tooltip>
                        )}
                        {!isRemnant &&
                            (deleting ? (
                                <StyledBusyDeletingIcon aria-label="Deleting process" />
                            ) : (
                                !readOnlyMode && (
                                    <Tooltip title="Delete" placement="top">
                                        <StyledDeleteButtonIcon onClick={handleDeleteNode} />
                                    </Tooltip>
                                )
                            ))}
                    </StyledBoxFlexRow>
                </StyledListItem>
            )}
            {sectionName === 'Event Listeners' && (
                <StyledListItem
                    key={uid}
                    onClick={handleOpenNode}
                    sx={selected ? selectedStyle : wizardIncomplete ? wizardIncompleteStyle : null}
                >
                    <StyledBoxFlexRow>
                        <StyledBoxFlexColumn>
                            <StyledBoxFlexRow>
                                <StyledBoxFlexRow sx={isRemnant ? remnantStyles : null}>
                                    {appName !== APP.GENERIC.NAME ? (
                                        <Tooltip title="Event Listener" placement="top">
                                            <StyledBoltIcon />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Generic HTTP Event Listener" placement="top">
                                            <StyledLanguageIcon />
                                        </Tooltip>
                                    )}
                                    <StyledEventsTitleText noWrap>
                                        {eventType ?? 'New Event Listener'}
                                    </StyledEventsTitleText>
                                </StyledBoxFlexRow>
                            </StyledBoxFlexRow>
                            {appName && appName !== APP.GENERIC.NAME && (
                                <StyledSubtitleBox sx={isRemnant ? remnantStyles : null}>
                                    <Tooltip title={appName ?? ''} placement="top">
                                        <StyledEventTypeIcon role="img">
                                            <ProductIcon name={appName ?? ''} />
                                        </StyledEventTypeIcon>
                                    </Tooltip>
                                    <StyledSubtitleText noWrap>
                                        {readOnlyMode ? appName : connectionName ?? appName}
                                    </StyledSubtitleText>
                                </StyledSubtitleBox>
                            )}
                            {!readOnlyMode && appName && appName === APP.GENERIC.NAME && urlId && (
                                <Tooltip title={`${externalTriggerBaseUrl}/${urlId}`} placement="top-start">
                                    <StyledSubtitleBox sx={isRemnant ? remnantStyles : null}>
                                        <StyledLinkIcon />
                                        <StyledSubtitleText noWrap>{urlId}</StyledSubtitleText>
                                    </StyledSubtitleBox>
                                </Tooltip>
                            )}
                            {appName && appName === APP.GENERIC.NAME && scriptName && (
                                <StyledSubtitleBox sx={isRemnant ? remnantStyles : null}>
                                    <StyledLinkScriptIcon />
                                    <StyledScriptsTitleText noWrap>{scriptName}</StyledScriptsTitleText>
                                </StyledSubtitleBox>
                            )}
                            {!readOnlyMode && warning && !isRemnant && (
                                <StyledSubtitleBox>
                                    <ScriptWarningIcon />
                                    <StyledWarningText noWrap>{warningText}</StyledWarningText>
                                </StyledSubtitleBox>
                            )}
                            {!readOnlyMode && inactive && !warning && !isRemnant && (
                                <StyledSubtitleBox>
                                    <ScriptWarningIcon />
                                    <StyledWarningText noWrap>Inactive</StyledWarningText>
                                </StyledSubtitleBox>
                            )}
                        </StyledBoxFlexColumn>
                        {!readOnlyMode && appName && appName === APP.GENERIC.NAME && urlId && (
                            <Tooltip title="Copy URL" placement="top">
                                <StyledContentCopyIcon onClick={handleEventListenerUrlCopy} />
                            </Tooltip>
                        )}
                        {!readOnlyMode && !isRemnant && !warning && !deleting && (
                            <StyledPlayButtonWrap>
                                <Tooltip title="Emulate event with test payload" placement="top">
                                    {playButton}
                                </Tooltip>
                                {executing && <StyledLoadingSpinner />}
                            </StyledPlayButtonWrap>
                        )}
                        {!isRemnant &&
                            (deleting ? (
                                <StyledBusyDeletingIcon aria-label="Deleting process" />
                            ) : (
                                !readOnlyMode && (
                                    <Tooltip title="Delete" placement="top">
                                        <StyledDeleteButtonIcon onClick={handleDeleteNode} />
                                    </Tooltip>
                                )
                            ))}
                    </StyledBoxFlexRow>
                </StyledListItem>
            )}
            {sectionName === 'Scheduled Triggers' && (
                <StyledListItem key={uid} onClick={handleOpenNode} sx={selected ? selectedStyle : null}>
                    <StyledBoxFlexRow>
                        <StyledBoxFlexColumn>
                            <StyledBoxFlexRow sx={isRemnant ? remnantStyles : null}>
                                <Tooltip title={'Scheduled Trigger'} placement="top">
                                    <StyledListItemIcon role="img">
                                        <StyledScheduleIcon />
                                    </StyledListItemIcon>
                                </Tooltip>

                                {readOnlyMode && <StyledCronExpression>{'Scheduled Trigger'}</StyledCronExpression>}
                                {!readOnlyMode && (
                                    <Tooltip title={cronDescription ?? 'Add a cron expression'} placement="top">
                                        <StyledCronExpression>
                                            {cronExpression || 'New Scheduled Trigger'}
                                        </StyledCronExpression>
                                    </Tooltip>
                                )}
                            </StyledBoxFlexRow>
                            {scriptName && (
                                <Tooltip title={scriptName} placement="top-start">
                                    <StyledSubtitleBox sx={isRemnant ? remnantStyles : null}>
                                        <StyledScriptIcon />
                                        <StyledScriptsTitleText>{scriptName}</StyledScriptsTitleText>
                                    </StyledSubtitleBox>
                                </Tooltip>
                            )}
                            {!readOnlyMode && warning && !isRemnant && (
                                <StyledSubtitleBox>
                                    <ScriptWarningIcon />
                                    <StyledWarningText>{warningText}</StyledWarningText>
                                </StyledSubtitleBox>
                            )}
                            {!readOnlyMode && inactive && !warning && !isRemnant && (
                                <StyledSubtitleBox>
                                    <ScriptWarningIcon />
                                    <StyledWarningText>Inactive</StyledWarningText>
                                </StyledSubtitleBox>
                            )}
                        </StyledBoxFlexColumn>
                        {!isRemnant &&
                            (deleting ? (
                                <StyledBusyDeletingIcon aria-label="Deleting process" />
                            ) : (
                                !readOnlyMode && (
                                    <Tooltip title="Delete" placement="top">
                                        <StyledDeleteButtonIcon onClick={handleDeleteNode} />
                                    </Tooltip>
                                )
                            ))}
                    </StyledBoxFlexRow>
                </StyledListItem>
            )}
        </>
    );
};

import { refetch } from '../utils/refetch';
import { from, identity, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { hasError, isMetaDirectory, isMetaFile } from './guards';
import {
    PackageSelector,
    PackageJson,
    PackageError,
    FetchFromJsdelivrProps,
    FetchFromDelivrViaUrlProps,
    TypeDeclFile,
    MetaFile,
    MetaEntry,
    MetaDirectory,
} from './types';
import { selectedWorkspacePackages$ } from '../store/workspace/package-manager';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { satisfies } from 'semver';
import { typescriptVersion$ } from '../store/editor/editor';

export const JSDELIVR_URL = 'https://cdn.jsdelivr.net/npm';
export const DEFAULT_VERSION = 'latest';

// TODO: Validate response data

const getJsdelivrUrl = (name: string, version: string = DEFAULT_VERSION, path = ''): string =>
    !jsdelivrPinnedUrlCache.has(`${name}@${version}`)
        ? `${JSDELIVR_URL}/${name}@${version}${path}`
        : // eslint-disable-next-line sonarjs/no-nested-template-literals
          `${jsdelivrPinnedUrlCache.get(`${name}@${version}`)}${path}`;

const packageCache = new Map<string, Promise<PackageJson | PackageError>>();
export const jsdelivrPinnedUrlCache = new Map<string, string>();

/**
 * Fetch the package.json of an NPM package
 *
 * @param name regular or scoped package name
 * @param version exact or fuzzy version (1.0.0, ^1.0.0, latest, etc.) - defaults to latest
 */
export const getPackageJson = async ({
    name,
    version = DEFAULT_VERSION,
    path = '',
}: PackageSelector): Promise<PackageJson | PackageError> => {
    const key = `${name}@${version}${path}`;
    let promise = packageCache.get(key);
    if (!promise) {
        promise = fetchPackageJson(name, version, path);
        packageCache.set(key, promise);
    }
    const result = await promise;
    if (hasError(result)) {
        // Remove from cache to allow it to be retried later
        packageCache.delete(key);
    }
    return result;
};

const fetchPackageJson = async (name: string, version: string, path: string): Promise<PackageJson | PackageError> => {
    try {
        // Uncomment to simulate randomized errors, consider putting it behind a feature flag
        // if (Math.random() > 0.7) {
        //     throw Error('Randomized error');
        // }

        const response = await fetchFromJsdelivr({
            name,
            version,
            path: `${path}/package.json`,
        });

        const packageJson = (await response.json()) as PackageJson;

        if (version === 'latest' && !isFuzzyVersion(packageJson.version)) {
            const pinnedPackage = `${name}@${packageJson.version}`;
            const pinnedUrl = response.url
                .substring(0, response.url.length - 13)
                .replace('@latest', `@${packageJson.version}`);
            jsdelivrPinnedUrlCache.set(pinnedPackage, pinnedUrl);
            console.info(`Cached pinned URL - Package: ${pinnedPackage} - URL: ${pinnedUrl}`);
        }

        return packageJson;
    } catch (e) {
        return {
            name,
            version,
            path,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (e as any).responseStatus ?? String(e),
        };
    }
};

export const fetchFromJsdelivr = async ({
    name,
    version = DEFAULT_VERSION,
    path = '',
}: FetchFromJsdelivrProps): Promise<Response> => {
    const url = getJsdelivrUrl(name, version, path);
    const response = await fetchFromJsdelivrViaUrl({
        url,
        cache: isFuzzyVersion(version) ? 'no-cache' : undefined,
    });

    if (response.url.endsWith('/package.json')) {
        const pinnedVersion = response.url
            .substring(0, response.url.lastIndexOf('/package.json'))
            .substring(response.url.lastIndexOf('@') + 1);

        if (!isFuzzyVersion(pinnedVersion)) {
            const pinnedPackage = `${name}@${pinnedVersion}`;
            const pinnedUrl = response.url.substring(0, response.url.length - 13);
            jsdelivrPinnedUrlCache.set(pinnedPackage, pinnedUrl);
            console.info(`Cached pinned URL - Package: ${pinnedPackage} - URL: ${pinnedUrl}`);
        }
    }

    return response;
};

export const fetchFromJsdelivrViaUrl = async ({
    url,
    method,
    cache,
}: FetchFromDelivrViaUrlProps): Promise<Response> => {
    return await refetch(url, {
        method,
        cache,
    });
};

const metaCache = new Map<string, Promise<MetaEntry | PackageError>>();

const isFuzzyVersion = (version: string): boolean =>
    version.toLowerCase() === 'latest' ||
    version.toLowerCase() === '*' ||
    version.toLowerCase() === 'x' ||
    version.startsWith('^') ||
    version.startsWith('~');

/**
 * Get meta data for a directory or file within a package
 * This returns a fully recursive listing for directories
 */
export const getMeta = async ({
    name,
    version = DEFAULT_VERSION,
}: PackageSelector): Promise<MetaEntry | PackageError> => {
    const key = `${name}@${version}`;
    let promise = metaCache.get(key);
    if (!promise) {
        promise = fetchMeta(name, version);
        metaCache.set(key, promise);
    }
    const result = await promise;

    if (hasError(result)) {
        // Remove from cache to allow it to be retried later
        metaCache.delete(key);
        console.error(`Failed to load package metadata: ${name}@${version}`, result.error);
    }
    return result;
};

const fetchMeta = async (name: string, version: string): Promise<MetaEntry | PackageError> => {
    try {
        // Uncomment to simulate randomized errors, consider putting it behind a feature flag
        // if (Math.random() > 0.7) {
        //     throw Error('Randomized error')
        // }

        if (jsdelivrPinnedUrlCache.has(`${name}@${version}`)) {
            return await fetchMetaFromJsdelivr(name, version);
        } else {
            // Don't try to fetch meta from Jsdelivr if the package is not cached,
            // as it means that the parsing most likely failed and will cause weird redirect loop if tried here
            throw 'Not found in Jsdelivr cache';
        }
    } catch (e) {
        return {
            name,
            version,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (e as any).responseStatus ?? String(e),
        };
    }
};

const fetchMetaFromJsdelivr = async (name: string, version: string): Promise<MetaEntry> => {
    const response = await fetchFromJsdelivrViaUrl({
        url: `https://data.jsdelivr.com/v1/package/npm/${name}@${version}/flat`,
    });

    const meta: { files: { name: string; hash: string }[] } = await response.json();

    const files = meta.files
        .filter((f) => f.name.endsWith('.js') || f.name.endsWith('.d.ts'))
        .map(
            (f) =>
                ({
                    type: 'file',
                    path: f.name,
                    integrity: f.hash,
                } as MetaFile)
        );

    return {
        path: '/',
        type: 'directory',
        files,
    } as MetaDirectory;
};

/**
 * Flatten the nested meta data to an array of files
 */
const flattenMeta = (meta: MetaEntry | PackageError): MetaFile[] => {
    if (isMetaFile(meta)) {
        return [
            {
                ...meta,
            },
        ];
    } else if (isMetaDirectory(meta)) {
        return meta.files.reduce((files, entry) => {
            files.push(...flattenMeta(entry));
            return files;
        }, [] as MetaFile[]);
    }
    return [];
};

const isTypeDeclaration = (entry: MetaFile): boolean => entry.path.endsWith('.d.ts');

/**
 * Get the .d.ts files for a package
 */
// eslint-disable-next-line max-len
export const getTypeDeclaration$ =
    () =>
    ({
        name,
        version = DEFAULT_VERSION,
        path = '/',
        filter: fileFilter,
        typings,
        types,
        main,
        typesVersions,
    }: // eslint-disable-next-line sonarjs/cognitive-complexity
    PackageSelector): Observable<TypeDeclFile> => {
        const baseUrl = getJsdelivrUrl(name, version);
        let fileRedirects: Record<string, string[]> = {};
        if (typesVersions) {
            const typescriptVersion = typescriptVersion$.value;
            for (const [versionRange, fileRedirect] of Object.entries(typesVersions)) {
                if (!typescriptVersion || satisfies(typescriptVersion, versionRange)) {
                    fileRedirects = fileRedirect;
                    break;
                }
            }
        }
        const mungedName = name.startsWith('@')
            ? name.startsWith('@types/')
                ? name.slice(7)
                : name.slice(1).replace('/', '__')
            : name;

        const typesStartIndex = types?.startsWith('./') ? 2 : types?.startsWith('/') ? 1 : 0;
        const mainStartIndex = main?.startsWith('./') ? 2 : main?.startsWith('/') ? 1 : 0;
        const typesBasePath =
            types && types.includes('/')
                ? types.substring(typesStartIndex, types.lastIndexOf('/'))
                : main && main.includes('/')
                ? main.substring(mainStartIndex, main.lastIndexOf('/'))
                : undefined;

        const selected = selectedWorkspacePackages$.value;
        return from(getMeta({ name, version, path })).pipe(
            mergeMap((meta) => {
                const typeDecFiles = flattenMeta(meta).filter(isTypeDeclaration);
                if (
                    typeDecFiles.length === 0 &&
                    !name.startsWith('@types/') &&
                    selected.find((pkg) => pkg.name === name) &&
                    !selected.find((pkg) => `@types/${name}` === pkg.name)
                ) {
                    console.error(`Could not find type declaration files for: ${name}`);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to load type declarations for "${name}" package, make sure you're using a package with type declarations or add "@types/${name}" package if it exists.`,
                        noToast: true,
                    });
                }

                return from(typeDecFiles);
            }),
            fileFilter ? filter(fileFilter) : identity,
            map((entry) => ({
                ...entry,
                name,
                version,
                baseUrl,
                filePath: `file:///node_modules/@types/${mungedName}${getAdjustedPath(
                    entry.path,
                    typesBasePath,
                    typings,
                    fileRedirects[entry.path] ?? fileRedirects['*'] ?? undefined
                )}`,
            }))
        );
    };

const getAdjustedPath = (path: string, typesBasePath?: string, typings?: string, redirects?: string[]): string => {
    if (typings && path.length > 0 && path.substr(1) === typings) {
        return '/index.d.ts';
    }
    if (redirects) {
        redirects.forEach((redirect) => {
            const redirectStartIndex = redirect.startsWith('./') ? 2 : redirect.startsWith('/') ? 1 : 0;
            const redirectBasePath = redirect.substring(redirectStartIndex, redirect.lastIndexOf('/'));
            path = path.replace(`/${redirectBasePath}`, '');
        });
    }
    if (typesBasePath) {
        path = path.replace(`/${typesBasePath}`, '');
    }
    return path;
};

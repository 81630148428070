import { getOnboardingDetails as getOnboardingDetailsRpc } from '@avst-stitch/repository-lib/lib';
import { saveOnboardingResponses as saveOnboardingResponsesRpc } from '@avst-stitch/repository-lib/lib';
import { Response as OnboardingDetails } from '@avst-stitch/repository-lib/lib/rpcs/getOnboardingDetails';
import { Request as SaveOnboardingResponsesRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveOnboardingResponses';
import { repositoryInvoker } from '../utils/repository';
export type { OnboardingDetails };

export const getOnboardingDetails = async (): Promise<OnboardingDetails> => {
    return await getOnboardingDetailsRpc(repositoryInvoker);
};

export const saveOnboardingResponses = async (request: SaveOnboardingResponsesRequest): Promise<void> => {
    return await saveOnboardingResponsesRpc(repositoryInvoker, request);
};

import { LoaderFn } from 'react-location';
import { acceptOrganizationMemberInvite, getLoggedUserDetails } from '../data/user';
import { LocationGenerics } from '../router';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { loggedInUserDetails$ } from '../store/user';
import { InformativeError, VerificationRequiredError } from '../utils/repository';
import { loadErrorPage } from '../store/error';
import { promptMessage } from '../store/confirm';

export const appLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        const userDetails = await getLoggedUserDetails();
        loggedInUserDetails$.next(userDetails);
    } catch (e) {
        if (e instanceof VerificationRequiredError) {
            loggedInUserDetails$.next({
                accountVerified: false,
            });
        } else {
            loadErrorPage({
                error: e,
                genericMessage: 'Failed to load user details.',
            });

            throw e;
        }
    }

    try {
        const inviteId = new URLSearchParams(window.location.search).get('inviteId');
        if (inviteId && loggedInUserDetails$.value?.termsAccepted) {
            const { organizationName } = await acceptOrganizationMemberInvite(inviteId);
            promptMessage(`Successfully joined team: ${organizationName}`);
        }
    } catch (e) {
        const errorMessage = e instanceof InformativeError ? `. ${e.message}` : '';

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message:
                `Could not join team ${errorMessage}. Click on the invite link again ` +
                'and if the issue persists please contact support.',
            toastOptions: {
                autoClose: false,
            },
        });

        throw e;
    }

    return {};
};

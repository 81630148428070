import { BehaviorSubject, debounceTime, map, Subject, tap } from 'rxjs';
import { newReadmeFileCreatedAction$, selectedWorkspaceSelectedResource$, selectedWorkspaceUid$ } from '.';
import { InformativeError } from '../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { saveReadmeFile as saveReadmeFileRpcCall, ReadmeFile } from '../../data/readme';
import { getDefaultReadmeFile, getDefaultReadmeFileUid } from './utils';
import { marked } from 'marked';
import sanitizeMarkdown from 'sanitize-markdown';

const renderer = {
    link(href: string, title: string, text: string) {
        return `<a href="${href}" target="_blank" title="${title}">
                ${text}
                </a>`;
    },
};
marked.use({ renderer });

export const selectReadmeFileAction$ = monitor('selectReadmeFileAction$', new Subject<string>());
export const navigateToReadmeFileAction$ = monitor('navigateToReadmeFileAction$', new Subject<string>());
export const saveReadmeFileAction$ = monitor('saveReadmeFileAction$', new Subject<void>());

export const selectedReadmeFileContentChangedAction$ = monitor(
    'selectedReadmeFileContentChangedAction$',
    new BehaviorSubject<string>('')
);

export const savedReadmeFileDetails$ = monitor(
    'savedReadmeFileDetails$',
    new BehaviorSubject<Record<string, ReadmeFile>>({})
);
export const unsavedReadmeFileDetails$ = monitor(
    'unsavedReadmeFileDetails$',
    new BehaviorSubject<Record<string, ReadmeFile>>({})
);
export const parsedReadmeFileContent$ = monitor(
    'parsedReadmeFileContent$',
    new BehaviorSubject<Record<string, string>>({})
);
export const selectedReadmeFileUid$ = monitor('selectedReadmeFileUid$', new BehaviorSubject<string | null>(null));
export const readmeFileBeingSaved$ = monitor('readmeFileBeingSaved$', new BehaviorSubject<boolean>(false));
export const readmeFileHasUnsavedChanges$ = monitor(
    'readmeFileHasUnsavedChanges$',
    new BehaviorSubject<Record<string, boolean>>({})
);

selectReadmeFileAction$.subscribe((fileUid) => {
    selectedReadmeFileUid$.next(fileUid);
    const unsavedScriptDetails = unsavedReadmeFileDetails$.value[fileUid];
    if (unsavedScriptDetails) {
        selectedReadmeFileContentChangedAction$.next(unsavedScriptDetails?.content);
        selectedWorkspaceSelectedResource$.next({
            resourceType: 'README',
            uid: fileUid,
        });
    }
});

selectedReadmeFileContentChangedAction$.subscribe((content) => {
    if (selectedReadmeFileUid$.value) {
        const unsavedReadmeFileDetails = unsavedReadmeFileDetails$.value[selectedReadmeFileUid$.value];

        const updatedDetails = unsavedReadmeFileDetails
            ? { ...unsavedReadmeFileDetails, content }
            : { name: 'README.md', content };

        unsavedReadmeFileDetails$.next({
            ...unsavedReadmeFileDetails$.value,
            [selectedReadmeFileUid$.value]: {
                ...updatedDetails,
            },
        });
    }
});

saveReadmeFileAction$
    .pipe(
        map(async () => {
            const fileUid = selectedReadmeFileUid$.value;
            const tempFileUid = getDefaultReadmeFileUid();
            if (!fileUid || fileUid === tempFileUid) {
                await createReadmeFile(tempFileUid);
            } else {
                await saveReadmeFile(fileUid);
            }
        })
    )
    .subscribe();

selectedReadmeFileContentChangedAction$
    .pipe(
        debounceTime(100),
        tap(() => updateReadmeFileSavedStatus(selectedReadmeFileUid$.value ?? ''))
    )
    .subscribe();

selectedReadmeFileContentChangedAction$
    .pipe(
        debounceTime(500),
        tap((content) => {
            if (selectedReadmeFileUid$.value) {
                const parsedContent = getSanitizedParsedMarkdown(content ?? '');
                parsedReadmeFileContent$.next({
                    ...parsedReadmeFileContent$.value,
                    [selectedReadmeFileUid$.value]: parsedContent,
                });
            }
        })
    )
    .subscribe();

const updateReadmeFileSavedStatus = (fileUid: string): void => {
    readmeFileHasUnsavedChanges$.next({
        ...readmeFileHasUnsavedChanges$.value,
        [fileUid]: readmeFileHasUnsavedChanges(fileUid),
    });
};

const readmeFileHasUnsavedChanges = (fileUid: string): boolean =>
    savedReadmeFileDetails$.value[fileUid]?.content !== unsavedReadmeFileDetails$.value[fileUid]?.content;

export const createReadmeFile = async (tempFileUid = getDefaultReadmeFileUid()): Promise<void> => {
    // const tempFileUid = defaultReadmeFileUid;

    const readmeFileHasUnsavedChanges = readmeFileHasUnsavedChanges$.value;

    const unsavedReadmeFileDetails = unsavedReadmeFileDetails$.value;
    const unsavedReadmeFileDetailsForTempUid = unsavedReadmeFileDetails[tempFileUid];

    const readme = !unsavedReadmeFileDetailsForTempUid ? getDefaultReadmeFile() : unsavedReadmeFileDetailsForTempUid;

    readmeFileBeingSaved$.next(true);

    try {
        const { uid: readmeFileUid } = await saveReadmeFileRpcCall(selectedWorkspaceUid$.value ?? '', readme.content);
        newReadmeFileCreatedAction$.next(readmeFileUid);

        delete unsavedReadmeFileDetails[tempFileUid];
        delete readmeFileHasUnsavedChanges[tempFileUid];

        const newReadme = {
            name: readme.name,
            content: readme.content,
        };
        unsavedReadmeFileDetails$.next({ ...unsavedReadmeFileDetails, [readmeFileUid]: newReadme });

        savedReadmeFileDetails$.next({
            ...savedReadmeFileDetails$.value,
            [readmeFileUid]: newReadme,
        });

        readmeFileHasUnsavedChanges$.next({
            ...readmeFileHasUnsavedChanges,
            [readmeFileUid]: false,
        });

        selectedReadmeFileUid$.next(readmeFileUid);
        selectedReadmeFileContentChangedAction$.next(readme.content);

        navigateToReadmeFileAction$.next(readmeFileUid);
    } catch (e) {
        if (e instanceof InformativeError) {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: `Error while saving README.md file: ${e.message}. Please try saving the file again, if the issue persists please contact support.`,
            });
        } else {
            console.error('Failed to save README.md file while saving', e);
            publishGenericError();
        }
    }

    readmeFileBeingSaved$.next(false);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const saveReadmeFile = async (fileUid: string): Promise<void> => {
    const readme = unsavedReadmeFileDetails$.value[fileUid];
    if (readme) {
        readmeFileBeingSaved$.next(true);

        try {
            await saveReadmeFileRpcCall(selectedWorkspaceUid$.value ?? '', readme.content, fileUid);
        } catch (e) {
            if (e instanceof InformativeError) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Error while saving README.md file: ${e.message}. Please try saving the file again, if the issue persists please contact support.`,
                });
            } else {
                console.error('Failed to save README.md file while saving', e);
                publishGenericError();
            }
        }

        savedReadmeFileDetails$.value[fileUid] = {
            name: readme.name,
            content: readme.content,
            // TODO: set last modified date and by whom
        };

        updateReadmeFileSavedStatus(fileUid);
        selectedReadmeFileContentChangedAction$.next(readme.content);

        readmeFileBeingSaved$.next(false);
    } else {
        publishGenericError();
    }
};

const publishGenericError = (): void => {
    publishLocalFeedbackEventAction$.next({
        level: 'ERROR',
        message:
            'Error while saving README.md file. Please try saving the file again, if the issue persists please contact support.',
    });
};

export const getSanitizedParsedMarkdown = (content: string): string => {
    return sanitizeMarkdown(marked.parse(content));
};

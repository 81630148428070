/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button } from '../../buttons/Button';
import { CreateOrganizationWizardSteps, CreateOrganizationWizardStage } from './CreateOrganizationWizardSteps';
import { OrgAvatarContainer } from '../../avatar';
import { BillingPlanCard } from './BillingPlanCard';
import { ExistingBillingDetailsList } from '../billing-details/ExistingBillingDetailsList';
import { DialogAlert, DialogTitleSmall } from '../../dialog';
import { IconCircle } from '../../icon-circle';
import isEmail from 'validator/lib/isEmail';
import { PageItalicText } from '../../layout';
import { StyledImageBox, StyledImageTextBox, StyledOrgDetailsContainer } from '../OrganizationComponents';
import { handleKeyUp } from '../../../utils/handleKeyUp';
import { autoFocus, isFocused } from '../../../utils/autoFocus';
import { OrganizationPlan } from '../../../data/organization';
import { OrganizationInvoicePlanInformation } from './OrganizationInvoicePlanInformation';

export interface CreateOrganizationDialogProps {
    avatar?: string;
    billingEntities?: {
        uid: string;
        contactName: string;
        contactEmail: string;
    }[];
    errors?: string;
    freeOrganizationsLeft: number;
    disabledPaidTiers?: boolean;
    isSalablePlan?: boolean;
    open?: boolean;
    saving?: boolean;
    onAvatarUpload: () => void;
    onCancel: () => void;
    onCreate(event: CreateOrgEvent): void;
}

export interface CreateOrgEvent {
    name: string;
    description?: string;
    plan: Plan;
    paymentMethod: PaymentMethod;
    existingBillingEntityUid?: string;
    newBillingEntity?: {
        contactName: string;
        contactEmail: string;
    };
    emails: string[];
}

export interface Plan {
    tier: 'free' | 'silver' | 'gold' | 'platinum';
    period: 'monthly' | 'annually';
    subscriptionStatus?: OrganizationPlan['subscriptionStatus'];
}
type BillingForm = 'saved' | 'create';
type PaymentMethod = OrganizationPlan['paymentType'];

export const prices = {
    silver: {
        monthly: '200',
        annually: '2000',
    },
    gold: {
        monthly: '400',
        annually: '4000',
    },
    platinum: {
        monthly: '600',
        annually: '6000',
    },
    suspended: {
        monthly: '0',
        annually: '0',
    },
};

const StyledCardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& .MuiCard-root:not(:last-of-type)': {
        marginRight: theme.spacing(2),
    },
    marginBottom: theme.spacing(1),
}));

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        minHeight: 750,
        minWidth: 1176,
    },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
}));

const StyledSelectPaymentMethodContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(0.5),
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const StyledPaymentMethodContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const StyledPaymentMethodTooltipContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const StyledPaymentMethodTooltipButton = styled(Button)(() => ({
    textTransform: 'capitalize',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    width: '60%',
    marginBottom: theme.spacing(1),
    borderRadius: theme.constants.borderRadius,
    outline: `1px solid ${theme.palette.action.focus}`,
    '&:hover': {
        outline: theme.constants.borderSelected,
    },
}));

const StyledFormControlLabelTitle = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const StyledPaymentMethodRadioGroup = styled(RadioGroup)(({ theme }) => ({
    paddingLeft: theme.spacing(1.625),
}));

const StyledFormControlLabelText = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    borderRadius: 0,
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(0.5),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    outline: `1px solid ${theme.palette.action.focus}`,
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    outline: `1px solid ${theme.palette.action.focus}`,
}));

const StyledSpan = styled('span')(({ theme }) => ({
    color: theme.palette.success.main,
}));

export const disabledFreeOrganizationTooltip = `You have hit the limit of free teams permitted on your user account. You can either upgrade an existing free team to a paid plan and create a new free team or create a new team on a paid plan directly. Contact us if you need any support with this!`;

export const CreateOrganizationWizardDialog: React.FC<CreateOrganizationDialogProps> = ({
    avatar,
    billingEntities = [],
    errors,
    freeOrganizationsLeft,
    disabledPaidTiers = false,
    isSalablePlan,
    open = false,
    saving = false,
    onAvatarUpload,
    onCancel,
    onCreate,
}) => {
    const [stage, setStage] = useState<CreateOrganizationWizardStage>(CreateOrganizationWizardStage.BASIC_INFO);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [tier, setTier] = useState<Plan['tier'] | undefined>(undefined);
    const [period, setPeriod] = useState<Plan['period']>('annually');
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('CARD');
    const [billingFormType, setBillingFormType] = useState<BillingForm>(billingEntities.length ? 'saved' : 'create');
    const [billingContactName, setBillingContactName] = useState('');
    const [billingContactEmail, setBillingContactEmail] = useState('');
    const [selectedBillingEntity, setSelectedBillingEntity] = useState(billingEntities[0]?.uid);
    const [emailsValue, setEmailsValue] = useState('');

    const teamNameRef = useRef<HTMLInputElement>(null);
    const teamDescriptionRef = useRef<HTMLInputElement>(null);
    const billingContactNameRef = useRef<HTMLInputElement>(null);
    const emailsRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!billingEntities.length) {
            setBillingFormType('create');
        } else {
            setBillingFormType('saved');
        }
    }, [billingEntities.length]);

    useEffect(() => {
        setStage(CreateOrganizationWizardStage.BASIC_INFO);
        setName('');
        setDescription('');
        setTier(undefined);
        setPeriod('annually');
        setPaymentMethod('CARD');
        setBillingContactName('');
        setBillingContactEmail('');
        setSelectedBillingEntity(billingEntities[0]?.uid);
        setEmailsValue('');
        autoFocus(teamNameRef);
    }, [open]);

    useEffect(() => {
        if (billingFormType === 'create' && stage === CreateOrganizationWizardStage.BILLING_DETAILS) {
            autoFocus(billingContactNameRef);
        }
    }, [billingFormType]);

    useEffect(() => {
        if (stage === CreateOrganizationWizardStage.INVITE_MEMBERS) {
            autoFocus(emailsRef);
        }
    }, [stage]);

    const currentEmails = emailsValue
        .split(' ')
        .filter((e) => e.length)
        .map((e) => e.trim());

    const inviteeEmailValidationError = currentEmails.some((ce) => !isEmail(ce));

    const billingEmailValidationError = !!billingContactEmail && !isEmail(billingContactEmail);

    const hasValidBillingDetails =
        billingFormType === 'saved'
            ? !!selectedBillingEntity
            : !!billingContactEmail && !!billingContactName && !billingEmailValidationError;

    const error = <DialogAlert severity="error" alertTitle="Error" text={errors} />;

    const handleCreateOrganization = (): void => {
        if (tier) {
            const event: CreateOrgEvent = {
                name,
                description,
                paymentMethod,
                plan: {
                    period,
                    tier,
                },
                emails: currentEmails,
            };

            if (tier !== 'free') {
                if (billingFormType === 'create') {
                    event.newBillingEntity = {
                        contactEmail: billingContactEmail,
                        contactName: billingContactName,
                    };
                } else {
                    event.existingBillingEntityUid = selectedBillingEntity;
                }
            }

            onCreate(event);
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const getStageContent = (): JSX.Element => {
        switch (stage) {
            case CreateOrganizationWizardStage.BASIC_INFO:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterCondition: !!name && !isFocused(teamDescriptionRef),
                                enterFn: () => setStage(CreateOrganizationWizardStage.SELECT_PLAN),
                                escFn: onCancel,
                            })
                        }
                    >
                        <CreateOrganizationWizardSteps stage={stage} freeTier={tier === 'free'} period={period} />
                        <DialogTitleSmall icon={<IconCircle icon={<CorporateFareIcon />} />} title="Team details" />
                        {errors && error}
                        <DialogContent>
                            <StyledOrgDetailsContainer>
                                {avatar ? (
                                    <StyledImageBox>
                                        <img src={`data:image/jpeg;base64,${avatar}`} width={52} height={52} alt="" />
                                    </StyledImageBox>
                                ) : (
                                    <OrgAvatarContainer name={''} style="round" />
                                )}
                                <StyledImageTextBox>
                                    <Link onClick={onAvatarUpload}>Upload image/logo</Link>
                                    <PageItalicText>File type .PNG, .JPEG (max file size 2MBs)</PageItalicText>
                                </StyledImageTextBox>
                            </StyledOrgDetailsContainer>
                            <TextField
                                inputRef={teamNameRef}
                                variant="outlined"
                                label="Team name"
                                placeholder="Enter a name"
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                                required
                            />
                            <TextField
                                inputRef={teamDescriptionRef}
                                variant="outlined"
                                label="Description"
                                placeholder="Enter a description"
                                value={description}
                                multiline
                                maxRows={2}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => onCancel()}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!name}
                                onClick={() => setStage(CreateOrganizationWizardStage.SELECT_PLAN)}
                            >
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            case CreateOrganizationWizardStage.SELECT_PLAN:
                return (
                    <StyledDialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                escFn: onCancel,
                            })
                        }
                    >
                        <CreateOrganizationWizardSteps stage={stage} freeTier={tier === 'free'} period={period} />
                        <DialogTitleSmall title="Select your plan" icon={<IconCircle icon={<CategoryIcon />} />} />
                        {errors && error}
                        <StyledRadioGroup
                            name="controlled-radio-buttons-group-plan"
                            value={period}
                            onChange={(e) => setPeriod(e.target.value as Plan['period'])}
                        >
                            {!!isSalablePlan && (
                                <FormControlLabel
                                    value="monthly"
                                    control={<Radio />}
                                    label="Monthly billing"
                                    sx={{
                                        '&>.MuiTypography-root':
                                            period === 'monthly' ? { fontWeight: 500 } : { fontWeight: 400 },
                                    }}
                                />
                            )}

                            <FormControlLabel
                                value="annually"
                                control={<Radio />}
                                label={
                                    <>
                                        <span>Yearly billing </span>
                                        <StyledSpan>(2 months for free)</StyledSpan>
                                    </>
                                }
                                sx={{
                                    '&>.MuiTypography-root':
                                        period === 'annually' ? { fontWeight: 500 } : { fontWeight: 400 },
                                }}
                            />
                        </StyledRadioGroup>

                        <StyledPaymentMethodContainer>
                            <StyledSelectPaymentMethodContainer>
                                <Typography color="text.secondary">Available Payment Methods:</Typography>
                                <StyledChip label="Credit / Debit Card" icon={<CreditCardIcon />} />
                                {period === 'annually' && <StyledChip label="Invoice" icon={<ReceiptIcon />} />}
                            </StyledSelectPaymentMethodContainer>
                            <StyledPaymentMethodTooltipContainer>
                                <Tooltip
                                    title="To switch between invoice and credit card payment methods, please create a new team and select your preferred payment method during the setup process."
                                    placement="top"
                                >
                                    <StyledPaymentMethodTooltipButton variant="text" startIcon={<InfoOutlinedIcon />}>
                                        Changing Payment Method
                                    </StyledPaymentMethodTooltipButton>
                                </Tooltip>
                            </StyledPaymentMethodTooltipContainer>
                        </StyledPaymentMethodContainer>

                        <DialogContent>
                            <StyledCardContainer>
                                {freeOrganizationsLeft < 1 ? (
                                    <Tooltip title={disabledFreeOrganizationTooltip}>
                                        <Box sx={(theme) => ({ margin: theme.spacing(0, 2) })}>
                                            <BillingPlanCard
                                                disabled
                                                title="Free"
                                                price="Free"
                                                timeUnit={period === 'monthly' ? 'monthly' : 'yearly'}
                                                value="free"
                                                onUpgrade={() => {
                                                    setTier('free');
                                                    setPaymentMethod('FREE');
                                                    setStage(CreateOrganizationWizardStage.INVITE_MEMBERS);
                                                }}
                                            />
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    <BillingPlanCard
                                        title="Free"
                                        price="Free"
                                        timeUnit={period === 'monthly' ? 'monthly' : 'yearly'}
                                        value="free"
                                        onUpgrade={() => {
                                            setTier('free');
                                            setPaymentMethod('FREE');
                                            setStage(CreateOrganizationWizardStage.INVITE_MEMBERS);
                                        }}
                                    />
                                )}

                                <BillingPlanCard
                                    title="Silver"
                                    price={prices.silver[period] || ''}
                                    timeUnit={period === 'monthly' ? 'monthly' : 'yearly'}
                                    value="silver"
                                    disabled={disabledPaidTiers}
                                    onUpgrade={() => {
                                        setTier('silver');
                                        setStage(
                                            period === 'monthly'
                                                ? CreateOrganizationWizardStage.INVITE_MEMBERS
                                                : CreateOrganizationWizardStage.PAYMENT_METHOD
                                        );
                                    }}
                                />
                                <BillingPlanCard
                                    title="Gold"
                                    price={prices.gold[period] || ''}
                                    timeUnit={period === 'monthly' ? 'monthly' : 'yearly'}
                                    value="gold"
                                    disabled={disabledPaidTiers}
                                    onUpgrade={() => {
                                        setTier('gold');
                                        setStage(
                                            period === 'monthly'
                                                ? CreateOrganizationWizardStage.INVITE_MEMBERS
                                                : CreateOrganizationWizardStage.PAYMENT_METHOD
                                        );
                                    }}
                                />
                                <BillingPlanCard
                                    title="Platinum"
                                    price={prices.platinum[period] || ''}
                                    timeUnit={period === 'monthly' ? 'monthly' : 'yearly'}
                                    value="platinum"
                                    disabled={disabledPaidTiers}
                                    onUpgrade={() => {
                                        setTier('platinum');
                                        setStage(
                                            period === 'monthly'
                                                ? CreateOrganizationWizardStage.INVITE_MEMBERS
                                                : CreateOrganizationWizardStage.PAYMENT_METHOD
                                        );
                                    }}
                                />
                            </StyledCardContainer>
                        </DialogContent>
                        <StyledDialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(CreateOrganizationWizardStage.BASIC_INFO)}
                            >
                                Back
                            </Button>
                        </StyledDialogActions>
                    </StyledDialog>
                );
            case CreateOrganizationWizardStage.PAYMENT_METHOD:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterFn: () => setStage(CreateOrganizationWizardStage.INVITE_MEMBERS),
                                escFn: () => {
                                    setPaymentMethod('CARD');
                                    onCancel();
                                },
                            })
                        }
                    >
                        <CreateOrganizationWizardSteps stage={stage} freeTier={tier === 'free'} period={period} />
                        <DialogTitleSmall
                            title="Payment method"
                            icon={<IconCircle icon={<PaymentsOutlinedIcon />} />}
                        />
                        {errors && error}
                        <DialogContent>
                            <StyledPaymentMethodRadioGroup
                                name="controlled-radio-buttons-group-billing"
                                value={paymentMethod}
                                onChange={(e) => setPaymentMethod(e.target.value as PaymentMethod)}
                            >
                                <StyledFormControlLabel
                                    value="CARD"
                                    control={<Radio />}
                                    label={
                                        <>
                                            <StyledFormControlLabelTitle>
                                                <CreditCardIcon />
                                                <StyledFormControlLabelText>
                                                    Credit / Debit Card
                                                </StyledFormControlLabelText>
                                            </StyledFormControlLabelTitle>
                                        </>
                                    }
                                    sx={{
                                        '&>.MuiTypography-root': {
                                            fontWeight: paymentMethod === 'CARD' ? 500 : 400,
                                        },
                                    }}
                                />

                                <StyledFormControlLabel
                                    value="INVOICE"
                                    control={<Radio />}
                                    label={
                                        <>
                                            <StyledFormControlLabelTitle>
                                                <ReceiptIcon />
                                                <StyledFormControlLabelText>Invoice</StyledFormControlLabelText>
                                            </StyledFormControlLabelTitle>
                                        </>
                                    }
                                    sx={{
                                        '&>.MuiTypography-root': {
                                            fontWeight: paymentMethod === 'INVOICE' ? 500 : 400,
                                        },
                                    }}
                                />
                            </StyledPaymentMethodRadioGroup>
                            {paymentMethod === 'INVOICE' && <OrganizationInvoicePlanInformation />}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setTier('platinum');
                                    setPaymentMethod('CARD');
                                    setStage(CreateOrganizationWizardStage.SELECT_PLAN);
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                disabled={paymentMethod === 'FREE'}
                                onClick={() => setStage(CreateOrganizationWizardStage.INVITE_MEMBERS)}
                            >
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            case CreateOrganizationWizardStage.INVITE_MEMBERS:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterCondition: !saving && !inviteeEmailValidationError && !isFocused(emailsRef),
                                enterFn: () =>
                                    tier === 'free'
                                        ? handleCreateOrganization()
                                        : setStage(CreateOrganizationWizardStage.BILLING_DETAILS),
                                escFn: onCancel,
                            })
                        }
                    >
                        <CreateOrganizationWizardSteps stage={stage} freeTier={tier === 'free'} period={period} />
                        <DialogTitleSmall
                            title="Invite new member"
                            icon={<IconCircle icon={<PersonAddAltOutlinedIcon />} />}
                        />
                        {errors && error}
                        <DialogContent>
                            <TextField
                                inputRef={emailsRef}
                                label="Work emails (separated by spaces)"
                                placeholder="Enter emails (separated by spaces)"
                                multiline
                                rows={2}
                                error={inviteeEmailValidationError}
                                value={emailsValue}
                                onChange={(e) => setEmailsValue(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                busy={saving}
                                disabled={saving}
                                onClick={() => {
                                    if (period === 'monthly' || tier === 'free') {
                                        setStage(CreateOrganizationWizardStage.SELECT_PLAN);
                                        setPaymentMethod('CARD');
                                    } else {
                                        setStage(CreateOrganizationWizardStage.PAYMENT_METHOD);
                                    }
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                busy={saving}
                                disabled={saving || inviteeEmailValidationError}
                                onClick={() =>
                                    tier === 'free'
                                        ? handleCreateOrganization()
                                        : setStage(CreateOrganizationWizardStage.BILLING_DETAILS)
                                }
                            >
                                {tier === 'free' ? 'Create' : 'Next'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            case CreateOrganizationWizardStage.BILLING_DETAILS:
                return (
                    <Dialog
                        open={open}
                        onKeyUp={(event) =>
                            handleKeyUp({
                                event,
                                enterCondition: !saving && hasValidBillingDetails,
                                enterFn: handleCreateOrganization,
                                escFn: onCancel,
                            })
                        }
                    >
                        <CreateOrganizationWizardSteps stage={stage} freeTier={tier === 'free'} period={period} />
                        <DialogTitleSmall
                            title="Billing details"
                            icon={<IconCircle icon={<ReceiptLongOutlinedIcon />} />}
                        />
                        {errors && error}
                        <DialogContent>
                            <StyledAccordion
                                disabled={!billingEntities.length}
                                expanded={billingFormType === 'saved'}
                                onChange={(_, checked) => (checked ? setBillingFormType('saved') : undefined)}
                            >
                                <StyledAccordionSummary
                                    expandIcon={billingFormType === 'saved' ? <RemoveIcon /> : <AddIcon />}
                                >
                                    Use existing billing details
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <ExistingBillingDetailsList
                                        billingEntities={billingEntities}
                                        onSelect={(uid) => setSelectedBillingEntity(uid)}
                                        selectedBillingEntity={selectedBillingEntity}
                                    />
                                </AccordionDetails>
                            </StyledAccordion>
                            <StyledAccordion
                                expanded={billingFormType === 'create'}
                                onChange={(_, checked) => (checked ? setBillingFormType('create') : undefined)}
                            >
                                <StyledAccordionSummary
                                    expandIcon={billingFormType === 'create' ? <RemoveIcon /> : <AddIcon />}
                                >
                                    Create new billing details
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <>
                                        <TextField
                                            inputRef={billingContactNameRef}
                                            variant="outlined"
                                            label="Billing contact name"
                                            placeholder="Enter a name"
                                            value={billingContactName}
                                            required
                                            onChange={(event) => {
                                                setBillingContactName(event.target.value);
                                            }}
                                            sx={{ marginTop: 2 }}
                                        />
                                        <TextField
                                            variant="outlined"
                                            label="Email"
                                            placeholder="Enter an email"
                                            required
                                            error={billingEmailValidationError}
                                            value={billingContactEmail}
                                            onChange={(event) => {
                                                setBillingContactEmail(event.target.value);
                                            }}
                                        />
                                    </>
                                </AccordionDetails>
                            </StyledAccordion>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                busy={saving}
                                disabled={saving}
                                onClick={() => setStage(CreateOrganizationWizardStage.INVITE_MEMBERS)}
                            >
                                Back
                            </Button>
                            <Button
                                busy={saving}
                                disabled={saving || !hasValidBillingDetails}
                                onClick={handleCreateOrganization}
                            >
                                {paymentMethod === 'CARD' ? 'Proceed to payment' : 'Next'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
        }
    };

    return getStageContent();
};

import React, { ReactNode } from 'react';
import { Badge, styled, TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ProductIcon } from '../../icons/ProductIcon';
import { ThreeDotsButton } from '../../buttons/ThreeDotsButton';
import { AvatarContainer } from '../../avatar';
import {
    SelectorAppIconsInner,
    SelectorAppIconsOuter,
    SelectorImageBox,
    SelectorTableRow,
    selectorUserAvatarsStyle,
    StyledStatus,
    SelectorItemDescription,
    SelectorItemTitle,
    selectorCardIconWrapStyle,
} from '../../selector/SelectorComponents';
import Tooltip from '@mui/material/Tooltip';
import { handleDisplayName } from '../../../utils/displayName';

interface WorkspaceSelectorTableItemProps {
    description?: string;
    environments: {
        name: string;
        uid: string;
        selected: boolean;
        deployment?: {
            uid: string;
            version: string;
        };
    }[];
    template: boolean;
    incoming?: string[];
    onDelete(uid: string): void;
    onSelect(uid: string): void;
    outgoing?: string[];
    status?: 'ACTIVE' | 'INACTIVE';
    title?: string;
    uid: string;
    shared?: boolean;
    users: {
        avatar?: string;
        firstName?: string;
        lastName?: string;
        email: string;
    }[];
    orgName?: string;
    showOrganizations?: boolean;
}

export const StyledTextCell = styled(TableCell)(() => ({
    minWidth: 200,
    width: '20%',
}));

export const StyledIconCell = styled(TableCell)(() => ({
    minWidth: 190,
    width: '12%',
    '& .MuiTableCell-head': {
        alignItems: 'center',
        display: 'inline-flex',
        flexDirection: 'row',
    },
}));

export const StyledShortCell = styled(TableCell)(() => ({
    textAlign: 'center',
    width: '4%',
}));

const StyledUserAvatars = styled('div')(({ theme }) => ({
    ...selectorUserAvatarsStyle(),
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(1),
    width: '100%',
    '& > div:not(:nth-of-type(5))': {
        marginLeft: `-${theme.spacing(1.5)}`,
    },
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    ...selectorCardIconWrapStyle(theme),
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

export const WorkspaceSelectorTableItem: React.FC<WorkspaceSelectorTableItemProps> = ({
    description,
    environments,
    template,
    incoming = [],
    onDelete,
    onSelect,
    outgoing = [],
    status = 'INACTIVE',
    title,
    uid,
    shared,
    users,
    orgName,
    showOrganizations,
}) => {
    const ENVIRONMENTS_TOOLTIP = environments
        .map((environment) => {
            return `${environment.name}${environment.deployment ? ' - v' + environment.deployment?.version : ''}`;
        })
        .join(', ');

    const iconList = (connections: string[]): ReactNode[] => {
        return connections.map((connection, index) => {
            return (
                <SelectorAppIconsInner key={index}>
                    <Tooltip title={connection} describeChild>
                        <StyledIconContainer>
                            <ProductIcon name={connection} />
                        </StyledIconContainer>
                    </Tooltip>
                </SelectorAppIconsInner>
            );
        });
    };

    return (
        <SelectorTableRow onClick={() => onSelect(uid)}>
            <StyledTextCell>
                <SelectorItemTitle title={title} block={true} />
            </StyledTextCell>
            <StyledTextCell>
                <SelectorItemDescription description={description} />
            </StyledTextCell>
            <StyledIconCell>
                <SelectorAppIconsOuter>{iconList(incoming)}</SelectorAppIconsOuter>
            </StyledIconCell>
            <StyledIconCell>
                <SelectorAppIconsOuter>{iconList(outgoing)}</SelectorAppIconsOuter>
            </StyledIconCell>
            {showOrganizations && (
                <>
                    <StyledShortCell>
                        <StyledUserAvatars>
                            {users.map((user, index) => {
                                return user.avatar ? (
                                    <SelectorImageBox key={'userAvatar' + index}>
                                        <Tooltip
                                            key={'userAvatar' + index}
                                            title={handleDisplayName(user.email, user.firstName, user.lastName)}
                                        >
                                            <img
                                                src={`data:image/jpeg;base64,${user.avatar}`}
                                                width={30}
                                                height={30}
                                                alt={user.firstName + ' ' + user.lastName}
                                            />
                                        </Tooltip>
                                    </SelectorImageBox>
                                ) : (
                                    <Tooltip
                                        key={'userAvatar' + index}
                                        title={handleDisplayName(user.email, user.firstName, user.lastName)}
                                    >
                                        <div>
                                            <AvatarContainer
                                                key={'userAvatar' + index}
                                                credentials={{
                                                    firstName: user.firstName ?? '',
                                                    lastName: user.lastName ?? '',
                                                    email: user.email ?? '',
                                                }}
                                                size="small"
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            })}
                        </StyledUserAvatars>
                    </StyledShortCell>
                    <StyledTextCell>
                        <Typography variant="subtitle2">{orgName}</Typography>
                    </StyledTextCell>
                </>
            )}
            <StyledShortCell>
                <Tooltip title={ENVIRONMENTS_TOOLTIP} placement="top">
                    <Badge badgeContent={environments.length} color="primary" />
                </Tooltip>
            </StyledShortCell>
            <StyledShortCell>
                <Box display="flex" flexDirection="column" marginBottom={1}>
                    <StyledStatus
                        label={status === 'ACTIVE' ? 'Active' : 'Inactive'}
                        sx={{
                            backgroundColor: status === 'ACTIVE' ? 'success.main' : 'action.disabledBackground',
                            color: status === 'ACTIVE' ? 'primary.contrastText' : 'text.primary',
                        }}
                    />
                    {template && <StyledStatus label="Template" sx={{ backgroundColor: 'warning.light' }} />}
                    {shared && <StyledStatus label="Shared" sx={{ backgroundColor: 'success.light' }} />}
                </Box>
            </StyledShortCell>
            <StyledShortCell>
                <ThreeDotsButton
                    uid={uid}
                    actions={[{ name: 'Delete', onSelect: onDelete, icon: <DeleteOutlineIcon /> }]}
                />
            </StyledShortCell>
        </SelectorTableRow>
    );
};

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { UsersWithWorkspaces } from '../../data/report/usersWithWorkspaces';
import { styled } from '@mui/material/styles';
import { PageContainer } from '../layout/PageComponents';

export interface UsersWithWorkspacesReportPageProps {
    users?: UsersWithWorkspaces;
}

export const StyledContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%',
    padding: theme.spacing(2, 4),
}));

export const StyledTitleContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(3),
}));

export const UsersWithWorkspacesReportPage: React.FC<UsersWithWorkspacesReportPageProps> = ({ users }) => {
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledTitleContainer>
                <Typography variant="h3">Users with Workspaces</Typography>
            </StyledTitleContainer>
            <TableContainer>
                <Table sx={{ width: '400px' }} component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Total Users</TableCell>
                            <TableCell>Users with Workspaces</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{users?.total}</TableCell>
                            <TableCell>{users?.withWorkspaces}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    );
};

import { LoaderFn } from 'react-location';
import { getIndustryRoles } from '../data/user';
import { LocationGenerics } from '../router';
import { industryRoles$ } from '../store/roles';
import { loadErrorPage } from '../store/error';

export const rolesLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        if (industryRoles$.value.length === 0) {
            const roles = await getIndustryRoles();
            industryRoles$.next(roles);
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Profile settings',
            background: 'paper',
            genericMessage: 'Failed to load user roles.',
        });

        throw e;
    }

    return {};
};

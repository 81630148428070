/* eslint-disable sonarjs/cognitive-complexity */
import { styled } from '@mui/material/styles';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-location';
// https://github.com/leefsmp/Re-Flex
import { ReflexContainer, ReflexSplitter, ReflexElement, HandlerProps } from 'react-reflex';
import 'react-reflex/styles.css';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { WorkspaceResources } from '../../data/workspace';
import { ResourceTree, ResourceTreeProps } from '../workspace/resource-tree';
import { WorkspaceHeader, WorkspaceHeaderProps } from './WorkspaceHeader';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';
import { Theme } from '@mui/material/styles';
import cronstrue from 'cronstrue';
import { Button } from '../buttons/Button';
import { RemnantEnvironment } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceResources';
import { Banner } from '../alert/Banner';
import { WorkspaceLockDetails } from '../../utils/types';
import { getUserDisplayName } from '../../utils/user';
import { AiAssistancePanel } from '../workspace/ai-assistance';
import { chatGPTModel } from '@avst-stitch/repository-lib/src/utils/chatGPT';

export interface WorkspaceProps {
    useRouter?: boolean;
    readOnlyTemplateMode?: boolean;
    loadingWorkspaceResources: boolean;
    onCreateWorkspaceFromTemplate?: () => void;
    readmeFile: ResourceTreeProps['readmeFile'];
    workspaceResources: WorkspaceResources;
    scriptHasUnsavedChanges: Record<string, boolean>;
    scriptExecutionInProgress: Record<string, boolean>;
    eventListenerExecutionInProgress: Record<string, boolean>;
    selectedNode?: ResourceTreeProps['selectedNode'];
    selectedWorkspaceLanguage?: ResourceTreeProps['selectedWorkspaceLanguage'];
    deploymentButtonDisabled?: WorkspaceHeaderProps['deploymentButtonDisabled'];
    manageEnvironmentDeploymentTargetsButtonDisabled?: WorkspaceHeaderProps['manageEnvironmentDeploymentTargetsButtonDisabled'];
    console?: JSX.Element;
    workspaces?: WorkspaceHeaderProps['workspaces'];
    environments?: WorkspaceHeaderProps['environments'];
    selectedWorkspaceUid?: WorkspaceHeaderProps['selectedWorkspaceUid'];
    isDraft?: WorkspaceHeaderProps['isDraft'];
    isTemplate: WorkspaceHeaderProps['isTemplate'];
    templateActionsInProgress?: WorkspaceHeaderProps['templateActionsInProgress'];
    selectedEnvironmentUid?: WorkspaceHeaderProps['selectedEnvironmentUid'];
    loadingEnvironments?: WorkspaceHeaderProps['loadingEnvironments'];
    externalTriggerBaseUrl?: ResourceTreeProps['externalTriggerBaseUrl'];
    apiHandlersBeingDeleted?: Record<string, boolean>;
    eventListenersBeingDeleted?: Record<string, boolean>;
    scriptsBeingDeleted?: Record<string, boolean>;
    scheduledTriggersBeingDeleted?: Record<string, boolean>;
    showScheduledTriggers?: ResourceTreeProps['showScheduledTriggers'];
    wizard?: ResourceTreeProps['wizard'];
    aiAssistanceOpen?: boolean;
    organizationName?: string;
    showOrganizations?: boolean;
    workspaceLockedBy?: WorkspaceLockDetails;
    showAiAssistance?: boolean;
    aiGeneratedAnswer?: string;
    aiAssistanceChat?: {
        role: 'user' | 'assistant';
        message: string;
    }[];
    aiAssistanceLoading?: boolean;
    aiAssistanceAnswering?: boolean;
    shortcutsPopupVisible?: boolean;
    onToggleShortcutsPopupVisibility?: (open: boolean) => void;
    onOpenAiAssistance?: WorkspaceHeaderProps['onOpenAiAssistance'];
    onCloseAiAssistance?: WorkspaceHeaderProps['onCloseAiAssistance'];
    onNewScript?: ResourceTreeProps['onNewScript'];
    onNewApiHandler?: ResourceTreeProps['onNewApiHandler'];
    onNewEventListener?: ResourceTreeProps['onNewEventListener'];
    onNewScheduledTrigger?: ResourceTreeProps['onNewScheduledTrigger'];
    onSelectScript?: ResourceTreeProps['onOpenScript'];
    onSelectReadmeFile?: ResourceTreeProps['onOpenReadmeFile'];
    onSelectApiHandler?: ResourceTreeProps['onOpenApiHandler'];
    onSelectEventListener?: ResourceTreeProps['onOpenEventListener'];
    onSelectScheduledTrigger?: ResourceTreeProps['onOpenScheduledTrigger'];
    onDeploy?: WorkspaceHeaderProps['onDeploy'];
    onTriggerScript?: ResourceTreeProps['onTriggerScript'];
    onTriggerEventListener?: ResourceTreeProps['onTriggerEventListener'];
    onSetResourceManagerOffsets?: ResourceTreeProps['onSetOffsets'];
    onCreateNewEnvironment?: WorkspaceHeaderProps['onCreateNewEnvironment'];
    onEnvironmentChange?: WorkspaceHeaderProps['onEnvironmentChange'];
    onLanguageSelector?: WorkspaceHeaderProps['onLanguageSelector'];
    onEditWorkspace?: WorkspaceHeaderProps['onEditWorkspace'];
    onPackageManager?: WorkspaceHeaderProps['onPackageManager'];
    onManageEnvironmentDeploymentTargets?: WorkspaceHeaderProps['onManageEnvironmentDeploymentTargets'];
    onSwitchWorkspace?: WorkspaceHeaderProps['onSwitchWorkspace'];
    onApiHandlerDelete?: ResourceTreeProps['onApiHandlerDelete'];
    onEventListenerDelete?: ResourceTreeProps['onEventListenerDelete'];
    onScriptDelete?: ResourceTreeProps['onScriptDelete'];
    onScheduledTriggerDelete?: ResourceTreeProps['onScheduledTriggerDelete'];
    onApiHandlerPathCopy?: ResourceTreeProps['onApiHandlerPathCopy'];
    onEventListenerUrlCopy?: ResourceTreeProps['onEventListenerUrlCopy'];
    onPublishTemplate?: WorkspaceHeaderProps['onPublishTemplate'];
    onUnpublishTemplate?: WorkspaceHeaderProps['onUnpublishTemplate'];
    onManageEnvironments?: WorkspaceHeaderProps['onManageEnvironments'];
    onAddWorkspaceToOrganization?: WorkspaceHeaderProps['onAddWorkspaceToOrganization'];
    onShareWorkspace?: WorkspaceHeaderProps['onShareWorkspace'];
    onAssumeWorkspaceEditControl?(userDisplayName?: string): void;
    onAsk?(message: string, model: chatGPTModel): void;
    onResetAiAssistanceChat?(): void;
}

const aiAssistanceExampleQuestions = [
    'How to sync two Jira Cloud instances?',
    'How to import an API Connection?',
    'What is a Connector?',
    'How to authorize a Connector?',
    'Which Managed APIs and methods are you aware of?',
    'How to create an issue in one Jira Cloud instance when an issue is created in another instance?',
    'Get most recent issues from Jira Cloud and print out issue key, summary, reporter and assignee.',
    'Get the issue with key ISSUE-1 from one of my instance and create a copy of it in another instance under the project key TEST, copy over summary, description and assignee.',
];

const headerHeight = 60;
const minColWidth = 32;
const consoleFlexGrowKey = 'stitchConsoleHeight';
const mainFlexGrowKey = 'stitchMainHeight';
const treeFlexGrowKey = 'stitchResourceTreeWidth';
const outletFlexGrowKey = 'stitchOutletWidth';
const aiChatFlexGrowKey = 'stitchAiChatWidth';
const outletContentFlexGrowKey = 'stitchOutletContentWidth';
const treeIsOpenKey = 'stitchIsTreeOpen';
const consoleDefaultFlexGrow = 2;
const mainDefaultFlexGrow = 10.5;
const treeDefaultFlexGrow = 3;
const outletDefaultFlexGrow = 10;
const aiChatDefaultFlexGrow = 1.1;
const outletContentDefaultFlexGrow = 1.7;
const deployedWorkspaceInformationText = (
    <span>
        Although the current environment is deployed and the code that is triggered externally is running the deployed
        version, you are still seeing the latest (draft) version of your workspace. Learn more about{' '}
        <Link href={'https://docs.adaptavist.com/src/workspaces/deployments-and-environments'} target={'_blank'}>
            Deployments and Environments.
        </Link>
    </span>
);

const openMixin = {
    transition: (theme: Theme) =>
        theme.transitions.create('flex', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
};
const closeMixin = {
    transition: (theme: Theme) =>
        theme.transitions.create('flex', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
};

const StyledCollapseIcon = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(1.5),
    zIndex: 1,
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        height: 20,
        width: 20,
    },
}));

const StyledHorizontalDragIndicator = styled(DragIndicatorIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: 20,
    position: 'absolute',
    left: '50%',
    top: theme.spacing(-0.5),
    transform: 'translateX(-50%) rotate(90deg)',
    width: 20,
}));

const StyledVerticalDragIndicator = styled(DragIndicatorIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: 20,
    position: 'absolute',
    left: theme.spacing(-0.5),
    top: '50%',
    transform: 'translateY(-50%)',
    width: 20,
}));

const StyledHorizontalContainer = styled(ReflexContainer)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(1),
    // styling the splitter here because creating a styled component breaks the splitter functionality
    '&.horizontal > .reflex-splitter, .vertical .reflex-splitter': {
        backgroundColor: theme.palette.background.default,
        border: 'none !important',
        filter: theme.constants.boxShadow,
        zIndex: 0,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    '&.horizontal>.reflex-splitter': {
        height: theme.spacing(1.5) + ' !important',
    },
}));

const StyledReflexContainer = styled(ReflexContainer)(({ theme }) => ({
    '&.vertical>.reflex-splitter': {
        width: theme.spacing(1.5) + ' !important',
    },
    '& .reflex-size-aware': { overflow: 'hidden' },
}));

const StyledHeader = styled('div')(() => ({
    overflow: 'hidden !important',
}));

const StyledReflexMain = styled(ReflexElement)(() => ({
    // in case we need to style the main element
}));

const StyledReflexElement = styled(ReflexElement)(() => ({
    minWidth: minColWidth,
}));

const StyledReflexResourceTree = styled(ReflexElement)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    minWidth: minColWidth,
}));

const StyledReflexFooter = styled(ReflexElement)(() => ({
    minHeight: headerHeight,
}));

const StyledButton = styled(Button)(() => ({
    minWidth: 200,
}));

export const Workspace: React.FC<WorkspaceProps> = ({
    useRouter = true,
    readOnlyTemplateMode = false,
    readmeFile,
    loadingWorkspaceResources,
    workspaceResources,
    scriptHasUnsavedChanges,
    scriptExecutionInProgress,
    eventListenerExecutionInProgress,
    selectedNode,
    selectedWorkspaceLanguage,
    deploymentButtonDisabled = false,
    manageEnvironmentDeploymentTargetsButtonDisabled = false,
    console: consoleElement,
    workspaces,
    environments,
    isTemplate,
    isDraft,
    templateActionsInProgress = false,
    selectedWorkspaceUid,
    selectedEnvironmentUid,
    loadingEnvironments,
    apiHandlersBeingDeleted,
    eventListenersBeingDeleted,
    scriptsBeingDeleted,
    scheduledTriggersBeingDeleted,
    externalTriggerBaseUrl,
    showScheduledTriggers,
    wizard,
    aiAssistanceOpen = false,
    organizationName,
    showOrganizations,
    workspaceLockedBy,
    showAiAssistance,
    aiGeneratedAnswer,
    aiAssistanceChat = [],
    aiAssistanceLoading,
    aiAssistanceAnswering,
    shortcutsPopupVisible,
    onToggleShortcutsPopupVisibility,
    onOpenAiAssistance,
    onCloseAiAssistance,
    onNewScript,
    onNewApiHandler,
    onNewEventListener,
    onNewScheduledTrigger,
    onSelectScript,
    onSelectReadmeFile,
    onSelectApiHandler,
    onSelectEventListener,
    onSelectScheduledTrigger,
    onDeploy,
    onTriggerScript,
    onTriggerEventListener,
    onSetResourceManagerOffsets,
    onCreateNewEnvironment,
    onEnvironmentChange,
    onLanguageSelector,
    onEditWorkspace,
    onPackageManager,
    onManageEnvironmentDeploymentTargets,
    onSwitchWorkspace,
    onApiHandlerDelete,
    onEventListenerDelete,
    onScriptDelete,
    onScheduledTriggerDelete,
    onApiHandlerPathCopy,
    onEventListenerUrlCopy,
    onPublishTemplate,
    onUnpublishTemplate,
    onManageEnvironments,
    onAddWorkspaceToOrganization,
    onShareWorkspace,
    onCreateWorkspaceFromTemplate,
    onAssumeWorkspaceEditControl,
    onAsk,
    onResetAiAssistanceChat,
}) => {
    const [consoleFlexGrow, setConsoleFlexGrow] = useState(
        readLocalStorage(consoleFlexGrowKey, consoleDefaultFlexGrow)
    );
    const [mainFlexGrow, setMainFlexGrow] = useState(readLocalStorage(mainFlexGrowKey, mainDefaultFlexGrow));
    const [treeOpen, setTreeOpen] = useState(readLocalStorage(treeIsOpenKey, true));
    const [treeFlexGrow, setTreeFlexGrow] = useState(
        treeOpen ? readLocalStorage(treeFlexGrowKey, treeDefaultFlexGrow) : 0.15
    );
    const [aiChatFlexGrow, setAiChatFlexGrow] = useState(
        aiAssistanceOpen ? readLocalStorage(aiChatFlexGrowKey, aiChatDefaultFlexGrow) : 0
    );
    const [outletContentFlexGrow, setOutletContentFlexGrow] = useState(
        aiAssistanceOpen ? readLocalStorage(outletContentFlexGrowKey, outletContentDefaultFlexGrow) : 1.7
    );
    const [animating, setAnimating] = useState(false);
    const [outletFlexGrow, setOutletFlexGrow] = useState(readLocalStorage(outletFlexGrowKey, outletDefaultFlexGrow));
    const [infoBannerOpen, setInfoBannerOpen] = useState(true);

    useEffect(() => {
        setConsoleFlexGrow(readLocalStorage(consoleFlexGrowKey, consoleDefaultFlexGrow));
        setMainFlexGrow(readLocalStorage(mainFlexGrowKey, mainDefaultFlexGrow));
        if (treeOpen) {
            setTreeFlexGrow(readLocalStorage(treeFlexGrowKey, treeDefaultFlexGrow));
            setOutletFlexGrow(readLocalStorage(outletFlexGrowKey, outletDefaultFlexGrow));
        }
    }, []);

    useEffect(() => {
        if (aiAssistanceOpen) {
            setAiChatFlexGrow(readLocalStorage(aiChatFlexGrowKey, aiChatDefaultFlexGrow));
            setOutletContentFlexGrow(readLocalStorage(outletContentFlexGrowKey, outletContentDefaultFlexGrow));
        } else setAiChatFlexGrow(0), setOutletContentFlexGrow(1.7);
    }, [aiAssistanceOpen]);

    const handleReflexResize = (e: HandlerProps, key: string): void => {
        if (e.domElement instanceof Element) {
            const flexGrowCurrent = +window.getComputedStyle(e.domElement).flexGrow;
            saveLocalStorage(key, flexGrowCurrent);
        }
    };

    const handleCollapse = (): void => {
        setAnimating(true);
        setTreeOpen(false);
        saveLocalStorage(treeIsOpenKey, false);
        setTreeFlexGrow(0.15);
        setTimeout(() => {
            setAnimating(false);
        }, 300);
    };

    const handleExpand = (): void => {
        setAnimating(true);
        setTreeOpen(true);
        saveLocalStorage(treeIsOpenKey, true);
        setTreeFlexGrow(readLocalStorage(treeFlexGrowKey, treeDefaultFlexGrow));
        setTimeout(() => {
            setAnimating(false);
        }, 300);
    };

    const handleOpenAiAssistance = (): void => {
        onOpenAiAssistance?.();
        setAiChatFlexGrow(readLocalStorage(aiChatFlexGrowKey, aiChatDefaultFlexGrow));
    };

    const handleCloseAiAssistance = (): void => {
        onCloseAiAssistance?.();
        setAiChatFlexGrow(0);
    };

    const handleScriptWidthChange = (width: number): void => {
        if (shortcutsPopupVisible && width < 500) {
            onToggleShortcutsPopupVisibility?.(false);
        } else if (!shortcutsPopupVisible && width >= 500) {
            onToggleShortcutsPopupVisibility?.(true);
        }
    };

    const getCronDescription = (cronExpression: string | undefined): string | undefined => {
        if (!cronExpression) {
            return undefined;
        }

        try {
            return cronstrue.toString(cronExpression);
        } catch (e) {
            console.error('Failed to translate CRON expression', cronExpression, e);
            return 'Error while translating CRON expression';
        }
    };

    const filterRemnantEnvironments = (remnantEnvironments: RemnantEnvironment[]): boolean =>
        remnantEnvironments.length === 0 ||
        remnantEnvironments.some((re) => re.environmentUid === selectedEnvironmentUid);

    const isDeployed = !!environments?.find((en) => en.uid === selectedEnvironmentUid)?.deployed;
    return (
        <>
            <StyledHorizontalContainer orientation="horizontal">
                <StyledHeader className="header">
                    {readOnlyTemplateMode ? (
                        <Banner
                            severity="info"
                            alertTitle={
                                'This is a Read-Only view of the Template. You can create a Workspace from this Template.'
                            }
                            action={
                                <Button variant="contained" onClick={onCreateWorkspaceFromTemplate}>
                                    Create a Workspace
                                </Button>
                            }
                            text={
                                'Creating a Workspace from this Template will copy over all of the Template resources.'
                            }
                        />
                    ) : (
                        <WorkspaceHeader
                            deploymentButtonDisabled={deploymentButtonDisabled}
                            manageEnvironmentDeploymentTargetsButtonDisabled={
                                manageEnvironmentDeploymentTargetsButtonDisabled
                            }
                            workspaces={workspaces}
                            environments={environments}
                            isTemplate={isTemplate}
                            isDraft={isDraft}
                            templateActionsInProgress={templateActionsInProgress}
                            selectedWorkspaceUid={selectedWorkspaceUid}
                            selectedEnvironmentUid={selectedEnvironmentUid}
                            loadingEnvironments={loadingEnvironments}
                            organizationName={organizationName}
                            showOrganizations={showOrganizations}
                            onDeploy={() => onDeploy?.()}
                            onCreateNewEnvironment={() => onCreateNewEnvironment?.()}
                            onEnvironmentChange={(uid) => onEnvironmentChange?.(uid)}
                            onLanguageSelector={() => onLanguageSelector?.()}
                            onEditWorkspace={() => onEditWorkspace?.()}
                            onPackageManager={() => onPackageManager?.()}
                            onManageEnvironmentDeploymentTargets={() => onManageEnvironmentDeploymentTargets?.()}
                            onSwitchWorkspace={(workspaceUid, environmentUid) =>
                                onSwitchWorkspace?.(workspaceUid, environmentUid)
                            }
                            onPublishTemplate={() => onPublishTemplate?.()}
                            onUnpublishTemplate={() => onUnpublishTemplate?.()}
                            onManageEnvironments={() => onManageEnvironments?.()}
                            onAddWorkspaceToOrganization={() => onAddWorkspaceToOrganization?.()}
                            onShareWorkspace={(workspaceUid) => onShareWorkspace?.(workspaceUid)}
                            locked={!!workspaceLockedBy}
                            showAiAssistance={showAiAssistance}
                            onOpenAiAssistance={handleOpenAiAssistance}
                            onCloseAiAssistance={handleCloseAiAssistance}
                            aiAssistanceOpen={aiAssistanceOpen}
                        />
                    )}
                </StyledHeader>
                {isDeployed && infoBannerOpen && (
                    <Banner
                        severity="info"
                        alertTitle="Information"
                        text={deployedWorkspaceInformationText}
                        onClose={() => setInfoBannerOpen(false)}
                    />
                )}
                {workspaceLockedBy && workspaceLockedBy.bySamePerson && (
                    <Banner
                        severity="info"
                        alertTitle="Workspace is in read-only mode"
                        text={`Looks like you have opened the same workspace from a different tab or you are sharing an account and someone else is working on this workspace right now. You can assume edit control over the workspace immediately by clicking on 'Assume Edit Control' button.`}
                        action={
                            <StyledButton variant="contained" onClick={() => onAssumeWorkspaceEditControl?.()}>
                                Assume Edit Control
                            </StyledButton>
                        }
                    />
                )}
                {workspaceLockedBy && !workspaceLockedBy.bySamePerson && (
                    <Banner
                        severity="info"
                        alertTitle="Workspace is in read-only mode"
                        text={`${getUserDisplayName(
                            workspaceLockedBy
                        )} has acquired edit control over the workspace. You can assume edit control over the workspace immediately by clicking on 'Assume Edit Control' button`}
                        action={
                            <StyledButton
                                variant="contained"
                                onClick={() => onAssumeWorkspaceEditControl?.(getUserDisplayName(workspaceLockedBy))}
                            >
                                Assume Edit Control
                            </StyledButton>
                        }
                    />
                )}
                <StyledReflexMain
                    onStopResize={(e) => handleReflexResize(e, mainFlexGrowKey)}
                    className="header"
                    flex={mainFlexGrow}
                >
                    <StyledReflexContainer orientation="vertical">
                        <StyledReflexResourceTree
                            onStopResize={(e) => handleReflexResize(e, treeFlexGrowKey)}
                            flex={treeFlexGrow}
                            sx={
                                treeOpen && animating
                                    ? closeMixin
                                    : !treeOpen && animating
                                    ? { ...openMixin, borderRight: '1px solid rgba(0, 0, 0, 0.12)' }
                                    : !treeOpen
                                    ? { borderRight: '1px solid rgba(0, 0, 0, 0.12)' }
                                    : null
                            }
                        >
                            {treeOpen ? (
                                <StyledCollapseIcon onClick={handleCollapse} title="Close section">
                                    <KeyboardArrowLeft />
                                </StyledCollapseIcon>
                            ) : (
                                <StyledCollapseIcon onClick={handleExpand} title="Open section">
                                    <KeyboardArrowRight />
                                </StyledCollapseIcon>
                            )}
                            <ResourceTree
                                open={treeOpen}
                                readOnlyMode={readOnlyTemplateMode || !!workspaceLockedBy}
                                loading={loadingWorkspaceResources}
                                scripts={workspaceResources.scripts.map((script) => ({
                                    ...script,
                                    unsaved: !!scriptHasUnsavedChanges[script.uid],
                                    executing: !!scriptExecutionInProgress[script.uid],
                                    deleting: scriptsBeingDeleted?.[script.uid] ?? false,
                                }))}
                                apiHandlers={workspaceResources.apiHandlers
                                    .filter(({ remnantEnvironments }) => filterRemnantEnvironments(remnantEnvironments))
                                    .map((api) => ({
                                        ...api,
                                        deleting: apiHandlersBeingDeleted?.[api.uid] ?? false,
                                    }))}
                                eventListeners={workspaceResources.eventListeners
                                    .filter(({ remnantEnvironments }) => filterRemnantEnvironments(remnantEnvironments))
                                    .map((el) => ({
                                        ...el,
                                        scriptName: el.script?.name,
                                        deleting: eventListenersBeingDeleted?.[el.uid] ?? false,
                                        executing: !!eventListenerExecutionInProgress[el.uid],
                                    }))}
                                scheduledTriggers={workspaceResources.scheduledTriggers
                                    .filter(({ remnantEnvironments }) => filterRemnantEnvironments(remnantEnvironments))
                                    .map((st) => ({
                                        ...st,
                                        deleting: scheduledTriggersBeingDeleted?.[st.uid] ?? false,
                                        scriptName: st.script?.name,
                                        cronDescription: getCronDescription(st.cronExpression ?? ''),
                                    }))}
                                onNewScheduledTrigger={() => onNewScheduledTrigger?.()}
                                onScheduledTriggerDelete={(uid) => onScheduledTriggerDelete?.(uid)}
                                selectedNode={selectedNode}
                                selectedWorkspaceLanguage={selectedWorkspaceLanguage}
                                readmeFile={readmeFile}
                                externalTriggerBaseUrl={externalTriggerBaseUrl}
                                showScheduledTriggers={showScheduledTriggers}
                                workspaceUid={selectedWorkspaceUid ?? ''}
                                wizard={wizard}
                                onApiHandlerDelete={(uid) => onApiHandlerDelete?.(uid)}
                                onEventListenerDelete={(uid) => onEventListenerDelete?.(uid)}
                                onNewApiHandler={() => onNewApiHandler?.()}
                                onNewEventListener={() => onNewEventListener?.()}
                                onNewScript={() => onNewScript?.()}
                                onOpenApiHandler={(uid) => onSelectApiHandler?.(uid)}
                                onOpenEventListener={(uid) => onSelectEventListener?.(uid)}
                                onOpenScheduledTrigger={(uid) => onSelectScheduledTrigger?.(uid)}
                                onOpenScript={(uid) => onSelectScript?.(uid)}
                                onOpenReadmeFile={(uid) => onSelectReadmeFile?.(uid)}
                                onScriptDelete={(uid) => onScriptDelete?.(uid)}
                                onTriggerEventListener={(uid) => onTriggerEventListener?.(uid)}
                                onTriggerScript={(uid) => onTriggerScript?.(uid)}
                                onApiHandlerPathCopy={(uid) => onApiHandlerPathCopy?.(uid)}
                                onEventListenerUrlCopy={(uid) => onEventListenerUrlCopy?.(uid)}
                                onSetOffsets={(offsets) => onSetResourceManagerOffsets?.(offsets)}
                            />
                        </StyledReflexResourceTree>
                        {treeOpen && (
                            <ReflexSplitter>
                                <StyledVerticalDragIndicator
                                    sx={{
                                        position: 'relative',
                                    }}
                                    aria-label="Vertical resize"
                                />
                            </ReflexSplitter>
                        )}

                        <StyledReflexElement
                            onStopResize={(e) => handleReflexResize(e, outletFlexGrowKey)}
                            flex={outletFlexGrow}
                        >
                            <StyledReflexContainer orientation="vertical">
                                <StyledReflexElement
                                    propagateDimensions
                                    propagateDimensionsRate={300}
                                    onStopResize={(e) => handleReflexResize(e, outletContentFlexGrowKey)}
                                    flex={outletContentFlexGrow}
                                >
                                    <SizeAwareContainer onDimensionsChange={handleScriptWidthChange}>
                                        {useRouter && <Outlet />}
                                    </SizeAwareContainer>
                                </StyledReflexElement>
                                {showAiAssistance && aiAssistanceOpen && (
                                    <ReflexSplitter>
                                        <StyledVerticalDragIndicator
                                            // eslint-disable-next-line sonarjs/no-identical-functions
                                            sx={{
                                                position: 'relative',
                                            }}
                                            aria-label="Vertical resize for AI assistance"
                                        />
                                    </ReflexSplitter>
                                )}
                                {showAiAssistance && aiAssistanceOpen && (
                                    <StyledReflexElement
                                        onStopResize={(e) => handleReflexResize(e, aiChatFlexGrowKey)}
                                        flex={aiChatFlexGrow}
                                    >
                                        <AiAssistancePanel
                                            selectedGptVersion="gpt-4"
                                            examples={aiAssistanceExampleQuestions}
                                            loading={false}
                                            asking={!!aiAssistanceLoading}
                                            answering={!!aiAssistanceAnswering}
                                            aiGeneratedAnswer={aiGeneratedAnswer}
                                            chat={aiAssistanceChat}
                                            onAsk={(msg, model) => {
                                                onAsk?.(msg, model);
                                            }}
                                            onClose={() => onCloseAiAssistance?.()}
                                            onReset={() => onResetAiAssistanceChat?.()}
                                            gptVersions={['gpt-4', 'gpt-3.5-turbo']}
                                        />
                                    </StyledReflexElement>
                                )}
                            </StyledReflexContainer>
                        </StyledReflexElement>
                    </StyledReflexContainer>
                </StyledReflexMain>
                {!readOnlyTemplateMode ? (
                    <ReflexSplitter>
                        <StyledHorizontalDragIndicator
                            // eslint-disable-next-line sonarjs/no-identical-functions
                            sx={{
                                position: 'relative',
                            }}
                            aria-label="Horizontal resize"
                        />
                    </ReflexSplitter>
                ) : (
                    <></>
                )}
                {!readOnlyTemplateMode ? (
                    <StyledReflexFooter
                        onStopResize={(e) => handleReflexResize(e, consoleFlexGrowKey)}
                        className="footer"
                        flex={consoleFlexGrow}
                    >
                        {consoleElement !== undefined && consoleElement}
                    </StyledReflexFooter>
                ) : (
                    <></>
                )}
            </StyledHorizontalContainer>
        </>
    );
};

interface Dimensions {
    height: number;
    width: number;
}

const SizeAwareContainer: React.FC<
    PropsWithChildren<{ dimensions?: Dimensions; onDimensionsChange: (width: number) => void }>
> = ({ children, dimensions, onDimensionsChange }) => {
    useEffect(() => {
        onDimensionsChange(dimensions?.width || 1000);
    }, [dimensions]);
    return <Box sx={{ height: '100%', overflow: 'auto' }}>{children}</Box>;
};

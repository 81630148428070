import { useObservableState } from 'observable-hooks';
import {
    organizationBillingDetails$,
    selectedOrganizationPlan$,
    selectedOrganizationUid$,
} from '../../store/organization';
import {
    changeOrganizationPlanAction$,
    changeOrganizationPlanActionV2$,
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$,
    closeChangeOrganizationPlanConfirmationDialogAction$,
    openChangeOrganizationPlanConfirmPurchaseDialogAction$,
} from '../../store/organization/changeOrganizationPlan';
import { changeOrganizationPlanConfirmationDialogOpen$ } from '../../store/organization/changeOrganizationPlan';
import { ChangeOrganizationPlanConfirmationDialog } from '../../components/organization/change-organization-plan-wizard/ChangeOrganizationPlanConfirmationDialog';
import { featureFlagsTopic$ } from '../../store/config';

export const ChangeOrganizationPlanConfirmationContainer: React.FC = () => {
    const open = useObservableState(changeOrganizationPlanConfirmationDialogOpen$);
    const nextPlan = useObservableState(changeOrganizationPlanConfirmPurchaseDialogNextPlan$);
    const currentPlan = useObservableState(selectedOrganizationPlan$);
    const orgUid = useObservableState(selectedOrganizationUid$);
    const billingDetails = useObservableState(organizationBillingDetails$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    if (!currentPlan || !nextPlan) {
        return null;
    }

    const handelProceed = (): void => {
        closeChangeOrganizationPlanConfirmationDialogAction$.next();
        if (currentPlan.tier === 'free' || nextPlan.tier === 'free') {
            if (featureFlags.salablePlan) {
                changeOrganizationPlanActionV2$.next({
                    plan: nextPlan,
                    organizationUid: orgUid || '',
                    existingBillingEntityUid: billingDetails?.selectedBillingEntityUid,
                    originalReferrer: window.location.origin,
                });
            } else {
                changeOrganizationPlanAction$.next({
                    plan: nextPlan,
                    organizationUid: orgUid || '',
                    selectedBillingEntityUid: billingDetails?.selectedBillingEntityUid,
                });
            }
        } else {
            openChangeOrganizationPlanConfirmPurchaseDialogAction$.next(nextPlan);
        }
    };

    return (
        <ChangeOrganizationPlanConfirmationDialog
            open={open}
            currentPlan={currentPlan}
            nextPlan={nextPlan}
            onCancel={() => closeChangeOrganizationPlanConfirmationDialogAction$.next()}
            onProceed={handelProceed}
        />
    );
};

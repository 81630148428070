import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AbsoluteCenteredLoadingSpinner } from '../../loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { ConnectionModalSecretTextField } from '../../textfield/ConnectionModalTextField';
import { Alert } from '../../alert/Alert';
import { ReadOnlyTextField } from '../../textfield/ReadOnlyTextField';
import { handleKeyUp } from '../../../utils/handleKeyUp';

interface MicrosoftEventListenerSetupDialogProps {
    errors?: string;
    loading?: boolean;
    saving?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    workspaceLocked?: boolean;
    onClose: () => void;
    onSave: (token: string) => void;
}

export const MicrosoftEventListenerSetupDialog: React.FC<MicrosoftEventListenerSetupDialogProps> = ({
    errors,
    loading = false,
    open = false,
    saving = false,
    webhookBaseUrl,
    webhookUrlId,
    workspaceLocked = false,
    onClose,
    onSave,
}) => {
    const appName = APP.MICROSOFT.NAME;

    const [token, setToken] = useState('');

    const handleSaveToken = (): void => {
        onSave(token);
    };

    const canSave = !loading && !saving && !!token;

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyUp={(event) =>
                    handleKeyUp({ event, enterCondition: canSave, enterFn: () => onSave(token), escFn: onClose })
                }
            >
                <DialogTitle variant="h6">Event Listener setup instructions for {appName}</DialogTitle>
                {loading ? (
                    <AbsoluteCenteredLoadingSpinner />
                ) : (
                    <>
                        {workspaceLocked && (
                            <Alert
                                severity="warning"
                                alertTitle="Warning"
                                text="The workspace is currently locked and the token field cannot be edited"
                                sx={{ marginBottom: 2 }}
                            />
                        )}
                        {errors && <Alert severity="error" alertTitle="Error" text={errors} sx={{ marginBottom: 2 }} />}
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            <DialogContentText variant="subtitle2">
                                You need to setup an outgoing webhook in your {appName} channel in order to listen to
                                events in ScriptRunner Connect.
                            </DialogContentText>
                            <DialogContentText component="ol">
                                <li>Copy the URL above.</li>
                                <li>
                                    Open <strong>Microsoft Teams</strong> and navigate to the team you want to setup the
                                    event listener for.
                                </li>
                                <li>Click on the three dots next to the team.</li>
                                <li>
                                    Click on <strong>Manage team</strong>.
                                </li>
                                <li>
                                    Click on the <strong>Apps</strong> tab.
                                </li>
                                <li>
                                    On the bottom right, click on <strong>Create an outgoing webhook</strong>.
                                </li>
                                <li>
                                    Give the webhook a name. This is the name you will use to invoke the webhook on the
                                    channel.
                                </li>
                                <li>
                                    Paste the copied URL into the <strong>Callback URL</strong> field.
                                </li>
                                <li>
                                    Give the webhook a description to make it easier for your team to know the purpose
                                    of this webhook.
                                </li>
                                <li>
                                    Click on <strong>Create</strong>. A security token will appear on the screen. Copy
                                    this token and paste it in the <strong>Security Token</strong> field below.
                                </li>
                            </DialogContentText>
                            <ConnectionModalSecretTextField
                                disabled={workspaceLocked}
                                label="Security Token"
                                value={token}
                                onUpdate={(e) => setToken(e.target.value.trim())}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button busy={saving} disabled={!canSave} onClick={handleSaveToken}>
                                Save
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { TemplatesUsedRequest, TemplatesUsed } from '../../data/report/templatesUsed';
import { styled } from '@mui/material';
import { PageContainer } from '../layout/PageComponents';
import { StyledTitleContainer } from './UsersWithWorkspacesReportPage';

export interface TemplatesUsedReportPageProps extends TemplatesUsedRequest {
    templates: TemplatesUsed;
    onSearch(userType: UserType): void;
}

type UserType = 'all' | 'adaptavist' | 'non-adaptavist' | 'non-stitch';

export const StyledTitle = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(3),
}));

export const TemplatesUsedReportPage: React.FC<TemplatesUsedReportPageProps> = ({
    templates,
    userType = 'all',
    onSearch,
}) => {
    const [userTypeFilterValue, setUserTypeFilterValue] = useState<UserType>(userType);

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledTitleContainer>
                <Typography variant="h3">Templates Used</Typography>
            </StyledTitleContainer>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by User Type"
                            value={userTypeFilterValue}
                            onChange={(event) => setUserTypeFilterValue(event.target.value as UserType)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="non-adaptavist">Non-Adaptavist</MenuItem>
                            <MenuItem value="adaptavist">Adaptavist Only</MenuItem>
                            <MenuItem value="non-stitch">Non-Stitch</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>Total Templates: {templates.length}</Typography>
                </Box>
                <div>
                    <Button variant="contained" onClick={() => onSearch(userTypeFilterValue)} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Templates</TableCell>
                        <TableCell>Workspaces Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {templates.map((t) => (
                        <TableRow key={t.uid}>
                            <TableCell>{t.name}</TableCell>
                            <TableCell>{t.workspacesCount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </PageContainer>
    );
};

import { LoaderFn } from 'react-location';
import { getInvocationDataUrl } from '../data/invocationLogs';
import { LocationGenerics } from '../router';
import { selectedHttpLogsInvocationId$, selectedHttpLogs$, selectedHttpLogsUrl$ } from '../store/httpLogs';
import { InformativeError } from '../utils/repository';
import { loadErrorPage } from '../store/error';

export const httpLogsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    selectedHttpLogsInvocationId$.next(undefined);
    selectedHttpLogsUrl$.next(undefined);
    selectedHttpLogs$.next([]);

    try {
        const invocationId = routeMatch.params.invocationId;
        const workspaceUid = routeMatch.params.workspaceUid;

        if (invocationId && workspaceUid) {
            selectedHttpLogsInvocationId$.next(invocationId);
            const httpLogsUrl = await getInvocationDataUrl({ workspaceUid, invocationId, dataType: 'httpLogs' });

            selectedHttpLogsUrl$.next(httpLogsUrl);
            const response = await fetch(httpLogsUrl);

            if (!response.ok) {
                throw new InformativeError('Failed to fetch HTTP logs');
            }

            const httpLogsStringified = await response.text();
            const httpLogs = JSON.parse(httpLogsStringified);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const logs: string[] = httpLogs.map((log: any) => JSON.stringify(log, null, '\t'));

            selectedHttpLogs$.next(logs);
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load HTTP logs.',
        });

        throw e;
    }

    return {};
};

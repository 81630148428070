import { useRef, useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import ContrastIcon from '@mui/icons-material/Contrast';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { SideBarMenu } from './SideBarMenu';
import { UserTools } from './UserTools';
import { Navigate, Outlet } from 'react-location';
import { getBasePath } from '../../utils/path';
import { DropdownMenu } from '../dropdown/DropdownMenu';
import { ThemeMode, stitchThemeModeKey } from '../../theme';
import { saveLocalStorage } from '../../utils/localStorage';
import CssBaseline from '@mui/material/CssBaseline';
import useResizeObserver from 'use-resize-observer';
import { AppBanners } from './AppBanners';

export interface AppMainProps {
    rootPath: string;
    showDashboard?: boolean;
    showOrganizations?: boolean;
    useRouter?: boolean;
    isConnectedToFeedback?: boolean;
    impersonating?: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    notificationBanner?: {
        message: string;
        level: 'info' | 'warning' | 'error';
    };
    showSessionExpiredWarning?: boolean;
    sessionExpired?: boolean;
    credentials: {
        firstName: string;
        lastName: string;
        email: string;
    };
    onSetLoadTokens(loadTokens: boolean): void;
    onLogOut?(): void;
    onOpenConnectAndSupportModal?(): void;
    onThemeSwitch(mode: ThemeMode): void;
    onStopImpersonation?(): void;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 210;
const toolBarHeight = 70;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `${theme.spacing(7)}`,
    },
});

const StyledWrapper = styled(Box)<{ bannersheight: number }>(({ theme, bannersheight }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: `calc(100% - ${bannersheight}px)`,
    position: 'relative',
}));

const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    width: `calc(100% - ${theme.spacing(6.25)})`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    boxSizing: 'border-box',
    flexShrink: 0,
    width: drawerWidth,
    whiteSpace: 'nowrap',
    '& .MuiPaper-root': {
        position: 'relative',
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        '& .MuiListItemIcon-root': {
            minWidth: theme.spacing(4),
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const StyledToolbar = styled(Toolbar)(() => ({
    alignItems: 'center',
    height: toolBarHeight,
    display: 'flex',
    justifyContent: 'flex-end',
}));

const StyledInnerContent = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    overflowX: 'hidden',
    marginTop: theme.spacing(9),
    position: 'relative',
    width: '100%',
}));

export const AppMain: React.FC<AppMainProps> = ({
    useRouter = true,
    showDashboard = true,
    showOrganizations = true,
    isConnectedToFeedback,
    impersonating,
    notificationBanner,
    showSessionExpiredWarning,
    sessionExpired,
    onStopImpersonation,
    onSetLoadTokens,
    credentials,
    rootPath,
    onOpenConnectAndSupportModal,
    onLogOut,
    onThemeSwitch,
}) => {
    const [open, setOpen] = useState(true);
    const [bannersHeight, setBannersHeight] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useResizeObserver({
        ref: containerRef,
        onResize: () => {
            if (containerRef.current) {
                setBannersHeight(containerRef.current.clientHeight);
            }
        },
    });

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    const handleThemeSwitch = (mode: ThemeMode): void => {
        saveLocalStorage(stitchThemeModeKey, mode);
        onThemeSwitch(mode);
    };

    return (
        <>
            <AppBanners
                isConnectedToFeedback={isConnectedToFeedback}
                impersonating={impersonating}
                onStopImpersonation={onStopImpersonation}
                notificationBanner={notificationBanner}
                showRefreshSessionWarning={showSessionExpiredWarning && sessionExpired}
                onSetLoadTokens={onSetLoadTokens}
            />
            <StyledWrapper bannersheight={bannersHeight}>
                <CssBaseline />
                <StyledAppBar position="absolute" open={open} color={'inherit'}>
                    <StyledToolbar id="styled-toolbar">
                        <Tooltip title="Change theme mode">
                            <>
                                <DropdownMenu
                                    sx={{ marginRight: 1 }}
                                    dropdownItems={[
                                        {
                                            label: 'Light mode',
                                            name: 'Light',
                                            startIcon: <LightModeOutlinedIcon />,
                                            action: () => {
                                                handleThemeSwitch('light');
                                            },
                                        },
                                        {
                                            label: 'Dark mode',
                                            name: 'Dark',
                                            startIcon: <DarkModeOutlinedIcon />,
                                            action: () => {
                                                handleThemeSwitch('dark');
                                            },
                                        },
                                        {
                                            label: 'System default',
                                            name: 'System',
                                            startIcon: <LaptopOutlinedIcon />,
                                            action: () => {
                                                handleThemeSwitch('system');
                                            },
                                        },
                                    ]}
                                >
                                    <ContrastIcon sx={{ color: 'text.secondary' }} />
                                </DropdownMenu>
                            </>
                        </Tooltip>
                        <UserTools
                            showOrganizations={showOrganizations}
                            onLogOut={onLogOut}
                            credentials={credentials}
                        />
                    </StyledToolbar>
                </StyledAppBar>
                <StyledDrawer variant="permanent" open={open}>
                    <SideBarMenu
                        useRouter={useRouter}
                        onClose={handleDrawerClose}
                        onOpen={handleDrawerOpen}
                        open={open}
                        showDashboard={showDashboard}
                        showOrganizations={showOrganizations}
                        onOpenConnectAndSupportModal={onOpenConnectAndSupportModal}
                    />
                </StyledDrawer>
                <StyledInnerContent component="main">
                    {useRouter && (
                        <>
                            <Outlet />
                            {window.location.pathname === rootPath && (
                                <Navigate to={getBasePath() + (showDashboard ? 'dashboard' : 'templates')} />
                            )}
                        </>
                    )}
                </StyledInnerContent>
            </StyledWrapper>
        </>
    );
};

import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Organisations } from '../../data/report/organisations';
import { getBasePath } from '../../utils/path';
import { StyledTitleContainer } from './UsersWithWorkspacesReportPage';
import { PageContainer } from '../layout/PageComponents';

export interface OrganisationsReportPageProps {
    organisations: Organisations;
}

export const OrganisationsReportPage: React.FC<OrganisationsReportPageProps> = ({ organisations }) => {
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledTitleContainer>
                <Typography variant="h3">Teams</Typography>
            </StyledTitleContainer>

            <Box>
                <Typography>Total Teams: {organisations.length}</Typography>
            </Box>

            {organisations.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Team ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell>Plan Period</TableCell>
                            <TableCell>Plan Version</TableCell>
                            <TableCell>Workspaces</TableCell>
                            <TableCell>Members</TableCell>
                            <TableCell>Member Invites</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organisations.map((organisation) => (
                            <TableRow id={organisation.uid} key={organisation.uid}>
                                <TableCell>
                                    <Link href={`${getBasePath()}reports/team/${organisation.uid}`} target="_blank">
                                        {organisation.uid}
                                    </Link>
                                </TableCell>
                                <TableCell>{organisation.name}</TableCell>
                                <TableCell>{organisation.createdBy}</TableCell>
                                <TableCell>{organisation.plan ?? ''}</TableCell>
                                <TableCell>{organisation.planPeriod ?? ''}</TableCell>
                                <TableCell>{organisation.planVersion ?? ''}</TableCell>
                                <TableCell>{organisation.workspaces}</TableCell>
                                <TableCell>{organisation.members}</TableCell>
                                <TableCell>{organisation.memberInvites}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div>No Teams found</div>
            )}
        </PageContainer>
    );
};

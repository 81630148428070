import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material';
import { AppMainProps } from '../layout';

interface AvatarContainerProps {
    credentials: AppMainProps['credentials'];
    size?: 'regular' | 'large' | 'small';
}

interface OrgAvatarContainerProps {
    name: string;
    style?: 'round' | 'square';
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    backgroundClip: 'padding-box',
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.background.default}`,
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.secondary.contrastText,
}));

export const AvatarContainer: React.FC<AvatarContainerProps> = ({ credentials, size = 'regular' }) => {
    const { firstName, lastName, email } = credentials;

    const handleInitials = (): string => {
        if (firstName && lastName) {
            return `${firstName.substring(0, 1).toUpperCase()}${lastName.substring(0, 1).toUpperCase()}`;
        } else {
            return email?.substring(0, 1).toUpperCase();
        }
    };
    const initials = handleInitials();

    return (
        <StyledAvatar
            sx={
                size === 'large'
                    ? { width: 80, height: 80, fontSize: '1.875rem' }
                    : size === 'small'
                    ? { width: 30, height: 30 }
                    : {}
            }
        >
            {initials}
        </StyledAvatar>
    );
};

const StyledOrgAvatar = styled(StyledAvatar)(({ theme }) => ({
    backgroundClip: 'padding-box',
    backgroundColor: theme.palette.secondary.light,
    height: 52,
    marginRight: theme.spacing(1),
    width: 52,
}));

export const OrgAvatarContainer: React.FC<OrgAvatarContainerProps> = ({ name, style = 'round' }) => {
    return (
        <StyledOrgAvatar sx={style === 'square' ? { borderRadius: '12px' } : {}}>
            {name[0]?.toUpperCase() ?? ''}
        </StyledOrgAvatar>
    );
};

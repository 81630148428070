import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

export const googleManagedConnectionOpenDialogAction$ = monitor(
    'googleManagedConnectionOpenDialogAction$',
    new Subject<ConnectionDetails>()
);
export const googleManageConnectionCloseDialogAction$ = monitor(
    'googleManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const googleManageConnectionInitiateAuthorizeAction$ = monitor(
    'googleManageConnectionInitiateAuthorizeAction$',
    new Subject<string>()
);

export const googleSaveConnectionAction$ = monitor('googleSaveConnectionAction$', new Subject<string>());
export const googleConnectionCreatedAction$ = monitor('googleConnectionCreatedAction$', new Subject<string>());
export const googleConnectionSavedAction$ = monitor(
    'googleConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const googleManageConnectionInitiateSetupAction$ = monitor(
    'googleManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const googleManageConnectionDialogOpen$ = monitor(
    'googleManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const googleConnectionSaving$ = monitor('googleConnectionSaving$', new BehaviorSubject(false));
export const googleManageConnectionAuthorizeLoading$ = monitor(
    'googleManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const googleManageConnectionDetails$ = monitor(
    'googleManageConnectionDetails$',
    new BehaviorSubject<ConnectionDetails | undefined>(undefined)
);
export const googleManageConnectionDialogErrors$ = monitor(
    'googleManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

googleManagedConnectionOpenDialogAction$.subscribe((details) => {
    googleManageConnectionDialogErrors$.next(undefined);
    googleManageConnectionDialogOpen$.next(true);
    googleManageConnectionDetails$.next(details);
});

googleManageConnectionCloseDialogAction$.subscribe(() => {
    googleManageConnectionAuthorizeLoading$.next(false);
    googleManageConnectionDialogOpen$.next(false);
    googleManageConnectionDetails$.next(undefined);
});

googleConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

googleSaveConnectionAction$
    .pipe(
        map(async (name) => {
            googleConnectionSaving$.next(true);
            googleManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = googleManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Google connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveGoogleConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            message: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveGoogleConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                googleConnectionCreatedAction$.next(uid);
                                googleConnectionSavedAction$.next({ uid, connectionTypeUid });
                                googleManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, googleManageConnectionDialogErrors$, 'Google');
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, googleManageConnectionDialogErrors$, 'Google');
            }

            googleConnectionSaving$.next(false);
        })
    )
    .subscribe();

googleManageConnectionInitiateAuthorizeAction$
    .pipe(
        map(async (name) => {
            googleConnectionSaving$.next(true);
            googleManageConnectionAuthorizeLoading$.next(true);
            googleManageConnectionDialogErrors$.next(undefined);

            const baseUrl = configTopic$.value.connection?.google?.baseUrl;
            if (!baseUrl) {
                throw new Error('No Google Connector URL configured in meta');
            }
            const initiateUrl = `${baseUrl}/initiate`;

            const { uid, connectionType } = googleManageConnectionDetails$.value ?? {};

            if (!connectionType) {
                throw Error('Google Connector type not defined');
            }
            const connectionTypeUid = connectionType.uid;
            const connectionTypeName = connectionType.name.toLowerCase().replace(' ', '-');

            if (uid) {
                await saveConnection(uid, name);

                googleConnectionSavedAction$.next({ uid, connectionTypeUid });
                const response = await initiate({ connectionId: uid, connectionType: connectionTypeName }, initiateUrl);
                openConsentWindow(response.location, googleManageConnectionAuthorizeLoading$);
            } else {
                try {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    googleConnectionCreatedAction$.next(uid);
                    googleConnectionSavedAction$.next({ uid, connectionTypeUid });
                    googleManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    googleConnectionSaving$.next(false);
                    const response = await initiate(
                        { connectionId: uid, connectionType: connectionTypeName },
                        initiateUrl
                    );
                    openConsentWindow(response.location, googleManageConnectionAuthorizeLoading$);
                    connectionCreatedFirstTime$.next(true);
                } catch (e) {
                    handleManageConnectionError(e, googleManageConnectionDialogErrors$, 'Google');
                    googleManageConnectionAuthorizeLoading$.next(false);
                }
            }

            googleConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(googleConnectionSavedAction$);

const saveGoogleConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        googleConnectionSavedAction$.next({ uid, connectionTypeUid });
        googleManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, googleManageConnectionDialogErrors$, 'Google');
    }
};

import { BehaviorSubject, map, Subject } from 'rxjs';
import { selectedEnvironmentUid$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '.';
import { SaveEventListenerEvent } from '../../components/workspace/event-listener-details';
import { createEventListener, deleteEventListener, EventListener, saveEventListener } from '../../data/event-listener';
import { openEventListenerSetupDialog } from '../../utils/eventListener';
import { executeEventListener } from '../../utils/eventListener';
import { InformativeError } from '../../utils/repository';
import { saveScriptBundledOutput } from '../../utils/script';
import { apps$, appSelectorOpen$, loadingAppSelector$ } from '../apps';
import { promptQuestion } from '../confirm';
import { loggedInUserConnections$ } from '../connections';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import {
    saveWizardCreatedWorkspaceResourceAction$,
    updateWizardStepAction$,
    WizardState,
    wizardState$,
    WizardStep,
    wizardStep$,
    wizardWorkspaceDetails$,
} from '../wizard';
import { newScriptCreatedAction$ } from './script';
import { DeletionRequest } from './types';
import { loadWorkspaceResourcesWhenEventIsEmitted } from './utils';

export const createEventListenerAction$ = monitor('createEventListenerAction$', new Subject<CreateEventListener>());
export const eventListenerCreatedAction$ = monitor('eventListenerCreatedAction$', new Subject<string>());
export const navigateToEventListenerAction$ = monitor('navigateToEventListenerAction$', new Subject<string>());
export const saveEventListenerAction$ = monitor('saveEventListenerAction$', new Subject<SaveEventListenerEvent>());
export const eventListenerSavedAction$ = monitor(
    'eventListenerSavedAction$',
    new Subject<{ eventListenerUid: string; scriptUid?: string }>()
);
export const deleteEventListenerAction$ = monitor('deleteEventListenerAction$', new Subject<DeletionRequest>());
export const eventListenerDeletedAction$ = monitor('eventListenerDeletedAction$', new Subject<string>());

export const selectedEventListenerErrors$ = monitor(
    'selectedEventListenerErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const selectedEventListenerDetails$ = monitor(
    'electedEventListenerDetails$',
    new BehaviorSubject<EventListener | undefined>(undefined)
);
export const savingSelectedEventListener$ = monitor('savingSelectedEventListener$', new BehaviorSubject(false));
export const eventListenersBeingDeleted$ = monitor(
    'eventListenerBeingDeleted$',
    new BehaviorSubject<Record<string, boolean>>({})
);
export const triggerEventListenerAction$ = monitor('triggerEventListenerAction$', new Subject<string>());
export const eventListenerExecutionInProgress$ = monitor(
    'eventListenerExecutionInProgress$',
    new BehaviorSubject<Record<string, boolean>>({})
);

createEventListenerAction$
    .pipe(
        map(async (event) => {
            loadingAppSelector$.next(true);

            try {
                const { uid } = await createEventListener(
                    selectedWorkspaceUid$.value ?? '',
                    event.appUid,
                    event.eventListenerTypeUid
                );
                eventListenerCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while creating Event Listener', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to create Event Listener, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }

            loadingAppSelector$.next(false);
            appSelectorOpen$.next(false);
        })
    )
    .subscribe();

saveEventListenerAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (event) => {
            const connection = loggedInUserConnections$.value.find((el) => el.uid === event.connectionUid);

            const initiateSelectedEventListenersSave = async (): Promise<void> => {
                savingSelectedEventListener$.next(true);
                selectedEventListenerErrors$.next(undefined);

                try {
                    const { urlId, scriptUid, eventTypeCategory } = await saveEventListener(
                        event.uid,
                        selectedEnvironmentUid$.value ?? '',
                        event.eventTypeUid,
                        event.connectionUid,
                        event.scriptUid,
                        event.scriptName,
                        event.urlId
                    );

                    eventListenerSavedAction$.next({
                        eventListenerUid: event.uid,
                        scriptUid,
                    });
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Event Listener saved.',
                    });

                    const details = selectedEventListenerDetails$.value;
                    if (details && urlId) {
                        selectedEventListenerDetails$.next({
                            ...details,
                            eventTypeCategory,
                            eventTypeUid: event.eventTypeUid,
                            urlId,
                        });
                    }

                    openEventListenerSetupDialogIfNeeded(urlId);

                    if (scriptUid) {
                        newScriptCreatedAction$.next({ uid: scriptUid });
                    }
                } catch (e) {
                    if (e instanceof InformativeError) {
                        selectedEventListenerErrors$.next(e.message);
                    } else {
                        selectedEventListenerErrors$.next(
                            'Failed to save Event Listener, please try again, if the issue persists please contact support.'
                        );
                        console.error('Error while saving Event Listener', e);
                    }
                }

                savingSelectedEventListener$.next(false);
            };

            if ((connection && connection.authorized) || !connection) {
                await initiateSelectedEventListenersSave();
            } else {
                promptQuestion({
                    message:
                        'Are you sure you want to save the Event Listener without having authorized the related connection?',
                    onProceed: async () => await initiateSelectedEventListenersSave(),
                });
            }
        })
    )
    .subscribe();

const updateEventListenerExecutionStatus = (eventListenerUid: string, isInProgress: boolean): void => {
    eventListenerExecutionInProgress$.next({
        ...eventListenerExecutionInProgress$.value,
        [eventListenerUid]: isInProgress,
    });
};

deleteEventListenerAction$
    .pipe(
        map(async (event) => {
            const initiateEventListenerDelete = async (): Promise<void> => {
                eventListenersBeingDeleted$.next({
                    ...eventListenersBeingDeleted$.value,
                    [event.uid]: true,
                });

                try {
                    const response = await deleteEventListener(event.uid, event.ignoreWarnings);

                    if (!event.ignoreWarnings && response.warning) {
                        promptQuestion({
                            message: response.warning,
                            onProceed: () =>
                                deleteEventListenerAction$.next({
                                    ...event,
                                    ignoreWarnings: true,
                                }),
                        });
                    } else if (!response.warning) {
                        eventListenerDeletedAction$.next(event.uid);
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Event Listener deleted.',
                        });
                    }
                } catch (e) {
                    console.error('Error while deleting Event Listener', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to delete Event Listener, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }

                eventListenersBeingDeleted$.next({
                    ...eventListenersBeingDeleted$.value,
                    [event.uid]: false,
                });
            };

            if (!event.ignoreWarnings) {
                promptQuestion({
                    message: 'Are you sure you want to delete the Event Listener?',
                    onProceed: async () => await initiateEventListenerDelete(),
                });
            } else {
                await initiateEventListenerDelete();
            }
        })
    )
    .subscribe();

eventListenerCreatedAction$.subscribe((uid) => {
    if (
        selectedWorkspaceUid$.value === wizardWorkspaceDetails$.value?.workspaceUid &&
        wizardState$.value === WizardState.ACTIVE &&
        wizardStep$.value === WizardStep.ADD_EVENT_LISTENER
    ) {
        updateWizardStepAction$.next(WizardStep.SAVE_EVENT_LISTENER);
        saveWizardCreatedWorkspaceResourceAction$.next(uid);
    }
    navigateToEventListenerAction$.next(uid);
});

triggerEventListenerAction$
    .pipe(
        map(async (eventListenerUid) => {
            await triggerEventListener(selectedEnvironmentUid$.value ?? '', eventListenerUid);
        })
    )
    .subscribe();

loadWorkspaceResourcesWhenEventIsEmitted(eventListenerCreatedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(eventListenerSavedAction$);

eventListenerSavedAction$.subscribe(() => {
    const details = selectedEventListenerDetails$.value;
    if (details) {
        selectedEventListenerDetails$.next({
            ...details,
            selectedSharedConnection: undefined,
        });
    }
});

const openEventListenerSetupDialogIfNeeded = (urlId?: string): void => {
    if (urlId) {
        const details = selectedEventListenerDetails$.value;

        if (details) {
            const connectionType = (apps$.value ?? []).find((a) => a.uid === details.appUid)?.connectionType;

            if (connectionType) {
                openEventListenerSetupDialog(
                    {
                        ...details,
                        urlId,
                    },
                    connectionType
                );
            }
        }
    }
};

const triggerEventListener = async (environmentUid: string, eventListenerUid: string): Promise<void> => {
    const eventListener = selectedWorkspaceResources$.value.eventListeners.find(
        (eventListener) => eventListener.uid === eventListenerUid
    );

    if (eventListener?.script) {
        try {
            updateEventListenerExecutionStatus(eventListenerUid, true);
            await saveScriptBundledOutput(eventListener.script.uid, [], true);
            await executeEventListener(environmentUid, eventListenerUid);
        } catch (e) {
            if (e instanceof InformativeError) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: e.message,
                    noToast: true,
                });
            } else {
                console.error('Error while executing Event Listener', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to execute the Event Listener, try executing the Event Listener again, if the issue persists please contact support.`,
                    noToast: true,
                });
            }
        }
    }

    updateEventListenerExecutionStatus(eventListenerUid, false);
};

export type CreateEventListener = {
    appUid: string;
    eventListenerTypeUid: string;
};

import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormGroup from '@mui/material/FormGroup';
// import Checkbox from '@mui/material/Checkbox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Button } from '../buttons/Button';
import { FileUploader } from 'react-drag-drop-files';
import { userFeedbackDialogErrors$ } from '../../store/userFeedback';
import { DialogAlert } from '../dialog';
import { handleKeyUp } from '../../utils/handleKeyUp';
import { isFocused } from '../../utils/autoFocus';

interface SendProps {
    attachments: File[];
    canContact: boolean;
    email: string;
    message: string;
}
interface UserFeedbackProps {
    defaultEmail?: string;
    errors?: string;
    open?: boolean;
    saving?: boolean;
    onClose(): void;
    onSend(userFeedback: SendProps): void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .drop_area': {
        border: theme.constants.borderHidden,
        cursor: 'pointer',
        display: 'flex',
        marginBottom: theme.spacing(1),
        '&:hover': {
            border: theme.constants.borderDashedHover,
            borderRadius: theme.constants.borderRadius,
        },
    },
}));

const StyledMultilineTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: 'auto',
    },
});

const AttachmentContainerDiv = styled('div')(({ theme }) => ({
    alignItems: 'center',
    color: theme.palette.text.secondary,
    columnGap: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'repeat(3,min-content)',
    width: 300,
}));

const AttachmentLimit = styled('em')({
    gridColumn: '2 / 4',
});

export const UserFeedback: React.FC<UserFeedbackProps> = ({
    defaultEmail = '',
    errors,
    open = false,
    saving = false,
    onClose,
    onSend,
}) => {
    const [email, setEmail] = useState(defaultEmail);
    const [message, setMessage] = useState('');
    const [contact, setContact] = useState(true);
    const [attachments, setAttachments] = useState<File[]>([]);
    const [disabled, setDisabled] = useState(false);

    const messageRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        userFeedbackDialogErrors$.next(undefined);
        setDisabled(false);
        if (attachments.length > 0) {
            const sizeSum = attachments.map((attachment) => attachment.size).reduce((acc, size) => acc + size, 0);
            if (sizeSum > 5000000) {
                userFeedbackDialogErrors$.next('Total size of attachments exceeds the 5MB limit.');
                setDisabled(true);
            }
        }
    }, [attachments]);

    useEffect(() => {
        if (open === true) {
            setEmail(defaultEmail);
            setMessage('');
            setContact(false);
            setAttachments([]);
            setDisabled(false);
        }
    }, [open]);

    const handleSend = (): void => {
        onSend({
            email,
            message,
            attachments,
            canContact: contact,
        });
    };

    const handleDelete = (name: string): void => {
        setAttachments(attachments.filter((attachment) => attachment.name !== name));
    };

    const handleChange = (files: FileList): void => {
        const newAttachments = Array.from(files).filter(
            (file) => !attachments.find((attachment) => attachment.name === file.name)
        );
        if (newAttachments.length > 0) {
            setAttachments([...attachments, ...newAttachments]);
        }
    };

    const canSend = !saving && !disabled && !!email && !!message;

    return (
        <StyledDialog
            open={open}
            onKeyUp={(event) =>
                handleKeyUp({
                    event,
                    enterCondition: canSend && !isFocused(messageRef),
                    enterFn: handleSend,
                    escFn: onClose,
                })
            }
        >
            <DialogTitle variant="h6">Send Feedback</DialogTitle>
            <DialogContent>
                {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                <TextField
                    label="Your email"
                    placeholder="Enter your email"
                    variant="outlined"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    inputProps={{ maxLength: 100 }}
                />
                <StyledMultilineTextField
                    inputRef={messageRef}
                    label={message.length > 0 ? `Message ${message.length}/5000` : 'Message'}
                    placeholder="Enter a message"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={message}
                    required
                    onChange={(e) => setMessage(e.target.value)}
                    inputProps={{ maxLength: 5000 }}
                />
                <FileUploader multiple classes="drop_area" handleChange={handleChange}>
                    <AttachmentContainerDiv>
                        <AttachmentIcon />
                        <Typography variant="subtitle2" color="primary">
                            Attachments
                        </Typography>
                        <span>Optional</span>
                        <AttachmentLimit>5MB max upload</AttachmentLimit>
                    </AttachmentContainerDiv>
                </FileUploader>
                {attachments.length > 0 && (
                    <>
                        {attachments.map((attachment) => (
                            <Chip
                                label={attachment.name}
                                key={attachment.name}
                                variant="outlined"
                                onDelete={() => handleDelete(attachment.name)}
                            />
                        ))}
                    </>
                )}
                {/* <FormControl>
                    <FormGroup>
                        <FormControlLabel
                            label="Are you happy for us to contact you?"
                            control={<Checkbox onChange={(_event, checked) => setContact(checked)} checked={contact} />}
                        />
                    </FormGroup>
                </FormControl> */}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" data-hotspot="cancel" onClick={onClose}>
                    Cancel
                </Button>
                <Button busy={saving} disabled={!canSend} variant="contained" onClick={handleSend}>
                    Send
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

import {
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { UserFeedback } from '../../data/report/userFeedback';
import { StyledTitleContainer } from './UsersWithWorkspacesReportPage';
import { PageContainer } from '../layout/PageComponents';
import { getUserFeedbackAttachment } from '../../data/report/userFeedbackAttachment';
import { publishLocalFeedbackEventAction$ } from '../../store/feedback';

export interface UserFeedbackReportPageProps {
    feedback?: UserFeedback;
}

export const UserFeedbackReportPage: React.FC<UserFeedbackReportPageProps> = ({ feedback }) => {
    const handleAttachment = async (uid: string, filename: string): Promise<void> => {
        if (uid && filename) {
            const url = await getUserFeedbackAttachment({ userFeedbackUid: uid, attachmentName: filename });
            const response = await fetch(url);

            if (response.ok) {
                window.open(url, '_blank');
            } else {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to download attachment: ${filename}`,
                    toastOptions: {
                        autoClose: 10000,
                    },
                });
            }
        }
    };

    const sortedFeedback = feedback?.sort(
        (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledTitleContainer>
                <Typography variant="h3">User Feedback</Typography>
            </StyledTitleContainer>
            <TableContainer>
                <Table component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell sx={{ width: '700px' }}>Feedback</TableCell>
                            <TableCell>Attachment</TableCell>
                            <TableCell>Created Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedFeedback?.map((x) => {
                            const message = x?.message.match(/.*[^\n]/g);
                            return (
                                <TableRow>
                                    <TableCell>{x?.email}</TableCell>
                                    <TableCell>
                                        {message && message?.map((y) => <Typography>{y}</Typography>)}
                                    </TableCell>
                                    <TableCell>
                                        {x.userFeedbackAttachments.map((e) => (
                                            <>
                                                <Link
                                                    onClick={() => {
                                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                        handleAttachment(x!.uid, e!.filename!);
                                                    }}
                                                >
                                                    {e?.filename}
                                                </Link>
                                                <br />
                                            </>
                                        ))}
                                    </TableCell>
                                    <TableCell>{new Date(x?.createdDate).toLocaleString('en-GB')}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </PageContainer>
    );
};

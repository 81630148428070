import { useState } from 'react';
import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { ScheduledTriggerDetails } from '../../components/workspace/scheduled-trigger-details';
import {
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '../../store/workspace';
import {
    createScriptForScheduledTriggerAction$,
    creatingScriptForScheduledTrigger$,
    saveScheduledTriggerAction$,
    savingSelectedScheduledTrigger$,
    selectedScheduledTriggerDetails$,
    selectedScheduledTriggerErrors$,
} from '../../store/workspace/scheduled-trigger';
import { scriptCreatedForScheduledTriggerAction$ } from '../../store/workspace/script';
import { newDeploymentCreatedAction$ } from '../../store/workspace/deployment';
import { getBasePath } from '../../utils/path';

export const ScheduledTriggerDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const details = useObservableState(selectedScheduledTriggerDetails$);
    const errors = useObservableState(selectedScheduledTriggerErrors$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const saving = useObservableState(savingSelectedScheduledTrigger$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    const [createdScriptUid, setCreatedScriptUid] = useState<string | undefined>(undefined);

    const handleCancel = (): void => {
        navigate({ to: '../../' });
        selectedWorkspaceSelectedResource$.next(undefined);
    };

    useSubscription(scriptCreatedForScheduledTriggerAction$, ({ uid, name }) => {
        creatingScriptForScheduledTrigger$.next(false);
        selectedWorkspaceResources$.next({
            ...workspaceResources,
            scripts: [...workspaceResources.scripts, { name, uid }],
        });
        setCreatedScriptUid(uid);
    });

    useSubscription(newDeploymentCreatedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../../' });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}/workspace/${selectedWorkspaceUid$.value}/environment/${
                        selectedEnvironmentUid$.value
                    }/scheduled/${details?.uid}`,
                }),
            100
        );
    });

    return (
        <ScheduledTriggerDetails
            uid={details?.uid ?? ''}
            errors={errors}
            saving={saving}
            warnings={details?.warnings}
            cronExpression={details?.cronExpression}
            scripts={workspaceResources.scripts}
            createdScriptUid={createdScriptUid}
            savedScriptUid={details?.scriptUid}
            actualLastScheduledDate={
                details?.actualLastTriggerDate ? new Date(details.actualLastTriggerDate) : undefined
            }
            nextScheduledDates={details?.nextScheduledDates.map((t) => new Date(t)) ?? []}
            remnantEnvironments={details?.remnantEnvironments ?? []}
            onCancel={handleCancel}
            onSave={(event) => saveScheduledTriggerAction$.next(event)}
            onCreateNewScript={() => createScriptForScheduledTriggerAction$.next()}
            workspaceLocked={!!selectedWorkspace?.locked}
        />
    );
};

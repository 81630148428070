import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '../../buttons/Button';
import { DialogAlert } from '../../dialog';
import { Dropdown } from '../../dropdown/Dropdown';
import { InfoIcon } from '../../icons/InfoIcon';
import { PageContainer, PageSubTitle } from '../../layout';
import { DeletionRequest } from '../../../store/workspace/types';

export interface ApiHandlerDetailsProps {
    connections?: {
        authorized?: boolean;
        icon: JSX.Element;
        name: string;
        value: string;
    }[];
    createdConnectionUid?: string;
    errors?: string;
    hasImplicitlySharedConnectionAttached?: boolean;
    path?: string;
    remnantEnvironments?: {
        environmentName: string;
        deploymentVersion: string;
    }[];
    saving?: boolean;
    selectedConnectionUid?: string;
    uid: string;
    wizardWarning?: boolean;
    workspaceLocked?: boolean;
    onCancel: (deleteEvent: DeletionRequest) => void;
    onNewConnection: () => void;
    onSave: (saveEvent: SaveApiHandlerEvent) => void;
    onWizardWarningClose: () => void;
}

export interface SaveApiHandlerEvent {
    connectionUid?: string;
    path?: string;
    uid: string;
}

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1.5),
}));

const StyledInnerContainer = styled('div')(({ theme }) => ({
    maxWidth: '340px',
    paddingTop: theme.spacing(1),
}));

const StyledInputAdornment = styled(InputAdornment)({
    height: 20.125,
    lineHeight: 1.5,
    marginRight: 0,
    padding: 0,
    '& p': {
        alignItems: 'center',
        display: 'flex',
        height: 20.125,
    },
});

const StyledInputContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

export const ApiHandlerDetails: React.FC<ApiHandlerDetailsProps> = ({
    connections = [],
    createdConnectionUid,
    errors,
    hasImplicitlySharedConnectionAttached = false,
    path,
    remnantEnvironments = [],
    saving = false,
    selectedConnectionUid,
    uid,
    wizardWarning = false,
    workspaceLocked = false,
    onCancel,
    onNewConnection,
    onSave,
    onWizardWarningClose,
}) => {
    const [currentSelectedConnectionUid, setCurrentSelectedConnectionUid] = useState<string | undefined>(
        createdConnectionUid ?? selectedConnectionUid
    );
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => setCurrentPath(path ?? ''), [path]);
    useEffect(() => setCurrentSelectedConnectionUid(createdConnectionUid), [createdConnectionUid]);
    useEffect(() => setCurrentSelectedConnectionUid(selectedConnectionUid), [selectedConnectionUid]);

    const isConnectionAuthorized = currentSelectedConnectionUid
        ? Boolean(connections.find((con) => con.value === currentSelectedConnectionUid)?.authorized)
        : true;

    const remnantEnvironment = remnantEnvironments
        .map(({ environmentName, deploymentVersion }) => `${environmentName} (${deploymentVersion})`)
        .join(', ');

    const hasUnsavedChanges = currentSelectedConnectionUid !== selectedConnectionUid || currentPath !== path;
    const canSave = !!currentSelectedConnectionUid && !!currentPath && !workspaceLocked && !saving && hasUnsavedChanges;

    const PATH_TOOLTIP =
        'Path is a unique identifier and is also used to import API Connection in scripts for consumption.';
    const CONNECTION_TOOLTIP =
        'Connector is used to make sure proper authentication headers are passed along when you use API Connection.';

    return (
        <>
            <PageContainer dataTestId="api-handler-details" sx={{ bgcolor: 'background.paper', minWidth: 500 }}>
                <PageSubTitle>Edit API Connection</PageSubTitle>
                {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                {path && !!selectedConnectionUid && selectedConnectionUid !== currentSelectedConnectionUid && (
                    <DialogAlert
                        severity="warning"
                        alertTitle="Warning"
                        text="Changing the Connector will take effect immediately after saving for selected Environment.
                            Changing rest of the fields requires deployment to take effect if the Environment already has Deployment attached."
                    />
                )}
                {!isConnectionAuthorized && (
                    <DialogAlert severity="warning" alertTitle="Warning" text="Selected Connector is not authorized." />
                )}
                {remnantEnvironments.length > 0 && (
                    <DialogAlert
                        severity="info"
                        alertTitle="Information"
                        text={`This is a remnant API Connection which means that it has been deleted but is still active in the following
                            Environments: ${remnantEnvironment}. You can continue editing the Connector mapping for this remnant API Connection.`}
                    />
                )}
                {hasImplicitlySharedConnectionAttached && (
                    <DialogAlert
                        severity="info"
                        alertTitle="Information"
                        text={`This API Connection is currently using implicitly shared connector owned by someone else, if you change it, you won't be able to change it back on your own.`}
                    />
                )}
                {wizardWarning && (
                    <DialogAlert
                        severity="info"
                        onClose={() => onWizardWarningClose()}
                        alertTitle="Information"
                        text="Please configure and save your API Connection to complete the step."
                    />
                )}
                <StyledInnerContainer>
                    {remnantEnvironments.length === 0 && (
                        <>
                            <StyledInputContainer>
                                <TextField
                                    required
                                    disabled={workspaceLocked}
                                    InputProps={{
                                        startAdornment: (
                                            <StyledInputAdornment position="start">./api/</StyledInputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        '& .MuiInputBase-input': {
                                            height: 20.125,
                                            lineHeight: 1.5,
                                            padding: '0 !important',
                                        },
                                    }}
                                    variant="outlined"
                                    label="Path"
                                    placeholder="path"
                                    value={currentPath}
                                    onChange={(event) => setCurrentPath(event.target.value)}
                                ></TextField>
                                <Tooltip title={PATH_TOOLTIP} placement="top">
                                    <StyledInfoIcon aria-label="info" />
                                </Tooltip>
                            </StyledInputContainer>
                        </>
                    )}
                    <StyledInputContainer>
                        <Dropdown
                            required
                            disabled={workspaceLocked}
                            items={connections}
                            label="Uses Connector"
                            onCreateNew={onNewConnection}
                            onSelect={(value) => setCurrentSelectedConnectionUid(value)}
                            selectedItem={currentSelectedConnectionUid}
                        />
                        <Tooltip title={CONNECTION_TOOLTIP} placement="top">
                            <StyledInfoIcon aria-label="info" />
                        </Tooltip>
                    </StyledInputContainer>
                    <DialogActions>
                        <Button
                            disabled={workspaceLocked}
                            onClick={() => onCancel({ uid, ignoreWarnings: true })}
                            variant="outlined"
                            data-hotspot="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            busy={saving}
                            disabled={!canSave}
                            onClick={() => {
                                onSave({
                                    uid: uid,
                                    connectionUid: currentSelectedConnectionUid,
                                    path: currentPath,
                                });
                            }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </StyledInnerContainer>
            </PageContainer>
        </>
    );
};
